import { display } from "@mui/system";
import {
  IconBoxChecked,
  IconBoxUnchecked,
} from "atoms/NewIconCheckbox/IconCheckBox";
import React from "react";

const Checkbox = ({ isToggle, onToggle }) => {
  return (
    <div onClick={onToggle} style={{ display: "flex", alignItems: "center" }}>
      {isToggle ? <IconBoxChecked /> : <IconBoxUnchecked />}
    </div>
  );
};

export default Checkbox;
