import { CircularProgress, circularProgressClasses } from "@mui/material";
import theme from "theme";

type LoaderProps = {
  size?: number;
  color?: string;
};

const Loader = ({
  size = 66,
  color = theme.primary.brand.blue["100"],
}: LoaderProps) => {
  return (
    <CircularProgress
      variant="indeterminate"
      sx={{
        color: color,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: "round",
        },
      }}
      size={size}
    />
  );
};

export default Loader;
