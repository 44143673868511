import styled from "@emotion/styled/macro";

export const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 767px) {
    width: auto;
    margin-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 64px;
  }
`;

export const Title = styled.p`
  margin: 0px;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  color: var(--main-color-text-title);
  margin-top: 50px;
  margin-bottom: 16px;
  width: 80%;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 15px;
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Description = styled.p`
  margin: 0px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--main-color-text-secondary);
  margin-bottom: 40px;
  width: 100%;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
    color: var(--main-color-text-title);
    margin-bottom: 0px;
  }
`;

export const Icon = styled.img`
  width: 150px;

  @media (max-width: 767px) {
    order: -1;
  }
`;
