import axios from "axios";
import {
  AuthorizationApi,
  RegistrationV3Api,
  CurrentUserApi,
  PasswordResetApi,
  CountryApi,
  AuthorizationWebApi,
  RegistrationWebAuthnApi,
  AuthorizationWebAuthnApi,
  ChangePinWebApi,
} from "./auth";
import {
  OtpApi,
  ChatApi,
  NotificationApi,
  ContentApi,
  PushNotificationApi,
} from "./notification";
import {
  CardApi,
  StatementApi,
  CardLimitsApi,
  PaymentApi,
  BankRateApi,
  RefillApi,
  AddressApi,
  InformationApi,
} from "./account";
import env from "../env";

export const axiosInstance = axios.create({
  withCredentials: true,
});

type Path = "auth" | "notification" | "account";

const createApiWithConfigs = <T>(api: new (...args: any) => T, path: Path) => {
  const url = `${env.REACT_APP_BASE_PATH}${path}`;
  return new api(undefined, url, axiosInstance);
};

// Authentication
const registrationApi = createApiWithConfigs(RegistrationV3Api, "auth");
const authWebApi = createApiWithConfigs(AuthorizationWebApi, "auth");
const currentUserApi = createApiWithConfigs(CurrentUserApi, "auth");
const resetApi = createApiWithConfigs(PasswordResetApi, "auth");
const pinChange = createApiWithConfigs(ChangePinWebApi, "auth");
const authApi = createApiWithConfigs(AuthorizationApi, "auth");
const countryApi = createApiWithConfigs(CountryApi, "auth");
const registrationWEBApi = createApiWithConfigs(
  RegistrationWebAuthnApi,
  "auth"
);
const authBiometryWebApi = createApiWithConfigs(
  AuthorizationWebAuthnApi,
  "auth"
);

const cardLimitsApi = createApiWithConfigs(CardLimitsApi, "account");
const statementApi = createApiWithConfigs(StatementApi, "account");
const bankRateApi = createApiWithConfigs(BankRateApi, "account");
const paymentApi = createApiWithConfigs(PaymentApi, "account");
const bankAddressApi = createApiWithConfigs(AddressApi, "account");
const cardApi = createApiWithConfigs(CardApi, "account");
const refillApi = createApiWithConfigs(RefillApi, "account");
const informationApi = createApiWithConfigs(InformationApi, "account");

const notificationsApi = createApiWithConfigs(NotificationApi, "notification");
const contentApi = createApiWithConfigs(ContentApi, "notification");
const chatApi = createApiWithConfigs(ChatApi, "notification");
const otpApi = createApiWithConfigs(OtpApi, "notification");
const pushApi = createApiWithConfigs(PushNotificationApi, "notification");

export {
  authApi,
  authWebApi,
  otpApi,
  pushApi,
  cardApi,
  statementApi,
  registrationApi,
  countryApi,
  currentUserApi,
  resetApi,
  registrationWEBApi,
  cardLimitsApi,
  chatApi,
  paymentApi,
  bankAddressApi,
  notificationsApi,
  authBiometryWebApi,
  contentApi,
  bankRateApi,
  pinChange,
  refillApi,
  informationApi,
};
