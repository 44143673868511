export const IconArrowRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
      {...props}
    >
      <path
        fill={props.color ?? "#739B67"}
        d="M12.672 7.00017L7.308 1.63617L8.722 0.222168L16.5 8.00017L8.722 15.7782L7.308 14.3642L12.672 9.00017H0.5V7.00017H12.672Z"
      />
    </svg>
  );
};
