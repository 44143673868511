import { useMemo } from "react";
import { useWindowSize } from "./useWindowSize";
import { SizeType, sizeThresholds } from "components/Grid/Grid";

const useSizeKey = (): SizeType => {
  const { width = 0 } = useWindowSize();

  return useMemo(() => {
    let size: SizeType = "xs";
    for (const threshold of sizeThresholds) {
      if (width >= threshold.minWidth) {
        size = threshold.key;
      } else {
        break;
      }
    }
    return size;
  }, [width]);
};

export default useSizeKey;
