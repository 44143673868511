import { Modal } from "molecules/Modal/Modal";
import Box from "@mui/material/Box";

import { useDispatch, useSelector } from "react-redux";
import { TransferCheck } from "./TransferCheck";
import { TransferConfirmed } from "./TransferConfirmed";
import { TransferOTP } from "./TransferOTP";
import { useEffect, useState } from "react";
import { SkeletonContainer } from "../../../../components";
import { RootState } from "store";
import { TransferError } from "./TransferError";
import { TRANSFER_ERROR_CODE } from "store/slices/transferSlice";
import { useIsMobile } from "hooks/useIsMobile";

type Props = {
  onClose: () => void;
  payloadModal: object;
};

export const TransfersSteps: React.FC<Props> = ({ onClose, payloadModal }) => {
  const {
    transfer: {
      createdTransfer,
      isLoading,
      isTransferChecked,
      confirmedTransfer,
      isTransferConfirmed,
      errorMessage,
      isConfirmLoading,
    },
  } = useSelector((state: RootState) => state);

  const [chooseCard, setChooseCard] = useState(false);
  const [tabIndex, setTabIndex] = useState<number | null>(null);
  const { isMobile } = useIsMobile();

  const showErrorScreen =
    errorMessage.code === TRANSFER_ERROR_CODE.PAYMENT_ERROR ||
    errorMessage.code === TRANSFER_ERROR_CODE.PAYMENT_RESPONSE_ERROR;

  useEffect(() => {
    if (createdTransfer && !isTransferChecked && !isLoading) {
      setTabIndex(0);
    } else if (isTransferChecked && !confirmedTransfer && !showErrorScreen) {
      setTabIndex(1);
    } else if (isTransferConfirmed) {
      setTabIndex(2);
    } else if (showErrorScreen) {
      setTabIndex(3);
    }
  }, [
    createdTransfer,
    isTransferChecked,
    isTransferConfirmed,
    confirmedTransfer,
    showErrorScreen,
    chooseCard,
    isLoading,
  ]);

  return (
    <Box height="100%">
      <SkeletonContainer
        isLoading={isLoading || isConfirmLoading}
        width="100%"
        height="500px"
      >
        {!isLoading && tabIndex === 0 && (
          <TransferCheck
            payloadModal={payloadModal}
            onClose={onClose}
            setChooseCard={setChooseCard}
            chooseCard={chooseCard}
            onTab={(idx) => setTabIndex(idx)}
          />
        )}
        {tabIndex === 1 && !showErrorScreen && (
          <TransferOTP onTab={(idx) => setTabIndex(idx)} />
        )}
        {tabIndex === 2 && (
          <TransferConfirmed
            payloadModal={payloadModal}
            onTab={(idx) => setTabIndex(idx)}
          />
        )}
        {tabIndex === 3 && <TransferError onTab={(idx) => setTabIndex(idx)} />}
      </SkeletonContainer>
    </Box>
  );
};
