import React from "react";

interface IconSnowProps extends React.SVGProps<SVGSVGElement> {}

export const IconSnow = (props: IconSnowProps) => {
  const { color = "#9DB6DB" } = props;
  // TODO
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2.5" y="2" width="20" height="20" rx="5" fill={color} />
      <path
        d="M12.5 6V18"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9876 7.28418C13.5386 7.56541 13.0242 7.71362 12.4999 7.71275C11.9757 7.71362 11.4612 7.56541 11.0122 7.28418"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0122 16.7137C11.4615 16.4333 11.9758 16.2852 12.4999 16.2852C13.024 16.2852 13.5383 16.4333 13.9876 16.7137"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 9.00098L7.5 15.001"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.172 10.9822C16.7135 10.7179 16.3329 10.3291 16.0709 9.85721C15.8088 9.38535 15.6753 8.8483 15.6845 8.30359"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.82764 13.0173C8.28608 13.2816 8.66671 13.6704 8.92874 14.1423C9.19077 14.6141 9.32432 15.1512 9.31508 15.6959"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.00098L17.5 15.001"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.82764 10.9822C8.28608 10.7179 8.66671 10.3291 8.92874 9.85721C9.19077 9.38535 9.32432 8.8483 9.31508 8.30359"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.172 13.0173C16.7135 13.2816 16.3329 13.6704 16.0709 14.1423C15.8088 14.6141 15.6753 15.1512 15.6845 15.6959"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
