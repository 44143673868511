import Box from "@mui/material/Box";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IconVisibilityOff } from "atoms/IconVisibilityOff/IconVisibilityOff";
import { IconVisibility } from "atoms/IconVisibility/IconVisibility";
import { forwardRef, useEffect, useState } from "react";
import { Label } from "molecules/Label/Label";
import theme from "theme";
import { useIsMobile } from "hooks/useIsMobile";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "&.MuiOutlinedInput-root": {
      color: "var(--main-color-text-title)",
      background: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
      height: (isMobile) => (isMobile ? "56px" : "64px"),
      paddingRight: 0,
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid var(--brand-color-hover)",
      },
    },
    "&.MuiOutlinedInput-root input": {
      padding: (isMobile) => (isMobile ? "0px 18px 0px 16px" : "0px 20px"),
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 500,
      background: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
    },
    "&.MuiOutlinedInput-root input::placeholder": {
      textAlign: "initial",
      color: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #000000",
    },
    "&.MuiOutlinedInput-root .MuiInputAdornment-root": {
      position: "absolute",
      right: "16px",
    },
    "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--error-color-illustration)",
    },
    "&.MuiOutlinedInput-root.Mui-focused": {
      background: "var(--brand-color-disabled)",
    },
    "&.MuiOutlinedInput-root.Mui-focused input": {
      background: "var(--brand-color-disabled)",
    },
  },
  errorRoot: {
    "&.Mui-error": {
      color: "var(--error-color-primary)",
      fontSize: 14,
      lineHeight: "20px",
    },
  },
}));

export const PasswordInput = forwardRef(function PasswordInput(
  {
    maxLength = 18,
    value,
    onChange,
    autoComplete = "off",
    label,
    name,
    error,
    placeholder = "",
    checkInputFocus,
    isProfile = false,
    ...props
  },
  ref
) {
  const { isMobile } = useIsMobile();
  const classes = useStyles(isMobile);
  const [showPassword, setShowPassword] = useState(false);
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getErrorMessage = (error) => {
    if (+error.code >= 500) {
      return error.message;
    } else {
      return error.message || error.comment;
    }
  };

  useEffect(() => {
    if (checkInputFocus) checkInputFocus(inputIsFocused);
  }, [inputIsFocused, checkInputFocus]);

  return (
    <Box getInputRef={ref} {...props}>
      <Label
        label={label}
        htmlFor={name}
        style={{
          color: theme.primary.gray[900],
          fontSize: isProfile ? "16px" : undefined,
        }}
      />
      <OutlinedInput
        onFocus={() => setInputIsFocused(true)}
        onBlur={() => setInputIsFocused(false)}
        classes={{ root: classes.inputRoot }}
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        error={!value ? false : !!error}
        autoComplete={autoComplete}
        inputProps={{
          maxLength: maxLength,
        }}
        endAdornment={
          value.length ? (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                aria-label="toggle password visibility"
                edge="end"
              >
                {showPassword ? <IconVisibility /> : <IconVisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
        id={name}
        fullWidth
      />
      {!!error && (
        <FormHelperText classes={{ root: classes.errorRoot }} error>
          {error || getErrorMessage(error)}
        </FormHelperText>
      )}
    </Box>
  );
});
