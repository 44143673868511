import { useState, useEffect, useMemo } from "react";
import theme from "theme";

const { sm, md, lg, xl } = theme.breakpoints.values;
const getDeviceConfig = (width) => {
  if (width < sm) {
    return "xs";
  } else if (width >= sm && width < md) {
    return "sm";
  } else if (width >= md && width < lg) {
    return "md";
  } else if (width >= lg && width < xl) {
    return "lg";
  } else if (width > xl) {
    return "xl";
  }
};

export const useIsMobile = () => {
  const [sizes, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    };
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  const size = useMemo(() => {
    return sizes;
  }, [sizes]);

  const isMobile = size === "xs" || size === "sm";

  return { isMobile, size };
};
