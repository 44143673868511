import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { authSelector } from "store";

export const PrivateRoute = ({ element: Element }) => {
  const { isAuthenticated, isConfirmed } = useSelector(authSelector);
  const isAuth = sessionStorage.getItem("isAuthenticated") === "true";

  const value = localStorage.getItem("isAuthed");

  if (value === null && !isAuthenticated && !isConfirmed && !isAuth) {
    return <Navigate to="/sign-in" replace={true} />;
  }

  if (value === "true" && !isAuthenticated && !isConfirmed && !isAuth) {
    return <Navigate to="/pin-enter" replace={true} />;
  }

  return <Element />;
};
