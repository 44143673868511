export type payloadCardModal = {
  id?: number;
  title?: string;
  actionType?: string;
  srcJpg?: string;
  subtitle?: string;
  textBtn?: string;
};

export const CARD_STATUS = {
  Active: "ACTIVE",

  Temporary: "TEMPORARY",
  Referral: "REFERRAL",

  Lost: "LOST",
  Stolen: "STOLEN",
  Compromised: "COMPROMISED",

  Closed: "CLOSED",
  Declared: "DECLARED",
  Restricted: "RESTRICTED",
  OpenDomestic: "OPEN_DOMESTIC",
  Expired: "EXPIRED",
  Blocked: ["LOST", "STOLEN", "COMPROMISED"],
};

export const CARD_FRONT_ACTION = {
  Temporary: "TEMPORARY",
  //относится блокировке
  Lost: "LOST",
  Change_Pin: "CHANGE_PIN",
  Change_Name: "CHANGE_NAME",
  Unfrozen: "UNFROZEN",
};

export const FROZEN_BLOCK_CARDS = [
  // TODO CLEANUP reliz 2
  // "TEMPORARY",
  // "REFERRAL",
  // "LOST",
  // "STOLEN",
  // "COMPROMISED",
  "CLOSED",
];
