import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import {
  IconCreditBlocked,
  IconCreditCardClosed,
  IconCreditCardFrozen,
} from "atoms/IconCards/IconCards";
import { CARD_STATUS } from "components/CardsInfo/model";

interface ICardProps {
  card: any;
}

const cardBg = (status: any) => {
  switch (status) {
    case CARD_STATUS.Temporary:
    case CARD_STATUS.Referral:
      return "#90C7F1";
    case CARD_STATUS.Lost:
    case CARD_STATUS.Stolen:
    case CARD_STATUS.Compromised:
      return "#E39090";
    case CARD_STATUS.Closed:
      return "#B8BBBF";
    default:
      return "#739B67";
  }
};
const renderIcon = (status: any) => {
  switch (status) {
    case CARD_STATUS.Temporary:
    case CARD_STATUS.Referral:
      return <IconCreditCardFrozen color="none" size={14} />;
    case CARD_STATUS.Lost:
    case CARD_STATUS.Stolen:
    case CARD_STATUS.Compromised:
      return <IconCreditBlocked color="white" width="8.5px" height="10px" />;
    case CARD_STATUS.Closed:
      return <IconCreditCardClosed size={12} />;
    default:
      return null;
  }
};

export const Card = ({ card }: ICardProps) => {
  const shortCardPan = card.cardPan.slice(-4);

  return (
    <Box
      sx={{
        width: "72px",
        height: "44px",
        borderRadius: "8px",
        padding: "4px 5px 6px",
        backgroundColor: cardBg(card.status),
      }}
    >
      <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
        <Typography
          variant="text_3"
          sx={{
            color: "#FFFFFF",
            position: "absolute",
            bottom: 0,
            left: 0,

            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          ··{shortCardPan}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
          }}
        >
          {renderIcon(card.status)}
        </Box>
      </Box>
    </Box>
  );
};
