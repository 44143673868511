import React from "react";

export const IconLoginChangeWarning = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="110" height="110" rx="55" fill="#F5F5F5" />
      <path
        d="M52.6699 33.8984C54.5944 30.5651 59.4056 30.5651 61.3301 33.8984L80.3827 66.8984C82.3072 70.2318 79.9016 74.3984 76.0526 74.3984H37.9474C34.0984 74.3984 31.6928 70.2318 33.6173 66.8984L52.6699 33.8984Z"
        fill="#739B67"
      />
      <rect
        x="55.1719"
        y="41.0234"
        width="4.18992"
        height="22.3463"
        rx="2.09496"
        fill="white"
      />
      <circle cx="57.2668" cy="66.8606" r="2.09496" fill="white" />
    </svg>
  );
};
