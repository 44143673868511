import styled from "@emotion/styled/macro";

interface Props {
  percent?: string;
  background?: string;
  opacity?: number;
  withPadding?: boolean;
  withMarginBottom?: boolean;
  cursor?: boolean;
  start?: boolean;
}

export const Container = styled.div`
  border-radius: 24px;
  margin-bottom: ${(props: Props) => (props.withMarginBottom ? "20px" : "0")};
  background: ${(props: Props) => props.background};
  padding: ${(props: Props) =>
    props.withPadding ? "16px 20px 15px 20px" : "0"};
`;

export const Sum = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--main-color-text-title);
`;

export const LinesContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Line = styled.div`
  height: 8px;
  border-radius: 33px;
  cursor: ${(props: Props) => (props.cursor ? "pointer" : "auto")};
  width: ${(props: Props) => props.percent};
  background: ${(props: Props) => props.background};
`;

export const CategoryItemsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

export const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 16px;
  background: var(--main-color-bg-widgets);
  cursor: pointer;
  opacity: ${(props: Props) => props.opacity};
`;

export const CategoryItemColor = styled.div`
  margin-right: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props: Props) => props.background};
`;

export const CategoryItemText = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: var(--main-color-text-title);
`;

export const CategoryItemSum = styled.p`
  margin: 0;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
`;

export const HeaderBlock = styled.div`
  position: relative;
`;

export const HeaderLeft = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--main-color-text-title);
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderMonth = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--main-color-text-secondary);
  margin-left: 10px;
`;

export const MoreButton = styled.div`
  padding: 8px 12px 8px 12px;
  border-radius: 10px;
  border: 1px solid var(--main-color-border-icon);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--main-color-text-title);
  &:hover {
    color: var(--brand-color-primary);
    border: 1px solid var(--brand-color-primary);
  }
`;

export const DateWrapper = styled.div`
  border-radius: 16px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  z-index: 1;
  background: var(--main-color-bg-widgets);
  width: 100%;
  max-width: 360px;
  top: 25px;
  box-shadow: 0px 4px 8px -2px #67798152;

  @media (max-width: 768px) {
    top: 35px;
    width: 90%;
  }
`;

export const HeaderSubtitleWrapper = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const Expense = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 13px;
  background: var(--main-color-text-title);
  color: white;
  margin-right: 16px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

export const ArrowsBlock = styled.div`
  display: flex;
  gap: 30px;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CustomArrowWrapper = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 0;
  top: -5px;
`;

export const PageTitle = styled.p`
  margin-top: 4px;
  margin-bottom: 20px;
  margin-left: 20px;
  line-height: 32px;
  font-weight: 500;
  font-size: 24px;
  color: var(--main-color-text-title);
`;
