import React from "react";

interface IconAlertTriangleProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  width?: string;
  height?: string;
}

export const IconAlertTriangle = (props: IconAlertTriangleProps) => {
  const { color = "#E64545", width = 20, height = 20 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3796 12.9196L12.6796 4.21959C12.0925 3.15854 10.9755 2.5 9.76292 2.5C8.55028 2.5 7.43331 3.15854 6.84625 4.21959L2.07125 12.9113C1.51025 13.9435 1.5341 15.1949 2.13404 16.2051C2.73397 17.2152 3.82138 17.8349 4.99625 17.8363H14.4462C15.6201 17.8363 16.7074 17.2189 17.3089 16.2108C17.9103 15.2027 17.9372 13.9526 17.3796 12.9196ZM9.04625 8.11959C9.04625 7.77441 9.32607 7.49459 9.67125 7.49459C10.0164 7.49459 10.2963 7.77441 10.2963 8.11959V10.6946C10.2963 11.0398 10.0164 11.3196 9.67125 11.3196C9.32607 11.3196 9.04625 11.0398 9.04625 10.6946V8.11959ZM9.67958 13.7446C10.0248 13.7446 10.3046 13.4648 10.3046 13.1196L10.2963 13.1113C10.2963 12.7707 10.0202 12.4946 9.67958 12.4946C9.33628 12.4991 9.05907 12.7763 9.05458 13.1196C9.05458 13.4648 9.33441 13.7446 9.67958 13.7446Z"
        fill={color}
      />
    </svg>
  );
};
