import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import { getSecondsPluralForms } from "utils";

import { useCounter } from "hooks/useCounter";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "store";
import {
  authActions,
  loginConfirm,
  retryOneTimePassword,
} from "store/slices/auth";
import { useIsMobile } from "hooks/useIsMobile";
import {
  systemActions,
  TabVariants,
  MobileTabVariants,
} from "store/slices/system";
import { useIsPWA } from "hooks/useIsPWA";
import { BackButtonArrow } from "components/BackButtonArrow";
import styles from "./style.module.css";
import { CodeInput } from "components/CodeInput";
import { Button } from "components/Button";

const Confirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pinCode, setCode] = useState<string>("");
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();

  const { isLoading, error, elseDevice } = useSelector(authSelector);

  const handleRetryOneTimePassword = () => {
    dispatch(retryOneTimePassword());
    restartCounter();
  };

  const handleConfirm = () => {
    dispatch(loginConfirm({ pinCode, isPWA }))
      //@ts-ignore
      .unwrap()
      .then(() => {
        const nextPage = elseDevice ? "/" : "/pin-code";
        const tabVariant = isMobile
          ? MobileTabVariants.main
          : TabVariants.transfersHub;
        navigate(nextPage, { replace: true });
        dispatch(systemActions.setActiveTab({ tab: tabVariant }));
      })
      .catch(() => {});
  };

  return (
    <div
      style={{
        paddingTop: isMobile ? "0px" : "150px",
        paddingBottom: isMobile ? "0px" : "150px",
      }}
    >
      <div className={styles.container}>
        <div className={styles.backBtn}>
          <BackButtonArrow onClick={() => navigate("/sign-in")} />
        </div>
        <div className={styles.titleBlock}>
          <p className={styles.title}>Подтверждение номера</p>
          <p className={styles.description}>Код из SMS</p>
        </div>
        <Box
          component="form"
          autoComplete="off"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <CodeInput
            length={4}
            setCode={setCode}
            error={Boolean(error)}
            clearError={() => dispatch(authActions.resetError())}
          />
          <Box
            display="flex"
            flexDirection="column"
            rowGap={count > 0 ? 20 : 12}
            width="100%"
            mt={40}
          >
            <Button
              onClick={handleConfirm}
              variant="primary"
              isLoading={isLoading}
              disabled={pinCode.length < 4}
              style={{ marginTop: "40px" }}
              title="Продолжить"
            />
            {count === 0 ? (
              <Button
                onClick={handleRetryOneTimePassword}
                variant="secondary"
                title="Отправить код повторно"
              />
            ) : (
              <Box minHeight={48} textAlign="center">
                <Typography
                  variant="text_5"
                  color="gray.b400"
                  textAlign="center"
                >
                  Вы сможете отправить код повторно
                  <br />
                  через{" "}
                  <Typography
                    variant="text_5"
                    color="blue.b400"
                    textAlign="center"
                  >
                    {count} {secondsWord}.
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Confirmation;
