import cn from "classnames";
import { Box } from "@mui/material";

// TODO: Переписать на TypeScript
export function IconContainer({ size, onClick, className, ...props }) {
  return (
    <Box
      style={{ width: size, height: size }}
      className={cn("flexCenter", { [className]: className })}
      onClick={onClick}
      {...props}
    >
      {props.children}
    </Box>
  );
}
