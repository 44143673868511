import { useDispatch } from "react-redux";
import { authActions, logout } from "../store/slices/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useResetStore } from "./useResetStore";
import { useIsPWA } from "./useIsPWA";

export const useAuthCommonActions = () => {
  const resetStore = useResetStore();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const isPWA = useIsPWA();
  const handleLogout = useCallback(() => {
    dispatch(logout(isPWA))
      //@ts-ignore
      .finally(() => {
        dispatch(authActions.setLogout());
        const deviceIdItem = localStorage.getItem("deviceId");
        const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
        localStorage.clear();
        if (deviceIdItem !== null) {
          localStorage.setItem("deviceId", deviceIdItem);
        }
        if (PWAdeviceIdItem !== null) {
          localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
        }
        sessionStorage.clear();
        nav("/sign-in", { replace: true });
        resetStore();
      });

    return true;
  }, [dispatch, nav, resetStore, isPWA]);
  return { handleLogout };
};
