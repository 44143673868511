import React from "react";

export const IconSquarePlus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3466 0.333374H12.6533C18.7358 0.333374 23.6666 5.26421 23.6666 11.3467V12.6534C23.6666 18.7359 18.7358 23.6667 12.6533 23.6667H11.3466C5.26415 23.6667 0.333313 18.7359 0.333313 12.6534V11.3467C0.333313 5.26421 5.26415 0.333374 11.3466 0.333374ZM12.875 12.875H16.6666C17.1499 12.875 17.5416 12.4833 17.5416 12C17.5416 11.5168 17.1499 11.125 16.6666 11.125H12.875V7.33337C12.875 6.85013 12.4832 6.45837 12 6.45837C11.5167 6.45837 11.125 6.85013 11.125 7.33337V11.125H7.33331C6.85006 11.125 6.45831 11.5168 6.45831 12C6.45831 12.4833 6.85006 12.875 7.33331 12.875H11.125V16.6667C11.125 17.15 11.5167 17.5417 12 17.5417C12.4832 17.5417 12.875 17.15 12.875 16.6667V12.875Z"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
