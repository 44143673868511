import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import CardInfoItem from "./CardInfoItem";

const CardsListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const CardsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
});

const CardsList = () => {
  const {
    cards: {
      allCards,
      account: { description },
    },
  } = useSelector((state: any) => state);

  return (
    <CardsListContainer>
      <Typography
        noWrap
        sx={{
          color: "var(--main-color-text-title)",
          width: "85%",
          fontSize: "18px",
          fontWeight: 500,
        }}
      >
        {description}
      </Typography>
      <CardsContainer>
        {allCards.map((cardInfo, index) => (
          <CardInfoItem
            cardInfo={cardInfo}
            key={cardInfo.cardId}
            index={index}
          />
        ))}
      </CardsContainer>
    </CardsListContainer>
  );
};

export default CardsList;
