import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { throttle } from "utils/throttle";

export const useScroll = () => {
  const [scrolled, setScrolled] = useState(false);
  const isMatch = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scroll = isMatch ? document.body.scrollTop : window.scrollY;
      setScrolled(scroll > 10);
    }, 100);

    window.addEventListener("scroll", handleScroll, { capture: true });

    return () => {
      window.removeEventListener("scroll", handleScroll, { capture: true });
    };
  }, [isMatch]);

  return scrolled;
};
