export enum ProfileModalType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  PASSWORD = "PASSWORD",
  PINCODE = "PINCODE",
  BIOMETRY = "BIOMETRY",
  NOTIFICATION = "NOTIFICATION",
  SHOWCARDS = "SHOWCARDS",
  news = "news",
  notifications = "notifications",
}
