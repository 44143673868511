import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ChatAttachmentDto } from "api/notification";
import { IconDocument } from "atoms/IconDocument/IconDocument";
import { IconImage } from "atoms/IconImage/IconImage";
import theme from "theme";

type FilesMessagesProps = {
  files: ChatAttachmentDto[];
  onClick: (file: ChatAttachmentDto) => () => void;
  user?: string;
};

const FilesMessages = ({ files, onClick, user }: FilesMessagesProps) => {
  if (!files.length) return null;

  return (
    <Box>
      {files.map((file) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={onClick(file)}
          key={file.id}
          display="flex"
          alignItems="center"
          gap={6}
        >
          {file.mimeType?.includes("image") ? <IconImage /> : <IconDocument />}
          <Typography
            typography="text_5"
            color={theme.primary.brand.blue[400]}
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: 150,
            }}
          >
            {file.fileName}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default FilesMessages;
