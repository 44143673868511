import { FC, useState } from "react";
import { Alert, Box } from "@mui/material";
import { Loader } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  biometryRegConfirm,
  checkIsBiometryKeyExist,
  getBiometryCredentials,
  regBiometryToken,
} from "store/slices/auth";
import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { useNavigate } from "react-router-dom";
import { authSelector } from "store";
import { profileActions } from "store/slices/profile";
import { useIsPWA } from "hooks/useIsPWA";
import { IconBiometryRegistration } from "atoms/IconBiometryRegistration/IconBiometryRegistration";
import styles from "./styles.module.css";
import { Button } from "components/Button";

export const Biometry: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPWA = useIsPWA();
  const [isToken, setIsToken] = useState(false);
  const { isLoading } = useSelector(authSelector);
  const setItemToStorage = () => {
    localStorage.setItem("useBiometry", "true");
  };

  const biometryAgree = () => {
    dispatch(regBiometryToken(isPWA))
      //@ts-ignore
      .unwrap()
      .then((data) => {
        dispatch(getBiometryCredentials(data.registrationAddToken))
          //@ts-ignore
          .unwrap()
          .then(async (data) => {
            const message = data.publicKeyCredentialCreationOptions;
            const optionsJson = parseCreationOptionsFromJSON({
              publicKey: message,
            });
            // @ts-ignore
            optionsJson.publicKey.extensions.appidExclude =
              // @ts-ignore
              optionsJson.publicKey.extensions.appidExclude.id;
            try {
              const credential = await create(optionsJson);
              const registrationToken = data.registrationId;
              //@ts-ignore
              dispatch(biometryRegConfirm({ registrationToken, credential }))
                //@ts-ignore
                .unwrap()
                .then(() => {
                  setItemToStorage();
                  navigate("/push-registration", { replace: true });
                  dispatch(profileActions.setBiometryConnected("connected"));
                })
                .catch((e) => {});
            } catch (err: any) {
              dispatch(checkIsBiometryKeyExist())
                // @ts-ignore
                .unwrap()
                .then((res) => {
                  if (res) {
                    setItemToStorage();
                    dispatch(profileActions.setBiometryConnected("connected"));
                  } else {
                    dispatch(profileActions.setBiometryConnected("error"));
                  }
                  navigate("/push-registration", { replace: true });
                })
                .catch((err) => {
                  if (err.response.data.code === "WRONG_DEVICE") {
                    dispatch(
                      authActions.setAnotherDeviceError({
                        title: err.response.data.title,
                        description: err.response.data.subtitle,
                      })
                    );
                    navigate("/login-error");
                  }
                });
            }
            // }
          });
      });
  };

  return (
    <div className={styles.container}>
      {isLoading && (
        <Box
          width="100%"
          height="calc(100% - 150px)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "azure",
            opacity: 0.4,
            zIndex: 10,
          }}
        >
          <Loader />
        </Box>
      )}
      {isToken && (
        <Alert
          color="info"
          action={
            <Button
              variant="primary"
              onClick={() => {
                setIsToken(false);
              }}
              title="Понятно"
            />
          }
        >
          У вас уже есть ключ биометрии. Вы будете перенаправлены на главную
          страницу
        </Alert>
      )}
      <IconBiometryRegistration />
      <p className={styles.title}>
        Использовать
        <br />
        биометрические данные
        <br />
        для входа в приложение?
      </p>
      <p className={styles.description}>
        Вы сможете быстро и безопасно
        <br />
        входить в личный кабинет с помощью
        <br />
        Face и Touch ID
      </p>
      <Button
        onClick={biometryAgree}
        variant="primary"
        isLoading={false}
        disabled={false}
        title="Использовать"
      />
      <Button
        onClick={() => {
          navigate("/push-registration", { replace: true });
        }}
        variant="secondary"
        isLoading={false}
        disabled={false}
        style={{ marginTop: 10 }}
        title="Напомнить позже"
      />
    </div>
  );
};
