import React from "react";

interface IconTransferProps extends React.SVGProps<SVGSVGElement> {}

export const IconTransfer = (props: IconTransferProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#739B67"
        fillRule="evenodd"
        d="M13.4 18.334H6.59c-2.941 0-4.925-1.893-4.925-4.717V6.392c0-2.825 1.984-4.725 4.925-4.725h6.81c2.95 0 4.933 1.9 4.933 4.725v7.225c0 2.824-1.983 4.717-4.933 4.717Zm-1.508-8.959H6.6a.624.624 0 0 0-.625.625c0 .35.275.625.625.625h5.292l-2.067 2.059a.639.639 0 0 0-.183.441c0 .158.066.317.183.442a.629.629 0 0 0 .883 0l3.142-3.125a.645.645 0 0 0 0-.883l-3.142-3.125a.629.629 0 0 0-.883 0 .639.639 0 0 0 0 .891l2.067 2.05Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
