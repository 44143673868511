/* eslint-disable no-console */
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { Home, SignUp, SignIn } from "pages";
import { NotFound } from "pages/not-found";
import { PrivateRoute } from "navigation/PrivateRoute";
import { PrivateRoutePinEnter } from "navigation/PrivateRoutePinEnter";
import { Confirmation } from "./organisms/Confirmation";
import { injectStore } from "./common";
import { authActions } from "./store/slices/auth";
import {
  alertsSelector,
  authSelector,
  systemSelector,
  store,
  redirectSelector,
} from "store";
import { Header } from "./organisms/Header/Header";
import Footer from "./components/Footer/Footer";
import UnregisteredHeader from "./organisms/UnregisteredHeader";
import "./App.module.sass";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Hidden, useMediaQuery } from "@mui/material";
import Alert from "./components/Alert/Alert";
import HugeAlert from "./components/HugeAlert/HugeAlert";
import { Modal } from "./molecules/Modal/Modal";
import { LogoutModal } from "./organisms/LogoutModal";
import { useAuthCommonActions } from "./hooks";
import { setHugeError } from "store/slices/alerts";
import { MobileTabs } from "widgets";
import { useIsMobile } from "hooks/useIsMobile";
import {
  NotificationType,
  getNotificationTypeFromUrl,
} from "utils/pwaPushDeepLink";
import { PinCode } from "organisms/PinCode";
import { Biometry } from "organisms/Biometry";
import { RefillInfo } from "pages/refill-info";
import { Push } from "./organisms/Push";
import { LoginError } from "organisms/LoginError/LoginError";
import { redirectAction } from "./store/slices/redirect";
import useSizeKey from "hooks/useSize";
import { TabVariants } from "store/slices/system";
import { AnotherPages } from "store/slices/system/slice";
import { notificationActions } from "store/slices/notifications";
import { getDateForNotification } from "utils/getDateForNotification";

injectStore(store);
const CommonContainer = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const size = useSizeKey();
  const {
    isAuthenticated,
    isConfirmed,
    isBurgerActive,
    isLogoutVisible,
    isInitialPin,
  } = useSelector(authSelector);
  // const resetStore = useResetStore();

  const {
    errorMessage,
    errorTitle,
    isOpen,
    isHugeAlertOpen,
    errorHugeMessage,
    errorHugeTitle,
  } = useSelector(alertsSelector);

  const { handleLogout } = useAuthCommonActions();
  const { bodyMaxHeight, activeTab } = useSelector(systemSelector);
  const isAuth = sessionStorage.getItem("isAuthenticated") === "true";
  const { isMobile } = useIsMobile();
  const handleToggle = useCallback(() => {
    dispatch(authActions.setIsLogoutVisible());
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(authActions.setIsAuthenticated());
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    dispatch(
      setHugeError({
        isOpen: false,
        message: "",
        title: "",
      })
    );
  }, [activeTab, dispatch]);

  const isHistoryActive = activeTab === "history" && isMobile;
  const hideElement =
    location.pathname === "/biometry-registration" ||
    location.pathname === "/pin-code" ||
    location.pathname.includes("/refill-info") ||
    location.pathname.includes("/404") ||
    location.pathname === "/pin-enter" ||
    location.pathname === "/push-registration";

  const isWayBack =
    location.pathname === "/pin-enter" || location.pathname === "/pin-code";

  const displayTransfersBlock = activeTab !== AnotherPages.transfers;

  const currentUrl = window.location.href;
  useEffect(() => {
    const notificationType = getNotificationTypeFromUrl(currentUrl);
    switch (notificationType) {
      case NotificationType.MESSAGE:
        localStorage.setItem("pushType", "MESSAGE");
        break;
      case NotificationType.NEWS:
        localStorage.setItem("pushType", "NEWS");
        break;
      case NotificationType.NOTIFICATION:
        localStorage.setItem("pushType", "NOTIFICATION");
        break;
      default:
        break;
    }
  }, [currentUrl, dispatch]);

  const redirectUrl = useSelector(redirectSelector);

  useEffect(() => {
    if (redirectUrl.redirectUrl) {
      navigate(redirectUrl.redirectUrl);
      dispatch(redirectAction.setRedirectUrl({ redirectUrl: null }));
    }
  }, [dispatch, navigate, redirectUrl.redirectUrl]);

  useEffect(() => {
    const broadcast = new BroadcastChannel("sw-messages");

    broadcast.onmessage = (event) => {
      const { type, text } = event.data;
      if (type !== "MESSAGE" && type !== "NEWS") {
        dispatch(
          notificationActions.setNotification([
            {
              dateTime: new Date().toISOString(),
              iconType: "NOTIFY",
              id: crypto.randomUUID(),
              notificationDateTime: getDateForNotification(),
              notificationType: "NOTIFICATION",
              pushFromFb: true,
              readStatus: false,
              subTitle: text,
            },
          ])
        );
      }
    };

    return () => {
      broadcast.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDesktop = useMediaQuery("(min-width:1024px)");
  const isTablet = useMediaQuery("(min-width:980px)");

  let padTop = "0";
  let borderRadius = "20px 20px 0 0";

  if (location.pathname !== "/") {
    padTop = isDesktop ? "88px" : "68px";
  } else {
    padTop = isDesktop ? "88px" : "0";

    if (activeTab !== TabVariants.main && !isDesktop) {
      borderRadius = "0";
    }
    if (activeTab == TabVariants.main) {
      padTop = "191px";
      if (isTablet) padTop = "68px";
      if (isDesktop) padTop = "88px";
    }
  }

  const isRegister = !hideElement && isAuthenticated && isConfirmed;

  return (
    <Box
      sx={{
        overflow: isBurgerActive ? "hidden" : "unset",
        maxHeight: bodyMaxHeight,
        backgroundColor: isRegister ? "#D9E3CC" : "transparent",

        paddingTop: padTop,
      }}
    >
      {isRegister ? <Header /> : <UnregisteredHeader />}
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: borderRadius,
        }}
      >
        <Box
          maxWidth={1440}
          margin="0 auto"
          component="main"
          height={{
            xs: isHistoryActive && !isMobile && "calc(100svh - 162px)",
          }}
        >
          {!isWayBack ? (
            <Alert isOpen={isOpen} title={errorTitle} message={errorMessage} />
          ) : errorTitle === "Срок действия сессии истек" ? (
            <Alert isOpen={isOpen} title={errorTitle} message={errorMessage} />
          ) : null}
          <HugeAlert
            isOpen={isHugeAlertOpen}
            title={errorHugeTitle}
            message={errorHugeMessage}
          />
          {children}
          <Modal
            title="Выход из аккаунта"
            isOpen={isLogoutVisible}
            onClose={handleToggle}
            callback={handleToggle}
            buttonVisible={false}
          >
            <LogoutModal
              onClick={() => {
                handleToggle();
                handleLogout();
              }}
              onCancel={handleToggle}
            />
          </Modal>
        </Box>
      </Box>
      {!isHistoryActive && !isRegister && <Footer />}
      {!hideElement &&
        isAuthenticated &&
        isConfirmed &&
        displayTransfersBlock && (
          <Hidden lgUp>
            <MobileTabs />
          </Hidden>
        )}
    </Box>
  );
};

const App = () => (
  <CommonContainer>
    <Routes>
      <Route path="/" element={<PrivateRoute element={Home} />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="sign-in" element={<SignIn />} />
      <Route path="confirm" element={<Confirmation />} />
      <Route path="login-error" element={<LoginError />} />
      <Route path="pin-code" element={<PinCode />} />
      <Route path="biometry-registration" element={<Biometry />} />
      <Route path="push-registration" element={<Push />} />
      <Route path="pin-enter" element={<PrivateRoutePinEnter />} />
      <Route path="refill-info/:id" element={<RefillInfo />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </CommonContainer>
);

export default App;
