import { Box, BoxProps, Typography } from "@mui/material";
import Planet from "./Planet.png";

interface ILogoProps extends BoxProps {}

export const Logo = (props: ILogoProps) => {
  const { ...restProps } = props;

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      display="flex"
      sx={{ cursor: "pointer" }}
      {...restProps}
    >
      <img src={Planet} width={24} height={24} style={{ marginRight: 6 }} />
      <Typography
        variant="h1"
        color="var(--brand-color-primary)"
        fontSize={20}
        fontWeight={600}
      >
        Лучи
      </Typography>
    </Box>
  );
};
