import styled from "@emotion/styled/macro";

export const EmptyStyled = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
  width: 327px;
  h2 {
    color: var(--main-color-text-title);
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    margin: 0px;
    margin-top: 22px;
  }
  h4 {
    color: var(--main-color-text-title);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 0px;
    margin-top: 8px;
  }
`;
