import React from "react";

interface IconBellNotificationProps extends React.SVGProps<SVGSVGElement> {}

export const IconBellNotification = (props: IconBellNotificationProps) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0055 12.6417C17.0079 13.126 17.2208 13.5853 17.5888 13.9001C18.8471 15.0251 17.9555 16.9501 16.1721 16.9501H13.0055C12.595 18.0704 11.5319 18.8179 10.3388 18.8251C9.14181 18.8302 8.07231 18.0782 7.67214 16.9501H4.50547C2.72214 16.9501 1.83047 15.0251 3.08881 13.9001C3.45683 13.5853 3.66975 13.126 3.67214 12.6417V8.61675C3.67214 5.33341 6.65547 2.66675 10.3388 2.66675C14.0221 2.66675 17.0055 5.33341 17.0055 8.61675V12.6417ZM9.07214 16.9501C9.3754 17.3426 9.8428 17.5732 10.3388 17.5751C10.8231 17.5631 11.2761 17.3335 11.5721 16.9501H9.07214Z"
        fill="#739B67"
      />
      <circle cx="16.6663" cy="3.50008" r="3.33333" fill="#DEB16E" />
    </svg>
  );
};
