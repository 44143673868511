import React from "react";

export const IconSuccessDesktop = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="426"
      height="150"
      viewBox="0 0 426 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="426" height="150" rx="15" fill="#F5F5F5" />
      <path
        d="M297 110.5C297 114.918 293.418 118.5 289 118.5H137C132.582 118.5 129 114.918 129 110.5V42.5038C129 38.087 132.582 34.5058 136.999 34.5043C193.929 34.4848 247.278 34.5396 288.997 34.5137C293.418 34.5109 297 38.0938 297 42.5149V110.5Z"
        fill="white"
      />
      <path
        d="M278.936 90.0254H258.114C256.788 90.0254 255.712 91.9377 255.712 94.2966C255.712 96.6555 256.788 98.5678 258.114 98.5678H278.936C280.263 98.5678 281.339 96.6555 281.339 94.2966C281.339 91.9377 280.263 90.0254 278.936 90.0254Z"
        fill="#E9E9E9"
      />
      <path
        d="M210.153 90.0254H147.508C145.15 90.0254 143.237 91.9377 143.237 94.2966C143.237 96.6555 145.15 98.5678 147.508 98.5678H210.153C212.511 98.5678 214.424 96.6555 214.424 94.2966C214.424 91.9377 212.511 90.0254 210.153 90.0254Z"
        fill="#E9E9E9"
      />
      <path
        d="M213 65C226.255 65 237 54.2548 237 41C237 27.7452 226.255 17 213 17C199.745 17 189 27.7452 189 41C189 54.2548 199.745 65 213 65Z"
        fill="#739B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.842 33.1752C224.298 32.7196 225.036 32.7196 225.492 33.1752C225.913 33.5958 225.945 34.2575 225.589 34.7152L225.492 34.8251L210.683 49.6344C210.262 50.0549 209.6 50.0873 209.143 49.7314L209.033 49.6344L200.509 41.1103C200.053 40.6547 200.053 39.916 200.509 39.4604C200.929 39.0398 201.591 39.0075 202.049 39.3633L202.159 39.4604L209.857 47.159L223.842 33.1752Z"
        fill="white"
      />
    </svg>
  );
};
