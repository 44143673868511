import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: 767px) {
    gap: 0;
  }
`;
export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:not(:last-child) {
    border-bottom: 1px solid #ebedf3;
  }
`;

export const Wrapper = styled.div`
  margin-top: 45px;
  width: 1200px;

  @media (max-width: 1440px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: var(--main-color-notification-title);
  width: 100%;
  text-align: center;

  @media (max-width: 767px) {
    text-align: left;
    padding: 20px 20px 44px;
  }
`;

export const Description = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--main-color-text-secondary);
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 16px;
  }
`;

export const Image = styled.img`
  margin: 0;
  width: 100%;
  max-width: 426px;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    width: 150px;
    height: 150px;
  }
`;

export const InfoBlock = styled.div`
  padding: 12px 0px;
  width: 100%;
  max-width: 426px;
`;

export const InfoBlockTitle = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8b8c88;
`;

export const InfoBlockDescription = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #454a3f;
  word-break: break-all;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
