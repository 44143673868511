export const getEndOfDay = (dateNow: Date) => {
  return new Date(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate(),
    23,
    59,
    59
  ).toISOString();
};
