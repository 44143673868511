export const getSortedCountries = (array: any) => {
  if (array.length > 0) {
    const firstElement = array.find((ct: any) => ct.name === "Россия");

    let arrayCopy = [...array];
    arrayCopy.sort((f: any, s: any) => {
      if (f.name < s.name) {
        return -1;
      }
      if (f.name > s.name) {
        return 1;
      }
      return 0;
    });

    const resultArray = arrayCopy.filter((ct: any) => ct.name !== "Россия");
    resultArray.unshift(firstElement);

    return resultArray;
  }
  return [];
};
