import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IconCardAccount } from "atoms/IconCardAccount/IconCardAccount";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAccountInfoOpen } from "store/slices/cardsSlice";
import theme from "theme";
import RightIcon from "./assets/RightIcon.png";

function CardAccount() {
  const dispatch = useDispatch();
  const [activeIndexItem, setActiveIndexItem] = useState(false);
  const { isMobile } = useIsMobile();
  const {
    cards: {
      account: { description, accountNumber },
      allCards,
      chosenCardIndex,
    },
  } = useSelector((state: any) => state);
  const card = allCards[chosenCardIndex || 0];

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      boxSizing="border-box"
      rowGap={12}
      mb={24}
      order={{ xs: 10, md: 10 }}
    >
      <Typography order={-1} fontSize="18px" color="gray.b800" fontWeight={500}>
        Привязана к счёту
      </Typography>
      <Box
        order={{ xs: 4, md: 4 }}
        width="100%"
        p={12}
        bgcolor={
          activeIndexItem
            ? "var(--main-color-white)"
            : "var(--main-color-bg-widgets)"
        }
        boxSizing="border-box"
        display="flex"
        gap={12}
        alignItems="center"
        borderRadius="16px"
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => {
          dispatch(setIsAccountInfoOpen(accountNumber));
        }}
        onMouseEnter={() => setActiveIndexItem(true)}
        onMouseLeave={() => setActiveIndexItem(false)}
      >
        <IconButton
          sx={{
            backgroundColor: activeIndexItem
              ? "var(--main-color-bg-widgets)"
              : "var(--main-color-white)",
            borderRadius: isMobile ? 20 : 8,
            width: isMobile ? 36 : 40,
            height: isMobile ? 36 : 40,
          }}
        >
          <IconCardAccount />
        </IconButton>
        <Box
          display="flex"
          alignItems="start"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="text_5"
            mr={5}
            color={theme.primary.main.gray[800]}
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </Typography>
          <Typography
            variant="text_5"
            color="gray.b600"
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            {" • "}
            {card?.cardPan?.substring(card?.cardPan.length - 4)}
          </Typography>
        </Box>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "10px",
          }}
        >
          <img src={RightIcon} />
        </div>
      </Box>
    </Box>
  );
}

export default CardAccount;
