import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserStatus, authActions } from "store/slices/auth";

import { IconUnBlocked } from "atoms/IconUnBlocked";
import { useNavigate } from "react-router-dom";
import NewNotificationResult from "molecules/NewNotificationResult/NewNotificationResult";
import { authSelector } from "store";

const UnBlockedScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(authActions.setAccountStatus(UserStatus.ACTIVE));
    dispatch(authActions.resetError());
    navigate("/sign-up");
  };

  const { error } = useSelector(authSelector);

  let title = error?.title ?? "Зарегистрируйтесь для входа в приложение";
  let subtitle =
    error?.subtitle ??
    "После разблокировки вашей учётной записи нужно повторно пройти регистрацию";

  return (
    <Grid
      flexGrow="1"
      display="flex"
      //   alignItems="center"
      component="main"
      container
      px={{ xs: 16, xl: 86 }}
      justifyContent="center"
      fontFamily="Inter"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Box p={{ xs: "104px 0 64px", md: "40px 30.5px 140px" }} component="div">
        <NewNotificationResult
          pageTitle={title}
          title={subtitle}
          icon={<IconUnBlocked />}
          buttons={[
            {
              name: "Зарегистрироваться",
              variant: "primary",
              size: "lg",
              onClick: handleClick,
            },
          ]}
          buttonStyle={{ width: "327px" }}
          buttonsMarginTop={24}
        />
      </Box>
    </Grid>
  );
};

export default UnBlockedScreen;
