import { Box, useMediaQuery } from "@mui/material";
import { RefillErrorScreen } from "components/CardsInfo/CardModalContent/RefillErrorScreen";
import { RefillBlock } from "components/RefillBlock/RefillBlock";
import { useIsMobile } from "hooks/useIsMobile";
import { Modal } from "molecules/Modal/Modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isSocces: boolean;
}

export const RefillModal: React.FC<Props> = ({ isOpen, onClose, isSocces }) => {
  const { isMobile } = useIsMobile();
  const isDesktop = useMediaQuery("(min-width:768px)");

  return (
    <Modal
      title="Пополнение карты"
      isOpen={isOpen}
      onClose={onClose}
      callback={onClose}
      buttonVisible={isMobile}
      isProfile
    >
      <Box sx={{ px: isDesktop ? "32px" : "16px" }}>
        {isSocces ? (
          <RefillBlock onClose={onClose} />
        ) : (
          <RefillErrorScreen onClick={onClose} />
        )}
      </Box>
    </Modal>
  );
};
