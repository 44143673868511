import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface ISectionHeader {
  date: string;
}

const SectionHeader: FC<ISectionHeader> = ({ date }) => {
  return (
    <Box
      pr={12}
      py={12}
      bgcolor="var(--main-color-white)"
      px={{ xs: "16px", lg: "32px" }}
    >
      <Typography
        fontWeight={400}
        fontSize={14}
        lineHeight="20px"
        color="var(--brand-color-primary)"
      >
        {date}
      </Typography>
    </Box>
  );
};

export default SectionHeader;
