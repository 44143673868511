import { RefObject } from "react";
import useEventListener from "./useEventListener";

type Callback = (event: MouseEvent) => void;

export default function useClickOutside(
  ref: RefObject<HTMLElement>,
  cb: Callback
) {
  useEventListener(
    "click",
    (e: MouseEvent) => {
      if (ref.current == null || ref.current.contains(e.target as Node)) return;
      cb(e);
    },
    document
  );
}
