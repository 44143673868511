import { format, isToday, isYesterday, parseISO, getYear } from "date-fns";
import { ru } from "date-fns/locale";

export const parseTransactionsDate = (dateString: string) => {
  if (!dateString) return "";

  const date = parseISO(dateString);
  const currentYear = new Date().getFullYear();

  if (isToday(date)) {
    return "Сегодня";
  }

  if (isYesterday(date)) {
    return "Вчера";
  }

  if (getYear(date) === currentYear) {
    return format(date, "d MMMM", { locale: ru });
  } else {
    return format(date, "d MMMM yyyy", { locale: ru });
  }
};
