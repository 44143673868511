export const IconNotFound = (props) => (
  <svg
    width={404}
    height={263}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 404 263"
    {...props}
  >
    <path
      d="M89.869 109.246a63.692 63.692 0 0 1 63.898 63.898 63.692 63.692 0 0 1-63.898 63.899 63.691 63.691 0 0 1-63.898-63.899 63.691 63.691 0 0 1 63.898-63.898Zm0-26.383C40.4 82.863 0 122.85 0 172.73 0 222.614 40.399 262.6 89.869 262.6c49.468 0 89.868-40.399 89.868-89.869.003-49.468-40.399-89.868-89.868-89.868Zm18.964-56.48c-27.208 0-50.705 15.253-62.661 37.927L27.207 99.352C44.11 85.336 65.544 76.678 89.455 76.678c40.813 0 75.44 25.146 89.456 61.011l.003-41.636c0-38.337-31.332-69.67-70.081-69.67Zm70.081 40.813c.826-4.123 1.648-8.244 1.648-12.779C180.562 24.324 156.24 0 126.145 0c-20.201 0-37.927 11.131-47.407 27.62 9.481-4.535 20.2-7.008 31.33-7.008 30.921 0 57.715 19.375 68.846 46.584Zm41.636 27.62c0-4.534.411-9.48 1.237-13.603-1.237-1.237-2.887-2.062-4.535-2.887-4.535-2.473-9.895-4.124-15.666-4.124-5.771 0-10.718 1.648-15.666 4.124-1.648.825-2.887 1.647-4.535 2.887a75.767 75.767 0 0 1 1.237 13.604l-1.237 67.196h.826c.411-.825.825-2.062 1.236-2.887l1.648-2.887c3.709-4.946 9.481-7.834 16.077-7.834 9.069 0 16.488 5.772 18.965 13.604h.825l-.412-67.192Zm74.204-74.203c11.131 0 21.848 2.473 31.329 7.008C316.602 11.133 298.875 0 278.677 0c-30.093 0-54.417 24.321-54.417 54.417 0 4.535.411 8.659 1.647 12.779 11.135-27.209 37.518-46.584 68.847-46.584Zm20.2 56.065c23.91 0 45.349 8.658 62.248 22.674L358.238 64.31c-11.543-22.674-35.453-37.927-62.662-37.927-38.751 0-70.08 31.33-70.495 69.67v41.636c14.433-35.45 49.061-61.011 89.876-61.011h-.003Zm63.077 96.464a63.69 63.69 0 0 1-63.898 63.898 63.69 63.69 0 0 1-63.898-63.898 63.69 63.69 0 0 1 63.898-63.898 63.69 63.69 0 0 1 63.898 63.898Zm25.969-.414c0-49.469-40.399-89.869-89.869-89.869-49.468 0-89.868 40.399-89.868 89.869 0 49.469 40.399 89.868 89.868 89.868C363.6 262.185 404 222.197 404 172.728Z"
      fill="#F2F4F7"
    />
  </svg>
);
