import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/system";

import AccountsList from "./components/AccountsList";
import CardsList from "./components/CardsList";
import { setStatement } from "store/slices/cardsSlice";
import { cardsSelector } from "store";
import { SkeletonContainer } from "components";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const AccountsAndCardsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  width: "100%",
  position: "relative",

  "@media (max-width: 980px)": {
    marginBottom: "28px",
  },
});

export const AccountsAndCards: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(cardsSelector);

  const isMatch = useMediaQuery("(max-width:980px)");

  useEffect(() => {
    dispatch(
      setStatement({
        accId: "%",
        forceRefresh: false,
      })
    );
  }, [dispatch]);

  return (
    <Box>
      {isLoading ? (
        <SkeletonContainer
          height={!isMatch ? "272px" : "100px"}
          isLoading={isLoading}
          width="100%"
          marginBottom={isMatch ? 20 : 0}
        />
      ) : (
        <AccountsAndCardsContainer>
          <AccountsList />
          <CardsList />
        </AccountsAndCardsContainer>
      )}
    </Box>
  );
};
