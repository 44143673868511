import { MobileTabVariants } from "store/slices/system/slice";

interface SectionTitle {
  desktop: string | null;
  mobile: string | null;
}

export const sectionTitles: Record<MobileTabVariants, SectionTitle> = {
  [MobileTabVariants.main]: {
    desktop: null,
    mobile: null,
  },
  [MobileTabVariants.transfersHub]: {
    desktop: "Переводы",
    mobile: "Переводы",
  },
  [MobileTabVariants.history]: {
    desktop: "История операций",
    mobile: "История операций",
  },
  [MobileTabVariants.chat]: {
    desktop: null,
    mobile: null,
  },
  [MobileTabVariants.other]: {
    desktop: null,
    mobile: null,
  },
};
