import { Container, Description, Title, TitleBlock } from "./components";
import { IconErrorBiometry } from "atoms/IconErrorBiometry";

export const WarningTransfer = () => {
  return (
    <Container>
      <TitleBlock>
        <IconErrorBiometry width={20} height={20} />
        <Title>Перевод нельзя отменить</Title>
      </TitleBlock>
      <Description>
        Проверьте внимательно реквизиты получателя, не переводите деньги
        незнакомым людям.
      </Description>
    </Container>
  );
};
