import { FC, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CardDto } from "api/account";
import { AnchorStyled, DropdownAnchor } from "../..";
import { CARD_STATUS } from "components/CardsInfo/model";
import {
  IconCreditBlocked,
  IconCreditCard,
  IconCreditCardClosed,
  IconCreditCardFrozen,
} from "atoms/IconCards/IconCards";

interface OperationCardProps {
  value: string;
  cards: CardDto[];
  isMobile: boolean;
  onChangeCard: (cardId: string) => void;
  onClear: () => void;
}

export const OperationCard: FC<OperationCardProps> = ({
  value,
  cards,
  isMobile,
  onChangeCard,
  onClear,
}) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [isDropped, setDropped] = useState<boolean>(false);
  const [appliedValues, setAppliedValues] = useState<string>(value);

  const handleDrop = () => setDropped(!isDropped);
  const handleBlur = () => setDropped(false);

  useEffect(() => {
    if (!value && !isDropped) {
      setAppliedValues(value);
    }
  }, [value, isDropped]);

  const content = (
    <>
      {cards?.map((cardItem: any, index: number) => {
        const cardIsClosed = cardItem.status === CARD_STATUS.Closed;
        const cardIsBlocked = CARD_STATUS.Blocked.includes(cardItem.status);
        const cardIsFrozen =
          cardItem.status === CARD_STATUS.Temporary ||
          cardItem.status === CARD_STATUS.Referral;

        return (
          <Box
            key={index}
            sx={{
              cursor: "pointer",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              padding: "0 16px",
              opacity: cardIsClosed || cardIsBlocked || cardIsFrozen ? 0.5 : 1,
              ":hover": {
                backgroundColor: "var(--brand-color-hover)",
              },
            }}
            onClick={() => {
              setDropped(false);
              setAppliedValues(cardItem?.cardId);
              onChangeCard(cardItem?.cardId);
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "8px",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      width: "24px",
                    }}
                  >
                    {[
                      "ACTIVE",
                      "VIP",
                      "DECLARED",
                      "RESTRICTED",
                      "OPEN_DOMESTIC",
                      "EXPIRED",
                    ].includes(cardItem.status) && <IconCreditCard />}
                    {cardIsFrozen && <IconCreditCardFrozen />}
                    {cardIsBlocked && <IconCreditBlocked />}
                    {cardIsClosed && <IconCreditCardClosed />}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                  }}
                >
                  <Typography
                    noWrap
                    sx={{
                      color: "var(--main-color-text-subtitle)",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    {cardItem.paymentNetwork
                      ? cardItem.paymentNetwork === "MIR"
                        ? "МИР"
                        : cardItem.paymentNetwork
                      : "МИР"}
                  </Typography>
                  <Box
                    sx={{
                      width: "5px",
                      height: "5px",
                      borderRadius: "100px",
                      backgroundColor: "var(--main-color-text-subtitle)",
                    }}
                  />

                  <Typography
                    noWrap
                    sx={{
                      color: "var(--main-color-text-subtitle)",
                      fontWeight: 400,
                      fontSize: "16px",
                    }}
                  >
                    {cardItem.cardPan?.slice(-4)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );

  const trigger = appliedValues ? (
    <AnchorStyled>
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          sx={{
            width: "5px",
            height: "5px",
            borderRadius: "100px",
            backgroundColor: "var(--brand-color-primary)",
          }}
        />
        <Box
          sx={{
            width: "5px",
            height: "5px",
            borderRadius: "100px",
            backgroundColor: "var(--brand-color-primary)",
          }}
        />
        {cards
          .find((card) => card.cardId === appliedValues)
          ?.cardPan?.slice(-4)}
      </Box>
    </AnchorStyled>
  ) : (
    "Карта"
  );

  return (
    <>
      <DropdownAnchor
        trigger={trigger}
        isDropped={isDropped}
        onDrop={handleDrop}
        onBlur={handleBlur}
        withArrow={!appliedValues}
        clear={!!appliedValues}
        onClear={onClear}
        refElement={isMobile ? null : cardRef}
        isCard
      >
        {content}
      </DropdownAnchor>
    </>
  );
};
