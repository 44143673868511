import styled from "@emotion/styled";
import theme from "theme";
import { PmpDto } from "api/auth";
import { Box, Typography } from "@mui/material";
import { EmptyProfileHuge } from "atoms/EmptyProfileHuge";
import { SkeletonContainer } from "../../components";
import { useIsMobile } from "hooks/useIsMobile";

const ProfileCardStyled = styled.div<{ isMobile: boolean }>`
  font-family: Geologica;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 48px;
  padding: ${(props) => (props.isMobile ? "0px 48px" : "0px 48px 0px 102px")};
  & > div {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    & > div:first-of-type {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      color: ${theme.palette.gray.b900};
    }
    & > div:last-of-type {
      display: flex;
      gap: 10px;
      color: ${theme.palette.gray.b400};
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    h4 {
      text-align: center;
    }
    & > div {
      align-items: center;
      & > div:first-of-type {
        // font-size: 30px;
        // line-height: 38px;
      }
      & > div:last-of-type > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

type ProfileCardProps = Pick<PmpDto, "fio" | "phone" | "birthdate"> & {
  isProfileLoading?: boolean;
};

export const ProfileCard = (props: ProfileCardProps) => {
  const { phone, birthdate, isProfileLoading } = props;
  const { isMobile } = useIsMobile();
  return (
    <ProfileCardStyled {...props} isMobile={isMobile}>
      <EmptyProfileHuge />
      <SkeletonContainer height={80} isLoading={isProfileLoading}>
        <Box>
          <Typography variant="text_8" style={{ fontWeight: 500 }}>
            Клиент
          </Typography>
          <Box>
            {phone && (
              <Box>
                +
                {`${phone}`.replace(
                  /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                  "$1 $2 $3 $4 $5"
                )}
                ,
              </Box>
            )}
            {birthdate && <Box>{birthdate}</Box>}
          </Box>
        </Box>
      </SkeletonContainer>
    </ProfileCardStyled>
  );
};
