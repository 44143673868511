import React from "react";
import styles from "./style.module.css";

export const Switch = ({ isToggle, onToggle, raunded = true }) => {
  return (
    <label className={styles.switch}>
      <input
        className={styles.checkbox}
        type="checkbox"
        checked={isToggle}
        onChange={onToggle}
      />
      <span className={`${styles.slider} ${raunded && styles.raunded}`} />
    </label>
  );
};
