import { Box, Grid, Typography } from "@mui/material";
import { IconBlocked } from "atoms/IconBlocked";
import { IconBlockedWeb } from "atoms/IconBlockedWeb";
import { Button } from "components/Button";
import { useIsMobile } from "hooks/useIsMobile";

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "store/slices/auth";

const BlockedScreenPinChange = () => {
  const dispatch = useDispatch();

  const { isMobile, size } = useIsMobile();

  const handleClick = () => {
    dispatch(authActions.setLoginIsBlocked(false));
  };

  const getSubtitle = useCallback(() => {
    switch (size) {
      case "xl":
        return "Вы можете авторизоваться по логину и паролю";
      case "sm":
        return `Вы можете авторизоваться\nпо логину и паролю`;
      case "xs":
        return `Вы можете авторизоваться\nпо логину и паролю`;
      default:
        return `Вы можете авторизоваться\nпо логину и паролю`;
    }
  }, [size]);

  return (
    <Grid
      flexGrow="1"
      display="flex"
      // alignItems="center"
      container
      px={{ xs: 16, xl: 86 }}
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
      flexDirection="column"
    >
      <Box
        p={{ xs: "32px 0 180px", md: "50px 30.5px" }}
        component="div"
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="text_20"
          color="gray.b800"
          fontWeight={500}
          paddingBottom={16}
          order={isMobile ? 2 : 1}
        >
          Вход в приложение “Лучи” заблокирован
        </Typography>
        <Box
          order={isMobile ? 1 : 2}
          display="flex"
          justifyContent="center"
          marginTop={isMobile ? 0 : 40}
          marginBottom={20}
        >
          {isMobile ? <IconBlocked /> : <IconBlockedWeb />}
        </Box>
        <Typography
          variant="text_12"
          color="gray.b500"
          fontWeight={500}
          order={3}
        >
          {getSubtitle()}
        </Typography>
        <Box
          maxWidth="376px"
          width="100%"
          margin={{ xs: "50px auto 0", xl: "50px auto 0" }}
          order={4}
        >
          <Button
            variant="primary"
            onClick={handleClick}
            title="Авторизоваться"
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default BlockedScreenPinChange;
