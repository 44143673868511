import React from "react";

interface IconWalletProps extends React.SVGProps<SVGSVGElement> {}

export const IconWallet = (props: IconWalletProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8075 6.98483H18.3334C18.3334 4.15383 16.6371 2.5 13.763 2.5H6.23712C3.36304 2.5 1.66675 4.15383 1.66675 6.94872V13.0513C1.66675 15.8462 3.36304 17.5 6.23712 17.5H13.763C16.6371 17.5 18.3334 15.8462 18.3334 13.0513V12.7913H14.8075C13.1711 12.7913 11.8445 11.4979 11.8445 9.9025C11.8445 8.30707 13.1711 7.01372 14.8075 7.01372V6.98483ZM14.8075 8.22701H17.7112C18.0548 8.22701 18.3334 8.49861 18.3334 8.83365V10.9425C18.3294 11.2759 18.0532 11.5452 17.7112 11.5491H14.8742C14.0457 11.56 13.3213 11.007 13.1334 10.2203C13.0393 9.73191 13.1714 9.22797 13.4943 8.84351C13.8172 8.45906 14.2979 8.2334 14.8075 8.22701ZM14.9334 10.4442H15.2075C15.5593 10.4442 15.8445 10.1661 15.8445 9.82306C15.8445 9.48004 15.5593 9.20197 15.2075 9.20197H14.9334C14.7651 9.20004 14.6031 9.26387 14.4834 9.3792C14.3637 9.49453 14.2964 9.65177 14.2964 9.81584C14.2964 10.16 14.5804 10.4402 14.9334 10.4442ZM5.6149 6.98483H10.3186C10.6704 6.98483 10.9556 6.70676 10.9556 6.36375C10.9556 6.02073 10.6704 5.74266 10.3186 5.74266H5.6149C5.26594 5.74263 4.98192 6.01633 4.97786 6.35652C4.97784 6.70073 5.26188 6.98088 5.6149 6.98483Z"
        fill={props.color || "var(--brand-color-primary)"}
        fillOpacity={props.fillOpacity}
      />
    </svg>
  );
};
