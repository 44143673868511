import React from "react";

interface IconCheckedProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconChecked = (props: IconCheckedProps) => {
  const { size = 18, color = "white" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39175 0.666992H12.6167C15.4417 0.666992 17.3334 2.65033 17.3334 5.60033V12.4095C17.3334 15.3503 15.4417 17.3337 12.6167 17.3337H5.39175C2.56675 17.3337 0.666748 15.3503 0.666748 12.4095V5.60033C0.666748 2.65033 2.56675 0.666992 5.39175 0.666992ZM8.52508 11.492L12.4834 7.53366C12.7667 7.25033 12.7667 6.79199 12.4834 6.50033C12.2001 6.21699 11.7334 6.21699 11.4501 6.50033L8.00841 9.94199L6.55008 8.48366C6.26675 8.20033 5.80008 8.20033 5.51675 8.48366C5.23341 8.76699 5.23341 9.22533 5.51675 9.51699L7.50008 11.492C7.64175 11.6337 7.82508 11.7003 8.00841 11.7003C8.20008 11.7003 8.38341 11.6337 8.52508 11.492Z"
        fill={color}
      />
    </svg>
  );
};
