import styled from "@emotion/styled/macro";

export const ItemsBlock = styled.div`
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;
`;

export const Item = styled.div`
  background: var(--main-color-bg-widgets);
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  border-radius: 14px;
  padding: 12px 16px;
  row-gap: 12px;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
`;

export const ImgWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  overflow: hidden;
  background-position: center;
  background-size: 200%;
`;

export const ItemReduction = styled.p`
  margin: 0;
  color: #7e7f7a;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
`;

export const ItemValue = styled.p`
  margin: 0;
  font-weight: 500;
  color: #2d3227;
  font-size: 16px;
  line-height: 20px;
  line-height: normal;
`;

export const BlockIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
`;
