import { SizeType, sizeThresholds } from "components/Grid/Grid";

export const findAvailableSizeKey = (
  size: SizeType,
  variansts: any
): SizeType => {
  return (
    sizeThresholds
      .slice(0, sizeThresholds.findIndex((t) => t.key === size) + 1)
      .reverse()
      .find((t) => variansts[t.key])?.key || "xs"
  );
};
