import React from "react";

interface IconPhoneProps extends React.SVGProps<SVGSVGElement> {}

export const IconPhoneHollow = (props: IconPhoneProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5348 16.1201C18.9659 15.5252 17.588 14.657 16.9195 14.3072C16.0489 13.8521 15.9773 13.8149 15.293 14.3425C14.8365 14.6946 14.5331 15.0091 13.9989 14.8909C13.4647 14.7727 12.304 14.106 11.2876 13.0546C10.2712 12.0032 9.59155 10.7636 9.47727 10.2111C9.36298 9.65864 9.67109 9.34745 10.0071 8.87267C10.4808 8.20344 10.4449 8.0919 10.0401 7.18844C9.72447 6.48575 8.86356 5.06922 8.28819 4.48178C7.67269 3.85085 7.67269 3.96239 7.27609 4.13341C6.32381 4.54921 4.60157 5.37202 4.14029 6.39496C3.9178 6.88833 3.81785 8.04498 4.9668 10.2111C6.11576 12.3771 6.92185 13.4847 8.59029 15.2113C10.2587 16.9379 11.5417 17.8663 13.4172 18.9579C15.7373 20.3064 16.6272 20.0435 17.1041 19.813C17.8072 19.4731 19.5698 17.9103 19.8712 17.1685C20.0364 16.7584 20.1439 16.7584 19.5348 16.1201Z"
        stroke="#C2C9D6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
