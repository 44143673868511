export const StatusIconError = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333405 6.99943C0.333405 3.3201 3.32007 0.332764 7.00007 0.332764C10.6867 0.332764 13.6667 3.3201 13.6667 6.99943C13.6667 10.6801 10.6867 13.6661 7.00007 13.6661C3.32007 13.6661 0.333405 10.6801 0.333405 6.99943ZM6.4134 4.47276C6.4134 4.15343 6.68007 3.8861 7.00007 3.8861C7.32007 3.8861 7.58007 4.15343 7.58007 4.47276V7.41943C7.58007 7.7401 7.32007 7.99943 7.00007 7.99943C6.68007 7.99943 6.4134 7.7401 6.4134 7.41943V4.47276ZM7.00674 10.1201C6.68007 10.1201 6.42007 9.85343 6.42007 9.53343C6.42007 9.21343 6.68007 8.95343 7.00007 8.95343C7.32674 8.95343 7.58674 9.21343 7.58674 9.53343C7.58674 9.85343 7.32674 10.1201 7.00674 10.1201Z"
      fill="#E64545"
    />
  </svg>
);
