import { createPortal } from "react-dom";
import { Box } from "@mui/material";
import { Notification } from "components";
import React from "react";

type Props = {
  refElement: HTMLDivElement | null;
  description: string;
  onClose: () => void;
};

const ErrorNotification = ({ description, refElement, onClose }: Props) => {
  return createPortal(
    <Box
      sx={{
        position: "absolute",
        zIndex: 999999,
        top: 0,
        left: 0,
        right: 0,
        "& > div": {
          border: "none",
        },
      }}
    >
      <Notification
        type="error"
        title="Недопустимый размер файла"
        description={description}
        onClose={onClose}
      />
    </Box>,
    refElement || document.body
  );
};

export default ErrorNotification;
