import React from "react";

export const IconLoginChangeError = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#FFF4F4"
      />
      <path
        d="M68.9378 26.5C71.6321 21.8333 78.3679 21.8333 81.0622 26.5L120.033 94C122.728 98.6667 119.36 104.5 113.971 104.5H36.0289C30.6403 104.5 27.2724 98.6667 29.9667 94L68.9378 26.5Z"
        fill="white"
      />
      <rect
        x="71.4375"
        y="43.8101"
        width="7.71997"
        height="40.9005"
        rx="3.85998"
        fill="#F29494"
      />
      <ellipse
        cx="75.2975"
        cy="91.101"
        rx="3.85998"
        ry="3.83442"
        fill="#F29494"
      />
    </svg>
  );
};
