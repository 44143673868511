import { forwardRef } from "react";
import Box from "@mui/material/Box";
import { TextField, FormHelperText } from "@mui/material";
import NumberFormat from "react-number-format";
import { Label } from "molecules/Label/Label";
import { makeStyles } from "@mui/styles";
import theme from "theme";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .Mui-error": {
      borderColor: "var(--error-color-primary)",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid var(--error-color-illustration) !Important",
    },
    "& .MuiOutlinedInput-root": {
      color: theme.palette.gray.b900,
      backgroundColor: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
      height: "68px",
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.palette.indigo.b300}`,
      },
    },
    "& > .MuiOutlinedInput-root > input": {
      "&::placeholder": {
        color: theme.palette.gray.b400,
        opacity: 1,
      },
    },
    "& > .MuiOutlinedInput-root > .MuiInputAdornment-root > p": {
      color: theme.palette.gray.b400,
    },
    "& .MuiOutlinedInput-input": {
      padding: "22px 18px",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 500,
      background: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: theme.palette.gray.b500,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
}));

const DateFormatInput = forwardRef(function DateFormatInput(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="##.##.####"
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

export const DateInput = ({
  name,
  label,
  value,
  onChange,
  error = false,
  isDateValid,
  placeholder,
}) => {
  const classes = useStyles();

  return (
    <Box mb={8}>
      <Label
        label={label}
        htmlFor={name}
        style={{
          color: "var(--main-color-text-title)",
        }}
      />
      <TextField
        classes={{ root: classes.inputRoot }}
        name={name}
        value={value}
        onChange={onChange}
        autoComplete="off"
        InputProps={{
          inputComponent: DateFormatInput,
        }}
        id={name}
        fullWidth
        placeholder={placeholder || "01.01.1985"}
        error={error}
      />
      {!!isDateValid && (
        <FormHelperText
          sx={{
            color: "var(--error-color-primary)",
            fontSize: 14,
            lineHeight: "20px",
          }}
          error
        >
          {isDateValid}
        </FormHelperText>
      )}
    </Box>
  );
};
