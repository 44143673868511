import { authActions } from "../store/slices/auth";
import { chatActions } from "../store/slices/chat";
import { notificationActions } from "../store/slices/notifications";
import { profileActions } from "../store/slices/profile";
import { promotionActions } from "../store/slices/promotion";
import { systemActions } from "../store/slices/system";
import { resetCardsStore } from "../store/slices/cardsSlice";
import { resetHistoryStore } from "../store/slices/historySlice";
import { resetLimitsStore } from "../store/slices/limitsSlice";
import { resetTransferStore } from "../store/slices/transferSlice";
import { store } from "../store";

export function useResetStore() {
  const resetStore = () => {
    store.dispatch(authActions.resetStore());
    store.dispatch(chatActions.resetStore());
    store.dispatch(notificationActions.resetStore());
    store.dispatch(profileActions.resetStore());
    store.dispatch(promotionActions.resetStore());
    store.dispatch(systemActions.resetStore());

    store.dispatch(resetCardsStore());
    store.dispatch(resetHistoryStore());
    store.dispatch(resetLimitsStore());
    store.dispatch(resetTransferStore());
  };

  return resetStore;
}
