/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface ChatAttachment
 */
export interface ChatAttachment {
  /**
   *
   * @type {string}
   * @memberof ChatAttachment
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof ChatAttachment
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof ChatAttachment
   */
  mime: string;
}
/**
 *
 * @export
 * @interface ChatAttachmentDto
 */
export interface ChatAttachmentDto {
  /**
   *
   * @type {string}
   * @memberof ChatAttachmentDto
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChatAttachmentDto
   */
  fileName?: string;
  /**
   *
   * @type {string}
   * @memberof ChatAttachmentDto
   */
  mimeType?: string;
}
/**
 *
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof ChatMessage
   */
  chatId?: number;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  timestamp: string;
  /**
   *
   * @type {number}
   * @memberof ChatMessage
   */
  operator?: number;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  operatorName?: string;
  /**
   *
   * @type {number}
   * @memberof ChatMessage
   */
  user?: number;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  text?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChatMessage
   */
  read: boolean;
  /**
   *
   * @type {Array<ChatAttachment>}
   * @memberof ChatMessage
   */
  attachments?: Array<ChatAttachment>;
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  type?: ChatMessageTypeEnum;
}

export const ChatMessageTypeEnum = {
  Regular: "REGULAR",
  RatingRequest: "RATING_REQUEST",
  RatingDone: "RATING_DONE",
  RatingRefusal: "RATING_REFUSAL",
} as const;

export type ChatMessageTypeEnum =
  (typeof ChatMessageTypeEnum)[keyof typeof ChatMessageTypeEnum];

/**
 *
 * @export
 * @interface ChatMessageDto
 */
export interface ChatMessageDto {
  /**
   *
   * @type {number}
   * @memberof ChatMessageDto
   */
  chatId?: number;
  /**
   *
   * @type {string}
   * @memberof ChatMessageDto
   */
  id?: string;
  /**
   * Имя оператора поддержки или null если сообщение от клиента
   * @type {string}
   * @memberof ChatMessageDto
   */
  user?: string;
  /**
   *
   * @type {string}
   * @memberof ChatMessageDto
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof ChatMessageDto
   */
  createdAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChatMessageDto
   */
  read?: boolean;
  /**
   *
   * @type {Array<ChatAttachmentDto>}
   * @memberof ChatMessageDto
   */
  attachments?: Array<ChatAttachmentDto>;
  /**
   *
   * @type {string}
   * @memberof ChatMessageDto
   */
  type?: ChatMessageDtoTypeEnum;
}

export const ChatMessageDtoTypeEnum = {
  Regular: "REGULAR",
  RatingRequest: "RATING_REQUEST",
  RatingDone: "RATING_DONE",
  RatingRefusal: "RATING_REFUSAL",
} as const;

export type ChatMessageDtoTypeEnum =
  (typeof ChatMessageDtoTypeEnum)[keyof typeof ChatMessageDtoTypeEnum];

/**
 *
 * @export
 * @interface ChatMessagePage
 */
export interface ChatMessagePage {
  /**
   *
   * @type {Array<ChatMessage>}
   * @memberof ChatMessagePage
   */
  content: Array<ChatMessage>;
  /**
   *
   * @type {number}
   * @memberof ChatMessagePage
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof ChatMessagePage
   */
  size: number;
}
/**
 *
 * @export
 * @interface CheckNewNotificationsResponse
 */
export interface CheckNewNotificationsResponse {
  /**
   *
   * @type {boolean}
   * @memberof CheckNewNotificationsResponse
   */
  newNotifications?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CheckNewNotificationsResponse
   */
  newNews?: boolean;
}
/**
 *
 * @export
 * @interface ContentCategoryResponse
 */
export interface ContentCategoryResponse {
  /**
   * Код категории контента
   * @type {string}
   * @memberof ContentCategoryResponse
   */
  code?: string;
  /**
   * Описание категории контента
   * @type {string}
   * @memberof ContentCategoryResponse
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ContentResponse
 */
export interface ContentResponse {
  /**
   *
   * @type {number}
   * @memberof ContentResponse
   */
  id?: number;
  /**
   * Тип контента, пример, Акции и предложения; Новости
   * @type {string}
   * @memberof ContentResponse
   */
  type?: string;
  /**
   * Категория контента, пример, Предложение месяца; Эксклюзив
   * @type {string}
   * @memberof ContentResponse
   */
  category?: string;
  /**
   * Дата регистрации, или начала действия контента
   * @type {string}
   * @memberof ContentResponse
   */
  dateFrom?: string;
  /**
   * Дата окончания действия контента, если NULL, то бесрочное действие
   * @type {string}
   * @memberof ContentResponse
   */
  dateTo?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  titleShort?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ContentResponse
   */
  descriptionShort?: string;
  /**
   * Текст на кнопке переход на другие ресурсы
   * @type {string}
   * @memberof ContentResponse
   */
  buttonName?: string;
  /**
   * Ссылка для кнопки перехода на другие ресурссы
   * @type {string}
   * @memberof ContentResponse
   */
  buttonLink?: string;
  /**
   * Ссылка на картинку для отображения в ленте
   * @type {string}
   * @memberof ContentResponse
   */
  srcImgLenta?: string;
  /**
   * Ссылка на картинку для отображения в деталке
   * @type {string}
   * @memberof ContentResponse
   */
  srcImgDetail?: string;
}
/**
 *
 * @export
 * @interface ContentShortResponse
 */
export interface ContentShortResponse {
  /**
   * Идентификатор контента
   * @type {number}
   * @memberof ContentShortResponse
   */
  id?: number;
  /**
   * Тип контента, пример, Акции и предложения; Новости
   * @type {string}
   * @memberof ContentShortResponse
   */
  type?: string;
  /**
   * Тип контента, пример, Предложение месяца; Эксклюзив
   * @type {string}
   * @memberof ContentShortResponse
   */
  category?: string;
  /**
   * Дата регистрации, или начала действия контента
   * @type {string}
   * @memberof ContentShortResponse
   */
  dateFrom?: string;
  /**
   * Дата окончания действия контента, если NULL, то бесрочное действие
   * @type {string}
   * @memberof ContentShortResponse
   */
  dateTo?: string;
  /**
   *
   * @type {string}
   * @memberof ContentShortResponse
   */
  titleShort?: string;
  /**
   *
   * @type {string}
   * @memberof ContentShortResponse
   */
  descriptionShort?: string;
  /**
   * Ссылка на картинку для отображения в ленте
   * @type {string}
   * @memberof ContentShortResponse
   */
  srcImgLenta?: string;
  /**
   * Ссылка на картинку для отображения в деталке
   * @type {string}
   * @memberof ContentShortResponse
   */
  srcImgDetail?: string;
}
/**
 *
 * @export
 * @interface CreateMessageV2Request
 */
export interface CreateMessageV2Request {
  /**
   *
   * @type {Array<File>}
   * @memberof CreateMessageV2Request
   */
  attachments?: Array<File>;
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   * Символьный код ошибки
   * @type {string}
   * @memberof ErrorResponse
   */
  code?: string;
  /**
   * Заголовок ошибки
   * @type {string}
   * @memberof ErrorResponse
   */
  title?: string;
  /**
   * Подзаголовок ошибки
   * @type {string}
   * @memberof ErrorResponse
   */
  subtitle?: string;
  /**
   * Поясняющее сообщение
   * @type {string}
   * @memberof ErrorResponse
   */
  comment?: string;
  /**
   * Массив ошибок
   * @type {Array<string>}
   * @memberof ErrorResponse
   */
  errors?: Array<string>;
  /**
   * Дополнительная информация
   * @type {string}
   * @memberof ErrorResponse
   */
  additional?: string;
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  id?: string;
}
/**
 *
 * @export
 * @interface GroupedChatMessages
 */
export interface GroupedChatMessages {
  /**
   *
   * @type {number}
   * @memberof GroupedChatMessages
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof GroupedChatMessages
   */
  page?: number;
  /**
   *
   * @type {{ [key: string]: Array<ChatMessageDto>; }}
   * @memberof GroupedChatMessages
   */
  messages?: { [key: string]: Array<ChatMessageDto> };
}
/**
 *
 * @export
 * @interface GroupedNotificationAttachmentInfoDto
 */
export interface GroupedNotificationAttachmentInfoDto {
  /**
   *
   * @type {number}
   * @memberof GroupedNotificationAttachmentInfoDto
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof GroupedNotificationAttachmentInfoDto
   */
  page?: number;
  /**
   *
   * @type {Array<NotificationAttachmentInfoDto>}
   * @memberof GroupedNotificationAttachmentInfoDto
   */
  notifications?: Array<NotificationAttachmentInfoDto>;
}
/**
 *
 * @export
 * @interface GroupedNotificationDto
 */
export interface GroupedNotificationDto {
  /**
   *
   * @type {number}
   * @memberof GroupedNotificationDto
   */
  size?: number;
  /**
   *
   * @type {number}
   * @memberof GroupedNotificationDto
   */
  page?: number;
  /**
   *
   * @type {Array<NotificationDto>}
   * @memberof GroupedNotificationDto
   */
  notifications?: Array<NotificationDto>;
}
/**
 *
 * @export
 * @interface MakeRatingRequest
 */
export interface MakeRatingRequest {
  /**
   *
   * @type {string}
   * @memberof MakeRatingRequest
   */
  messageId: string;
  /**
   *
   * @type {string}
   * @memberof MakeRatingRequest
   */
  rating: string;
}
/**
 *
 * @export
 * @interface MarkReadRequest
 */
export interface MarkReadRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof MarkReadRequest
   */
  ids?: Array<string>;
}
/**
 *
 * @export
 * @interface NewChatMessages
 */
export interface NewChatMessages {
  /**
   *
   * @type {{ [key: string]: Array<ChatMessageDto>; }}
   * @memberof NewChatMessages
   */
  messages?: { [key: string]: Array<ChatMessageDto> };
}
/**
 *
 * @export
 * @interface NotificationAttachmentDto
 */
export interface NotificationAttachmentDto {
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentDto
   */
  base64?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentDto
   */
  fileName?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentDto
   */
  mimeType?: string;
}
/**
 *
 * @export
 * @interface NotificationAttachmentInfoDto
 */
export interface NotificationAttachmentInfoDto {
  /**
   * notification ID
   * @type {number}
   * @memberof NotificationAttachmentInfoDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  notificationDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  subTitle?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  detailedInfo?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationAttachmentInfoDto
   */
  readStatus?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  notificationType?: NotificationAttachmentInfoDtoNotificationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  iconType?: NotificationAttachmentInfoDtoIconTypeEnum;
  /**
   *
   * @type {number}
   * @memberof NotificationAttachmentInfoDto
   */
  attachment?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  dateTime?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  notificationStatus?: NotificationAttachmentInfoDtoNotificationStatusEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  attachmentFileName?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationAttachmentInfoDto
   */
  attachmentMimeType?: string;
}

export const NotificationAttachmentInfoDtoNotificationTypeEnum = {
  News: "NEWS",
  Notification: "NOTIFICATION",
} as const;

export type NotificationAttachmentInfoDtoNotificationTypeEnum =
  (typeof NotificationAttachmentInfoDtoNotificationTypeEnum)[keyof typeof NotificationAttachmentInfoDtoNotificationTypeEnum];
export const NotificationAttachmentInfoDtoIconTypeEnum = {
  Transfer: "TRANSFER",
  Payment: "PAYMENT",
  Notify: "NOTIFY",
} as const;

export type NotificationAttachmentInfoDtoIconTypeEnum =
  (typeof NotificationAttachmentInfoDtoIconTypeEnum)[keyof typeof NotificationAttachmentInfoDtoIconTypeEnum];
export const NotificationAttachmentInfoDtoNotificationStatusEnum = {
  Draft: "DRAFT",
  Sent: "SENT",
  OnReview: "ON_REVIEW",
  Created: "CREATED",
  Resolved: "RESOLVED",
  Declined: "DECLINED",
} as const;

export type NotificationAttachmentInfoDtoNotificationStatusEnum =
  (typeof NotificationAttachmentInfoDtoNotificationStatusEnum)[keyof typeof NotificationAttachmentInfoDtoNotificationStatusEnum];

/**
 * notification response dto
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
  /**
   * notification ID
   * @type {number}
   * @memberof NotificationDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  notificationDateTime?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  subTitle?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  detailedInfo?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationDto
   */
  readStatus?: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  notificationType?: NotificationDtoNotificationTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  iconType?: NotificationDtoIconTypeEnum;
  /**
   *
   * @type {number}
   * @memberof NotificationDto
   */
  attachment?: number;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  dateTime?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDto
   */
  notificationStatus?: NotificationDtoNotificationStatusEnum;
}

export const NotificationDtoNotificationTypeEnum = {
  News: "NEWS",
  Notification: "NOTIFICATION",
} as const;

export type NotificationDtoNotificationTypeEnum =
  (typeof NotificationDtoNotificationTypeEnum)[keyof typeof NotificationDtoNotificationTypeEnum];
export const NotificationDtoIconTypeEnum = {
  Transfer: "TRANSFER",
  Payment: "PAYMENT",
  Notify: "NOTIFY",
} as const;

export type NotificationDtoIconTypeEnum =
  (typeof NotificationDtoIconTypeEnum)[keyof typeof NotificationDtoIconTypeEnum];
export const NotificationDtoNotificationStatusEnum = {
  Draft: "DRAFT",
  Sent: "SENT",
  OnReview: "ON_REVIEW",
  Created: "CREATED",
  Resolved: "RESOLVED",
  Declined: "DECLINED",
} as const;

export type NotificationDtoNotificationStatusEnum =
  (typeof NotificationDtoNotificationStatusEnum)[keyof typeof NotificationDtoNotificationStatusEnum];

/**
 *
 * @export
 * @interface NotificationToRead
 */
export interface NotificationToRead {
  /**
   *
   * @type {Array<number>}
   * @memberof NotificationToRead
   */
  ids?: Array<number>;
}
/**
 *
 * @export
 * @interface NotificationsArrayValue
 */
export interface NotificationsArrayValue {
  /**
   *
   * @type {string}
   * @memberof NotificationsArrayValue
   */
  notificationDate?: string;
  /**
   *
   * @type {Array<NotificationDto>}
   * @memberof NotificationsArrayValue
   */
  notifications?: Array<NotificationDto>;
}
/**
 *
 * @export
 * @interface PushState200Response
 */
export interface PushState200Response {
  /**
   *
   * @type {boolean}
   * @memberof PushState200Response
   */
  enabled?: boolean;
}
/**
 *
 * @export
 * @interface PushStateRequest
 */
export interface PushStateRequest {
  /**
   *
   * @type {string}
   * @memberof PushStateRequest
   */
  deviceId?: string;
  /**
   *
   * @type {string}
   * @memberof PushStateRequest
   */
  fcm?: string;
}
/**
 * Токен otp
 * @export
 * @interface RetryOneTimePasswordRequest
 */
export interface RetryOneTimePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof RetryOneTimePasswordRequest
   */
  token?: string;
}
/**
 *
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
  /**
   *
   * @type {number}
   * @memberof SseEmitter
   */
  timeout?: number;
}
/**
 *
 * @export
 * @interface ToggleNotificationRequest
 */
export interface ToggleNotificationRequest {
  /**
   *
   * @type {string}
   * @memberof ToggleNotificationRequest
   */
  fcm?: string;
  /**
   *
   * @type {string}
   * @memberof ToggleNotificationRequest
   */
  deviceId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ToggleNotificationRequest
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ToggleNotificationRequest
   */
  device_type?: ToggleNotificationRequestDeviceTypeEnum;
}

export const ToggleNotificationRequestDeviceTypeEnum = {
  A: "A",
  I: "I",
  W: "W",
  H: "H",
} as const;

export type ToggleNotificationRequestDeviceTypeEnum =
  (typeof ToggleNotificationRequestDeviceTypeEnum)[keyof typeof ToggleNotificationRequestDeviceTypeEnum];

/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Отправка нового сообщения
     * @param {string} authorization
     * @param {string} [text]
     * @param {string} [deviceId]
     * @param {Array<File>} [attachments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMessage: async (
      authorization: string,
      text?: string,
      deviceId?: string,
      attachments?: Array<File>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("createMessage", "authorization", authorization);
      const localVarPath = `/v1/chat/message`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      if (text !== undefined) {
        localVarQueryParameter["text"] = text;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      if (attachments) {
        attachments.forEach((element) => {
          localVarFormParams.set("attachments", element as any);
        });
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отправка нового сообщения
     * @param {string} authorization
     * @param {string} [text]
     * @param {string} [deviceId]
     * @param {Array<File>} [attachments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMessageV2: async (
      authorization: string,
      text?: string,
      deviceId?: string,
      attachments?: Array<File>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("createMessageV2", "authorization", authorization);
      const localVarPath = `/v2/chat/message`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      if (text !== undefined) {
        localVarQueryParameter["text"] = text;
      }

      if (deviceId !== undefined) {
        localVarQueryParameter["deviceId"] = deviceId;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      if (attachments) {
        attachments.forEach((element) => {
          localVarFormParams.set("attachments", element as any);
        });
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAttachment: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getAttachment", "id", id);
      const localVarPath = `/v2/chat/attachment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение сообщений чата
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMessages: async (
      authorization: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getMessages", "authorization", authorization);
      const localVarPath = `/v1/chat/messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить новые сообщения
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewMessages: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getNewMessages", "authorization", authorization);
      const localVarPath = `/v1/chat/new_messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Подписка для получения количества непрочитанных сообщений клиента
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleSse: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("handleSse", "authorization", authorization);
      const localVarPath = `/v1/chat/subscription/new-messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Скачать вложение
     * @param {string} authorization
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadChatAttachment: async (
      authorization: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("loadChatAttachment", "authorization", authorization);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("loadChatAttachment", "id", id);
      const localVarPath = `/v1/chat/attachment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization
     * @param {MakeRatingRequest} makeRatingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    makeRating: async (
      authorization: string,
      makeRatingRequest: MakeRatingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("makeRating", "authorization", authorization);
      // verify required parameter 'makeRatingRequest' is not null or undefined
      assertParamExists("makeRating", "makeRatingRequest", makeRatingRequest);
      const localVarPath = `/v1/chat/rating`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/json;charset=utf-8";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        makeRatingRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization
     * @param {MarkReadRequest} [markReadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markRead: async (
      authorization: string,
      markReadRequest?: MarkReadRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("markRead", "authorization", authorization);
      const localVarPath = `/v1/chat/message`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/json;charset=utf-8";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        markReadRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageList: async (
      authorization: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("messageList", "authorization", authorization);
      const localVarPath = `/v2/chat/messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Отправка нового сообщения
     * @param {string} authorization
     * @param {string} [text]
     * @param {string} [deviceId]
     * @param {Array<File>} [attachments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMessage(
      authorization: string,
      text?: string,
      deviceId?: string,
      attachments?: Array<File>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMessage(
        authorization,
        text,
        deviceId,
        attachments,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Отправка нового сообщения
     * @param {string} authorization
     * @param {string} [text]
     * @param {string} [deviceId]
     * @param {Array<File>} [attachments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMessageV2(
      authorization: string,
      text?: string,
      deviceId?: string,
      attachments?: Array<File>,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessage>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMessageV2(
        authorization,
        text,
        deviceId,
        attachments,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAttachment(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachment(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Получение сообщений чата
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMessages(
      authorization: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupedChatMessages>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(
        authorization,
        page,
        size,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Получить новые сообщения
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewMessages(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NewChatMessages>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNewMessages(
        authorization,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Подписка для получения количества непрочитанных сообщений клиента
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleSse(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handleSse(
        authorization,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Скачать вложение
     * @param {string} authorization
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadChatAttachment(
      authorization: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.loadChatAttachment(
          authorization,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} authorization
     * @param {MakeRatingRequest} makeRatingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async makeRating(
      authorization: string,
      makeRatingRequest: MakeRatingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.makeRating(
        authorization,
        makeRatingRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} authorization
     * @param {MarkReadRequest} [markReadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markRead(
      authorization: string,
      markReadRequest?: MarkReadRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markRead(
        authorization,
        markReadRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async messageList(
      authorization: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ChatMessagePage>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.messageList(
        authorization,
        page,
        size,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ChatApiFp(configuration);
  return {
    /**
     *
     * @summary Отправка нового сообщения
     * @param {string} authorization
     * @param {string} [text]
     * @param {string} [deviceId]
     * @param {Array<File>} [attachments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMessage(
      authorization: string,
      text?: string,
      deviceId?: string,
      attachments?: Array<File>,
      options?: any
    ): AxiosPromise<ChatMessageDto> {
      return localVarFp
        .createMessage(authorization, text, deviceId, attachments, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Отправка нового сообщения
     * @param {string} authorization
     * @param {string} [text]
     * @param {string} [deviceId]
     * @param {Array<File>} [attachments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMessageV2(
      authorization: string,
      text?: string,
      deviceId?: string,
      attachments?: Array<File>,
      options?: any
    ): AxiosPromise<ChatMessage> {
      return localVarFp
        .createMessageV2(authorization, text, deviceId, attachments, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAttachment(id: string, options?: any): AxiosPromise<File> {
      return localVarFp
        .getAttachment(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение сообщений чата
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMessages(
      authorization: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GroupedChatMessages> {
      return localVarFp
        .getMessages(authorization, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить новые сообщения
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewMessages(
      authorization: string,
      options?: any
    ): AxiosPromise<NewChatMessages> {
      return localVarFp
        .getNewMessages(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Подписка для получения количества непрочитанных сообщений клиента
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleSse(authorization: string, options?: any): AxiosPromise<SseEmitter> {
      return localVarFp
        .handleSse(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Скачать вложение
     * @param {string} authorization
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadChatAttachment(
      authorization: string,
      id: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .loadChatAttachment(authorization, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization
     * @param {MakeRatingRequest} makeRatingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    makeRating(
      authorization: string,
      makeRatingRequest: MakeRatingRequest,
      options?: any
    ): AxiosPromise<ChatMessageDto> {
      return localVarFp
        .makeRating(authorization, makeRatingRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization
     * @param {MarkReadRequest} [markReadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markRead(
      authorization: string,
      markReadRequest?: MarkReadRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .markRead(authorization, markReadRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    messageList(
      authorization: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<ChatMessagePage> {
      return localVarFp
        .messageList(authorization, page, size, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
  /**
   *
   * @summary Отправка нового сообщения
   * @param {string} authorization
   * @param {string} [text]
   * @param {string} [deviceId]
   * @param {Array<File>} [attachments]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public createMessage(
    authorization: string,
    text?: string,
    deviceId?: string,
    attachments?: Array<File>,
    options?: AxiosRequestConfig
  ) {
    return ChatApiFp(this.configuration)
      .createMessage(authorization, text, deviceId, attachments, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отправка нового сообщения
   * @param {string} authorization
   * @param {string} [text]
   * @param {string} [deviceId]
   * @param {Array<File>} [attachments]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public createMessageV2(
    authorization: string,
    text?: string,
    deviceId?: string,
    attachments?: Array<File>,
    options?: AxiosRequestConfig
  ) {
    return ChatApiFp(this.configuration)
      .createMessageV2(authorization, text, deviceId, attachments, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public getAttachment(id: string, options?: AxiosRequestConfig) {
    return ChatApiFp(this.configuration)
      .getAttachment(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение сообщений чата
   * @param {string} authorization
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public getMessages(
    authorization: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ChatApiFp(this.configuration)
      .getMessages(authorization, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить новые сообщения
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public getNewMessages(authorization: string, options?: AxiosRequestConfig) {
    return ChatApiFp(this.configuration)
      .getNewMessages(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Подписка для получения количества непрочитанных сообщений клиента
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public handleSse(authorization: string, options?: AxiosRequestConfig) {
    return ChatApiFp(this.configuration)
      .handleSse(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Скачать вложение
   * @param {string} authorization
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public loadChatAttachment(
    authorization: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return ChatApiFp(this.configuration)
      .loadChatAttachment(authorization, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization
   * @param {MakeRatingRequest} makeRatingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public makeRating(
    authorization: string,
    makeRatingRequest: MakeRatingRequest,
    options?: AxiosRequestConfig
  ) {
    return ChatApiFp(this.configuration)
      .makeRating(authorization, makeRatingRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization
   * @param {MarkReadRequest} [markReadRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public markRead(
    authorization: string,
    markReadRequest?: MarkReadRequest,
    options?: AxiosRequestConfig
  ) {
    return ChatApiFp(this.configuration)
      .markRead(authorization, markReadRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public messageList(
    authorization: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return ChatApiFp(this.configuration)
      .messageList(authorization, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Получение списка категорий контента
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentCategories: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getContentCategories", "authorization", authorization);
      const localVarPath = `/v1/content/category`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение содержимого по выбранному контенту
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentItem: async (
      authorization: string,
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getContentItem", "authorization", authorization);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getContentItem", "id", id);
      const localVarPath = `/v1/content/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получение списка контента для отобржения в ленте
     * @param {string} authorization
     * @param {string} [type] Тип категории контента
     * @param {string} [category] Код категории контента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContents: async (
      authorization: string,
      type?: string,
      category?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getContents", "authorization", authorization);
      const localVarPath = `/v1/content`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (category !== undefined) {
        localVarQueryParameter["category"] = category;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Получение списка категорий контента
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContentCategories(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ContentCategoryResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContentCategories(
          authorization,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Получение содержимого по выбранному контенту
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContentItem(
      authorization: string,
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ContentResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContentItem(
        authorization,
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Получение списка контента для отобржения в ленте
     * @param {string} authorization
     * @param {string} [type] Тип категории контента
     * @param {string} [category] Код категории контента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContents(
      authorization: string,
      type?: string,
      category?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ContentShortResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContents(
        authorization,
        type,
        category,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ContentApiFp(configuration);
  return {
    /**
     *
     * @summary Получение списка категорий контента
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentCategories(
      authorization: string,
      options?: any
    ): AxiosPromise<ContentCategoryResponse> {
      return localVarFp
        .getContentCategories(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение содержимого по выбранному контенту
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContentItem(
      authorization: string,
      id: number,
      options?: any
    ): AxiosPromise<ContentResponse> {
      return localVarFp
        .getContentItem(authorization, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получение списка контента для отобржения в ленте
     * @param {string} authorization
     * @param {string} [type] Тип категории контента
     * @param {string} [category] Код категории контента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContents(
      authorization: string,
      type?: string,
      category?: string,
      options?: any
    ): AxiosPromise<ContentShortResponse> {
      return localVarFp
        .getContents(authorization, type, category, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
  /**
   *
   * @summary Получение списка категорий контента
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentApi
   */
  public getContentCategories(
    authorization: string,
    options?: AxiosRequestConfig
  ) {
    return ContentApiFp(this.configuration)
      .getContentCategories(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение содержимого по выбранному контенту
   * @param {string} authorization
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentApi
   */
  public getContentItem(
    authorization: string,
    id: number,
    options?: AxiosRequestConfig
  ) {
    return ContentApiFp(this.configuration)
      .getContentItem(authorization, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получение списка контента для отобржения в ленте
   * @param {string} authorization
   * @param {string} [type] Тип категории контента
   * @param {string} [category] Код категории контента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContentApi
   */
  public getContents(
    authorization: string,
    type?: string,
    category?: string,
    options?: AxiosRequestConfig
  ) {
    return ContentApiFp(this.configuration)
      .getContents(authorization, type, category, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkNewNotifications: async (
      authorization: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists(
        "checkNewNotifications",
        "authorization",
        authorization
      );
      const localVarPath = `/v1/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Скачать вложение (decoded)
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadAttachment: async (
      authorization: string,
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("downloadAttachment", "authorization", authorization);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("downloadAttachment", "id", id);
      const localVarPath = `/v2/attachment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getAllNews
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNews: async (
      authorization: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getAllNews", "authorization", authorization);
      const localVarPath = `/v1/news`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getAllNews
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNewsV2: async (
      authorization: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getAllNewsV2", "authorization", authorization);
      const localVarPath = `/v2/news`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary getAllNews with info about attachment
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNewsV3: async (
      authorization: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getAllNewsV3", "authorization", authorization);
      const localVarPath = `/v3/news`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get all notifications
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications: async (
      authorization: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getNotifications", "authorization", authorization);
      const localVarPath = `/v1/notification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get all notifications
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationsV2: async (
      authorization: string,
      page?: number,
      size?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("getNotificationsV2", "authorization", authorization);
      const localVarPath = `/v2/notification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузить вложение
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadAttachment: async (
      authorization: string,
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("loadAttachment", "authorization", authorization);
      // verify required parameter 'id' is not null or undefined
      assertParamExists("loadAttachment", "id", id);
      const localVarPath = `/v1/attachment/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary readNotification
     * @param {string} authorization
     * @param {NotificationToRead} notificationToRead
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readNotification: async (
      authorization: string,
      notificationToRead: NotificationToRead,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("readNotification", "authorization", authorization);
      // verify required parameter 'notificationToRead' is not null or undefined
      assertParamExists(
        "readNotification",
        "notificationToRead",
        notificationToRead
      );
      const localVarPath = `/v1/read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/json;charset=utf-8";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationToRead,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkNewNotifications(
      authorization: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CheckNewNotificationsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkNewNotifications(
          authorization,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Скачать вложение (decoded)
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadAttachment(
      authorization: string,
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadAttachment(
          authorization,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary getAllNews
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllNews(
      authorization: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationsArrayValue>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNews(
        authorization,
        page,
        size,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary getAllNews
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllNewsV2(
      authorization: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupedNotificationDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNewsV2(
        authorization,
        page,
        size,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary getAllNews with info about attachment
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllNewsV3(
      authorization: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupedNotificationAttachmentInfoDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNewsV3(
        authorization,
        page,
        size,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary get all notifications
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotifications(
      authorization: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationsArrayValue>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotifications(
          authorization,
          page,
          size,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary get all notifications
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotificationsV2(
      authorization: string,
      page?: number,
      size?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GroupedNotificationDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNotificationsV2(
          authorization,
          page,
          size,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Загрузить вложение
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadAttachment(
      authorization: string,
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationAttachmentDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loadAttachment(
        authorization,
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary readNotification
     * @param {string} authorization
     * @param {NotificationToRead} notificationToRead
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readNotification(
      authorization: string,
      notificationToRead: NotificationToRead,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.readNotification(
          authorization,
          notificationToRead,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationApiFp(configuration);
  return {
    /**
     *
     * @param {string} authorization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkNewNotifications(
      authorization: string,
      options?: any
    ): AxiosPromise<CheckNewNotificationsResponse> {
      return localVarFp
        .checkNewNotifications(authorization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Скачать вложение (decoded)
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadAttachment(
      authorization: string,
      id: number,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .downloadAttachment(authorization, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary getAllNews
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNews(
      authorization: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<NotificationsArrayValue> {
      return localVarFp
        .getAllNews(authorization, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary getAllNews
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNewsV2(
      authorization: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GroupedNotificationDto> {
      return localVarFp
        .getAllNewsV2(authorization, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary getAllNews with info about attachment
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNewsV3(
      authorization: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GroupedNotificationAttachmentInfoDto> {
      return localVarFp
        .getAllNewsV3(authorization, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary get all notifications
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications(
      authorization: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<NotificationsArrayValue> {
      return localVarFp
        .getNotifications(authorization, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary get all notifications
     * @param {string} authorization
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificationsV2(
      authorization: string,
      page?: number,
      size?: number,
      options?: any
    ): AxiosPromise<GroupedNotificationDto> {
      return localVarFp
        .getNotificationsV2(authorization, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузить вложение
     * @param {string} authorization
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadAttachment(
      authorization: string,
      id: number,
      options?: any
    ): AxiosPromise<NotificationAttachmentDto> {
      return localVarFp
        .loadAttachment(authorization, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary readNotification
     * @param {string} authorization
     * @param {NotificationToRead} notificationToRead
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readNotification(
      authorization: string,
      notificationToRead: NotificationToRead,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .readNotification(authorization, notificationToRead, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   *
   * @param {string} authorization
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public checkNewNotifications(
    authorization: string,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .checkNewNotifications(authorization, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Скачать вложение (decoded)
   * @param {string} authorization
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public downloadAttachment(
    authorization: string,
    id: number,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .downloadAttachment(authorization, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary getAllNews
   * @param {string} authorization
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getAllNews(
    authorization: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .getAllNews(authorization, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary getAllNews
   * @param {string} authorization
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getAllNewsV2(
    authorization: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .getAllNewsV2(authorization, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary getAllNews with info about attachment
   * @param {string} authorization
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getAllNewsV3(
    authorization: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .getAllNewsV3(authorization, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary get all notifications
   * @param {string} authorization
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getNotifications(
    authorization: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .getNotifications(authorization, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary get all notifications
   * @param {string} authorization
   * @param {number} [page]
   * @param {number} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getNotificationsV2(
    authorization: string,
    page?: number,
    size?: number,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .getNotificationsV2(authorization, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузить вложение
   * @param {string} authorization
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public loadAttachment(
    authorization: string,
    id: number,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .loadAttachment(authorization, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary readNotification
   * @param {string} authorization
   * @param {NotificationToRead} notificationToRead
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public readNotification(
    authorization: string,
    notificationToRead: NotificationToRead,
    options?: AxiosRequestConfig
  ) {
    return NotificationApiFp(this.configuration)
      .readNotification(authorization, notificationToRead, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OtpApi - axios parameter creator
 * @export
 */
export const OtpApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Повторно отправить одноразовый пароль
     * @param {RetryOneTimePasswordRequest} [retryOneTimePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retryOneTimePassword: async (
      retryOneTimePasswordRequest?: RetryOneTimePasswordRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/otp/repeat_message`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] =
        "application/json;charset=utf-8";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        retryOneTimePasswordRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OtpApi - functional programming interface
 * @export
 */
export const OtpApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OtpApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Повторно отправить одноразовый пароль
     * @param {RetryOneTimePasswordRequest} [retryOneTimePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retryOneTimePassword(
      retryOneTimePasswordRequest?: RetryOneTimePasswordRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.retryOneTimePassword(
          retryOneTimePasswordRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * OtpApi - factory interface
 * @export
 */
export const OtpApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OtpApiFp(configuration);
  return {
    /**
     *
     * @summary Повторно отправить одноразовый пароль
     * @param {RetryOneTimePasswordRequest} [retryOneTimePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retryOneTimePassword(
      retryOneTimePasswordRequest?: RetryOneTimePasswordRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .retryOneTimePassword(retryOneTimePasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OtpApi - object-oriented interface
 * @export
 * @class OtpApi
 * @extends {BaseAPI}
 */
export class OtpApi extends BaseAPI {
  /**
   *
   * @summary Повторно отправить одноразовый пароль
   * @param {RetryOneTimePasswordRequest} [retryOneTimePasswordRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OtpApi
   */
  public retryOneTimePassword(
    retryOneTimePasswordRequest?: RetryOneTimePasswordRequest,
    options?: AxiosRequestConfig
  ) {
    return OtpApiFp(this.configuration)
      .retryOneTimePassword(retryOneTimePasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PushNotificationApi - axios parameter creator
 * @export
 */
export const PushNotificationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} authorization
     * @param {PushStateRequest} [pushStateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushState: async (
      authorization: string,
      pushStateRequest?: PushStateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("pushState", "authorization", authorization);
      const localVarPath = `/v1/push/state`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/json;charset=utf-8";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pushStateRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} authorization
     * @param {ToggleNotificationRequest} [toggleNotificationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    togglePush: async (
      authorization: string,
      toggleNotificationRequest?: ToggleNotificationRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists("togglePush", "authorization", authorization);
      const localVarPath = `/v1/push/toggle`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authorization != null) {
        localVarHeaderParameter["Authorization"] = String(authorization);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/json;charset=utf-8";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        toggleNotificationRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PushNotificationApi - functional programming interface
 * @export
 */
export const PushNotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PushNotificationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} authorization
     * @param {PushStateRequest} [pushStateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pushState(
      authorization: string,
      pushStateRequest?: PushStateRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PushState200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pushState(
        authorization,
        pushStateRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @param {string} authorization
     * @param {ToggleNotificationRequest} [toggleNotificationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async togglePush(
      authorization: string,
      toggleNotificationRequest?: ToggleNotificationRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PushState200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.togglePush(
        authorization,
        toggleNotificationRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PushNotificationApi - factory interface
 * @export
 */
export const PushNotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PushNotificationApiFp(configuration);
  return {
    /**
     *
     * @param {string} authorization
     * @param {PushStateRequest} [pushStateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pushState(
      authorization: string,
      pushStateRequest?: PushStateRequest,
      options?: any
    ): AxiosPromise<PushState200Response> {
      return localVarFp
        .pushState(authorization, pushStateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization
     * @param {ToggleNotificationRequest} [toggleNotificationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    togglePush(
      authorization: string,
      toggleNotificationRequest?: ToggleNotificationRequest,
      options?: any
    ): AxiosPromise<PushState200Response> {
      return localVarFp
        .togglePush(authorization, toggleNotificationRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PushNotificationApi - object-oriented interface
 * @export
 * @class PushNotificationApi
 * @extends {BaseAPI}
 */
export class PushNotificationApi extends BaseAPI {
  /**
   *
   * @param {string} authorization
   * @param {PushStateRequest} [pushStateRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushNotificationApi
   */
  public pushState(
    authorization: string,
    pushStateRequest?: PushStateRequest,
    options?: AxiosRequestConfig
  ) {
    return PushNotificationApiFp(this.configuration)
      .pushState(authorization, pushStateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization
   * @param {ToggleNotificationRequest} [toggleNotificationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PushNotificationApi
   */
  public togglePush(
    authorization: string,
    toggleNotificationRequest?: ToggleNotificationRequest,
    options?: AxiosRequestConfig
  ) {
    return PushNotificationApiFp(this.configuration)
      .togglePush(authorization, toggleNotificationRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
