import React from "react";
import { Box, Typography } from "@mui/material";

import { ProfileModalType } from "./models";
import { IconChevronRight } from "atoms/IconChevronRight/IconChevronRight";

type ProfileOptionProps = {
  title: string;
  id: ProfileModalType;
  onClick: (id: ProfileModalType) => void;
  icon: React.ReactNode;
};

export const ProfileOption = (props: ProfileOptionProps) => {
  const { title, id, onClick, icon } = props;

  const handleClick = () => {
    onClick(id);
  };

  return (
    <Box
      sx={{
        cursor: "pointer",
        borderRadius: 15,
        backgroundColor: "var(--main-color-bg-widgets)",
        "&:hover": {
          background: "var(--brand-color-hover)",
        },
        "&:active": {
          background: "var(--brand-color-hover)",
        },
      }}
      p={{ xs: 12, xl: 12 }}
      display="flex"
      gap={12}
      alignItems="center"
      onClick={handleClick}
    >
      <Box
        sx={{
          borderRadius: 11,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 40,
          height: 40,
          backgroundColor: "#FFFFFF",
        }}
      >
        {icon}
      </Box>
      <Typography variant="text_22" fontWeight={500} color="gray.b800" flex={1}>
        {title}
      </Typography>

      <IconChevronRight color="var(--main-color-text-title)" />
    </Box>
  );
};
