import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { throttle } from "utils/throttle";
import { useAuthCommonActions } from "hooks";
import { INACTIVITY_TIMEOUT } from "utils/constants";
import { useNavigate } from "react-router-dom";
import { authActions } from "store/slices/auth";

// События, которые считаются активностью пользователя
const events = [
  "mousemove",
  "mousedown",
  "keydown",
  "click",
  "touchstart",
  "wheel",
  "scroll",
];

// Задержка для throttling
const THROTTLE_DELAY = 2000;
const EXPIRE_TIME_KEY = "expireTime";

const ActivityMonitor = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  // const { handleLogout } = useAuthCommonActions("/pin-enter");

  // Функция для обновления последней активности
  const updateExpiryTime = () => {
    const expireTime = Date.now() + INACTIVITY_TIMEOUT; // in milliseconds
    localStorage.setItem(EXPIRE_TIME_KEY, expireTime.toString());
  };

  const throttledUpdateExpiryTime = throttle(updateExpiryTime, THROTTLE_DELAY);

  const checkInactivity = () => {
    const expireTimeString = localStorage.getItem(EXPIRE_TIME_KEY);
    const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

    if (expireTime < Date.now()) {
      //logout
      dispatch(authActions.setLogout());

      sessionStorage.removeItem("isAuthenticated");
      sessionStorage.removeItem("accessToken");

      nav("/pin-enter", { replace: true });
    }
  };

  useEffect(() => {
    // Добавление слушателей событий для отслеживания активности
    events.forEach((event) => {
      window.addEventListener(event, throttledUpdateExpiryTime);
    });

    // Удаляем слушатели при размонтировании
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, throttledUpdateExpiryTime);
      });
    };
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkInactivity();
    }, 10000); // Check for inactivity every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return null; // Компонент не выводит UI
};

export default ActivityMonitor;
// втавлять в компонент, который появляется лишь когда пользователь активный. в крайнем случае создать обертку для всех активных пайдж компонентов
