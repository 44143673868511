import React from "react";

export const IconLogoutMobile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="151"
      height="153"
      viewBox="0 0 151 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#F5F5F5"
      />
      <g filter="url(#filter0_d_316_13002)">
        <mask
          id="mask0_316_13002"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="151"
          height="150"
        >
          <path
            d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
            fill="url(#paint0_linear_316_13002)"
          />
        </mask>
        <g mask="url(#mask0_316_13002)">
          <rect x="32" y="36" width="87" height="121" rx="7" fill="white" />
          <rect x="36" y="40" width="79" height="114" rx="5" fill="#F5F5F5" />
          <path
            d="M75.5 110C88.7548 110 99.5 99.2548 99.5 86C99.5 72.7452 88.7548 62 75.5 62C62.2452 62 51.5 72.7452 51.5 86C51.5 99.2548 62.2452 110 75.5 110Z"
            fill="#739B67"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.4622 76.6739C76.2907 76.6739 76.9622 77.3455 76.9622 78.1739V80.1697C76.9622 80.9981 77.6338 81.6697 78.4622 81.6697C79.2907 81.6697 79.9622 80.9982 79.9622 80.1697V78.1739C79.9622 75.6886 77.9475 73.6739 75.4622 73.6739H65.375C62.8897 73.6739 60.875 75.6886 60.875 78.1739V93.6235C60.875 96.1088 62.8897 98.1235 65.375 98.1235L75.4622 98.1235C77.9475 98.1235 79.9622 96.1088 79.9622 93.6235V91.5649C79.9622 90.7364 79.2907 90.0649 78.4622 90.0649C77.6338 90.0649 76.9622 90.7364 76.9622 91.5649V93.6235C76.9622 94.452 76.2907 95.1235 75.4622 95.1235L65.375 95.1235C64.5466 95.1235 63.875 94.452 63.875 93.6235L63.875 78.1739C63.875 77.3455 64.5466 76.6739 65.375 76.6739H75.4622ZM74.0833 87.0418H87.0256L82.9218 91.0496C82.5267 91.4355 82.5192 92.0686 82.9051 92.4637C83.291 92.8588 83.9241 92.8663 84.3192 92.4804L90.4942 86.4498C90.6868 86.2617 90.7955 86.0037 90.7955 85.7344C90.7955 85.4651 90.6868 85.2072 90.4942 85.019L84.3192 78.9884C83.9241 78.6025 83.291 78.61 82.9051 79.0051C82.5192 79.4002 82.5267 80.0334 82.9218 80.4192L86.6311 84.0418H74.0833C73.2549 84.0418 72.5833 84.7133 72.5833 85.5418C72.5833 86.3702 73.2549 87.0418 74.0833 87.0418Z"
            fill="white"
          />
        </g>
      </g>
      <rect x="69" y="44" width="11" height="2" rx="1" fill="white" />
      <rect x="81" y="44" width="2" height="2" rx="1" fill="white" />
      <defs>
        <filter
          id="filter0_d_316_13002"
          x="26"
          y="27"
          width="99"
          height="126"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_316_13002"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_316_13002"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_316_13002"
          x1="75.5"
          y1="0"
          x2="75.5"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3ECFA" />
          <stop offset="1" stopColor="#DAE7FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
