import React from "react";
import useSizeKey from "hooks/useSize";
import { SizeType } from "./Grid";
import { findAvailableSizeKey } from "utils/findAvailableSizeKey";

type Orders = {
  [key in SizeType]?: number;
};

type Columns = {
  [key in SizeType]?: [number, number];
};

export interface GridItemProps {
  children: React.ReactNode;
  columns: Columns;
  orders?: Orders;
}

/**
 * Компонент GridItem для размещения элементов внутри сетки
 *
 * @param {React.ReactNode} children - Дочерние элементы, которые будут отображаться внутри GridItem.
 * @param {Columns} columns - Объект, содержащий информацию о расположении колонок для разных размеров экрана.
 * @param {Orders} [orders] - Объект, содержащий порядок отображения элементов для разных размеров экрана.
 */

const GridItem: React.FC<GridItemProps> = ({ children, columns, orders }) => {
  let size: SizeType = useSizeKey();
  let itemStyle = {};

  if (orders) {
    if (!orders[size]) {
      size = findAvailableSizeKey(size, orders);
    }
    const order = orders[size] || 0;

    itemStyle = {
      ...itemStyle,
      order,
    };
  }

  if (columns) {
    if (!columns[size]) {
      size = findAvailableSizeKey(size, columns);
    }

    let gridColumn: string | "auto" = "auto";

    if (columns[size]) {
      const [start, end] = columns[size]!;
      gridColumn = `${start} / ${end}`;
    }

    itemStyle = {
      ...itemStyle,
      gridColumn,
    };
  }

  return <div style={itemStyle}>{children}</div>;
};

export default GridItem;
