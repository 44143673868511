import React from "react";

interface IconDocProps extends React.SVGProps<SVGSVGElement> {}

export const IconDoc = (props: IconDocProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50833 1.66699H13.4925C16.0667 1.66699 17.5 3.15033 17.5 5.69199V14.3003C17.5 16.8837 16.0667 18.3337 13.4925 18.3337H6.50833C3.975 18.3337 2.5 16.8837 2.5 14.3003V5.69199C2.5 3.15033 3.975 1.66699 6.50833 1.66699ZM6.73333 5.55033V5.54199H9.22417C9.58333 5.54199 9.875 5.83366 9.875 6.19116C9.875 6.55866 9.58333 6.85033 9.22417 6.85033H6.73333C6.37417 6.85033 6.08333 6.55866 6.08333 6.20033C6.08333 5.84199 6.37417 5.55033 6.73333 5.55033ZM6.73333 10.617H13.2667C13.625 10.617 13.9167 10.3253 13.9167 9.96699C13.9167 9.60866 13.625 9.31616 13.2667 9.31616H6.73333C6.37417 9.31616 6.08333 9.60866 6.08333 9.96699C6.08333 10.3253 6.37417 10.617 6.73333 10.617ZM6.73333 14.4253H13.2667C13.5992 14.392 13.85 14.1078 13.85 13.7753C13.85 13.4337 13.5992 13.1503 13.2667 13.117H6.73333C6.48333 13.092 6.24167 13.2087 6.10833 13.4253C5.975 13.6337 5.975 13.9087 6.10833 14.1253C6.24167 14.3337 6.48333 14.4587 6.73333 14.4253Z"
        fill="#739B67"
        fillOpacity={props.opacity}
      />
    </svg>
  );
};
