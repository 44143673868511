export const parseDate = (dateStr: string): Date => {
  const months: { [key: string]: string } = {
    января: "01",
    февраля: "02",
    марта: "03",
    апреля: "04",
    мая: "05",
    июня: "06",
    июля: "07",
    августа: "08",
    сентября: "09",
    октября: "10",
    ноября: "11",
    декабря: "12",
  };

  const [day, monthName, year] = dateStr.replace(",", "").split(" ");
  const month = months[monthName.toLowerCase()];
  const dateString = `${year}-${month}-${day.padStart(2, "0")}`;
  return new Date(dateString);
};
