import React from "react";
import styles from "./styles.module.css";

interface IconWrapperProps {
  size?: number;
  bgColor?: string;
  borderWidth?: string;
  borderColor?: string;
  br?: string;

  children: React.ReactNode;
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  size = "40px",
  bgColor = "#fff",
  borderWidth = "0px",
  borderColor = "transparent",
  br = "10px",
  children,
}) => {
  const inlineStyles = {
    backgroundColor: bgColor,
    borderWidth,
    borderColor,
    width: size,
    height: size,
    borderRadius: br,
  } as React.CSSProperties;

  return (
    <div className={styles.wrapper} style={inlineStyles}>
      {children}
    </div>
  );
};

export default IconWrapper;
