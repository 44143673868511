/* eslint-disable no-console */
import { initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";
var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestPermission = () => {
  const isSupported = () =>
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window;

  return new Promise((resolve, reject) => {
    if (!isSupported()) {
      reject({
        text: "Уведомления не поддерживаются на вашем устройстве",
        isOn: false,
      });
      return;
    }

    if (!(window.Notification && window.Notification.requestPermission)) {
      reject({
        text: "Включите push-уведомления для браузера",
        isOn: false,
        alert: true,
      });
      return;
    }

    window.Notification.requestPermission()
      .then((permission) => {
        if (permission !== "granted") {
          reject({
            text: "Разрешите браузеру получать уведомления",
            isOn: false,
            alert: true,
          });
          return;
        }

        getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              resolve({ text: "успешно", isOn: true, token: currentToken });
            } else {
              reject({
                text: "Попробуйте ещё раз",
                isOn: false,
              });
            }
          })
          .catch(() => {
            reject({
              text: "Попробуйте ещё раз",
              isOn: false,
            });
          });
      })
      .catch(() => {
        reject({
          text: "Системная ошибка при запросе разрешения",
          isOn: false,
          alert: true,
        });
      });
  });
};

export const deleteFCMToken = async () => {
  const currentToken = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  });
  if (currentToken) {
    await deleteToken(messaging);
  }
};

export const getFCMToken = async () => {
  const currentToken = await getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  });
  return currentToken;
};
