import React from "react";

interface IconBuyProps extends React.SVGProps<SVGSVGElement> {}

export const IconBuy = (props: IconBuyProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7678 9.36126H14.0744C14.4241 9.36126 14.6989 9.07211 14.6989 8.72344C14.6989 8.36626 14.4241 8.08562 14.0744 8.08562H11.7678C11.4181 8.08562 11.1433 8.36626 11.1433 8.72344C11.1433 9.07211 11.4181 9.36126 11.7678 9.36126ZM16.8139 4.9399C17.3218 4.9399 17.6549 5.11849 17.988 5.50969C18.3211 5.90088 18.3793 6.46216 18.3044 6.97157L17.5134 12.5503C17.3635 13.6227 16.4642 14.4128 15.4067 14.4128H6.32207C5.2146 14.4128 4.29865 13.5462 4.20705 12.4236L3.44098 3.15316L2.18363 2.93205C1.85056 2.87252 1.6174 2.54086 1.67569 2.20069C1.73398 1.85286 2.05873 1.6224 2.40013 1.67427L4.38608 1.97958C4.66919 2.03145 4.87736 2.26872 4.90234 2.55786L5.06055 4.46282C5.08554 4.7358 5.30203 4.9399 5.56849 4.9399H16.8139ZM6.18867 15.7569C5.48922 15.7569 4.92299 16.3352 4.92299 17.0495C4.92299 17.7554 5.48922 18.3337 6.18867 18.3337C6.8798 18.3337 7.44603 17.7554 7.44603 17.0495C7.44603 16.3352 6.8798 15.7569 6.18867 15.7569ZM15.5564 15.7569C14.8569 15.7569 14.2907 16.3352 14.2907 17.0495C14.2907 17.7554 14.8569 18.3337 15.5564 18.3337C16.2475 18.3337 16.8137 17.7554 16.8137 17.0495C16.8137 16.3352 16.2475 15.7569 15.5564 15.7569Z"
        fill="#739B67"
        fillOpacity={props.opacity}
      />
    </svg>
  );
};
