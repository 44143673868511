import React from "react";

export const TransferIn = ({ fill = "#0082BA" }) => {
  return (
    <svg
      width={57}
      height={56}
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={0.5} width={56} height={56} rx={28} fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.1 16.267c.59 0 1.067.477 1.067 1.066v19.2c0 .59-.478 1.067-1.067 1.067h-1.067v1.067a1.067 1.067 0 01-2.133 0V37.6H22.1v1.067a1.067 1.067 0 11-2.133 0V37.6H18.9c-.59 0-1.067-.478-1.067-1.067v-19.2c0-.589.478-1.066 1.067-1.066h19.2zM37.033 18.4H19.967v17.067h17.066V18.4zM28.5 22.667a4.266 4.266 0 110 8.532 4.266 4.266 0 010-8.533zm0 2.133a2.133 2.133 0 100 4.266 2.133 2.133 0 000-4.266z"
        fill={fill}
      />
    </svg>
  );
};
