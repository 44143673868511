import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Loader } from "components";
import { PIN_LENGTH } from "organisms/PinCode";
import { FC } from "react";

interface PinValueProps {
  value: string;
  isError: boolean;
  isLoading?: boolean;
}

const PinWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  position: relative;
`;

const PinItem = styled.div<{ isFilled?: boolean; isError?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 8px;
  background: ${(props) =>
    props.isFilled
      ? "var(--brand-color-primary)"
      : props.isError
      ? "var(--error-color-illustration)"
      : "var(--main-color-border-icon)"};
`;

export const PinValue: FC<PinValueProps> = ({ value, isError, isLoading }) => {
  return (
    <PinWrapper>
      {isLoading ? (
        <Box sx={{ height: 20 }}>
          <Loader size={48} />
        </Box>
      ) : (
        Array.from(Array(PIN_LENGTH).keys()).map((index) => {
          return (
            <PinItem
              key={index}
              isFilled={index + 1 <= value.length}
              isError={isError}
            />
          );
        })
      )}
      {}
    </PinWrapper>
  );
};
