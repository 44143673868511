interface IconBlockedProps extends React.SVGProps<SVGSVGElement> {}

export const IconBlocked = (props: IconBlockedProps) => {
  return (
    <svg
      width="150"
      height="153"
      viewBox="0 0 150 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#FFF4F4"
      />
      <g filter="url(#filter0_d_137_13868)">
        <mask
          id="mask0_137_13868"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="150"
          height="150"
        >
          <path
            d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
            fill="#FFF4F4"
          />
        </mask>
        <g mask="url(#mask0_137_13868)">
          <rect x="31.5" y="36" width="87" height="121" rx="7" fill="white" />
          <rect x="35.5" y="40" width="79" height="114" rx="5" fill="#FCD8D8" />
        </g>
      </g>
      <path
        d="M75.5 119C88.7548 119 99.5 108.255 99.5 95C99.5 81.7452 88.7548 71 75.5 71C62.2452 71 51.5 81.7452 51.5 95C51.5 108.255 62.2452 119 75.5 119Z"
        fill="#E64545"
      />
      <path
        d="M85.0264 104.528C84.2454 105.309 82.9791 105.309 82.198 104.528L75.127 97.4565L68.0559 104.528C67.2748 105.309 66.0085 105.309 65.2275 104.528C64.4464 103.747 64.4464 102.48 65.2275 101.699L72.2985 94.6281L65.2275 87.557C64.4464 86.776 64.4464 85.5096 65.2275 84.7286C66.0085 83.9475 67.2748 83.9475 68.0559 84.7286L75.127 91.7997L82.198 84.7286C82.9791 83.9475 84.2454 83.9475 85.0264 84.7286C85.8075 85.5096 85.8075 86.776 85.0264 87.557L77.9554 94.6281L85.0264 101.699C85.8075 102.48 85.8075 103.747 85.0264 104.528Z"
        fill="white"
      />
      <rect x="68.5" y="44" width="11" height="2" rx="1" fill="white" />
      <rect x="80.5" y="44" width="2" height="2" rx="1" fill="white" />
      <defs>
        <filter
          id="filter0_d_137_13868"
          x="25.5"
          y="27"
          width="99"
          height="126"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_137_13868"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_137_13868"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
