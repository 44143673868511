export const isMonth = (from, to) => {
  if (from.getDate() === 1) {
    const month = from.getMonth();
    const year = from.getFullYear();
    if (to.getMonth() === month) {
      let currentDate = new Date();
      if (
        currentDate.getMonth() === month &&
        currentDate.getFullYear() === year
      ) {
        return to.getDate() === currentDate.getDate() && to.getMilliseconds();
      }
      let test = new Date(to.getTime());
      test.setDate(test.getDate() + 1);
      let testmonth = test.getMonth() - 1;
      if (testmonth < 0) {
        testmonth = 11;
      }
      return testmonth === month;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
