import { IconNoSearchResults } from "atoms/IconNoSearchResults/IconNoSearchResults";
import { EmptyStyled } from "../../History";

export const EmptyScreenCustom = () => {
  return (
    <EmptyStyled>
      <IconNoSearchResults />
      <h2>Ничего не найдено</h2>
      <h4>
        По вашему запросу ничего не нашлось, проверьте, правильно ли введен
        запрос
      </h4>
    </EmptyStyled>
  );
};
