import React from "react";

interface IconNoSearchResultsProps extends React.SVGProps<SVGSVGElement> {}

export const IconNoSearchResults = (props: IconNoSearchResultsProps) => {
  return (
    <svg
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#F5F5F5"
      />
      <path
        d="M75.5 118C99.2482 118 118.5 98.7482 118.5 75C118.5 51.2518 99.2482 32 75.5 32C51.7518 32 32.5 51.2518 32.5 75C32.5 98.7482 51.7518 118 75.5 118Z"
        fill="white"
      />
      <path
        d="M91.2895 93.7106L91.2894 93.7106L91.2917 93.7128C91.6825 94.0874 92.2046 94.2941 92.7459 94.2886C93.2873 94.2831 93.805 94.0658 94.1881 93.6832C94.5712 93.3007 94.7892 92.7834 94.7955 92.242C94.8018 91.7006 94.5958 91.1783 94.2217 90.787L94.2217 90.7869L94.2193 90.7845L87.1302 83.6954C89.4644 80.7314 90.8679 76.9998 90.8679 72.9479C90.8679 63.3608 83.0549 55.5517 73.4679 55.5517C63.8809 55.5517 56.0717 63.3609 56.0717 72.9479C56.0717 82.5348 63.8807 90.3593 73.4679 90.3593C77.5151 90.3593 81.2409 88.9575 84.2003 86.6252L91.2895 93.7106ZM73.4679 59.6851C80.8176 59.6851 86.7309 65.5983 86.7309 72.9479C86.7309 80.2978 80.8174 86.2259 73.4679 86.2259C66.1185 86.2259 60.205 80.2978 60.205 72.9479C60.205 65.5983 66.1183 59.6851 73.4679 59.6851Z"
        fill="#739B67"
        stroke="#739B67"
        strokeWidth="0.3"
      />
    </svg>
  );
};
