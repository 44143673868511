import React from "react";

export const IconNotificationItem = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill="#F5F5F5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8575 8.5H19.1432C21.46 8.5 22.75 9.835 22.75 12.1225V19.87C22.75 22.195 21.46 23.5 19.1432 23.5H12.8575C10.5775 23.5 9.25 22.195 9.25 19.87V12.1225C9.25 9.835 10.5775 8.5 12.8575 8.5ZM13.06 11.995V11.9875H15.3017C15.625 11.9875 15.8875 12.25 15.8875 12.5718C15.8875 12.9025 15.625 13.165 15.3017 13.165H13.06C12.7367 13.165 12.475 12.9025 12.475 12.58C12.475 12.2575 12.7367 11.995 13.06 11.995ZM13.06 16.555H18.94C19.2625 16.555 19.525 16.2925 19.525 15.97C19.525 15.6475 19.2625 15.3842 18.94 15.3842H13.06C12.7367 15.3842 12.475 15.6475 12.475 15.97C12.475 16.2925 12.7367 16.555 13.06 16.555ZM13.06 19.9825H18.94C19.2393 19.9525 19.465 19.6968 19.465 19.3975C19.465 19.09 19.2393 18.835 18.94 18.805H13.06C12.835 18.7825 12.6175 18.8875 12.4975 19.0825C12.3775 19.27 12.3775 19.5175 12.4975 19.7125C12.6175 19.9 12.835 20.0125 13.06 19.9825Z"
        fill="#739B67"
      />
    </svg>
  );
};
