import {
  BackButtonContainer,
  SmallBackButton,
} from "components/SmallBackButton";
import { useNavigate } from "react-router-dom";
import { Container, Description, Icon, Title } from "./components";
import { Typography, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "store";
import icon from "./assets/icon.png";
import { IconPhone } from "atoms/IconPhone/IconPhone";

export const LoginError = () => {
  const navigate = useNavigate();
  const { title, description } = useSelector(
    (state: RootState) => state.auth.anotherDeviceError
  );

  return (
    <>
      <BackButtonContainer>
        <SmallBackButton clickHandler={() => navigate("/sign-in")} />
      </BackButtonContainer>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Icon src={icon} />
        <Typography
          variant="text_10"
          mt={20}
          color="var(--main-color-text-secondary)"
          sx={{ display: "flex", alignItems: "center", gap: 4 }}
        >
          <IconPhone color="#D3D3D3" /> Служба техподдержки
        </Typography>
        <Link
          mt={8}
          mb={{ lg: 140 }}
          variant="text_5"
          color="#739b67"
          fontWeight="500"
          underline="none"
          href="tel:+7 (800) 200 45 67"
        >
          +7 (800) 200 45 67
        </Link>
      </Container>
    </>
  );
};
