import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCurrentPassword,
  profileActions,
  updatePassword,
} from "store/slices/profile";
import { profileSelector } from "store";
import { NotificationResult } from "molecules/NotificationResult";
import { useIsMobile } from "hooks/useIsMobile";
import { authActions } from "store/slices/auth";
import { useNavigate } from "react-router-dom";
import { useResetStore } from "hooks/useResetStore";
import { CheckBlock } from "components/CheckBlock/CheckBlock";
import { ErrorScreen } from "components/ErrorScreen/ErrorScreen";
import { isServerError } from "utils/isServerError";
import { IconLoginChangeSuccess } from "atoms/IconLoginChangeSuccess/IconLoginChangeSuccess";
import { Button } from "components/Button";

interface UpdatePasswordProps {
  onClose: () => void;
}

const CurrentPassword = () => {
  const dispatch = useDispatch();
  const { isLoading, errorCurrentPassword, currentPassword } =
    useSelector(profileSelector);
  const isDisabled = !currentPassword.length;

  const handleCurrentPassword = () => {
    dispatch(checkCurrentPassword({ password: currentPassword }));
  };

  return (
    <Box>
      <PasswordInput
        // @ts-ignore
        value={currentPassword}
        onChange={(e) => {
          if (errorCurrentPassword)
            dispatch(profileActions.clearErrorCurrentPassword());
          dispatch(profileActions.setCurrentPassword(e.target.value));
        }}
        label="Введите текущий пароль"
        placeholder="Введите пароль"
        autoComplete="off"
        error={errorCurrentPassword?.comment || ""}
        isProfile
      />
      <Button
        isLoading={isLoading}
        disabled={isDisabled}
        onClick={handleCurrentPassword}
        variant="primary"
        style={{ marginTop: "40px" }}
        title="Продолжить"
      />
    </Box>
  );
};

const NewPassword = () => {
  const dispatch = useDispatch();
  const { isLoading, error, currentPassword } = useSelector(profileSelector);

  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isDisabledCheck, setIsDisabledCheck] = useState(false);
  const [repeatPasswordFocused, setRepeatPasswordFocused] = useState(false);
  const [errorFirstField, setErrorFirstField] = useState("");

  const isDisabled = !(isDisabledCheck && newPassword === repeatPassword);

  const isDisabledChecking = useCallback((value: boolean) => {
    setIsDisabledCheck(value);
  }, []);

  const handleNewPassword = () => {
    dispatch(updatePassword({ password: currentPassword, newPassword }));
  };

  useEffect(() => {
    if (repeatPasswordFocused && !isDisabledCheck) {
      setErrorFirstField("Пароль не соответствует требованиям");
    }
    if (isDisabledCheck) {
      setErrorFirstField("");
    }
  }, [repeatPasswordFocused, isDisabledCheck]);

  return (
    <Box gap={20} display="flex" flexDirection="column">
      <PasswordInput
        // @ts-ignore
        value={newPassword}
        onChange={(e) => {
          if (error) dispatch(profileActions.clearError());
          setNewPassword(e.target.value);
        }}
        label="Придумайте пароль"
        placeholder="Введите пароль"
        error={error?.comment || errorFirstField}
        isProfile
      />
      <PasswordInput
        // @ts-ignore
        value={repeatPassword}
        onChange={(e) => {
          if (error) dispatch(profileActions.clearError());
          setRepeatPassword(e.target.value);
        }}
        label="Повторите пароль"
        placeholder="Повторите пароль"
        error={error?.comment || ""}
        checkInputFocus={setRepeatPasswordFocused}
        isProfile
      />
      <CheckBlock
        value={newPassword}
        isDisabled={isDisabledChecking}
        type="password"
        isPasswordMatch={newPassword === repeatPassword}
        isFocus={repeatPasswordFocused}
        isProfile
      />
      <Button
        isLoading={isLoading}
        disabled={isDisabled}
        onClick={handleNewPassword}
        variant="primary"
        style={{ marginTop: "20px" }}
        title="Продолжить"
      />
    </Box>
  );
};

const SuccessUpdate = ({ onClose }: UpdatePasswordProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const resetStore = useResetStore();

  const clickSuccess = () => {
    dispatch(authActions.setLogout());
    const deviceIdItem = localStorage.getItem("deviceId");
    const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
    localStorage.clear();
    if (deviceIdItem !== null) localStorage.setItem("deviceId", deviceIdItem);
    if (PWAdeviceIdItem !== null)
      localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
    sessionStorage.clear();
    navigate("/sign-in", { replace: true });
    resetStore();
    onClose();
  };

  return (
    <Box
      width="100%"
      height="100%"
      pt="64px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <NotificationResult
        title={`Ваш пароль\nбыл успешно изменён`}
        icon={<IconLoginChangeSuccess />}
        height="100%"
        buttons={[
          {
            name: "Войти",
            variant: "primary",
            size: "lg",
            onClick: clickSuccess,
          },
        ]}
        titleStyle={{
          fontWeight: 500,
          fontSize: "28px",
          lineHeight: "36px",
          textAlign: "center",
          color: "var(--main-color-notification-title)",
        }}
        buttonsMarginTop={30}
      />
    </Box>
  );
};

const UpdatePassword = ({ onClose }: UpdatePasswordProps) => {
  const { updatePasswordStep, error, errorCurrentPassword } =
    useSelector(profileSelector);
  const dispatch = useDispatch();
  const errorServer =
    isServerError(error) || isServerError(errorCurrentPassword);

  const getTitle = useCallback(() => {
    switch (updatePasswordStep) {
      case "CURRENT_PASSWORD":
        return "Введите текущий пароль";
      case "NEW_PASSWORD":
      case "SUCCESS_UPDATE":
        return "";
    }
  }, [updatePasswordStep]);

  useEffect(() => {
    return () => {
      dispatch(profileActions.clearError());
      dispatch(profileActions.clearErrorCurrentPassword());
    };
  }, [dispatch]);

  if (errorServer) {
    return (
      <ErrorScreen
        title="Не удалось изменить пароль"
        subTitle="Попробуйте снова"
        buttonTitle="Изменить пароль"
        buttonHandler={() => {
          dispatch(profileActions.setUpdatePasswordStep("CURRENT_PASSWORD"));
          dispatch(profileActions.setCurrentPassword(""));
          dispatch(profileActions.clearError());
          dispatch(profileActions.clearErrorCurrentPassword());
        }}
        isProfile
      />
    );
  }

  if (updatePasswordStep === "SUCCESS_UPDATE") {
    return <SuccessUpdate onClose={onClose} />;
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box px={{ xs: "16px", lg: "32px" }}>
          {/* <Typography
            variant="text_5"
            fontWeight={400}
            display="flex"
            color={theme.primary.main.gray[800]}
            whiteSpace="pre-wrap"
            mt={{ xs: 16 }}
          >
            {getTitle()}
          </Typography> */}
          <Box
            display="flex"
            flexDirection="column"
            gap={{ xs: 20, xl: 24 }}
            mt={{ xs: 20, xl: 24 }}
          >
            {updatePasswordStep === "CURRENT_PASSWORD" && <CurrentPassword />}
            {updatePasswordStep === "NEW_PASSWORD" && <NewPassword />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePassword;
