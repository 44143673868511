import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  currentUserApi,
  bankRateApi,
  bankAddressApi,
  informationApi,
} from "api";
import { format } from "date-fns";
import { IRootState } from "../../index";
import { UserUpdatePasswordRequest } from "../../../api/auth";
import { AddressFilterType, AgreementType, SortType } from "api/account";

export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (_, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await currentUserApi.getPmpInfo(accessToken || "");
      return {
        ...response.data,
        birthdate: response?.data?.birthdate
          ? format(new Date(response.data.birthdate || ""), "dd.MM.yyyy")
          : null,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCardsShow = createAsyncThunk(
  "getCardsshow",
  async (payload: { all: boolean }, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.updateShowAllCards("", {
        all: payload.all,
      });
      return {
        ...response.data,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateLogin = createAsyncThunk(
  "updateLogin",
  async (login: string, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await currentUserApi.updateLogin(accessToken || "", {
        login,
      });
      localStorage.setItem("login", login);
      localStorage.removeItem("useBiometry");
      return {
        ...response.data,
        birthdate: response?.data?.birthdate
          ? format(new Date(response.data.birthdate || ""), "dd.MM.yyyy")
          : null,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkCurrentPassword = createAsyncThunk(
  "checkCurrentPassword",
  async (payload: UserUpdatePasswordRequest, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.checkOldPassword("", payload);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (payload: UserUpdatePasswordRequest, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.updatePassword("", payload);
      return {
        ...response.data,
        birthdate: response?.data?.birthdate
          ? format(new Date(response.data.birthdate || ""), "dd.MM.yyyy")
          : null,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBankRates = createAsyncThunk(
  "getBankRates",
  async (_, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await bankRateApi.getBankRates(accessToken || "");
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

interface GetBankAddressArgs {
  search?: string;
  sort?: SortType;
  filters?: Array<AddressFilterType>;
  options?: any; // options?: AxiosRequestConfig
}

export const getBankAddress = createAsyncThunk(
  "getBankAddress",
  async (
    { search, sort = SortType.Asc, filters, options }: GetBankAddressArgs,
    { rejectWithValue, getState }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await bankAddressApi.getAddressInfoV2(
        accessToken || "",
        search,
        sort,
        filters,
        options
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBankDocuments = createAsyncThunk(
  "getBankDocuments",
  async (type: AgreementType, { rejectWithValue, getState }) => {
    try {
      const response = await informationApi.getDocument(type, {
        responseType: "blob",
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
