import styled from "@emotion/styled/macro";

interface DropdownArrowProps {
  open: boolean;
}

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: calc(100% + 14px);
  background: var(--main-color-bg-widgets);
  border-radius: 16px;
  z-index: 10;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 45px;
  }
`;

export const DropdownTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background: var(--main-color-bg-widgets);
  border-radius: 12px;
  color: var(--main-color-text-title);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
`;

export const DropdownItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  &:hover {
    background: var(--brand-color-hover);
  }
  &:first-child {
    border-radius: 16px 16px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 16px 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
`;
