import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatApi, notificationsApi } from "api";
import { IRootState } from "../../index";
import { notificationActions } from "./index";
import { pushApi } from "../../../api";

export interface NotificationPayload {
  page?: number;
  size?: number;
  withLoader?: boolean;
}

export const getNotificationList = createAsyncThunk(
  "getNotificationList",
  async (
    payload: NotificationPayload,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    payload.withLoader && dispatch(notificationActions.setLoading());
    try {
      const response = await notificationsApi.getNotificationsV2(
        accessToken || "",
        payload?.page,
        payload?.size
      );

      const ids = (response.data.notifications ?? [])
        .filter((item) => !item.readStatus && item.id !== undefined)
        .map((item) => item.id as number);
      if (ids.length) dispatch(readNew(ids));

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNewsList = createAsyncThunk(
  "getNewsList",
  async (
    payload: NotificationPayload,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    payload.withLoader && dispatch(notificationActions.setLoading());
    try {
      const response = await notificationsApi.getAllNewsV2(
        accessToken || "",
        payload.page,
        payload?.size
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkNotifications = createAsyncThunk(
  "checkNotifications",
  async (_, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await notificationsApi.checkNewNotifications(
        accessToken || ""
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadAttachment = createAsyncThunk(
  "loadAttachment",
  async (id: number, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await notificationsApi.loadAttachment(
        accessToken || "",
        id
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const makeRating = createAsyncThunk(
  "makeRating",
  async (payload: { rating: string; messageId: string }, { getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    const response = await chatApi.makeRating(accessToken || "", payload);
    return response.data;
  }
);

export const handleTogglePush = createAsyncThunk(
  "handleTogglePush",
  async (
    payload: {
      deviceId: string;
      fcm: string;
      device_type: string;
      enabled: boolean;
    },
    { getState }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    //@ts-ignore
    const response = await pushApi.togglePush(accessToken || "", payload);
    return response.data;
  }
);

export const handleCheckPushStatus = createAsyncThunk(
  "handleCheckPushStatus",
  async (
    payload: {
      deviceId: string;
      fcm: string;
    },
    { getState }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    const response = await pushApi.pushState(accessToken || "", payload);
    return response.data;
  }
);

export const readNew = createAsyncThunk(
  "readNew",
  async (ids: Array<number>, { getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    const response = await notificationsApi.readNotification(
      accessToken || "",
      { ids }
    );
    return response.data;
  }
);
