export const IconSuccess = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#F5F5F5"
      />
      <path
        d="M134 142C134 146.418 130.418 150 126 150H24C19.5817 150 16 146.418 16 142V59.0026C16 54.5868 19.5786 51.006 23.9944 51.0038C62.0136 50.9851 97.6462 51.0427 125.994 51.0184C130.417 51.0146 134 54.5982 134 59.0213V142Z"
        fill="white"
      />
      <path
        d="M82.3125 106H67.6875C66.7555 106 66 107.343 66 109C66 110.657 66.7555 112 67.6875 112H82.3125C83.2445 112 84 110.657 84 109C84 107.343 83.2445 106 82.3125 106Z"
        fill="#E9E9E9"
      />
      <path
        d="M97 116H53C51.3431 116 50 117.343 50 119C50 120.657 51.3431 122 53 122H97C98.6569 122 100 120.657 100 119C100 117.343 98.6569 116 97 116Z"
        fill="#E9E9E9"
      />
      <path
        d="M75 94C88.2548 94 99 83.2548 99 70C99 56.7452 88.2548 46 75 46C61.7452 46 51 56.7452 51 70C51 83.2548 61.7452 94 75 94Z"
        fill="#739B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.8418 62.1753C86.2974 61.7197 87.0361 61.7197 87.4917 62.1753C87.9123 62.5959 87.9446 63.2577 87.5888 63.7153L87.4917 63.8252L72.6825 78.6345C72.2619 79.0551 71.6001 79.0874 71.1424 78.7316L71.0325 78.6345L62.5085 70.1104C62.0528 69.6548 62.0528 68.9161 62.5085 68.4605C62.929 68.0399 63.5908 68.0076 64.0485 68.3635L64.1584 68.4605L71.8571 76.1591L85.8418 62.1753Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
