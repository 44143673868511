import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import IconChevron from "atoms/IconChevron/IconChevronLeft";
import React from "react";
import { useSelector } from "react-redux";
import { profileSelector, useAppThunkDispatch } from "store";
import { TabVariants, systemActions } from "store/slices/system";
import {
  BlockIcon,
  FlexBox,
  ImgWrapper,
  Item,
  ItemReduction,
  ItemValue,
  ItemsBlock,
} from "./components";

import { ReactComponent as GreenIcon } from "./../assets/green.svg";
import { ReactComponent as RedIcon } from "./../assets/red.svg";
import { useIsMobile } from "hooks/useIsMobile";

const ExchangeRatesScreen = () => {
  const dispatch = useAppThunkDispatch();
  const { isMobile } = useIsMobile();
  const { bankRates } = useSelector(profileSelector);

  return (
    <Box sx={{ gridColumn: "1/-1" }}>
      <Box
        sx={{
          display: "flex",
          padding: "16px 20px 16px 0",
          marginTop: isMobile ? "-28px" : "-4px",
          marginBottom: "12px",
          cursor: "pointer",
        }}
        onClick={() =>
          dispatch(systemActions.setActiveTab({ tab: TabVariants.other }))
        }
      >
        <IconChevron />
        <Typography
          sx={{
            color: "#454A3F",
            textAlign: "center",
            width: "100%",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Курсы валют в рублях (₽)
        </Typography>
      </Box>
      <ItemsBlock>
        {bankRates.map((item) => {
          const itemIcon = item.isIncrease ? <GreenIcon /> : <RedIcon />;

          const rate = item.currentRate
            .replace(/(\.\d*?)0+$/, "$1")
            .replace(/\.$/, "")
            .split(".")
            .join(", ");

          return (
            <Item key={item.currencyCode}>
              <FlexBox>
                <ImgWrapper style={{ backgroundImage: `url(${item.flag})` }} />

                <ItemReduction>
                  {item.currencySymbol} ({item.currencyCode})
                </ItemReduction>
              </FlexBox>

              <FlexBox>
                <ItemValue>{rate}</ItemValue>
                <BlockIcon>{itemIcon}</BlockIcon>
              </FlexBox>
            </Item>
          );
        })}
      </ItemsBlock>
    </Box>
  );
};

export default ExchangeRatesScreen;
