import { Typography, useTheme, Box } from "@mui/material";
import { SkeletonContainer } from "components";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenModal, transferCheck } from "store/slices/transferSlice";

import { useState, useEffect } from "react";
import ChosenCard from "./ChosenCard";
import IconInfo from "../../../../atoms/IconInfo/IconInfo";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { WarningTransfer } from "components/WarningTransfer/WarningTransfer";
import { WarningTransferAlert } from "components/WarningTransferAlert/WarningTransferAlert";
import ClickableHeader from "./ClickableHeader";
import { WarningTransferAlertMobile } from "components/WarningTransferAlertMobile";
import { useIsMobile } from "hooks/useIsMobile";
import { Button } from "components/Button";

export const TransferCheck = ({
  payloadModal,
  onClose,
  chooseCard,
  setChooseCard,
  onTab,
}: any) => {
  const [isShowWarningAlert, setIsShowWarningAlert] = useState(true);
  const [isAlert, setIsAlert] = useState(false);
  const theme = useTheme();
  const { isMobile } = useIsMobile();

  const {
    transfer: { createdTransfer, isLoading },
  } = useSelector((state: any) => state);
  const {
    cards: { mainCard, fromTransferCard },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fromTransferCard?.amount) {
      fromTransferCard?.amount < createdTransfer.amount
        ? setIsAlert(true)
        : setIsAlert(false);
    }
  }, [fromTransferCard?.amount, mainCard, createdTransfer.amount]);

  const transferHandler = () => {
    onTab(1);
    dispatch(transferCheck({ paymentToken: createdTransfer?.paymentToken }));
  };

  const goToHandler = () => {
    onTab(0);
    dispatch(setIsOpenModal(false));
  };

  return (
    <>
      <SkeletonContainer width="400px" height="500px" isLoading={isLoading}>
        {chooseCard && (
          <ChosenCard
            setChooseCard={setChooseCard}
            payloadModal={payloadModal}
            onClose={onClose}
          />
        )}

        {!chooseCard && (
          <>
            <ClickableHeader onBack={() => goToHandler()} />

            <Box flexGrow="1">
              {isAlert && (
                <Box
                  display="flex"
                  flexDirection="column"
                  borderRadius="16px"
                  padding="18px"
                  gap={8}
                  bgcolor={theme.palette.orange.b50}
                  mb={32}
                >
                  <Box display="flex" gap={6}>
                    <IconInfo fill={theme.palette.orange.b500} />
                    <Typography
                      variant="text_5"
                      fontWeight={500}
                      color="orange.b500"
                    >
                      Недостаточно средств на карте
                    </Typography>
                  </Box>
                  <Typography
                    variant="text_4"
                    fontWeight={400}
                    color="gray.b500"
                  >
                    Пожалуйста, пополните счет или выберите другую карту
                  </Typography>
                </Box>
              )}
              {!isMobile && (
                <WarningTransferAlert
                  isOpen={isShowWarningAlert}
                  handler={() => setIsShowWarningAlert(false)}
                />
              )}

              {isMobile && (
                <WarningTransferAlertMobile
                  isOpen={isShowWarningAlert}
                  handler={() => setIsShowWarningAlert(false)}
                />
              )}

              <WarningTransfer />

              <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                sx={{ margin: "36px 0" }}
              >
                <InfoBlock
                  label="Карта списания"
                  value={
                    fromTransferCard?.cardPan
                      ? fromTransferCard.cardPan.slice(-4)
                      : null
                  }
                />
                <InfoBlock
                  label="Получатель"
                  value={
                    "************" +
                    createdTransfer?.toCardPan.substring(
                      createdTransfer?.toCardPan?.length - 4
                    )
                  }
                />
                <InfoBlock
                  label="Банк получателя"
                  value={createdTransfer.toClientBank}
                />
                <InfoBlock
                  label="Сумма перевода"
                  value={createdTransfer.amount}
                  isCurrency={true}
                />
                <InfoBlock
                  label="Комиссия"
                  value={createdTransfer.fee}
                  isCurrency={true}
                />
                {createdTransfer.reason && (
                  <InfoBlock
                    label="Сообщение получателю"
                    value={createdTransfer.reason}
                  />
                )}
              </Box>
            </Box>
            <Button
              variant="primary"
              onClick={transferHandler}
              isLoading={isLoading}
              title="Подтвердить перевод"
            />
          </>
        )}
      </SkeletonContainer>
    </>
  );
};

// TODO переместить в нужную папку
const InfoBlock = ({ label, value, isCurrency = false }) => (
  <Box display="flex" flexDirection="column">
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        color: "var(--main-color-text-secondary)",
      }}
    >
      {label}
    </Typography>
    <Typography
      sx={{
        fontSize: "17px",
        fontWeight: 500,
        lineHeight: "20px",
      }}
    >
      {isCurrency ? formatCurrency(value, true) : value}
    </Typography>
  </Box>
);
