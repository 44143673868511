import styled from "@emotion/styled";
import theme from "theme";
import { ChatCreateAttachProps } from "widgets";
import { ChatBlockDirection } from "../ChatBlock";
import { StatusIconOk } from "../../assets/StatusIconOk";

export enum ChatMessageAppearance {
  DATE = "DATE",
  MESSAGE = "MESSAGE",
  SERVICE = "SERVICE",
  UNREAD = "UNREAD",
}

export enum ChatMessageStatus {
  SENT = "SENT",
  OK = "OK",
  VIEW = "VIEW",
  ERROR = "ERROR",
}

export enum ChatMessageType {
  MESSAGE = "MESSAGE",
  IMAGE = "IMAGE",
  ATTACH = "ATTACH",
}

export enum ChatCreateMessageStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface ChatMessageProps {
  id: string;
  appearance: ChatMessageAppearance;
  message?: string;
  direction?: ChatBlockDirection;
  time?: string;
  type?: ChatMessageType;
  status?: ChatMessageStatus;
  index?: number;
  attach?: ChatCreateAttachProps;
}

const InMessageWrapper = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-template-rows: auto;
  grid-column-gap: 5px;
  align-self: self-start;
  align-items: end;
`;

const InMessageAvatar = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const InMessageAuthor = styled.div`
  color: ${theme.palette.gray.b300};
  font-size: 14px;
  margin: 0 0 4px 8px;
`;
const MessageTimeStyled = styled.div`
  color: ${theme.palette.gray.b300};
  font-size: 10px;
  line-height: 16px;
`;

const ChatMessageStyled = styled.div`
  font-size: 16px;
  line-height: 20px;
  gap: 10px;
  border-radius: 25px;
  padding: 12px 16px;
  display: flex;
  align-items: self-end;
  font-weight: 400;
`;

const InMessageStyled = styled(ChatMessageStyled)`
  background: ${theme.palette.gray.b75};
  color: ${theme.palette.gray.b800};
  border-bottom-left-radius: 0;
`;

const OutMessageStyled = styled(ChatMessageStyled)`
  background: ${theme.palette.gray.b600};
  color: ${theme.primary.white};
  border-bottom-right-radius: 0;
`;

const OutMessageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 14px;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  align-self: self-end;
  align-items: end;
`;

const ServiceMessageStyled = styled.div<Partial<ChatMessageProps>>`
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  padding: 4px 0;
  white-space: pre;
  width: 100%;
  text-align: center;
  color: ${(props) =>
    props.appearance === ChatMessageAppearance.DATE ||
    props.appearance === ChatMessageAppearance.UNREAD
      ? theme.palette.gray.b300
      : theme.palette.black};
  background: ${(props) =>
    props.appearance === ChatMessageAppearance.UNREAD
      ? theme.palette.gray.b50
      : "none"};
`;

const MessageAttachStyled = styled.div<Partial<ChatMessageProps>>`
  position: relative;
  height: 40px;
  overflow: hidden;
  padding-left: 50px;
  display: grid;
  &:before {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    left: 0;
    background-color: ${(props) =>
      props.direction === ChatBlockDirection.OUT
        ? theme.palette.gray.b75
        : theme.primary.white};
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 13px;
    cursor: pointer;
  }
  & > div:first-of-type {
    font-size: 16px;
    line-height: 20px;
    color: ${(props) =>
      props.direction === ChatBlockDirection.OUT
        ? theme.primary.white
        : theme.primary.gray};
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > div:last-of-type {
    font-size: 12px;
    line-height: 16px;
    color: ${theme.palette.gray.b300};
    cursor: pointer;
  }
`;

const MessageImageStyled = styled.div<Partial<ChatMessageProps>>`
  position: relative;
  width: 250px;
  height: 140px;
  background: url(${(props) => props.attach?.fileImage}) no-repeat center center /
    cover;
  border-radius: 13px;
  cursor: pointer;
`;

const StatusIconMap = {
  [ChatMessageStatus.OK]: StatusIconOk,
  // [ChatMessageStatus.SENT]: StatusIconSent,
  // [ChatMessageStatus.VIEW]: StatusIconView,
  // [ChatMessageStatus.ERROR]: StatusIconError,
};

const MessageAttach: React.FC<Partial<ChatMessageProps>> = ({
  attach,
  direction,
}) => (
  <MessageAttachStyled direction={direction}>
    <div>{attach?.fileName}</div>
    <div>{attach?.fileType}</div>
  </MessageAttachStyled>
);

const InMessage: React.FC<Partial<ChatMessageProps>> = ({
  message,
  time,
  index,
  type,
  attach,
  direction,
}) => (
  <InMessageWrapper>
    <div>{index === 0 && <InMessageAvatar />}</div>
    <div>
      {index === 0 && <InMessageAuthor>оператор</InMessageAuthor>}
      <InMessageStyled>
        {type === ChatMessageType.MESSAGE && message}
        {type === ChatMessageType.ATTACH && (
          <MessageAttach attach={attach} direction={direction} />
        )}
        {type === ChatMessageType.IMAGE && (
          <MessageImageStyled attach={attach} />
        )}
        <MessageTimeStyled>{time}</MessageTimeStyled>
      </InMessageStyled>
    </div>
  </InMessageWrapper>
);

const OutMessage: React.FC<Partial<ChatMessageProps>> = ({
  message,
  time,
  status,
  type,
  attach,
  direction,
}) => {
  // const MessageStatusIcon = StatusIconMap[status!];
  return (
    <OutMessageWrapper>
      <OutMessageStyled>
        {type === ChatMessageType.MESSAGE && message}
        {type === ChatMessageType.ATTACH && (
          <MessageAttach attach={attach} direction={direction} />
        )}
        {type === ChatMessageType.IMAGE && (
          <MessageImageStyled attach={attach} />
        )}
        <MessageTimeStyled>{time}</MessageTimeStyled>
      </OutMessageStyled>
      {/*<MessageStatusIcon />*/}
    </OutMessageWrapper>
  );
};

const ServiceMessage: React.FC<Partial<ChatMessageProps>> = ({
  appearance,
  message,
}) => (
  <ServiceMessageStyled appearance={appearance}>{message}</ServiceMessageStyled>
);

export const ChatMessage: React.FC<ChatMessageProps> = ({
  direction,
  type,
  ...rest
}) => {
  return direction === ChatBlockDirection.IN ? (
    <InMessage {...rest} type={type} direction={direction} />
  ) : direction === ChatBlockDirection.OUT ? (
    <OutMessage {...rest} type={type} direction={direction} />
  ) : (
    <ServiceMessage {...rest} type={type} />
  );
};
