import { useDispatch, useSelector } from "react-redux";
import { chatSelector, store } from "store";
import { useEffect } from "react";
import { chatActions, getChatMessages } from "store/slices/chat";

const initialValues = {
  page: -1,
  size: 20,
};

export const useGetChatMessages = () => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const { latestPage } = useSelector(chatSelector);

  useEffect(() => {
    if (latestPage === undefined || latestPage === null) {
      dispatch(getChatMessages(initialValues))
        .unwrap()
        .then((result) => {
          const { page = -1, size = 20, messages = {} } = result;
          if (size > 0 && size <= 20) {
            dispatch(chatActions.setLatestPage(page));
            dispatch(chatActions.setPaginationPage(page - 1));
            if (size < 15 && page > 0) {
              dispatch(chatActions.setMessages(messages));
              dispatch(getChatMessages({ page: page - 1, size: 20 }))
                .unwrap()
                .then((res) => {
                  dispatch(
                    chatActions.setPaginationMessages(res.messages || {})
                  );

                  if (res.size) {
                    dispatch(chatActions.setInitSumMessages(res.size));
                  }
                });
            } else if (size >= 15) {
              dispatch(chatActions.setMessages(messages));
              dispatch(chatActions.setLatestPage(page));
              dispatch(chatActions.setPaginationPage(page - 1));
            }
          }
          dispatch(chatActions.setInitSumMessages(size));
        });
    } else {
      dispatch(getChatMessages({ page: latestPage, size: 20 }))
        .unwrap()
        .then(({ messages, size }) => {
          if (messages) dispatch(chatActions.setMessages(messages));

          if (size) {
            dispatch(chatActions.setInitSumMessages(size));
          }
        });
    }
  }, [dispatch, latestPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(chatActions.setChatMessageState("scrolling"));
      dispatch(getChatMessages({ page: latestPage || -1, size: 20 }))
        .unwrap()
        .then(({ messages, size }) => {
          if (messages) {
            dispatch(chatActions.setTimeoutMessages({ messages, size }));
          }
        });
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch, latestPage]);
};
