import { Grid } from "@mui/material";

/**
 * Основной контейнер для посторения контента
 */
const MainContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <Grid
      container
      columnSpacing={{ xs: 0, xl: 40 }}
      px={{ xs: 20, xl: 120 }}
      pb={{ xs: 56, xl: 140 }}
    >
      {children}
    </Grid>
  );
};

export default MainContainer;
