import {
  Box,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import themeStyles from "theme";
import { PhoneCode } from "models";
import { Label } from "molecules/Label/Label";
import selected from "molecules/SelectInput/selected.png";

const useStyles = makeStyles((theme: typeof themeStyles) => ({
  selectRoot: {
    "& .MuiSelect-select > .MuiBox-root > .MuiBox-root": {
      display: "none",
    },
  },
  menuRoot: {
    "&.MuiPaper-root": {
      borderRadius: 16,
    },
    "&.MuiMenuItem-root": {
      padding: "10px 14px",
    },
    "&.MuiMenuItem-root.Mui-selected": {
      backgroundColor: theme.palette.indigo.b25,
      backgroundImage: `url(${selected})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "95% 50%",
      backgroundSize: "15px 11px",
    },
    "&.MuiMenuItem-root:hover": {
      backgroundColor: "var(--brand-color-hover)",
    },
  },
  label: {
    color: theme.palette.gray.b700,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      borderRadius: 16,
      width: 343,
      marginTop: 8,
      left: "200px",
      boxShadow:
        "0px 2px 16px -4px var(--main-color-box-shadow-1), 0px 4px 6px -2px var(--main-color-box-shadow-2)",
    },
    anchororigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformorigin: {
      vertical: "top",
      horizontal: "left",
    },
  },
};

type PhoneCodesSelect = {
  onChange: (phoneCode: PhoneCode) => void;
  countries: any;
  value: PhoneCode | null;
  error?: boolean;
};

export const PhoneCodesSelect = (props: PhoneCodesSelect) => {
  const { onChange, value, countries, error = false } = props;
  const classes = useStyles();

  const handleChange = (e: any) => {
    const codeItem = countries.find(
      (code: any) => code.name === e.target.value
    );

    if (codeItem) onChange(codeItem);
  };

  return (
    <Box
      width={75}
      mb={8}
      display="flex"
      flexDirection="column"
      position="absolute"
      right={0}
    >
      <Label
        label=""
        htmlFor="code"
        style={{
          color: "var(--main-color-text-title)",
        }}
      />
      <FormControl
        style={{ width: "100%" }}
        sx={
          error
            ? {
                "& fieldset": {
                  border: "1px solid var(--error-color-illustration)",
                },
              }
            : null
        }
      >
        <Select
          MenuProps={MenuProps}
          className={classes.selectRoot}
          startAdornment={
            <InputAdornment position="start">
              <Box
                component="img"
                sx={{
                  height: 20,
                  width: 24,
                  marginLeft: 8,
                }}
                src={value?.flag || countries?.[0]?.flag}
              />
            </InputAdornment>
          }
          onChange={handleChange}
          id="code"
          sx={{
            ".MuiSelect-select": {
              padding: "14px 18px",
            },
            fieldset: {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .arrow": {
              transform: "rotate(180deg)",
              position: "absolute",
              right: 24,
            },
          }}
        >
          {countries.map((codeValue: any) => (
            <MenuItem
              key={codeValue.name}
              value={codeValue.name}
              classes={{ root: classes.menuRoot }}
            >
              <Box display="flex" gap={8} alignItems="center">
                {codeValue.flag ? (
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 24,
                      marginLeft: 8,
                    }}
                    src={codeValue.flag}
                  />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 24,
                      marginLeft: 8,
                    }}
                  />
                )}

                <Box display="flex">
                  {codeValue.name} +{codeValue.code}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
