import React from "react";

interface IconVisibilityProps extends React.SVGProps<SVGSVGElement> {}

export const IconVisibility = (props: IconVisibilityProps) => {
  const { width = 20, height = 20, color = "#739B67" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        fill={color}
      />
      <path
        d="M15.4227 5.51484C13.7148 4.34375 11.8871 3.75 9.99062 3.75C8.28398 3.75 6.62031 4.25781 5.0457 5.25312C3.45781 6.25898 1.90117 8.08594 0.625 10C1.65703 11.7187 3.06875 13.4859 4.53906 14.4992C6.22578 15.6609 8.05976 16.25 9.99062 16.25C11.9047 16.25 13.7348 15.6613 15.432 14.5004C16.9262 13.4766 18.348 11.7117 19.375 10C18.3445 8.30351 16.918 6.54062 15.4227 5.51484ZM10 13.75C9.25832 13.75 8.53329 13.5301 7.91661 13.118C7.29993 12.706 6.81928 12.1203 6.53545 11.4351C6.25162 10.7498 6.17736 9.99584 6.32205 9.26841C6.46675 8.54098 6.8239 7.8728 7.34835 7.34835C7.87279 6.8239 8.54098 6.46675 9.26841 6.32205C9.99584 6.17736 10.7498 6.25162 11.4351 6.53545C12.1203 6.81928 12.706 7.29993 13.118 7.91661C13.5301 8.53329 13.75 9.25832 13.75 10C13.7489 10.9942 13.3534 11.9474 12.6504 12.6504C11.9474 13.3534 10.9942 13.7489 10 13.75Z"
        fill={color}
      />
    </svg>
  );
};
