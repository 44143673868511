import { useCallback } from "react";
import { Typography, Box } from "@mui/material";

import { Button } from "components/Button";
import { useIsMobile } from "hooks/useIsMobile";
import { IconLogoutMobile } from "atoms/IconLogoutMobile/IconLogoutMobile";

type LogoutModalProps = {
  onClick: () => void;
  onCancel: () => void;
};

export const LogoutModal = ({ onClick, onCancel }: LogoutModalProps) => {
  const { isMobile, size } = useIsMobile();

  const getTitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Вы уверены, что хотите\nвыйти из аккаунта?`;
      case "sm":
        return `Вы уверены, что хотите\nвыйти из аккаунта?`;
      case "xs":
        return `Вы уверены, что хотите\nвыйти из аккаунта?`;
      default:
        return `Вы уверены, что хотите выйти из аккаунта?`;
    }
  }, [size]);

  const getSubtitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Для входа вам потребуется повторный ввод логина\nи пароля`;
      case "sm":
        return `Для входа вам потребуется повторный\nввод логина и пароля`;
      case "xs":
        return `Для входа вам потребуется повторный\nввод логина и пароля`;
      default:
        return `Для входа вам потребуется повторный ввод логина и пароля`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      px={{ xs: "16px", lg: "32px" }}
      mt={12}
    >
      {isMobile && (
        <Box display="flex" justifyContent="center">
          <IconLogoutMobile />
        </Box>
      )}
      {/* <Typography
        variant="text_4"
        color="var(--main-color-text-secondary)"
        whiteSpace="pre-wrap"
        mt={{ xs: 24, xl: 16 }}
      >
        {getSubtitle()}
      </Typography> */}
      {!isMobile && (
        <Box display="flex" alignContent="center" justifyContent="center">
          <IconLogoutMobile />
        </Box>
      )}
      <Box mt={22} display="flex" alignContent="center" justifyContent="center">
        <Typography
          variant="text_8"
          fontWeight={500}
          color="var(--main-color-text-title)"
          whiteSpace="pre-wrap"
          textAlign="center"
        >
          {getTitle()}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" rowGap="12px" mt={48}>
        <Button onClick={onClick} variant="primary" title="Выйти из аккаунта" />
        <Button onClick={onCancel} variant="secondary" title="Отмена" />
      </Box>
    </Box>
  );
};
