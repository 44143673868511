import { Box } from "@mui/system";
import { IconCreditCardClosed } from "atoms/IconCards/IconCards";
import {
  IconBlockCardBg,
  IconClosedCardBg,
  IconSnowCardBg,
} from "atoms/IconCardsInfo/IconCardsInfo";

import { CARD_STATUS } from "components/CardsInfo/model";
import { BooleanArraySupportOption } from "prettier";

const renderBg = (status: any) => {
  switch (status) {
    case CARD_STATUS.Temporary:
    case CARD_STATUS.Referral:
    case CARD_STATUS.Closed:
    case CARD_STATUS.Compromised:
    case CARD_STATUS.Stolen:
    case CARD_STATUS.Lost:
      return "rgba(255, 255, 255, 0.17)";
  }
};

const renderIcon = (status: any, size: number) => {
  switch (status) {
    case CARD_STATUS.Temporary:
    case CARD_STATUS.Referral:
      return <IconSnowCardBg size={size} />;

    case CARD_STATUS.Lost:
    case CARD_STATUS.Compromised:
    case CARD_STATUS.Stolen:
      return <IconBlockCardBg size={size} />;

    case CARD_STATUS.Closed:
      return <IconClosedCardBg size={size} />;
  }
};

export const RenderStatus = ({
  status,
  width = "100%",
  height = "100%",
  radius = 11,
  isSmall = false,
}: {
  status: any;
  width?: string;
  height?: string;
  iconHeight?: number;
  iconWidth?: number;
  radius?: number;
  isSmall?: boolean;
}) => {
  const size = isSmall ? 53 : 64;
  return (
    <Box
      width={width}
      // height={height}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      zIndex={1}
      sx={{
        borderRadius: radius,
        backgroundColor: renderBg(status),
        backdropFilter: "blur(2.200000047683716px)",
        aspectRatio: "412/228",
      }}
    >
      <Box>{renderIcon(status, size)}</Box>
    </Box>
  );
};
