export const CardIcon = ({ color }) => {
  return (
    <svg
      width={21}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 24"
      stroke="none"
    >
      <path
        d="M0 6.12582C0 5.34572 0.453583 4.63681 1.16188 4.3099L9.66188 0.386823C10.1937 0.141381 10.8063 0.141381 11.3381 0.386823L19.8381 4.3099C20.5464 4.63681 21 5.34572 21 6.12582V6.61539C21 7.71996 20.1046 8.61539 19 8.61539H2C0.895431 8.61539 0 7.71995 0 6.61538V6.12582Z"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
      />
      <rect
        x="2.1543"
        y="10.7692"
        width="3.76923"
        height="6.46154"
        rx="1"
        fill={color}
      />
      <rect y="18.8462" width="21" height="4.30769" rx="1" fill={color} />
      <rect
        x="8.61523"
        y="10.7692"
        width="3.76923"
        height="6.46154"
        rx="1"
        fill={color}
      />
      <rect
        x="15.0771"
        y="10.7692"
        width="3.76923"
        height="6.46154"
        rx="1"
        fill={color}
      />
    </svg>
  );
};
