import theme from "theme";

export const IconArrowDown = ({ count }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={45} height={40} fill="none">
      <rect width={40} height={40} fill={theme.palette.blue.b400} rx={14} />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m13.5 16.5 6.5 7 6.5-7"
      />
      {count > 0 ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          x="25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="20" height="20" rx="10" fill="#FD9031" />
          <text
            fill="white"
            x={count < 10 || count > 99 ? "6" : "2"}
            y="15"
            fontSize="14"
          >
            {count > 99 ? "∞" : count}
          </text>
        </svg>
      ) : null}
    </svg>
  );
};
