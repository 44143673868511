import React, { FC } from "react";

const FrozenCardConfirm: FC = () => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#E8F1FF"
      />
      <g filter="url(#filter0_d_3396_317)">
        <mask
          id="mask0_3396_317"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="150"
          height="150"
        >
          <path
            d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
            fill="url(#paint0_linear_3396_317)"
          />
        </mask>
        <g mask="url(#mask0_3396_317)">
          <path
            d="M127 101C127 105.418 123.418 109 119 109H30C25.5817 109 22 105.418 22 101V49.0012C22 44.5849 25.5799 41.0038 29.9963 41.0023C63.1016 40.9906 94.1323 41.0283 118.991 41.0132C123.413 41.0105 127 44.5937 127 49.0157V101Z"
            fill="white"
          />
        </g>
        <rect x="60" y="60" width="30" height="30" rx="5" fill="#9DB6DB" />
        <path
          d="M75 66V84"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.2315 67.9258C76.558 68.3476 75.7863 68.5699 75 68.5686C74.2136 68.5699 73.4419 68.3476 72.7684 67.9258"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.7684 82.0706C73.4424 81.65 74.2138 81.4277 75 81.4277C75.7862 81.4277 76.5575 81.65 77.2315 82.0706"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.5 70.502L67.5 79.502"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.0082 73.4729C81.3205 73.0766 80.7495 72.4933 80.3565 71.7855C79.9634 71.0777 79.7631 70.2721 79.777 69.4551"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.9917 76.5264C68.6794 76.9227 69.2503 77.506 69.6434 78.2138C70.0364 78.9216 70.2368 79.7272 70.2229 80.5442"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.5 70.502L82.5 79.502"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.9917 73.4729C68.6794 73.0766 69.2503 72.4933 69.6434 71.7855C70.0364 71.0777 70.2368 70.2721 70.2229 69.4551"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.0082 76.5264C81.3205 76.9227 80.7495 77.506 80.3565 78.2138C79.9634 78.9216 79.7631 79.7272 79.777 80.5442"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3396_317"
          x="16"
          y="32"
          width="117"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3396_317"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3396_317"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3396_317"
          x1="75"
          y1="0"
          x2="75"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3ECFA" />
          <stop offset="1" stopColor="#DAE7FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FrozenCardConfirm;
