const IconErrorBiometry = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      {...props}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons/bold/18/alert-octagon">
        <path
          fill={props.color ?? "#E57616"}
          id="Icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.075 4.8525L12.6 2.3775C12.0369 1.81504 11.2734 1.49938 10.4775 1.5H6.975C6.17911 1.49938 5.41559 1.81504 4.8525 2.3775L2.3775 4.8525C1.81504 5.41559 1.49938 6.17911 1.5 6.975V10.4775C1.50136 11.2708 1.81687 12.0312 2.3775 12.5925L4.8525 15.075C5.41559 15.6375 6.17911 15.9531 6.975 15.9525H10.4775C11.2734 15.9531 12.0369 15.6375 12.6 15.075L15.075 12.5925C15.6356 12.0312 15.9511 11.2708 15.9525 10.4775V6.975C15.9531 6.17911 15.6375 5.41559 15.075 4.8525ZM8.16 6.2025C8.16 6.00154 8.26721 5.81584 8.44125 5.71536C8.61529 5.61488 8.82971 5.61488 9.00375 5.71536C9.17779 5.81584 9.285 6.00154 9.285 6.2025V8.5275C9.285 8.83816 9.03316 9.09 8.7225 9.09C8.41184 9.09 8.16 8.83816 8.16 8.5275V6.2025ZM8.73 11.265C9.04066 11.265 9.2925 11.0132 9.2925 10.7025C9.2925 10.3918 9.04066 10.14 8.73 10.14C8.41934 10.14 8.1675 10.3918 8.1675 10.7025C8.1675 11.0132 8.41934 11.265 8.73 11.265Z"
        />
      </g>
    </svg>
  );
};

export default IconErrorBiometry;
