import styles from "./style.module.css";
import icon from "./assets/icon.png";

export const BackButtonArrow = ({ onClick }: { onClick: () => void }) => {
  return (
    <button className={styles.container} onClick={onClick}>
      <img src={icon} className={styles.icon} />
    </button>
  );
};
