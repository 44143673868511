import React from "react";

interface IconEmptyBlockProps extends React.SVGProps<SVGSVGElement> {}

const IconEmptyBlock = (props: IconEmptyBlockProps) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#F5F5F5"
      />
      <path
        d="M134 132C134 136.418 130.418 140 126 140H24C19.5817 140 16 136.418 16 132V62.0023C16 57.5861 19.5791 54.0052 23.9953 54.0033C62.0149 53.9871 97.6478 54.0371 125.996 54.016C130.418 54.0127 134 57.596 134 62.0185V132Z"
        fill="white"
      />
      <path
        d="M81.8125 106H67.1875C66.2555 106 65.5 107.343 65.5 109C65.5 110.657 66.2555 112 67.1875 112H81.8125C82.7445 112 83.5 110.657 83.5 109C83.5 107.343 82.7445 106 81.8125 106Z"
        fill="#D3D3D3"
      />
      <path
        d="M96.5 116H52.5C50.8431 116 49.5 117.343 49.5 119C49.5 120.657 50.8431 122 52.5 122H96.5C98.1569 122 99.5 120.657 99.5 119C99.5 117.343 98.1569 116 96.5 116Z"
        fill="#D3D3D3"
      />
      <path
        d="M75 86C88.2548 86 99 75.2548 99 62C99 48.7452 88.2548 38 75 38C61.7452 38 51 48.7452 51 62C51 75.2548 61.7452 86 75 86Z"
        fill="#B5CDA3"
      />
      <path
        d="M85.3995 72.6985C84.6184 73.4795 83.3521 73.4795 82.5711 72.6985L75.5 65.6274L68.4289 72.6985C67.6479 73.4795 66.3816 73.4795 65.6005 72.6985C64.8195 71.9174 64.8195 70.6511 65.6005 69.8701L72.6716 62.799L65.6005 55.7279C64.8195 54.9469 64.8195 53.6805 65.6005 52.8995C66.3816 52.1184 67.6479 52.1184 68.4289 52.8995L75.5 59.9706L82.5711 52.8995C83.3521 52.1184 84.6184 52.1184 85.3995 52.8995C86.1805 53.6805 86.1805 54.9469 85.3995 55.7279L78.3284 62.799L85.3995 69.8701C86.1805 70.6511 86.1805 71.9174 85.3995 72.6985Z"
        fill="white"
      />
    </svg>
  );
};

export default IconEmptyBlock;
