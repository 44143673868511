import { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import { IconNewsItem } from "atoms/IconNewsItem/IconNewsItem";

interface IListItem {
  title?: string;
  subTitle?: string;
  date?: string;
  onClick?: () => void;
  readStatus?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  icon: {
    flexShrink: 0,
  },
  rightIcon: {
    display: "none",
  },
  date: {
    display: "inline",
  },
}));

const ListItem: FC<IListItem> = ({
  title,
  subTitle,
  date,
  onClick,
  readStatus,
}) => {
  const classes = useStyles();
  const time = date ? format(new Date(date), "HH:mm") : "";

  return (
    <Box
      onClick={onClick}
      className={classes.box}
      sx={{ background: readStatus ? "transparent" : "#E8ECE3" }}
      px={{ xs: "16px", lg: "32px" }}
      py={{ xs: "12px" }}
    >
      <Box display="flex" gap={12} width="100%">
        <IconNewsItem className={classes.icon} />
        <Box display="flex" flexDirection="column" width="100%" gap={4}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              fontSize={16}
              lineHeight="20px"
              fontWeight={400}
              color="var(--main-color-text-title)"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Typography>
            <Typography
              fontSize={13}
              lineHeight="14px"
              fontWeight={400}
              color="var(--main-color-notification-description)"
            >
              {time}
            </Typography>
          </Box>
          <Typography
            fontSize={13}
            lineHeight="14px"
            fontWeight={400}
            color="var(--main-color-notification-description)"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
            }}
          >
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ListItem;
