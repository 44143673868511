import React from "react";

interface IconChevronProps extends React.SVGProps<SVGSVGElement> {
  size?: string;
}

const IconChevron = (props: IconChevronProps) => {
  const { size = 24, color = "#454A3F" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7 17L2 11.5L7 6"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconChevron;
