import { TabVariants, systemActions } from "store/slices/system";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  HISTORY_TABS,
  getHistoryListLight,
  setActiveTab,
} from "store/slices/historySlice";
import { SkeletonContainer } from "components";
import { useIsMobile } from "hooks/useIsMobile";
import { EmptyStyled } from "../History/History";
import { getEndOfDay } from "utils/getEndOfDay";

import IconEmptyBlock from "atoms/IconEmptyBlock";

import { Box } from "@mui/material";
import { OperationItem } from "../History/components/OperationItem";

import { createLimitedList } from "utils/createLimitedList";
import {
  DateStyled,
  Title,
  TransferHistoryBlock,
  TransferHistoryBtn,
} from "./components";
import { AnotherPages } from "store/slices/system/slice";
import { parseTransactionsDate } from "utils/parseTransactionsDate";
import { HistoryModal } from "../History/HistoryModal";

const LatestTransactions = () => {
  const dispatch = useDispatch();

  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });

  const { isMobile } = useIsMobile();
  const {
    history: { historyList, isLoading },
    cards,
  } = useSelector((state: any) => state);
  const notFoundOperations = !isLoading && !historyList.length;

  const goToHandler = (tab: TabVariants) => {
    if (tab === TabVariants.history) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  useEffect(() => {
    if (cards.account.accountNumber) {
      dispatch(
        getHistoryListLight({
          size: 5,
          to: getEndOfDay(new Date()),
          page: 0,
          acctId: cards.account.accountNumber,
        })
      );
    }
  }, [cards.account.accountNumber, dispatch]);

  const List = createLimitedList(historyList);

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  return (
    <>
      <Title>Последние операции</Title>
      <SkeletonContainer isLoading={isLoading} height={345} width="100%">
        <TransferHistoryBlock>
          {List.map((item) => {
            return (
              <Box key={item.date} mb={8}>
                <DateStyled>
                  {parseTransactionsDate(item.date || "")}
                </DateStyled>
                <Box display="flex" flexDirection="column">
                  {/*@ts-ignore*/}
                  {item.statements.map((el) => (
                    <OperationItem
                      key={el.statementId}
                      item={el}
                      onOpenModal={openModal}
                    />
                  ))}
                </Box>
              </Box>
            );
          })}

          {notFoundOperations && (
            <EmptyStyled>
              <IconEmptyBlock />
              <h2>
                У вас пока не было операций <br /> по карте
              </h2>
            </EmptyStyled>
          )}

          {!notFoundOperations && (
            <TransferHistoryBtn
              onClick={() => goToHandler(TabVariants.history)}
            >
              Смотреть все
            </TransferHistoryBtn>
          )}
        </TransferHistoryBlock>
      </SkeletonContainer>

      {modalStatus.isOpen && (
        <HistoryModal
          isOpen={modalStatus.isOpen}
          onClose={closeModal}
          historyId={modalStatus.historyId}
        />
      )}
    </>
  );
};

export default LatestTransactions;
