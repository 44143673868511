import { useState, useEffect } from "react";

const useDebounce = <T,>(value: T, delay: number = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;

// const [search, setSearch] = useState("");
// const debouncedSearch = useDebounce<string>(search, 500);

// useEffect(() => {
//   if (debouncedSearch) {
//     console.log("Debounced search value:", debouncedSearch);

//        твой код
//   }
// }, [debouncedSearch]);
