import React from "react";

interface IconProfileLogoutProps extends React.SVGProps<SVGSVGElement> {}

export const IconProfileLogout = (props: IconProfileLogoutProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.90475 1.66663H14.1042C16.2039 1.66663 17.9163 3.33329 17.9163 5.36663V14.6333C17.9163 16.675 16.2039 18.3333 14.087 18.3333H9.89614C7.79644 18.3333 6.07536 16.675 6.07536 14.6416V10.6416H11.4107L10.0338 11.975C9.77567 12.225 9.77567 12.6333 10.0338 12.8833C10.1629 13.0083 10.335 13.0666 10.5071 13.0666C10.6706 13.0666 10.8427 13.0083 10.9718 12.8833L13.4846 10.4583C13.6137 10.3416 13.6825 10.175 13.6825 9.99996C13.6825 9.83329 13.6137 9.66663 13.4846 9.54996L10.9718 7.12496C10.7137 6.87496 10.292 6.87496 10.0338 7.12496C9.77567 7.37496 9.77567 7.78329 10.0338 8.03329L11.4107 9.35829H6.07536V5.37496C6.07536 3.33329 7.79644 1.66663 9.90475 1.66663ZM2.08301 9.99988C2.08301 9.64988 2.37903 9.35821 2.73426 9.35821H6.07511V10.6415H2.73426C2.37903 10.6415 2.08301 10.3582 2.08301 9.99988Z"
        fill={props.color ?? "#F29494"}
      />
    </svg>
  );
};
