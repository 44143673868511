export const formatValueByMask = (mask: string, value: string) => {
  const formatChars = mask
    .split("")
    .filter((c) => c !== "X")
    .join("");
  let s = "" + value,
    r = "";
  for (let im = 0, is = 0; im < mask.length && is < s.length; im++) {
    r +=
      mask.charAt(im) == "X" || formatChars.includes(s.charAt(im))
        ? s.charAt(is++)
        : mask.charAt(im);
  }
  return r;
};

export const parseDate = (date: string) =>
  new Date(Date.parse(date)).toLocaleDateString("ru");

export const checkDate = (date: string) => {
  const [day, month, year] = date.split(".");
  return parseDate(`${year}-${month}-${day}`) === date;
};
