import { CheckBlockType } from "components/CheckBlock/CheckBlock";

export const validateUpperLower = (string: string, type: CheckBlockType) => {
  if (type === "login") {
    return !/[а-яА-ЯёЁ]/u.test(string);
  } else {
    return (
      /[A-Z]/.test(string) &&
      /[a-z]/.test(string) &&
      !/[а-яА-ЯёЁ]/u.test(string)
    );
  }
};
