export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()"#'+,:;<=>._`{|}~^[\]\\/-])[A-Za-z\d\s@$!%*?&()"#'+,:;<=>._`{|}~^[\]\\/-]{8,18}$/;
//Сверху Новый пароль должен содержать не менее 8 символов, в том числе латинские заглавные, строчные буквы и специальные символы
// /([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+)/;

export const loginPattern =
  /[^a-zA-Z0-9~!@#$%^&*()_+?><}{":`=/. ,;[\]|\s\\-]+/g;

// /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,16})/;
// Придумайте логин от 6 до 16 символов, состоящий из латинских букв и/или цифр

export const loginPasswordPattern =
  /[^a-zA-Z0-9~!@#$%^&*()_+?><}{":`=/. ,;[\]|\s-]+/g;
