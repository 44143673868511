import React from "react";

interface IconDownloadProps extends React.SVGProps<SVGSVGElement> {}

export const IconDownload = (props: IconDownloadProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0661 8.14869L10.978 11.1302V3.01514C10.978 2.46285 11.4257 2.01514 11.978 2.01514C12.5303 2.01514 12.978 2.46285 12.978 3.01514V10.8663L15.6321 8.14869C16.018 7.75358 16.6511 7.74609 17.0462 8.13197C17.4413 8.51785 17.4488 9.15097 17.0629 9.54608L12.5645 14.1522C12.3764 14.3448 12.1184 14.4535 11.8491 14.4535C11.5798 14.4535 11.3219 14.3448 11.1337 14.1522L6.63527 9.54608C6.24939 9.15097 6.25687 8.51785 6.65199 8.13197C7.0471 7.74609 7.68022 7.75358 8.0661 8.14869ZM4.5 13.0002C4.5 12.4479 4.05228 12.0002 3.5 12.0002C2.94772 12.0002 2.5 12.4479 2.5 13.0002V17.0002C2.5 20.3139 5.18629 23.0002 8.5 23.0002H15.5C18.8137 23.0002 21.5 20.3139 21.5 17.0002V13.0002C21.5 12.4479 21.0523 12.0002 20.5 12.0002C19.9477 12.0002 19.5 12.4479 19.5 13.0002V17.0002C19.5 19.2094 17.7091 21.0002 15.5 21.0002H8.5C6.29086 21.0002 4.5 19.2094 4.5 17.0002V13.0002Z"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
