export const formatCurrency = (
  sum: string | number,
  separator?: boolean,
  sign?: string
): string => {
  const num = typeof sum === "number" ? sum : +sum;
  const floatOptions = {
    style: "currency",
    currency: "RUB",
  };
  const formatted = new Intl.NumberFormat("ru-RU", {
    ...(separator && floatOptions),
  }).format(num);

  return separator ? formatted : `${formatted} ${sign}`;
};

export const formatNumber = (
  amount: number,
  withFractional: boolean = true,
  currency?: string
) => {
  const parts = amount.toString().split(".");
  const integerPart = parseInt(parts[0]).toLocaleString("ru");
  const fractionalPart = (parts[1] || "0").padEnd(2, "0");
  return `${integerPart}${
    withFractional ? `,${fractionalPart}` : ""
  } ${currency}`;
};

export const stringToNumber = (amount: string) =>
  parseFloat(amount.replace(/,/g, ".").replace(/[^0-9.]/g, ""));

//@ts-ignore
export const formatAmount = (amount, currency, hideDecimal = false) => {
  const isNumber = typeof amount === "number";
  const isString = typeof amount === "string";
  const trimmedAmount = isNumber
    ? amount
    : isString
    ? amount?.replace(/\s/g, "")
    : 0;
  const decimalRegexp = /\d(?=(\d{3})+\.)/g;
  const numericRegexp = /(.)(?=(\d{3})+$)/g;
  if (hideDecimal) {
    const result = `${Number(trimmedAmount)
      .toString()
      .replace(numericRegexp, "$& ")
      .replace(".", ", ")} ${currency}`;
    return result;
  }

  const result = `${Number(trimmedAmount)
    .toFixed(2)
    .replace(decimalRegexp, "$& ")
    .replace(".", ",")} ${currency}`;
  return result;
};
