import React from "react";

export const IconErrorMobile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#FFEEED"
      />
      <g filter="url(#filter0_d_994_7286)">
        <mask
          id="mask0_994_7286"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="151"
          height="150"
        >
          <path
            d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
            fill="#FFF4F4"
          />
        </mask>
        <g mask="url(#mask0_994_7286)">
          <path
            d="M134 105C134 109.418 130.418 113 126 113H24C19.5817 113 16 109.418 16 105V45.002C16 40.5856 19.5795 37.0046 23.9959 37.0029C62.0159 36.9886 97.6489 37.0328 125.997 37.0141C130.419 37.0112 134 40.5943 134 45.0163V105Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M75 99C88.2548 99 99 88.2548 99 75C99 61.7452 88.2548 51 75 51C61.7452 51 51 61.7452 51 75C51 88.2548 61.7452 99 75 99Z"
        fill="#E64545"
      />
      <path
        d="M84.5264 84.5271C83.7454 85.3081 82.4791 85.3081 81.698 84.5271L74.627 77.456L67.5559 84.5271C66.7748 85.3081 65.5085 85.3081 64.7275 84.5271C63.9464 83.746 63.9464 82.4797 64.7275 81.6987L71.7985 74.6276L64.7275 67.5565C63.9464 66.7755 63.9464 65.5092 64.7275 64.7281C65.5085 63.9471 66.7748 63.9471 67.5559 64.7281L74.627 71.7992L81.698 64.7281C82.4791 63.9471 83.7454 63.9471 84.5264 64.7281C85.3075 65.5092 85.3075 66.7755 84.5264 67.5565L77.4554 74.6276L84.5264 81.6987C85.3075 82.4797 85.3075 83.746 84.5264 84.5271Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_994_7286"
          x="10"
          y="28"
          width="130"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_994_7286"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_994_7286"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
