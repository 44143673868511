export const StatusIconSent = () => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.24817 5.09106L7.77365 0.565581C8.08607 0.253162 8.5926 0.253162 8.90502 0.565581C9.21744 0.878 9.21744 1.38453 8.90502 1.69695L4.37954 6.22244L3.24817 5.09106Z"
      fill="#739B67"
    />
    <path
      d="M0.565693 3.23585C0.878112 2.92343 1.38464 2.92343 1.69706 3.23585L4.52549 6.06428L3.39412 7.19565L0.565692 4.36722C0.253273 4.0548 0.253273 3.54827 0.565693 3.23585Z"
      fill="#739B67"
    />
  </svg>
);
