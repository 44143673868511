import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "theme";

type TextMessageProps = {
  user?: string;
  text: string;
};

const TextMessage = ({ text, user }: TextMessageProps) => {
  return (
    <Box
      width="100%"
      maxWidth={218}
      sx={{
        wordBreak: "break-all",
      }}
    >
      <Typography
        typography="text_5"
        sx={{
          wordBreak: "break-word",
          hyphens: "auto",
          color: user ? "var(--main-color-text-title)" : "#2D3227",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "18px",
        }}
        color={theme.primary.main.gray["800"]}
        dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br/>") }}
      />
    </Box>
  );
};

export default TextMessage;
