export const transformOperationType = (type: string) => {
  const isNeedTransform = type.includes("-");

  if (isNeedTransform) {
    const typeArr = type.split("");
    const newTypeArr = typeArr.map((el) => {
      if (el === "-") return "_";
      return el;
    });
    return newTypeArr.join("").toUpperCase();
  } else {
    return type.toUpperCase();
  }
};
