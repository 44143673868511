import { getSavedCards } from "store/slices/transferSlice";

export const refillAction = async (dispatch) => {
  try {
    await dispatch(getSavedCards()).unwrap();
    return { success: true };
  } catch (error) {
    throw new Error("Ошибка запроса карт");
  }
};
