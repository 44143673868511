import { Box } from "@mui/material";
import { NotificationResult } from "molecules/NotificationResult";
import { IconUnSuccess } from "atoms/IconUnSuccess";
import { ErrorMessage } from "store/slices/cardsSlice";
import { width } from "@mui/system";

type Props = {
  onClick: () => void;
  payload?: ErrorMessage;
};

export const UnSuccess = ({ onClick, payload }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
    >
      <Box marginTop="80px" height="100%" width="100%">
        <NotificationResult
          title={payload?.title || "Ошибка"}
          titleSize="text_12"
          subtitleSize="text_5"
          subtitle={payload?.subtitle || "Попробуйте повторить попытку позже"}
          icon={<IconUnSuccess />}
          height="100%"
          buttons={[
            {
              name: "Закрыть",
              variant: "primary",
              size: "lg",
              onClick,
            },
          ]}
        />
      </Box>
    </Box>
  );
};
