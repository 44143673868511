import React from "react";
import { useSelector } from "react-redux";
import { authSelector } from "store";
import { RegisterScreens } from "store/slices/auth";
import { FirstScreen } from "./FirstScreen";
import { SecondScreen } from "./SecondScreen";
import { ThirdScreen } from "./ThirdScreen";
import { FourthScreen } from "./FourthScreen";
import { SuccessedScreen } from "./SuccessedScreen";
import { BlockedScreen } from "./BlockedScreen";
import { FrozenScreen } from "./FrozenScreen";

export const Registration = () => {
  const { registerScreen } = useSelector(authSelector);

  const screens: Record<RegisterScreens, React.ReactNode> = {
    initial: <FirstScreen />,
    confirmation: <SecondScreen />,
    cvv: <ThirdScreen />,
    createLoginAndPass: <FourthScreen />,
    success: <SuccessedScreen />,
    blocked: <BlockedScreen />,
    frozen: <FrozenScreen />,
  };

  return screens[registerScreen];
};
