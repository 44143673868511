import { FC, useEffect, useState } from "react";
import {
  Container,
  DropdownItem,
  DropdownTitle,
  DropdownWrapper,
} from "./components";
import { useOutsideClick } from "hooks/useOutsideClick";
import { IconCheck } from "atoms/IconCheck/IconCheck";
import IconCaret from "atoms/IconCaret/IconCaret";
import { PeriodDate } from "./utils";
import { ValueDateType } from "../OperationDate";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";

interface DropdownItem {
  id: OperationPeriodEnum | "";
  title: string;
}

interface DropdownProps {
  items: Array<DropdownItem>;
  onChange: (value: DropdownItem) => void;
  onClear: () => void;
  value: OperationPeriodEnum | "";
}

const Dropdown = ({ items, onChange, onClear, value }: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeDropdown = () => setOpen(false);
  const rootElement = useOutsideClick(closeDropdown);
  const DEFAULT_PERIOD = OperationPeriodEnum.MONTH;

  const clickHandler = (item: DropdownItem) => {
    onChange(item);
    closeDropdown();
  };

  return (
    <Container ref={rootElement}>
      <DropdownTitle
        style={{
          color:
            value && value !== DEFAULT_PERIOD
              ? "var(--brand-color-primary)"
              : open
              ? "var(--main-color-text-secondary)"
              : "var(--main-color-text-title)",
          backgroundColor:
            value && value !== DEFAULT_PERIOD
              ? "var(--brand-color-hover)"
              : undefined,
        }}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        {value
          ? OpertionPeriodOptions.find((i) => i.id === value)?.title
          : "Период"}
        {value && value !== DEFAULT_PERIOD ? (
          <IconCircleClose
            style={{ cursor: "pointer", marginLeft: "6px" }}
            onClick={onClear}
          />
        ) : (
          <IconCaret
            style={{
              marginLeft: 10,
              rotate: open ? "180deg" : "0deg",
              transition: "rotate 0.2s ease-out",
            }}
            color={
              open
                ? "var(--brand-color-primary)"
                : "var(--main-color-text-title)"
            }
          />
        )}
      </DropdownTitle>
      {open && (
        <DropdownWrapper>
          {items.map((item) => {
            return (
              <DropdownItem
                key={item.id}
                style={{
                  color:
                    value === item.id
                      ? "var(--main-color-text-secondary)"
                      : "var(--main-color-text-title)",
                }}
                onClick={() => clickHandler(item)}
              >
                {item.title}
                {value === item.id && (
                  <IconCheck
                    style={{ marginLeft: 10 }}
                    width={18}
                    height={18}
                  />
                )}
              </DropdownItem>
            );
          })}
        </DropdownWrapper>
      )}
    </Container>
  );
};

interface OperationPeriodProps {
  // value: OperationPeriodEnum | "";
  periodData: ValueDateType;
  onChange: (values: ValueDateType) => void;
}

export const OperationPeriod: FC<OperationPeriodProps> = ({
  periodData,
  onChange,
}) => {
  const [value, setValue] = useState<OperationPeriodEnum | "">("");

  useEffect(() => {
    const period = new PeriodDate(
      new Date(periodData.from),
      new Date(periodData.to)
    );

    let periodValue: OperationPeriodEnum | "" = "";

    if (period.isMonth()) {
      periodValue = OperationPeriodEnum.MONTH;
    } else if (period.isQuarter()) {
      periodValue = OperationPeriodEnum.LAST_THREE_MONTH;
    } else if (period.isYear()) {
      periodValue = OperationPeriodEnum.YEAR;
    } else if (period.isWeek()) {
      periodValue = OperationPeriodEnum.WEEK;
    }

    setValue(periodValue);
  }, [periodData]);

  const preparePeriod = (id: OperationPeriodEnum | "") => {
    const defaultTo = PeriodDate.defaultDateTo;
    switch (id) {
      case OperationPeriodEnum.WEEK:
        return new PeriodDate(PeriodDate.getWeekAgo(), defaultTo);
      case OperationPeriodEnum.MONTH:
        return PeriodDate.getMonthPeriodFromDate(new Date());
      case OperationPeriodEnum.LAST_THREE_MONTH:
        return new PeriodDate(PeriodDate.getQuarterAgo(), defaultTo);
      case OperationPeriodEnum.YEAR:
        return new PeriodDate(PeriodDate.getYearAgo(), defaultTo);
      default:
        return new PeriodDate(PeriodDate.defaultDateFrom, defaultTo);
    }
  };

  const handleChange = (item: DropdownItem) => {
    const { from, to } = preparePeriod(item.id);
    setValue(item.id);
    onChange({ from, to });
  };

  const handleClear = () => {
    handleChange({
      id: OperationPeriodEnum.MONTH,
      title: "Месяц",
    });
  };

  return (
    <Dropdown
      // @ts-ignore
      items={OpertionPeriodOptions}
      onChange={(value) => handleChange(value)}
      onClear={handleClear}
      value={value}
    />
  );
};

export enum OperationPeriodEnum {
  WEEK = "WEEK",
  MONTH = "MONTH",
  LAST_THREE_MONTH = "LAST_THREE_MONTH",
  YEAR = "YEAR",
}

export const OpertionPeriodOptions = [
  {
    id: OperationPeriodEnum.WEEK,
    title: "Неделя",
  },
  {
    id: OperationPeriodEnum.MONTH,
    title: "Текущий месяц",
  },
  {
    id: OperationPeriodEnum.LAST_THREE_MONTH,
    title: "3 месяца",
  },
  {
    id: OperationPeriodEnum.YEAR,
    title: "Год",
  },
];
