import React from "react";

interface IconVisibilityOffProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconVisibilityOff = (props: IconVisibilityOffProps) => {
  const { size = 20, color = "#739B67" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.35918 2.50063L2.49988 3.35993L16.6406 17.5007L17.4999 16.6414L3.35918 2.50063Z"
        fill={color}
      />
      <path
        d="M10.1305 7.50318L12.4965 9.8692C12.4636 9.25236 12.2038 8.66946 11.767 8.23267C11.3302 7.79588 10.7473 7.53605 10.1305 7.50318Z"
        fill={color}
      />
      <path
        d="M9.86956 12.4963L7.50354 10.1303C7.53641 10.7472 7.79624 11.3301 8.23303 11.7668C8.66982 12.2036 9.25271 12.4635 9.86956 12.4963Z"
        fill={color}
      />
      <path
        d="M10.0001 13.7496C9.42315 13.7496 8.85402 13.6165 8.33694 13.3607C7.81986 13.1049 7.36878 12.7332 7.01879 12.2746C6.6688 11.816 6.42934 11.2828 6.31905 10.7165C6.20875 10.1503 6.23059 9.56619 6.38287 9.00975L3.68483 6.31131C2.58287 7.32068 1.53287 8.63787 0.625061 9.99959C1.65709 11.7183 3.06881 13.4855 4.53912 14.4988C6.22584 15.6605 8.05982 16.2496 9.99068 16.2496C11.0459 16.2503 12.0932 16.0679 13.086 15.7105L10.9919 13.6168C10.6687 13.7052 10.3351 13.7499 10.0001 13.7496Z"
        fill={color}
      />
      <path
        d="M10 6.24967C10.5769 6.24964 11.146 6.38272 11.6631 6.63855C12.1802 6.89438 12.6313 7.26606 12.9813 7.72467C13.3313 8.18329 13.5707 8.71647 13.681 9.28273C13.7913 9.84899 13.7695 10.4331 13.6172 10.9895L16.3773 13.7497C17.516 12.7243 18.5676 11.345 19.375 9.99967C18.3445 8.30319 16.918 6.5403 15.4227 5.51452C13.7148 4.34342 11.8871 3.74967 9.99062 3.74967C8.94704 3.75117 7.91223 3.94024 6.93555 4.30788L9.01016 6.38248C9.33269 6.2942 9.6656 6.24954 10 6.24967Z"
        fill={color}
      />
    </svg>
  );
};
