import React from "react";

interface IconArrowProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconArrow = (props: IconArrowProps) => {
  const { color, rotate, style, size = 24 } = props;

  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      style={{ transform: `rotate(${rotate}deg)`, ...style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9986 18C12.5508 18 12.9984 17.5523 12.9984 17L12.9984 7.91373L17.5832 12.2068C17.9737 12.5974 18.6067 12.5974 18.9972 12.2068C19.3876 11.8163 19.3876 11.1831 18.9972 10.7925L13.2054 5.29228C12.8867 4.97351 12.4477 4.74 11.9762 4.74C11.5461 4.74 11.1682 4.91567 10.8775 5.20649L5.29283 10.7925C4.90239 11.1831 4.90239 11.8163 5.29283 12.2068C5.68328 12.5974 6.31632 12.5974 6.70676 12.2068L10.9988 7.91373L10.9988 17C10.9988 17.5523 11.4464 18 11.9986 18Z"
        fill={color ?? "#739B67"}
      />
    </svg>
  );
};
