import React from "react";

interface IconCopyProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconCopy = (props: IconCopyProps) => {
  const { size = 20, color = "#739B67" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.9375 18.75H7.1875C6.44158 18.75 5.72621 18.4537 5.19876 17.9262C4.67132 17.3988 4.375 16.6834 4.375 15.9375V7.1875C4.375 6.44158 4.67132 5.72621 5.19876 5.19876C5.72621 4.67132 6.44158 4.375 7.1875 4.375H15.9375C16.6834 4.375 17.3988 4.67132 17.9262 5.19876C18.4537 5.72621 18.75 6.44158 18.75 7.1875V15.9375C18.75 16.6834 18.4537 17.3988 17.9262 17.9262C17.3988 18.4537 16.6834 18.75 15.9375 18.75Z"
        fill={color}
      />
      <path
        d="M6.25 3.12598H15.4641C15.2696 2.57802 14.9104 2.10366 14.4356 1.76796C13.9609 1.43227 13.3939 1.25168 12.8125 1.25098H4.0625C3.31658 1.25098 2.60121 1.54729 2.07376 2.07474C1.54632 2.60218 1.25 3.31755 1.25 4.06348V12.8135C1.2507 13.3949 1.43129 13.9619 1.76699 14.4366C2.10268 14.9113 2.57705 15.2706 3.125 15.465V6.25097C3.125 5.42217 3.45424 4.62732 4.04029 4.04127C4.62634 3.45522 5.4212 3.12598 6.25 3.12598Z"
        fill={color}
      />
    </svg>
  );
};
