import { FC, ReactNode, useRef, useState } from "react";
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import ru from "date-fns/locale/ru";
import theme from "theme";
import styled from "@emotion/styled/macro";
import classNames from "classnames";
import { IconCalendar } from "atoms/IconCalendar/IconCalendar";
import "./assets/react-datepicker.scss";

interface DatePickerProps extends Omit<ReactDatePickerProps, "value"> {
  value: Date | null;
  format?: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  error?: boolean;
  leftNode?: ReactNode;
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px;
  border-radius: 16px;
  border: 1px solid ${theme.palette.gray.b300};
  padding: 0 14px;
  gap: 10px;
  align-self: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  input {
    width: 100%;
    padding: 16px 0;
    font-size: 16px;
    outline: none;
    border: none;
    flex-grow: 1;
    text-overflow: ellipsis;
    background-color: transparent;
    cursor: pointer;
    caret-color: transparent;
  }
  &:focus-within:not(.error) {
    border-color: ${theme.palette.indigo.b300};
  }
  &.disabled {
    background-color: ${theme.palette.gray.b50};
    pointer-events: none;
    input {
      color: ${theme.palette.gray.b400};
      background-color: ${theme.palette.gray.b50};
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  svg {
    position: absolute;
    right: 15px;
    z-index: -1;
  }
`;

const InputContainer = styled.div`
  font-family: Geologica;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;
  label {
    font-size: 14px;
    color: ${theme.palette.gray.b700};
  }
  .hint {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${theme.palette.gray.b500};
  }
  &.disabled {
    .hint {
      color: ${theme.palette.gray.b400};
    }
  }
  &.error {
    ${InputWrapper} {
      border-color: ${theme.palette.red.b300};
    }
    .hint {
      color: ${theme.palette.red.b500};
    }
  }
`;

export const DatePicker: FC<DatePickerProps> = ({
  className,
  error,
  label,
  disabled,
  leftNode,
  hint,
  value,
  placeholder,
  onChange,
}) => {
  registerLocale("ru", ru);
  const reactDatePickerRef = useRef<{
    [x: string]: any;
  }>({ current: { state: { open: false }, setOpen: () => {} } });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <InputContainer className={classNames(className, { error })}>
      {label && <label>{label}</label>}
      <InputWrapper
        className={classNames({ disabled, error })}
        style={{ background: "white" }}
      >
        {leftNode}
        <ReactDatePicker
          // @ts-ignore
          ref={reactDatePickerRef}
          selected={value}
          dateFormat="dd.MM.yyyy"
          locale="ru"
          nextMonthButtonLabel=""
          previousMonthButtonLabel=""
          placeholderText={placeholder}
          onKeyDown={(event) => {
            event.preventDefault();
          }}
          onChange={onChange}
        />
        <IconCalendar
          style={{
            zIndex: 1,
            pointerEvents: isOpen ? "none" : "auto",
          }}
          onClick={(e) => {
            e.preventDefault();
            const newValue = !reactDatePickerRef.current.state.open;
            setIsOpen(newValue);
            reactDatePickerRef.current.setOpen(newValue);
          }}
        />
      </InputWrapper>
      {hint && <div className="hint">{hint}</div>}
    </InputContainer>
  );
};
