import {
  Container,
  InfoBlock,
  InfoBlockDescription,
  InfoBlockTitle,
  InfoContainer,
  Title,
  Wrapper,
} from "./components";
import { OrderInfo, OrderStatus } from "api/account";
import { format } from "date-fns";
import { useIsMobile } from "hooks/useIsMobile";
import { IconErrorMobile } from "atoms/IconErrorMobile/IconErrorMobile";
import { IconBlockedCardHuge } from "atoms/IconBlockedCardHuge";
import { IconSuccess } from "atoms/IconSuccess";
import { IconSuccessDesktop } from "atoms/IconSuccessDesktop/IconSuccessDesktop";
import { Button } from "components";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

interface RefillInfoCardProps {
  refill: OrderInfo | null;
}

const createAmount = (amount: string) => {
  const amountArr = amount.split("");
  amountArr.splice(amountArr.length - 2, 0, ",");
  return amountArr.join("");
};

const RefillInfoCard = ({ refill }: RefillInfoCardProps) => {
  const navigate = useNavigate();
  const isMatch = useMediaQuery("(max-width:767px)");

  const { isMobile } = useIsMobile();
  const isSuccess = refill?.status === OrderStatus.Approved;
  const isCanceled = refill?.status === OrderStatus.Canceled;

  const successIcon = isMobile ? <IconSuccess /> : <IconSuccessDesktop />;
  const errorIcon = isMobile ? <IconErrorMobile /> : <IconBlockedCardHuge />;

  const amount = refill?.amount ? createAmount(refill.amount) : "";
  const hideAmount = !amount || amount === "0" || amount === ",0";

  const date = refill?.createDate
    ? format(new Date(refill.createDate), "dd.MM.yyyy")
    : "";

  const title = isSuccess
    ? "Карта успешно пополнена"
    : isCanceled
    ? "Оплата отменена"
    : "Ошибка. Операция отклонена";

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        {isSuccess ? successIcon : errorIcon}

        <InfoContainer>
          {!hideAmount && (
            <InfoBlock>
              <InfoBlockTitle>Сумма</InfoBlockTitle>
              <InfoBlockDescription>{amount}₽</InfoBlockDescription>
            </InfoBlock>
          )}
          <InfoBlock>
            <InfoBlockTitle>Дата выполнения операции</InfoBlockTitle>
            <InfoBlockDescription>{date}</InfoBlockDescription>
          </InfoBlock>
          <InfoBlock>
            <InfoBlockTitle>ID сессии</InfoBlockTitle>
            <InfoBlockDescription>{refill?.sessionId}</InfoBlockDescription>
          </InfoBlock>
          <InfoBlock>
            <InfoBlockTitle>ID заказа</InfoBlockTitle>
            <InfoBlockDescription>{refill?.id}</InfoBlockDescription>
          </InfoBlock>
        </InfoContainer>
        <Box
          sx={{
            width: "100%",
            maxWidth: "426px",
            marginTop: isMatch ? "40px" : 0,
          }}
        >
          <Button
            fullWidth
            variant="primary"
            onClick={() => navigate("/")}
            title="Закрыть"
          />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default RefillInfoCard;
