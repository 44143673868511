import React from "react";

interface IconFlameProps extends React.SVGProps<SVGSVGElement> {
  size?: string;
  color?: string;
}

export const IconFlame = (props: IconFlameProps) => {
  const { size = 20, color = "#739B67" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0426 1.66699L8.9009 4.00033C8.08511 5.66728 6.99174 7.18334 5.66757 8.48366L5.51757 8.62533C4.66712 9.45096 4.1812 10.5818 4.16757 11.767V11.917C4.14504 14.2379 5.52807 16.3421 7.66757 17.242L7.88424 17.3337C9.28746 17.9297 10.8727 17.9297 12.2759 17.3337H12.3259C14.4814 16.3971 15.8644 14.2586 15.8342 11.9087V8.29199C15.1159 9.93243 13.8114 11.2459 12.1759 11.9753C12.1759 11.9753 12.1759 11.9753 12.1259 11.9753C12.0759 11.9753 11.4926 12.217 11.2426 11.9753C11.0193 11.7494 10.9979 11.393 11.1926 11.142L11.2509 11.1003H11.2926C13.2057 9.64615 13.6517 6.95176 12.3092 4.95866C11.2259 3.30866 10.0426 1.66699 10.0426 1.66699Z"
        fill={color}
      />
    </svg>
  );
};
