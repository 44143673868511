import React from "react";

interface IconCardsProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconSnowCardBg = (props: IconCardsProps) => {
  const { size = 64 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
    >
      <g filter="url(#filter0_b_3535_5784)">
        <rect width="64" height="64" rx="18" fill="white" fillOpacity="0.51" />
        <rect
          x="1.5"
          y="1.5"
          width="61"
          height="61"
          rx="16.5"
          stroke="url(#paint0_linear_3535_5784)"
          strokeWidth="3"
        />
      </g>
      <path
        d="M32.0009 16V48"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.1662 19.4248C34.909 20.1748 33.4685 20.57 32.0006 20.5677C30.5327 20.57 29.0922 20.1748 27.835 19.4248"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.835 44.5696C29.0931 43.8218 30.5331 43.4268 32.0006 43.4268C33.4682 43.4268 34.9081 43.8218 36.1662 44.5696"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.0001 24.0029L18.0001 40.0029"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0815 29.2854C43.7978 28.5808 42.7321 27.5439 41.9984 26.2856C41.2647 25.0273 40.8908 23.5951 40.9166 22.1426"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.918 34.7129C20.2017 35.4175 21.2675 36.4545 22.0011 37.7128C22.7348 38.971 23.1088 40.4032 23.0829 41.8557"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0001 24.0029L46.0001 40.0029"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.918 29.2854C20.2017 28.5808 21.2675 27.5439 22.0011 26.2856C22.7348 25.0273 23.1088 23.5951 23.0829 22.1426"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0815 34.7129C43.7978 35.4175 42.7321 36.4545 41.9984 37.7128C41.2647 38.971 40.8908 40.4032 40.9166 41.8557"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_b_3535_5784"
          x="-7"
          y="-7"
          width="78"
          height="78"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3535_5784"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3535_5784"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3535_5784"
          x1="10.4096"
          y1="-1.92771"
          x2="50.506"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.21" />
          <stop offset="1" stopColor="white" stopOpacity="0.25" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IconBlockCardBg = (props: IconCardsProps) => {
  const { size = 64 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_3573_5748)">
        <rect width="64" height="64" rx="18" fill="white" fillOpacity="0.51" />
        <rect
          x="1.5"
          y="1.5"
          width="61"
          height="61"
          rx="16.5"
          stroke="url(#paint0_linear_3573_5748)"
          strokeWidth="3"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4432 26.6283V28.4172C40.4526 29.0445 41.9167 30.8635 41.9167 33.0361V38.7959C41.9167 41.4856 39.6868 43.6663 36.9376 43.6663H27.0636C24.3133 43.6663 22.0834 41.4856 22.0834 38.7959V33.0361C22.0834 30.8635 23.5486 29.0445 25.5569 28.4172V26.6283C25.5687 23.1503 28.4495 20.333 31.9823 20.333C35.5625 20.333 38.4432 23.1503 38.4432 26.6283ZM32.006 22.3619C34.4125 22.3619 36.3686 24.2748 36.3686 26.6283V28.1657H27.6315V26.6052C27.6433 24.2632 29.5994 22.3619 32.006 22.3619ZM33.0373 37.1977C33.0373 37.7658 32.575 38.2179 31.9941 38.2179C31.4251 38.2179 30.9627 37.7658 30.9627 37.1977V34.6239C30.9627 34.0674 31.4251 33.6152 31.9941 33.6152C32.575 33.6152 33.0373 34.0674 33.0373 34.6239V37.1977Z"
        fill="#E39191"
      />
      <defs>
        <filter
          id="filter0_b_3573_5748"
          x="-7"
          y="-7"
          width="78"
          height="78"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3573_5748"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3573_5748"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3573_5748"
          x1="10.4096"
          y1="-1.92771"
          x2="50.506"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.21" />
          <stop offset="1" stopColor="white" stopOpacity="0.25" />
        </linearGradient>
      </defs>{" "}
    </svg>
  );
};
export const IconClosedCardBg = (props: IconCardsProps) => {
  const { size = 64 } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_3573_5433)">
        <rect width="64" height="64" rx="18" fill="white" fillOpacity="0.51" />
        <rect
          x="1.5"
          y="1.5"
          width="61"
          height="61"
          rx="16.5"
          stroke="url(#paint0_linear_3573_5433)"
          strokeWidth="3"
        />
      </g>
      <path
        d="M32 17.375C23.9358 17.375 17.375 23.9359 17.375 32C17.375 40.0641 23.9358 46.625 32 46.625C40.0641 46.625 46.625 40.0641 46.625 32C46.625 23.9359 40.0641 17.375 32 17.375ZM38.0912 36.5L36.5 38.0912L32 33.5912L27.5 38.0912L25.9088 36.5L30.4088 32L25.9088 27.5L27.5 25.9088L32 30.4088L36.5 25.9088L38.0912 27.5L33.5912 32L38.0912 36.5Z"
        fill="#454A3F"
      />
      <defs>
        <filter
          id="filter0_b_3573_5433"
          x="-7"
          y="-7"
          width="78"
          height="78"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3573_5433"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3573_5433"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3573_5433"
          x1="10.4096"
          y1="-1.92771"
          x2="50.506"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.21" />
          <stop offset="1" stopColor="white" stopOpacity="0.25" />
        </linearGradient>
      </defs>
    </svg>
  );
};
