import React from "react";

interface IconCardAccountProps extends React.SVGProps<SVGSVGElement> {}

export const IconCardAccount = (props: IconCardAccountProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70703 16.2766C3.70703 15.9593 3.95825 15.7021 4.26814 15.7021H6.59272C6.90261 15.7021 7.15382 15.9593 7.15382 16.2766V17.4255C7.15382 17.7428 6.90261 18 6.59272 18H4.26814C3.95825 18 3.70703 17.7428 3.70703 17.4255V16.2766Z"
        fill="#739B67"
      />
      <path
        d="M10.9834 16.2766C10.9834 15.9593 11.2346 15.7021 11.5445 15.7021H13.8691C14.179 15.7021 14.4302 15.9593 14.4302 16.2766V17.4255C14.4302 17.7428 14.179 18 13.8691 18H11.5445C11.2346 18 10.9834 17.7428 10.9834 17.4255V16.2766Z"
        fill="#739B67"
      />
      <path
        d="M0.642578 1.91076C0.642578 0.855476 1.49805 0 2.55334 0H15.3471C16.4024 0 17.2579 0.855476 17.2579 1.91076V14.7045C17.2579 15.7598 16.4024 16.6153 15.3471 16.6153H2.55334C1.49805 16.6153 0.642578 15.7598 0.642578 14.7045V1.91076Z"
        fill="#739B67"
      />
      <path
        d="M2.1748 2.42453C2.1748 1.93172 2.5743 1.53223 3.06711 1.53223H14.667C15.1599 1.53223 15.5593 1.93172 15.5593 2.42453V14.0245C15.5593 14.5173 15.1599 14.9168 14.667 14.9168H3.06711C2.5743 14.9168 2.1748 14.5173 2.1748 14.0245V2.42453Z"
        fill="#87BC77"
      />
      <path
        d="M11.7191 8.3073C11.7191 9.8367 10.4793 11.0765 8.94988 11.0765C7.42048 11.0765 6.18066 9.8367 6.18066 8.3073C6.18066 6.77791 7.42048 5.53809 8.94988 5.53809C10.4793 5.53809 11.7191 6.77791 11.7191 8.3073Z"
        fill="#739B67"
      />
      <path
        d="M10.3345 8.30746C10.3345 9.07216 9.71461 9.69207 8.94992 9.69207C8.18522 9.69207 7.56531 9.07216 7.56531 8.30746C7.56531 7.54276 8.18522 6.92285 8.94992 6.92285C9.71461 6.92285 10.3345 7.54276 10.3345 8.30746Z"
        fill="#F5F5F5"
      />
      <line
        x1="13.1807"
        y1="7.76953"
        x2="13.0268"
        y2="7.76953"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="4.87305"
        y1="7.76953"
        x2="4.71919"
        y2="7.76953"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="8.41113"
        y1="3.61523"
        x2="8.41113"
        y2="3.76909"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="11.7188"
        y1="4.50668"
        x2="11.61"
        y2="4.61548"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="0.846144"
        y2="-1"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 6.10156 5.9209)"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="0.846144"
        y2="-1"
        transform="matrix(-6.79952e-08 1 1 2.81001e-08 9.41113 11.9209)"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="0.846144"
        y2="-1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 11.7188 10.6152)"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="6.10156"
        y1="12.0294"
        x2="6.21036"
        y2="11.9207"
        stroke="#F5F5F5"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
