import React from "react";

export const IconChevronDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={14}
      height={8}
      fill="none"
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.8 1.19922L6.99995 6.79922L12.2 1.19922"
        stroke={props.color ?? "#739B67"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
