import React from "react";

interface IconLocationProps extends React.SVGProps<SVGSVGElement> {}

export const IconLocation = (props: IconLocationProps) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.625 6.73832C0.625 3.28842 3.50791 0.5 6.99508 0.5C10.4921 0.5 13.375 3.28842 13.375 6.73832C13.375 8.47677 12.7428 10.0907 11.7021 11.4587C10.5541 12.9676 9.13912 14.2823 7.54641 15.3143C7.18188 15.5528 6.85291 15.5708 6.45284 15.3143C4.85105 14.2823 3.43607 12.9676 2.29787 11.4587C1.25649 10.0907 0.625 8.47677 0.625 6.73832ZM4.89567 6.93256C4.89567 8.08828 5.83874 8.99725 6.99508 8.99725C8.15218 8.99725 9.10433 8.08828 9.10433 6.93256C9.10433 5.78585 8.15218 4.83262 6.99508 4.83262C5.83874 4.83262 4.89567 5.78585 4.89567 6.93256Z"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
