import { formatInTimeZone } from "date-fns-tz";

export enum MessageType {
  USER = "USER",
  OPERATOR = "OPERATOR",
}

export const getMonthName = (date: Date) => {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  return months[date.getMonth()];
};

export const formattedDate = (date: string) => {
  const [day, month, year] = date.split(".").map(Number);
  return `${day} ${getMonthName(new Date(year, month - 1, day))}`;
};

export const getDateWithTimezone = (createdDate?: string) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (createdDate) {
    return formatInTimeZone(createdDate, timeZone, "HH:mm");
  }
};
