import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { AccountCard } from "./AccountCard";

import { ConnectedCards } from "./ConnectedCards";

export const AccountInfo = () => {
  const {
    cards: {
      account: { availableBalance, currency, description, accountNumber },
      allCards,
    },
  } = useSelector((state: any) => state);

  return (
    <Box
      p={{ xs: "0px 16px", md: "0 32px" }}
      display="flex"
      flexDirection="column"
    >
      <AccountCard
        availableBalance={availableBalance}
        currency={currency}
        description={description}
        accountNumber={accountNumber}
      />
      {allCards.length > 0 && <ConnectedCards cards={allCards} />}
    </Box>
  );
};
