import styled from "@emotion/styled/macro";
import { ReactNode } from "react";
import theme from "theme";
import { ReactComponent as ErrorIcon } from "./assets/Error.svg";
import { ReactComponent as InProgressIcon } from "./assets/InProgress.svg";
import { ReactComponent as DoneIcon } from "./assets/Done.svg";
import { ReactComponent as AcceptedIcon } from "./assets/Accepted.svg";
import { ReactComponent as CreatedIcon } from "./assets/Created.svg";

export enum StatusLabelAppearance {
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  ACCEPTED = "ACCEPTED",
  CREATED = "CREATED",
}

type AppearanceItem = {
  background: string;
  color: string;
  icon: ReactNode;
};

interface StatusLabelProps {
  title: string;
  appearance: StatusLabelAppearance;
}

const AppearanceColorsMap = {
  [StatusLabelAppearance.ERROR]: {
    background: theme.palette.red.b200,
    color: theme.palette.red.b500,
    icon: <ErrorIcon />,
  },
  [StatusLabelAppearance.IN_PROGRESS]: {
    background: theme.palette.orange.b50,
    color: theme.palette.orange.b300,
    icon: <InProgressIcon />,
  },
  [StatusLabelAppearance.DONE]: {
    background: theme.palette.green.b50,
    color: theme.palette.green.b500,
    icon: <DoneIcon />,
  },
  [StatusLabelAppearance.ACCEPTED]: {
    background: theme.palette.blueLight.b100,
    color: theme.palette.blue.b400,
    icon: <AcceptedIcon />,
  },
  [StatusLabelAppearance.CREATED]: {
    background: theme.palette.gray.b50,
    color: theme.palette.gray.b400,
    icon: <CreatedIcon />,
  },
};

const StatusLabelStyled = styled.div<AppearanceItem>`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  padding: 8px 13px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  svg {
    height: 16px;
    width: 16px;
  }
`;

export const StatusLabel: React.FC<StatusLabelProps> = ({
  title,
  appearance,
}) => {
  const icon = AppearanceColorsMap[appearance as StatusLabelAppearance]?.icon;
  return (
    <StatusLabelStyled
      {...AppearanceColorsMap[appearance as StatusLabelAppearance]}
    >
      {icon}
      {title}
    </StatusLabelStyled>
  );
};
