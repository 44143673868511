export const IconBlockedCardHuge = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="426"
      height="150"
      viewBox="0 0 426 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="426" height="150" rx="15" fill="#FFF4F4" />
      <path
        d="M272 119C272 123.418 268.418 127 264 127H162C157.582 127 154 123.418 154 119V36.0026C154 31.5868 157.579 28.006 161.994 28.0038C200.014 27.9851 235.646 28.0427 263.994 28.0184C268.417 28.0146 272 31.5982 272 36.0213V119Z"
        fill="white"
      />
      <path
        d="M220.312 94H205.688C204.756 94 204 95.3431 204 97C204 98.6569 204.756 100 205.688 100H220.312C221.244 100 222 98.6569 222 97C222 95.3431 221.244 94 220.312 94Z"
        fill="#F5F5F5"
      />
      <path
        d="M235 104H191C189.343 104 188 105.343 188 107C188 108.657 189.343 110 191 110H235C236.657 110 238 108.657 238 107C238 105.343 236.657 104 235 104Z"
        fill="#F5F5F5"
      />
      <path
        d="M213 71C226.255 71 237 60.2548 237 47C237 33.7452 226.255 23 213 23C199.745 23 189 33.7452 189 47C189 60.2548 199.745 71 213 71Z"
        fill="#E64545"
      />
      <path
        d="M221 39L205 55M205 39L221 55"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
