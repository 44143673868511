import React from "react";

interface IconCardDropDown extends React.SVGProps<SVGSVGElement> {}

export const IconCardDropDown = (props: IconCardDropDown) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 23.6666C5.56016 23.6666 0.333496 18.4283 0.333496 11.9999C0.333496 5.55992 5.56016 0.333252 12.0002 0.333252C18.4285 0.333252 23.6668 5.55992 23.6668 11.9999C23.6668 18.4283 18.4285 23.6666 12.0002 23.6666ZM16.6668 9.68992C16.3168 9.35159 15.7685 9.35159 15.4302 9.70159L12.0002 13.1433L8.57016 9.70159C8.23183 9.35159 7.67183 9.35159 7.3335 9.68992C6.9835 10.0399 6.9835 10.5883 7.3335 10.9266L11.3818 14.9983C11.5452 15.1616 11.7668 15.2549 12.0002 15.2549C12.2335 15.2549 12.4552 15.1616 12.6185 14.9983L16.6668 10.9266C16.8418 10.7633 16.9235 10.5416 16.9235 10.3199C16.9235 10.0866 16.8418 9.86492 16.6668 9.68992Z"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
