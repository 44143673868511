export enum NotificationType {
  MESSAGE = "MESSAGE",
  NEWS = "NEWS",
  NOTIFICATION = "NOTIFICATION",
}

export const getNotificationTypeFromUrl = (url) => {
  if (url.includes(NotificationType.MESSAGE)) {
    return NotificationType.MESSAGE;
  } else if (url.includes(NotificationType.NEWS)) {
    return NotificationType.NEWS;
  } else if (url.includes(NotificationType.NOTIFICATION)) {
    return NotificationType.NOTIFICATION;
  }
  return null;
};
