import React from "react";

interface IconBiometryProps extends React.SVGProps<SVGSVGElement> {}

export const IconBiometry = (props: IconBiometryProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 7.12199V6.93334C21 4.76102 19.239 3 17.0667 3V3M6.93333 21V21C4.76101 21 3 19.239 3 17.0667L3 16.878M17.0667 21V21C19.239 21 21 19.239 21 17.0667V16.878M3 7.12199V6.93334C3 4.76102 4.76101 3 6.93333 3V3"
        stroke={props.color ?? "#739B67"}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="7"
        y1="12"
        x2="17"
        y2="12"
        stroke={props.color ?? "#739B67"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
