export const getDateForNotification = () => {
  const today = new Date();

  const pad = (num, size) => String(num).padStart(size, "0");

  const year = today.getFullYear();
  const month = pad(today.getMonth() + 1, 2);
  const day = pad(today.getDate(), 2);
  const hours = pad(today.getHours(), 2);
  const minutes = pad(today.getMinutes(), 2);
  const seconds = pad(today.getSeconds(), 2);
  const milliseconds = pad(today.getMilliseconds(), 3) + "000";

  const timezoneOffset = -today.getTimezoneOffset();
  const sign = timezoneOffset >= 0 ? "+" : "-";
  const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60), 2);
  const offsetMinutes = pad(Math.abs(timezoneOffset) % 60, 2);

  const timezone = `${sign}${offsetHours}:${offsetMinutes}`;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezone}`;
};
