import systemSlice, { TabVariants, MobileTabVariants } from "./slice";
import type { SystemState } from "./slice";
import type AllTabVariants from "./slice";

const systemActions = systemSlice.actions;

export {
  systemSlice,
  SystemState,
  systemActions,
  TabVariants,
  MobileTabVariants,
  AllTabVariants,
};
