export interface IStatuses {
  [key: string]: {
    label: string;
    color: "green" | "orange" | "red";
  };
}

export const statuses: IStatuses = {
  RESOLVED: {
    label: "Решена",
    color: "green",
  },
  CREATED: {
    label: "В работе",
    color: "orange",
  },
  DECLINED: {
    label: "Отклонена",
    color: "red",
  },
};
