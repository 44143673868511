import React from "react";

interface IconArrowIncomingProps extends React.SVGProps<SVGSVGElement> {}

const IconArrowIncoming = (props: IconArrowIncomingProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.786 9.00667C12.4991 8.74819 12.0356 8.74819 11.7486 9.00667L8.71725 11.7533V4.66667C8.71725 4.29848 8.38784 4 7.98148 4C7.57513 4 7.24572 4.29848 7.24572 4.66667V11.7533L4.21435 9.00667C3.92243 8.78015 3.48727 8.79538 3.2155 9.04163C2.94373 9.28787 2.92693 9.68216 3.17692 9.94667L7.45909 13.8333C7.59724 13.9595 7.7853 14.0305 7.98148 14.0305C8.17767 14.0305 8.36573 13.9595 8.50388 13.8333L12.786 9.94667C13.0713 9.68664 13.0713 9.26669 12.786 9.00667Z"
        fill="#739B67"
      />
      <path
        d="M4.30264 3.33333H11.6603C12.0667 3.33333 12.3961 3.03486 12.3961 2.66667C12.3961 2.29848 12.0667 2 11.6603 2H4.30264C3.89629 2 3.56688 2.29848 3.56688 2.66667C3.56688 3.03486 3.89629 3.33333 4.30264 3.33333Z"
        fill="#739B67"
      />
    </svg>
  );
};

export default IconArrowIncoming;
