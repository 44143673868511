import styled from "@emotion/styled/macro";

export const IconWrapper = styled.div<{
  bgColor?: string;
  borderRadius?: string;
  size?: number;
}>`
  border-radius: ${(props) => props.borderRadius ?? "10px"};
  background-color: ${(props) => props.bgColor ?? "#fff"};
  width: ${(props) => props.size ?? 40}px;
  height: ${(props) => props.size ?? 40}px;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;
