import React from "react";

interface IconPinCodeProps extends React.SVGProps<SVGSVGElement> {}

export const IconPinCode = (props: IconPinCodeProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2933 12.6577L20.9933 16.3677C21.2527 16.651 21.3439 17.0499 21.2333 17.4177L20.5533 19.7977C20.4454 20.1545 20.1686 20.4351 19.8133 20.5477L17.3833 21.2677C16.9961 21.3814 16.5778 21.2739 16.2933 20.9877L12.5833 17.2777C11.8214 16.5192 10.681 16.2872 9.68331 16.6877C8.68254 17.09 7.5384 16.858 6.77331 16.0977L3.77331 13.0977C3.09342 12.4094 2.83734 11.408 3.10331 10.4777L4.24331 6.09774C4.48993 5.1857 5.19534 4.46892 6.10331 4.20774L10.5033 3.09774C11.4275 2.84167 12.418 3.09693 13.1033 3.76774L16.1033 6.76774C16.8923 7.54163 17.13 8.71824 16.7033 9.73774C16.2939 10.7411 16.5264 11.8921 17.2933 12.6577ZM8.82332 11.8977L11.9233 8.79774C12.2188 8.50503 12.221 8.02821 11.9283 7.73274C11.6356 7.43726 11.1588 7.43503 10.8633 7.72774L7.76332 10.8377C7.47086 11.1306 7.47086 11.6049 7.76332 11.8977C8.05613 12.1902 8.5305 12.1902 8.82332 11.8977Z"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
