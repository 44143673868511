import { useEffect, useRef, useState } from "react";

export const useCounter = () => {
  const [count, setCount] = useState(59);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const clear = () => clearInterval(timerRef.current);

  const interval = () => {
    timerRef.current = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
  };

  const restartCounter = () => {
    if (timerRef.current) clear();

    setCount(59);
    interval();
  };

  useEffect(() => {
    interval();

    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (count === 0) clear();
  }, [count]);

  return {
    count,
    restartCounter,
  };
};
