import { useMediaQuery } from "@mui/material";
import { useState, useEffect, useRef, RefObject } from "react";

interface UseStickyReturn {
  isSticky: boolean;
  ref: RefObject<HTMLDivElement>;
}

const useSticky = (): UseStickyReturn => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = (): void => {
    if (ref.current) {
      const offset = ref.current.getBoundingClientRect().top;
      setIsSticky(offset <= 0);
    }
  };

  useEffect(() => {
    if (ref.current) {
      const scrollableParent: Element | Body =
        ref.current.closest(".scrollable-container") || document.body;

      scrollableParent.addEventListener("scroll", handleScroll);

      return () => {
        scrollableParent.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return { isSticky, ref };
};

export default useSticky;
