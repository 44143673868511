import React from "react";

export const Cage = ({ fill = "#0082BA" }) => {
  return (
    <svg
      width={49}
      height={48}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/avg"
    >
      <rect x={0.5} width={48} height={48} rx={24} fill="#fff" />
      <path
        d="M20.125 34.792a.875.875 0 100-1.75.875.875 0 000 1.75z"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.375 34.792a.875.875 0 100-1.75.875.875 0 000 1.75z"
        stroke={fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 14.375h3.5L19.25 29.25h14"
        stroke={fill}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 25.751h13.641a.438.438 0 00.43-.352l1.575-7.875a.436.436 0 00-.43-.523H17.5"
        stroke={fill}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
