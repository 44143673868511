export const IconClose = (props) => {
  const { color = "#739B67", width = 20, height = 20 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M11.5909 9.99926L16.0441 5.54614C16.2554 5.33517 16.3743 5.04888 16.3745 4.75026C16.3748 4.45164 16.2564 4.16514 16.0455 3.9538C15.8345 3.74245 15.5482 3.62357 15.2496 3.62331C14.951 3.62305 14.6645 3.74142 14.4531 3.95239L9.99999 8.40551L5.54687 3.95239C5.33553 3.74105 5.04888 3.62231 4.75 3.62231C4.45111 3.62231 4.16447 3.74105 3.95312 3.95239C3.74178 4.16374 3.62305 4.45038 3.62305 4.74927C3.62305 5.04815 3.74178 5.3348 3.95312 5.54614L8.40625 9.99926L3.95312 14.4524C3.74178 14.6637 3.62305 14.9504 3.62305 15.2493C3.62305 15.5481 3.74178 15.8348 3.95312 16.0461C4.16447 16.2575 4.45111 16.3762 4.75 16.3762C5.04888 16.3762 5.33553 16.2575 5.54687 16.0461L9.99999 11.593L14.4531 16.0461C14.6645 16.2575 14.9511 16.3762 15.25 16.3762C15.5489 16.3762 15.8355 16.2575 16.0469 16.0461C16.2582 15.8348 16.3769 15.5481 16.3769 15.2493C16.3769 14.9504 16.2582 14.6637 16.0469 14.4524L11.5909 9.99926Z"
        fill={color}
      />
    </svg>
  );
};
