import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled/macro";
import theme from "theme";

import { InStatementTypes } from "../../History";
import IconArrowIncoming from "atoms/IconArrowIncoming/IconArrowIncoming";
import IconArrowOutcoming from "atoms/IconArrowOutcoming/IconArrowOutcoming";
import { formatNumber } from "utils/formatCurrency";
import { StatementShort } from "api/account/api";

interface IncomeIconWrapperProps {
  bgColor?: string;
}

const IncomeIconWrapper = styled.div<IncomeIconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  border-radius: 40px;
  border: 1px solid var(--main-color-bg-widgets);
  background-color: ${(props) => props.bgColor || "transparent"};

  @media (max-width: 450px) {
    width: 32px;
    height: 32px;
  }
`;

const LogoIconWrapper = styled.div<{ bgUrl: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  border-radius: 40px;
  border: 1px solid var(--main-color-bg-widgets);
  background-color: var(--brand-color-disabled);
  background-image: url(${(props) => props.bgUrl});
  background-size: ${(props) => (props.bgUrl ? "20px" : "cover")};

  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 450px) {
    width: 32px;
    height: 32px;
  }
`;

const AmountStyled = styled.span<{ isIncoming: boolean }>`
  font-size: 17px;
  line-height: 20px;
  font-weight: 500;
  color: ${(props) =>
    props.isIncoming ? theme.palette.blue.b400 : theme.palette.gray.b800};
  white-space: nowrap;
  &:before {
    content: "${(props) => (props.isIncoming ? "+" : "")}";
  }

  @media (max-width: 588px) {
    font-size: 16px;
  }
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

interface OperationItemProps {
  item: StatementShort;
  onOpenModal?: (id: string) => void;
}

export const OperationItem = ({ item, onOpenModal }: OperationItemProps) => {
  const logoUrl = item?.category_logo;
  const isIncoming =
    item.type !== undefined && InStatementTypes.includes(item.type);
  const amount = item.amount ?? 0;

  return (
    <Box
      p="12px 19px 12px 0px"
      display="flex"
      gap="12px"
      alignItems="center"
      onClick={() => onOpenModal?.(item.statementId || "")}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      {logoUrl ? (
        <LogoIconWrapper bgUrl={logoUrl} />
      ) : (
        <IncomeIconWrapper
          bgColor={isIncoming ? "var(--brand-color-disabled)" : ""}
        >
          {isIncoming ? <IconArrowIncoming /> : <IconArrowOutcoming />}
        </IncomeIconWrapper>
      )}

      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginRight: "auto",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "100%",

          "@media (max-width: 450px)": {
            fontSize: "14px",
          },
        }}
      >
        <Typography
          fontSize="17px"
          lineHeight="20px"
          color="var(--main-color-text-title)"
          fontWeight="400"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            "@media (max-width: 450px)": {
              fontSize: "14px",
            },
          }}
          title={item.acquirer}
        >
          {item.acquirer}
        </Typography>
        <Typography
          fontSize="14px"
          lineHeight="20px"
          color="var(--main-color-text-secondary)"
          fontWeight="400"
        >
          {item.acquirerGroup}
        </Typography>
      </Box>

      <AmountStyled isIncoming={amount > 0}>
        {formatNumber(amount, true, item.currency ? item.currency.sign : "")}
      </AmountStyled>
    </Box>
  );
};
