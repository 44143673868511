import React from "react";

export const IconDocument = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = 16, height = 18, color = "#454A3F" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50833 0.666992H11.4925C14.0667 0.666992 15.5 2.15033 15.5 4.69199V13.3003C15.5 15.8837 14.0667 17.3337 11.4925 17.3337H4.50833C1.975 17.3337 0.5 15.8837 0.5 13.3003V4.69199C0.5 2.15033 1.975 0.666992 4.50833 0.666992ZM4.73333 4.55033V4.54199H7.22417C7.58333 4.54199 7.875 4.83366 7.875 5.19116C7.875 5.55866 7.58333 5.85033 7.22417 5.85033H4.73333C4.37417 5.85033 4.08333 5.55866 4.08333 5.20033C4.08333 4.84199 4.37417 4.55033 4.73333 4.55033ZM4.73333 9.61699H11.2667C11.625 9.61699 11.9167 9.32533 11.9167 8.96699C11.9167 8.60866 11.625 8.31616 11.2667 8.31616H4.73333C4.37417 8.31616 4.08333 8.60866 4.08333 8.96699C4.08333 9.32533 4.37417 9.61699 4.73333 9.61699ZM4.73333 13.4253H11.2667C11.5992 13.392 11.85 13.1078 11.85 12.7753C11.85 12.4337 11.5992 12.1503 11.2667 12.117H4.73333C4.48333 12.092 4.24167 12.2087 4.10833 12.4253C3.975 12.6337 3.975 12.9087 4.10833 13.1253C4.24167 13.3337 4.48333 13.4587 4.73333 13.4253Z"
        fill={color}
      />
    </svg>
  );
};
