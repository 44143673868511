import styled from "@emotion/styled/macro";
import { IconCalendar } from "atoms/IconCalendar/IconCalendar";
import classNames from "classnames";
import { ReactNode } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import theme from "theme";

interface InputDateProps extends NumberFormatProps {
  format?: string;
  label?: string;
  hint?: string;
  error?: boolean;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px;
  border-radius: 16px;
  border: 1px solid ${theme.palette.gray.b300};
  padding: 0 14px;
  gap: 10px;
  align-self: flex-start;
  box-sizing: border-box;
  input {
    padding: 16px 0;
    font-size: 16px;
    outline: none;
    border: none;
    flex-grow: 1;
    text-overflow: ellipsis;
    background-color: transparent;
  }
  &:focus-within:not(.error) {
    border-color: ${theme.palette.indigo.b300};
  }
  &.disabled {
    background-color: ${theme.palette.gray.b50};
    pointer-events: none;
    input {
      color: ${theme.palette.gray.b400};
      background-color: ${theme.palette.gray.b50};
    }
  }
`;

const InputContainer = styled.div`
  font-family: Geologica;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;
  label {
    font-size: 14px;
    color: ${theme.palette.gray.b700};
  }
  .hint {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${theme.palette.gray.b500};
  }
  &.disabled {
    .hint {
      color: ${theme.palette.gray.b400};
    }
  }
  &.error {
    ${InputWrapper} {
      border-color: ${theme.palette.red.b300};
    }
    .hint {
      color: ${theme.palette.red.b500};
    }
  }
`;

export const InputDate: React.FC<InputDateProps> = ({
  format = "##.##.####",
  label,
  hint,
  error,
  leftNode,
  rightNode = <IconCalendar width={22} height={22} />,
  disabled,
  className,
  ...rest
}) => {
  return (
    <InputContainer className={classNames(className, { error })}>
      {label && <label>{label}</label>}
      <InputWrapper className={classNames({ disabled, error })}>
        {leftNode}
        <NumberFormat format="##.##.####" disabled={disabled} {...rest} />
        {rightNode}
      </InputWrapper>
      {hint && <div className="hint">{hint}</div>}
    </InputContainer>
  );
};
