import { IconNoSearchResults } from "atoms/IconNoSearchResults/IconNoSearchResults";
import { EmptyStyled } from "./components";

type Props = {
  title: string;
  subtitle: string;
};

export const EmptyCustom: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <EmptyStyled>
      <IconNoSearchResults />
      <h2>{title}</h2>
      <h4>{subtitle}</h4>
    </EmptyStyled>
  );
};
