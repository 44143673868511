import { FC } from "react";
import styled from "@emotion/styled/macro";
import theme from "theme";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";

const ResetButton = styled.div<Partial<OperationResetProps>>`
  background: ${(props) =>
    props.isChanged ? "var(--brand-color-disabled)" : theme.palette.gray.b50};
  padding: 12px 12px 12px 16px;
  border-radius: 12px;
  cursor: ${(props) => (props.isChanged ? "pointer" : "default")};
  color: ${(props) =>
    props.isChanged
      ? "var(--brand-color-primary)"
      : "var(--main-color-text-title)"};
  display: flex;
  gap: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  white-space: nowrap;
  align-items: center;
`;

interface OperationResetProps {
  isChanged: boolean;
  onReset: (fullReset: boolean) => void;
}

export const OperationReset: FC<OperationResetProps> = ({
  isChanged,
  onReset,
}) => (
  <ResetButton
    {...(isChanged && { onClick: () => onReset(true) })}
    isChanged={isChanged}
  >
    Сбросить{" "}
    <IconCircleClose
      color={
        isChanged
          ? "var(--brand-color-primary)"
          : "var(--main-color-text-title)"
      }
    />
  </ResetButton>
);
