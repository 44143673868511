import * as React from "react";

export const IconChatTab = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {typeof props.isUnread === "function" && props.isUnread() && (
      <svg
        width={6}
        height={6}
        viewBox="0 0 7 6"
        fill="none"
        style={{ position: "absolute", left: 14, top: 1, zIndex: 10 }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="3.5" cy="3" r="3" fill="#FD853A" />
      </svg>
    )}
    <path
      d="M5.209 17.365c.051-.19-.063-.453-.171-.643a1.946 1.946 0 0 0-.11-.164 8.646 8.646 0 0 1-1.428-4.76C3.485 6.941 7.512 3 12.493 3c4.343 0 7.969 3.009 8.816 7.003.127.592.19 1.196.191 1.802 0 4.864-3.872 8.867-8.852 8.867-.792 0-1.861-.199-2.444-.362a16.408 16.408 0 0 1-1.315-.438 1.346 1.346 0 0 0-1.004.016l-2.935 1.06a.693.693 0 0 1-.202.052.415.415 0 0 1-.414-.421.685.685 0 0 1 .026-.143l.849-3.07Z"
      fill={props?.color || "#C2C9D6"}
    />
  </svg>
);
