import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { useAppThunkDispatch } from "store";
import { loadAttachment } from "store/slices/notifications";
import { useIsMobile } from "hooks/useIsMobile";
import { IconDownload } from "atoms/IconDownload/IconDownload";
import { Button } from "components/Button";

interface INewsInfo {
  title?: string;
  subTitle?: string;
  dateTime?: string;
  attachment?: number;
}

export const NewsInfo: FC<INewsInfo> = ({
  title,
  dateTime,
  attachment,
  subTitle,
}) => {
  const { isMobile } = useIsMobile();
  const thunkDispatch = useAppThunkDispatch();
  const formattedDate = dateTime
    ? format(new Date(dateTime), "d MMMM yyyy", { locale: ru })
    : "";

  const handleFileDownload = () => {
    if (attachment) {
      thunkDispatch(loadAttachment(attachment))
        .unwrap()
        .then((res) => {
          //@ts-ignore
          const base64Data = res.base64.split(";base64,").pop();
          //@ts-ignore
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: res.mimeType });

          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = res.fileName || "downloadedFile.pdf";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
    }
  };

  return (
    <Box pl={isMobile ? 20 : 30} pr={isMobile ? 20 : 30}>
      <Box alignItems="center" display="flex" gap={8} mb={20}>
        <Box
          bgcolor="var(--brand-color-hover)"
          borderRadius={12}
          paddingX={10}
          pt={3}
          pb={5}
        >
          <Typography
            fontSize={13}
            fontWeight={400}
            lineHeight="14px"
            color="var(--main-color-text-subtitle)"
          >
            Новости банка
          </Typography>
        </Box>
        <Typography
          color="var(--main-color-notification-description)"
          fontWeight={600}
        >
          ·
        </Typography>
        <Typography
          color="var(--main-color-notification-description)"
          fontSize={13}
          fontWeight={400}
          lineHeight="14px"
        >
          {formattedDate}
        </Typography>
      </Box>
      <Typography
        mb={20}
        fontSize={24}
        fontWeight={600}
        lineHeight="28px"
        color="var(--main-color-text-title)"
      >
        {title}
      </Typography>
      <Typography
        mb={80}
        fontSize={16}
        fontWeight={400}
        lineHeight="20px"
        color="var(--main-color-text-title)"
      >
        {subTitle}
      </Typography>
      {attachment && (
        <Button
          variant="primary"
          onClick={handleFileDownload}
          startIcon={<IconDownload color="#FFFFFF" />}
          title="Скачать файл"
        />
      )}
    </Box>
  );
};
