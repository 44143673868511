import React, { Suspense } from "react";

interface TemplateCardProps {
  name: TemplateCardType;
}

export enum TemplateCardType {
  CREATE_REQUEST_TABLE = "CREATE_REQUEST_TABLE",
}

const TemplateCardMap = {
  [TemplateCardType.CREATE_REQUEST_TABLE]: "CreateRequestTable",
};

export const TemplateCard: React.FC<TemplateCardProps> = ({
  name,
  ...rest
}) => {
  const TemplateComponent = React.lazy(
    () => import(`./components/${TemplateCardMap[name]}`)
  );

  return (
    <Suspense fallback={<></>}>
      <TemplateComponent {...rest} />
    </Suspense>
  );
};
