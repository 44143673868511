import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import { Box, Typography, useTheme } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { setHugeError } from "store/slices/alerts";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
interface HugeAlert {
  title: string;
  message?: string;
  isOpen?: boolean;
}

const HugeAlert: FC<HugeAlert> = ({ isOpen, message, title }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const { red, gray } = theme.palette;
  const {
    primary: { error },
  } = theme;

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        dispatch(
          setHugeError({
            isOpen: false,
            message: "",
            title: "",
          })
        );
      }, 30000);
    }
  }, [isOpen, dispatch]);

  return createPortal(
    <Box
      sx={{
        backgroundColor: error?.[50],
        position: "fixed",
        right: 0,
        left: 0,
        top: isMobile ? 95 : 110,
      }}
      maxWidth={1440}
      margin="0 auto"
      mx={{ xs: 20, xl: 120 }}
      px={16}
      py={16}
      display={isOpen ? "flex" : "none"}
      flexDirection="column"
      borderRadius={16}
    >
      <Box display="flex" alignItems="center" mb={8}>
        <IconCircleClose width={20} height={20} color="#B72525" />
        <Typography
          ml={6}
          variant="text_5"
          fontWeight={500}
          color={red.b500}
          textAlign="start"
        >
          {title}
        </Typography>
      </Box>

      <Typography variant="text_3" color={gray.b500} textAlign="start">
        {message}
      </Typography>
    </Box>,
    document.body
  );
};

export default HugeAlert;
