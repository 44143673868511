import React from "react";

interface IconLimitProps extends React.SVGProps<SVGSVGElement> {}

export const IconLimit = (props: IconLimitProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        x="1.66663"
        y="1.66699"
        width="16.6667"
        height="16.6667"
        rx="5"
        fill="#739B67"
      />
      <path
        d="M6.29634 10.9256H9.07411M9.07411 10.9256V13.7034M9.07411 10.9256L5.83337 14.1663M13.7037 9.07375H10.926M10.926 9.07375V6.29597M10.926 9.07375L14.1667 5.83301"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
