import styles from "./Exit.module.css";
import { IconContainer } from "atoms/IconContainer";
import { makeStyles } from "@mui/styles";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { authActions } from "store/slices/auth";
import { useCallback } from "react";
import { IconArrow } from "atoms/IconArrow/IconArrow";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    "& > svg > path": {
      transition: ".2s",
    },
    "&:hover": {
      "& > svg > path": {
        transition: ".2s",
        fill: "#D9E3CC",
      },
    },
  },
}));

export const Exit = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleToggle = useCallback(() => {
    dispatch(authActions.setIsLogoutVisible());
  }, [dispatch]);

  return (
    <IconContainer
      className={cn(styles.iconExitContainer, classes.icon)}
      // onClick={handleToggle}
    >
      <IconArrow />
    </IconContainer>
  );
};
