import React from "react";

interface IconCardMiniProps extends React.SVGProps<SVGSVGElement> {}

export const IconCardMini = (props: IconCardMiniProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5H18C20.2091 5 22 6.79086 22 9V15C22 17.2091 20.2091 19 18 19H6C3.79086 19 2 17.2091 2 15V9C2 6.79086 3.79086 5 6 5ZM5.26 14.75H18.74C19.1542 14.75 19.49 14.4142 19.49 14C19.49 13.5858 19.1542 13.25 18.74 13.25H5.26C4.84579 13.25 4.51 13.5858 4.51 14C4.51 14.4142 4.84579 14.75 5.26 14.75Z"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
