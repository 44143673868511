import React, { PropsWithChildren } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { IconLeft } from "atoms/IconLeft";

interface Props extends PropsWithChildren {
  title: string;
  subtitle?: string | React.ReactElement;
  separation?: boolean;
  text?: any;
  error?: string;
  isLeft?: boolean;
  withLeftArrow?: boolean;
  leftArrowClick?: () => void;
}

const AuthContainer = ({
  children,
  text,
  title,
  subtitle,
  separation = false,
  isLeft = true,
  error = "",
  withLeftArrow,
  leftArrowClick,
}: Props) => {
  const { isMobile } = useIsMobile();
  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      component="main"
      container
      px={{ xs: 16, xl: 0 }}
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Grid
        item
        lg={12}
        md={8}
        xs={4}
        boxSizing="content-box"
        mt={{ xs: 24, xl: 60 }}
        position="relative"
      >
        {isMobile && withLeftArrow && (
          <div
            onClick={leftArrowClick}
            style={{
              position: "absolute",
              top: "-3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <IconLeft />
          </div>
        )}
        <Box sx={{ textAlign: isLeft && isMobile ? "left" : "center" }}>
          <Typography
            variant="text_20"
            color="gray.b900"
            fontWeight="600"
            whiteSpace="pre-wrap"
          >
            {title}
          </Typography>
          <Box mt={isMobile ? 16 : 8} mb={isMobile ? 28 : 40}>
            {!error &&
              !separation &&
              subtitle &&
              (typeof subtitle === "string" ? (
                <Typography
                  variant="text_19"
                  color="var(--main-color-text-subtitle)"
                  maxWidth={306}
                  style={{ whiteSpace: "pre-line" }}
                >
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              ))}
            {!error && separation && typeof subtitle === "string" && (
              <Typography
                variant="text_19"
                color="gray.b500"
                maxWidth={306}
                style={{ whiteSpace: "pre-wrap" }}
              >
                На номер{" "}
                <Typography
                  variant="text_19"
                  color="blue.b400"
                  maxWidth={306}
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {subtitle}
                </Typography>
                {"\n"}
                был отправлен код подтверждения
              </Typography>
            )}
            {!!error && (
              <Typography
                variant="text_19"
                color="error"
                maxWidth={306}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {error}
              </Typography>
            )}
          </Box>
          <Box
            maxWidth={{ sm: 375, xl: 404 }}
            margin="0 auto"
            mb={84}
            style={{ textAlign: "center" }}
          >
            <Box mt={12}>{children}</Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthContainer;
