import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface AlertsState {
  errorMessage: string;
  errorTitle: string;
  isOpen: boolean;
  isHugeAlertOpen: boolean;
  errorHugeMessage: string;
  errorHugeTitle: string;
}

const initialState: AlertsState = {
  errorMessage: "",
  errorTitle: "",
  isOpen: false,
  isHugeAlertOpen: false,
  errorHugeMessage: "",
  errorHugeTitle: "",
};

export interface IErrorMessage {
  errorMessage?: string;
  errorTitle: string;
}

export const setErrorMessage = createAction<IErrorMessage>("setErrorMessage");

export const closeErrorMessage = createAction("closeErrorMessage");

export const clearErrorMessage = createAction("clearErrorMessage");

let timeout: ReturnType<typeof setTimeout>;

export const showErrorMessage = createAsyncThunk(
  "showErrorMessage",
  (message: IErrorMessage, { dispatch, getState }) => {
    const state = getState() as RootState;
    if (state.alerts.errorTitle) dispatch(closeErrorMessage());
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(setErrorMessage(message)), 200);
  }
);

export const hideErrorMessage = createAsyncThunk(
  "hideErrorMessage",
  (_, { dispatch }) => {
    if (timeout) clearTimeout(timeout);
    dispatch(closeErrorMessage());
  }
);

export const alerts = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setHugeError: (state, { payload }) => {
      state.isHugeAlertOpen = payload.isOpen;
      state.errorHugeMessage = payload.message;
      state.errorHugeTitle = payload.title;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setErrorMessage, (state, { payload }) => {
      state.errorMessage = payload.errorMessage ?? "";
      state.errorTitle = payload.errorTitle || "Какая-то системная ошибка";
      state.isOpen = true;
    });
    builder.addCase(clearErrorMessage, (state) => {
      state.errorTitle = "";
    });
    builder.addCase(closeErrorMessage, (state) => {
      state.isOpen = false;
    });
  },
});

export const { setHugeError } = alerts.actions;
