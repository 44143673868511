import React from "react";

interface IconArrowProps extends React.SVGProps<SVGSVGElement> {}

export const IconArrow = (props: IconArrowProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42578 3L10.8008 6.375L7.42578 9.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#739B67"
      />
    </svg>
  );
};
