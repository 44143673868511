import Modal from "@mui/material/Modal";
import { Button } from "components";
import { Box } from "@mui/material";

import { IconPhone } from "atoms/IconPhone/IconPhone";

interface Props {
  isOpen: boolean;
  handler: () => void;
  phoneNumber: string;
}

export const ContactsBottomSheet = ({
  isOpen,
  handler,
  phoneNumber,
}: Props) => {
  if (!isOpen) return null;

  return (
    <Modal open={isOpen} onClick={handler}>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: "8px 12px 32px",
          boxSizing: "border-box",
          outline: "none",
        }}
      >
        <a href={`tel:${phoneNumber}`}>
          <Box
            sx={{
              width: "100%",
              boxSizing: "border-box",
              color: "#1C7ECE",
              fontSize: "17px",
              fontWeight: 400,
              lineHeight: "20px",
              backgroundColor: "#fff",
              padding: "16px 10px",
              borderRadius: "13px",
              marginBottom: "8px",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <IconPhone color="#7E7F7A" size={20} /> Вызов {phoneNumber}
          </Box>
        </a>

        <Button
          variant="secondary"
          title="Отменить"
          style={{
            width: "100%",
            boxSizing: "border-box",
            color: "#1C7ECE",
            fontSize: "17px",
            fontWeight: 600,
            lineHeight: "20px",
            padding: "16px 10px",
            borderRadius: "13px",
            backgroundColor: "#fff",
          }}
          onClick={handler}
        />
      </Box>
    </Modal>
  );
};
