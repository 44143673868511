import { NotificationDto } from "api/notification";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

const formatDate = (date: string, dateFormat: string): string =>
  format(new Date(date), dateFormat, {
    locale: ru,
  });

export const groupByDate = (
  items: NotificationDto[],
  state: Record<string, NotificationDto[]>,
  dateFormat: string
): Record<string, NotificationDto[]> => {
  const arr = [...items];
  return arr.reverse().reduce((acc: Record<string, NotificationDto[]>, el) => {
    if (el.dateTime) {
      const month = formatDate(el.dateTime, dateFormat);
      acc[month] = [...(acc[month] || []), el];
    }
    return acc;
  }, state);
};
