import styled from "@emotion/styled";
import { IconLogoutMobile } from "atoms/IconLogoutMobile/IconLogoutMobile";
import { Button } from "components/Button";

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ModalContent = styled.div`
  padding: 33px 48px 0px 48px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 24px;
  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
  div {
    text-align: center;
  }
  @media (max-width: 768px) {
    padding: 33px 16px 0px 16px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 100px;
  width: 100%;
`;

type LogoutModalProps = {
  onClick: () => void;
  onCancel: () => void;
};

export const LogoutModal = ({ onClick, onCancel }: LogoutModalProps) => (
  <ModalContentWrapper>
    <ModalContent>
      <IconLogoutMobile />
      <h4>Внимание!</h4>
      <div>
        Вы уверены, что хотите выйти из аккаунта?
        <br />
        Для входа вам потребуется повторный
        <br />
        ввод логина и пароля
      </div>
      <ButtonsWrapper>
        <Button onClick={onClick} variant="primary" title="Выйти" />
        <Button onClick={onCancel} variant="secondary" title="Отмена" />
      </ButtonsWrapper>
    </ModalContent>
  </ModalContentWrapper>
);
