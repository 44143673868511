import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { AddressOperationActionType, AddressOperationType } from "api/account";
import { IconChevronDown } from "atoms/IconChevronDown/IconChevronDown";
import { IconFilter } from "atoms/IconFilter/IconFilter";
import { IconLocation } from "atoms/IconLocation";
import { EmptyCustom } from "components/EmptyCustom/EmptyCustom";

import { Modal } from "molecules/Modal/Modal";

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileSelector } from "store/selectors";
import { getBankAddress } from "store/slices/profile/asyncThunks";
import { FilterModal } from "./FIlterModal";
import {
  matchActions,
  matchAllTimeAndIncoming,
  matchSearch,
  matchWorkingTime,
} from "./filtersFns";
import { SearchInput } from "components/SearchInput/SearchInput";
import { ContactsBottomSheet } from "./FiltersBottomSheet";
import { FilterState, initFiltersState } from "store/slices/profile/slice";
import { profileActions } from "store/slices/profile";

const FILTERS_KEY = "filtersState";

const BankAddresses = () => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width:980px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const { isLoadingBankAddress, bankAddress, filters } =
    useSelector(profileSelector);

  const [filtersModal, setFiltersModal] = useState(false);

  useEffect(() => {
    dispatch(getBankAddress({}));
  }, [dispatch]);

  const filteredAddresses = useMemo(() => {
    if (!bankAddress) return [];
    if (
      initFiltersState.cashIn == filters.cashIn &&
      initFiltersState.freeFee == filters.freeFee &&
      initFiltersState.allTime == filters.allTime &&
      initFiltersState.RUB == filters.RUB &&
      initFiltersState.TJS == filters.TJS &&
      initFiltersState.KGS == filters.KGS &&
      initFiltersState.KZT == filters.KZT &&
      initFiltersState.AMD == filters.AMD &&
      initFiltersState.search == filters.search
    )
      return bankAddress;

    localStorage.setItem(FILTERS_KEY, JSON.stringify(filters));

    let resultArr = bankAddress.filter((value) => {
      if (!value.operations) return false;

      const availableActions = value.operations
        .flatMap((op) => op.availableActions)
        .filter(
          (action): action is AddressOperationActionType => action !== undefined
        );

      const workingHours = value.operations
        .flatMap((op) => op.workingHour)
        .filter((hour): hour is string => hour !== undefined);

      const address = value.address ?? "";
      const country = value.country ?? "";

      return (
        matchActions(filters, availableActions) &&
        matchWorkingTime(filters, workingHours) &&
        matchSearch(filters, address, country)
      );
    });
    if (filters.allTime && filters.cashIn) {
      resultArr = matchAllTimeAndIncoming(filters, resultArr);
    }

    return resultArr;
  }, [bankAddress, filters]);

  const isSearchResult = bankAddress !== filteredAddresses;

  const handleSearch = (evt) => {
    dispatch(profileActions.setFilters({ search: evt.target.value }));
  };
  const handleFilters = (filters: FilterState) => {
    dispatch(profileActions.setFilters(filters));
  };

  const isFilterActive = Object.values(filters)
    .filter((value) => typeof value === "boolean")
    .some((value) => Boolean(value));

  return (
    <>
      <Typography
        fontSize={24}
        fontWeight={600}
        mb={14}
        sx={{
          color: "var(--main-color-text-title)",
          fontSize: isMobile ? "18px" : "24px",
          fontWeight: isMobile ? 500 : 600,
        }}
      >
        Полевые учреждения
      </Typography>
      <Box
        sx={{ mb: 14, display: "flex", alignItems: "center", columnGap: "8px" }}
      >
        <Box
          onClick={() => setFiltersModal(true)}
          sx={{ cursor: "pointer", display: "flex" }}
        >
          <IconFilter active={isFilterActive} />
        </Box>
        <SearchInput
          value={filters.search}
          onChange={(e) => handleSearch(e)}
          placeholder="Город или адрес"
        />
      </Box>

      {!isLoadingBankAddress && (
        <>
          {isDesktop && isSearchResult && filteredAddresses.length > 0 && (
            <Typography
              sx={{
                color: "#91AF8C", // TODO add to colors
                lineHeight: "20px",
              }}
              py={8}
            >
              Найдено: {filteredAddresses.length}
            </Typography>
          )}
          <Box display="flex" flexDirection="column" gap={16}>
            {filteredAddresses.length > 0 ? (
              filteredAddresses?.map((accordion, index) => (
                <Accordion
                  key={index}
                  elevation={0}
                  sx={{
                    py: 8,
                    width: "100%",
                    "&::before": {
                      display: "none",
                    },
                  }}
                  disableGutters
                >
                  <AccordionSummary
                    sx={{
                      ".MuiAccordionSummary-content": {
                        m: 0,
                        display: "flex",
                        alignItems: "center",
                        columnGap: "12px",
                      },
                    }}
                    expandIcon={<IconChevronDown />}
                  >
                    <Box
                      sx={{
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        flexShrink: 0,
                        borderRadius: "100px",
                        backgroundColor: "var(--main-color-bg-widgets)",
                      }}
                    >
                      <IconLocation />
                    </Box>

                    <Box>
                      <Typography
                        color="var(--main-color-text-title)"
                        mb={4}
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        {accordion.address}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: 400,
                          lineHeight: "14px",
                          color: "var(--main-color-text-secondary)",
                        }}
                      >
                        {accordion.country}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ ml: { md: 70 }, mb: 8, maxWidth: 250 }}
                  >
                    {accordion.operations?.map((detail, idx) => (
                      <Box
                        key={idx}
                        display="flex"
                        flexDirection="column"
                        gap={4}
                        mt={12}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#739B67", //  TODO add to style colors
                          }}
                        >
                          {detail.operationType === AddressOperationType.Atm
                            ? "Банкомат"
                            : "Пункт выдачи наличных"}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "14px",
                            color: "var(--main-color-text-title)",
                          }}
                        >
                          {detail.workingHour === "FULLDAY"
                            ? "Круглосуточно"
                            : detail.workingHour}
                        </Typography>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <EmptyCustom
                title="Ничего не найдено"
                subtitle="По вашему запросу ничего не нашлось, проверьте, правильно ли введен запрос"
              />
            )}
          </Box>
        </>
      )}

      {!isMobile && (
        <Modal
          title="Фильтр"
          isOpen={filtersModal}
          buttonVisible={!isDesktop}
          callback={() => setFiltersModal(false)}
          onClose={() => setFiltersModal(false)}
          isProfile
        >
          <FilterModal
            filtersState={filters}
            setFiltersState={handleFilters}
            onClose={() => setFiltersModal(false)}
            isMobile={isMobile}
          />
        </Modal>
      )}
      {isMobile && (
        <ContactsBottomSheet
          isOpen={filtersModal}
          onClose={() => setFiltersModal(false)}
        >
          <FilterModal
            filtersState={filters}
            setFiltersState={handleFilters}
            onClose={() => setFiltersModal(false)}
            isMobile={isMobile}
          />
        </ContactsBottomSheet>
      )}
    </>
  );
};

export default BankAddresses;
