import React from "react";
import { Typography, Box } from "@mui/material";
import { InputText } from "components";

type Props = {
  cardName?: string;
  cardPan?: string;
  setCardName?: (value: any) => void;
};

export const ChangeNameCard = ({ cardName, setCardName, cardPan }: Props) => {
  const inputChange = (e: any) => {
    if (e.target.value.length > 16) return;
    if (setCardName) {
      setCardName(e.target.value);
    }
  };
  return (
    <Box
      width="343px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={32}
    >
      <Typography
        variant="text_7"
        color="gray.b800"
        mb={32}
        textAlign="center"
        fontWeight={500}
      >
        {"Для карты " +
          cardPan?.substring(cardPan?.length - 8) +
          " придумайте новое имя"}
      </Typography>
      <InputText
        value={cardName}
        onInput={inputChange}
        label="Введите новое имя карты"
        placeholder="Имя карты"
      />
    </Box>
  );
};
