import React from "react";

export const IconLoginChangeSuccess = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#F5F5F5"
      />
      <path
        d="M134 132C134 136.418 130.418 140 126 140H24C19.5817 140 16 136.418 16 132V62.0023C16 57.5861 19.5791 54.0052 23.9953 54.0033C62.0149 53.9871 97.6478 54.0371 125.996 54.016C130.418 54.0127 134 57.596 134 62.0185V132Z"
        fill="white"
      />
      <path
        d="M81.8125 106H67.1875C66.2555 106 65.5 107.343 65.5 109C65.5 110.657 66.2555 112 67.1875 112H81.8125C82.7445 112 83.5 110.657 83.5 109C83.5 107.343 82.7445 106 81.8125 106Z"
        fill="#B5CDA3"
      />
      <path
        d="M96.5 116H52.5C50.8431 116 49.5 117.343 49.5 119C49.5 120.657 50.8431 122 52.5 122H96.5C98.1569 122 99.5 120.657 99.5 119C99.5 117.343 98.1569 116 96.5 116Z"
        fill="#B5CDA3"
      />
      <path
        d="M75.5 86C88.7548 86 99.5 75.2548 99.5 62C99.5 48.7452 88.7548 38 75.5 38C62.2452 38 51.5 48.7452 51.5 62C51.5 75.2548 62.2452 86 75.5 86Z"
        fill="#739B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.3411 54.1752C86.7967 53.7196 87.5354 53.7196 87.991 54.1752C88.4115 54.5958 88.4439 55.2575 88.088 55.7152L87.991 55.8251L73.1817 70.6344C72.7612 71.0549 72.0994 71.0873 71.6417 70.7314L71.5318 70.6344L63.0077 62.1103C62.5521 61.6547 62.5521 60.916 63.0077 60.4604C63.4283 60.0398 64.0901 60.0075 64.5477 60.3633L64.6576 60.4604L72.3563 68.159L86.3411 54.1752Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
