export const StatusIconView = () => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.44225 0.234314L3.06892 4.60765L1.36565 2.90438C1.05324 2.59196 0.546704 2.59196 0.234284 2.90438C-0.0781351 3.2168 -0.0781356 3.72333 0.234284 4.03575L3.06271 6.86418L4.04193 5.88496L4.04814 5.89117L8.57362 1.36569C8.88604 1.05327 8.88604 0.546734 8.57362 0.234315C8.2612 -0.0781048 7.75467 -0.0781049 7.44225 0.234314ZM7.90516 7.08152L7.85791 7.12877L6.06791 5.33877L7.19928 4.2074L8.00089 5.00901L11.4423 1.56765C11.7547 1.25523 12.2612 1.25523 12.5736 1.56765C12.886 1.88007 12.886 2.3866 12.5736 2.69902L8.04814 7.2245L7.90516 7.08152Z"
      fill="#739B67"
    />
  </svg>
);
