import { Grid } from "@mui/material";
import { MainContainer } from "layouts";
import { RefillInfoCard } from "pages/home/Cards/RefillInfoCard";
import { Title, TitleBlock, LoaderWrapper } from "./components";
import { useEffect, useState } from "react";
import { getRefillInfo } from "store/slices/transferSlice";
import { useDispatch } from "react-redux";
import { OrderInfo } from "api/account";
import { useIsMobile } from "hooks/useIsMobile";
import { Loader } from "components";
import { useNavigate } from "react-router-dom";

export const RefillInfoPage = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refill, setRefill] = useState<OrderInfo | null>(null);
  const [refillError, setRefillError] = useState<boolean>(false);
  const [refillLoading, setRefillLoading] = useState<boolean>(true);

  useEffect(() => {
    const refillId = location.pathname.split("/").at(-1);
    if (refillId) {
      dispatch(getRefillInfo(refillId))
        //@ts-ignore
        .unwrap()
        .then((res) => setRefill(res.orders[0]))
        .catch(() => setRefillError(true))
        .finally(() => setRefillLoading(false));
    }
  }, [dispatch]);

  if (refillError) {
    navigate("/404", { replace: true });
  }

  if (refillLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <MainContainer>
      <Grid item xs={12} xl={12} position="relative">
        {!isMobile && (
          <TitleBlock>
            <Title>Пополнение карты</Title>
          </TitleBlock>
        )}
        <RefillInfoCard refill={refill} />
      </Grid>
    </MainContainer>
  );
};
