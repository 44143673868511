import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { IconMenu } from "atoms/IconMenu/IconMenu";
import useClickOutside from "hooks/useClickOutside";
import React, { useRef, useState } from "react";

const CardMenu = ({ handleMenuClick }: { handleMenuClick: () => void }) => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useClickOutside(modalRef, () => {
    if (open) setOpen(false);
  });

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        ref={modalRef}
        onClick={() => setOpen(!open)}
        sx={{ cursor: "pointer" }}
      >
        <IconMenu />
      </Box>

      <Box
        sx={{
          display: open ? "block" : "none",
          padding: "10px 16px",
          position: "absolute",
          top: "27px",
          right: 0,
          width: "202px",
          boxSizing: "border-box",

          borderRadius: "9px",
          backgroundColor: "#FFF",
          boxShadow: "0px 4px 11.3px 0px rgba(133, 131, 131, 0.24)",
        }}
      >
        <Typography
          sx={{
            color: "#454A3F",
            fontSize: "13px",
            fontWeight: 400,
            lineHeight: "14px",
            cursor: "pointer",
          }}
          onClick={handleMenuClick}
        >
          Изменить название карты
        </Typography>
      </Box>
    </Box>
  );
};

export default CardMenu;
