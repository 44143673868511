export type RegisterScreens =
  | "initial"
  | "confirmation"
  | "cvv"
  | "createLoginAndPass"
  | "success"
  | "frozen"
  | "blocked";

export type RecoveryPasswordScreens =
  | "initial"
  | "confirmation"
  | "newPassword"
  | "cvv"
  | "frozen"
  | "success";

export enum UserStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  UNBLOCKED = "UNBLOCKED",
}
