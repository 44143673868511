import { StatementListResponse } from "api/account";

export const createLimitedList = (
  List: StatementListResponse[]
): StatementListResponse[] => {
  const newList: StatementListResponse[] = [];

  let count = 0;
  for (let i = 0; i < List.length && count < 5; i++) {
    const item = List[i];
    const newItem: StatementListResponse = {
      ...item,
      statements: [],
    };

    if (item.statements && item.statements.length > 0) {
      const statementsToTake = Math.min(5 - count, item.statements.length);
      newItem.statements = item.statements.slice(0, statementsToTake);
      count += statementsToTake;
    }

    newList.push(newItem);
  }

  return newList;
};
