import React from "react";

export const IconDots = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = 24, height = 44, color = "#739B67" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="44" rx="8" fill="#F5F5F5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 14C14 15.1046 13.1046 16 12 16C10.8954 16 10 15.1046 10 14C10 12.8954 10.8954 12 12 12C13.1046 12 14 12.8954 14 14ZM14 22C14 23.1046 13.1046 24 12 24C10.8954 24 10 23.1046 10 22C10 20.8954 10.8954 20 12 20C13.1046 20 14 20.8954 14 22ZM12 32C13.1046 32 14 31.1046 14 30C14 28.8954 13.1046 28 12 28C10.8954 28 10 28.8954 10 30C10 31.1046 10.8954 32 12 32Z"
        fill={color}
      />
    </svg>
  );
};
