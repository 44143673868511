import styled from "@emotion/styled/macro";
import { ReactComponent as BackIcon } from "./assets/backIcon.svg";
import { useDispatch } from "react-redux";
import {
  MobileTabVariants,
  systemActions,
  TabVariants,
} from "store/slices/system";
import { useIsMobile } from "hooks/useIsMobile";

const Container = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  background: var(--main-color-bg-widgets);
  border-radius: 8px;
  padding: 4px 8px;
`;

const Text = styled.p`
  margin: 0;
  margin-left: 11px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--main-color-text-secondary);
  cursor: pointer;
`;

const SmallBackButton = ({
  clickHandler,
  title,
}: {
  clickHandler?: () => void;
  title?: string;
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const handleBack = () => {
    if (clickHandler) {
      clickHandler();
    } else {
      dispatch(
        systemActions.setActiveTab({
          tab: isMobile ? MobileTabVariants.main : TabVariants.main,
        })
      );
    }
  };

  return (
    <Container onClick={handleBack}>
      <BackIcon />
      <Text>{title || "Назад"}</Text>
    </Container>
  );
};

export default SmallBackButton;
