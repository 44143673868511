export const formatPhoneNumber = (phoneNumberString: string): string => {
  return phoneNumberString.replace(
    /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g,
    `+$1 ($2) $3 $4 $5`
  );
};

export const formatPhoneNumberHide = (phoneNumberString: string): string => {
  return phoneNumberString.replace(
    /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g,
    `+$1 (•••) •••-$4-$5`
  );
};
