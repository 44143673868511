import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  payload: {
    id?: number;
    title?: string;
    titleConfirm?: string;
    actionType?: string;
    srcJpg?: string;
    subtitle?: string;
    subtitleConfirm?: string;
    textBtn?: string;
  };
  isPin?: boolean;
  cardPan?: string;
  phone?: string | null;
};

const AfterConfirmInfo = ({ payload, isPin, cardPan, phone }: Props) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" mt={32}>
        <img
          src={payload?.srcJpg}
          alt="картинка"
          style={{
            width: "150px",
            height: "150px",
          }}
        />
      </Box>
      <Typography
        variant="text_8"
        color="gray.b800"
        fontWeight={600}
        textAlign="center"
        mb={8}
        mt={24}
      >
        {payload?.titleConfirm}
        {isPin &&
          `Пин-код для карты ${cardPan?.substring(
            cardPan?.length - 7
          )} был успешно отправлен на номер ******${phone}`}
      </Typography>
      <Typography
        variant="text_5"
        color="gray.b800"
        fontWeight={400}
        textAlign="center"
      >
        {payload?.subtitleConfirm}
      </Typography>
      {isPin && (
        <Typography
          variant="text_5"
          color="blue.b400"
          fontWeight={400}
          textAlign="center"
        >
          + 7 (800) 200-45-67
        </Typography>
      )}
    </Box>
  );
};

export default AfterConfirmInfo;
