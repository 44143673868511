import { useEffect, useRef } from "react";

type EventHandler<T extends Event> = (event: T) => void;

export default function useEventListener<T extends Event>(
  eventType: string,
  callback: EventHandler<T>,
  element: EventTarget | null = window
) {
  const callbackRef = useRef<EventHandler<T>>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element === null) return;

    const handler: EventListener = (event: Event) => {
      callbackRef.current(event as T);
    };

    element.addEventListener(eventType, handler);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
}
