import React from "react";

export const IconNewsItem = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill="#F5F5F5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 15.8387C8.5 11.7857 11.7857 8.5 15.8387 8.5C19.8918 8.5 23.1775 11.7857 23.1775 15.8387C23.1775 19.8918 19.8918 23.1775 15.8387 23.1775C11.7857 23.1775 8.5 19.8918 8.5 15.8387ZM15.8425 18.64C15.5319 18.64 15.28 18.3882 15.28 18.0775V15.7525C15.28 15.4419 15.5319 15.19 15.8425 15.19C16.1532 15.19 16.405 15.4419 16.405 15.7525V18.0925C16.3931 18.3956 16.1458 18.6363 15.8425 18.64ZM15.28 13.9375C15.28 14.2481 15.5319 14.5 15.8425 14.5C16.1458 14.4962 16.3931 14.2556 16.405 13.9525V13.675C16.405 13.3643 16.1532 13.1125 15.8425 13.1125C15.5319 13.1125 15.28 13.3643 15.28 13.675V13.9375Z"
        fill="#739B67"
      />
    </svg>
  );
};
