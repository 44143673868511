import React from "react";
import { Box, Typography } from "@mui/material";
import IconWrapper from "components/IconWrapper/IconWrapper";
import { formatAmount } from "utils/formatCurrency";
import { IconCardDropDown } from "atoms/IconCardDropDown";
import { getCardIcon } from "utils/card";
import { CARD_STATUS } from "components/CardsInfo/model";
import { CardDto } from "api/account";
import { useIsMobile } from "hooks/useIsMobile";

interface CardDetailsProps {
  card: CardDto | undefined;
  hasMultipleCards: boolean;
  onClick?: () => void;
}

export const CardDetails: React.FC<CardDetailsProps> = ({
  card,
  hasMultipleCards,
  onClick,
}) => {
  const isBlockedCard = CARD_STATUS.Blocked.includes(card?.status as string);
  const { isMobile } = useIsMobile();

  const handleClick = () => {
    if (onClick && card) {
      onClick();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "12px 16px",
        borderRadius: "15px",
        boxSizing: "border-box",
        backgroundColor: "var(--main-color-bg-widgets)",

        "@media (max-width: 500px)": {
          padding: "12px",
        },
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: "flex", columnGap: !isMobile ? "12px" : "8px" }}>
        <IconWrapper
          bgColor={isBlockedCard ? "var(--error-color-notification)" : ""}
        >
          {getCardIcon(card)}
        </IconWrapper>

        <Box sx={{ display: "flex", alignItems: "center", columnGap: "4px" }}>
          {card && (
            <>
              {!isMobile && (
                <Typography
                  noWrap
                  sx={{
                    color: "var(--main-color-text-title)",
                    fontWeight: 500,
                    fontSize: "18px",
                  }}
                >
                  {card.paymentNetwork
                    ? card.paymentNetwork === "MIR"
                      ? "МИР"
                      : card.paymentNetwork
                    : "МИР"}
                </Typography>
              )}
            </>
          )}

          <Typography
            noWrap
            sx={{
              color: !isMobile ? "var(--main-color-text-secondary)" : "#454A3F",
              fontWeight: 500,
              fontSize: !isMobile ? "18px" : "16px",
            }}
          >
            {!isMobile ? "•" : "••"}{" "}
            {card ? card?.cardPan?.slice(-4) : "Нет доступных карт"}
          </Typography>
        </Box>
      </Box>

      {card && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: !isMobile ? "16px" : "8px",
          }}
        >
          <Typography
            noWrap
            sx={{
              color: "var(--main-color-text-title)",
              fontWeight: 500,
              fontSize: !isMobile ? "18px" : "16px",
            }}
          >
            {formatAmount(card?.amount, card?.currency?.sign)}
          </Typography>

          {hasMultipleCards && <IconCardDropDown />}
        </Box>
      )}
    </Box>
  );
};
