import React from "react";

interface IconIconStarProps extends React.SVGProps<SVGSVGElement> {}

export const IconStarFilled = (props: IconIconStarProps) => {
  const { width = "49", height = "48", color = "#739B67" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="star/outline">
        <path
          id="Vector"
          d="M41.7747 22.0664C42.8035 21.2342 42.3291 19.5745 41.016 19.4116L30.5357 18.1118C29.9818 18.0431 29.5118 17.6725 29.3158 17.1498L26.5391 9.74532C26.0524 8.44756 24.2168 8.44756 23.7301 9.74532L20.9534 17.1498C20.7574 17.6725 20.2875 18.0431 19.7336 18.1118L9.25327 19.4116C7.9401 19.5745 7.46573 21.2342 8.49448 22.0664L15.3106 27.5806C15.746 27.9328 15.9489 28.4982 15.8369 29.0468L13.9633 38.2239C13.6897 39.5639 15.2056 40.5411 16.3131 39.7385L24.2544 33.984C24.7795 33.6035 25.4897 33.6035 26.0148 33.984L33.9561 39.7385C35.0636 40.5411 36.5796 39.564 36.306 38.2239L34.4323 29.0468C34.3203 28.4982 34.5232 27.9328 34.9586 27.5806L41.7747 22.0664Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const IconStarHollow = (props: IconIconStarProps) => {
  const { width = "48", height = "48", color = "#D3D3D3" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M41.0247 22.0664C42.0535 21.2342 41.5791 19.5745 40.266 19.4116L29.7857 18.1118C29.2318 18.0431 28.7618 17.6725 28.5658 17.1498L25.7891 9.74532C25.3024 8.44756 23.4668 8.44756 22.9801 9.74532L20.2034 17.1498C20.0074 17.6725 19.5375 18.0431 18.9836 18.1118L8.50327 19.4116C7.1901 19.5745 6.71573 21.2342 7.74448 22.0664L14.5606 27.5806C14.996 27.9328 15.1989 28.4982 15.0869 29.0468L13.2133 38.2239C12.9397 39.5639 14.4556 40.5411 15.5631 39.7385L23.5044 33.984C24.0295 33.6035 24.7397 33.6035 25.2648 33.984L33.2061 39.7385C34.3136 40.5411 35.8296 39.564 35.556 38.2239L33.6823 29.0468C33.5703 28.4982 33.7732 27.9328 34.2086 27.5806L41.0247 22.0664Z"
        fill={color}
      />
    </svg>
  );
};
