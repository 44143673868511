import React from "react";

export const IconCircleClose = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_120_5352)">
        <path
          d="M12 20C7.5816 20 4 16.4184 4 12C4 7.5816 7.5816 4 12 4C16.4184 4 20 7.5816 20 12C20 16.4184 16.4184 20 12 20ZM12 10.8688L10.3035 9.1717C9.99098 8.85907 9.48418 8.85902 9.1716 9.1716C8.85902 9.48418 8.85907 9.99098 9.1717 10.3035L10.8688 12L9.1717 13.6965C8.85907 14.009 8.85902 14.5158 9.1716 14.8284C9.48418 15.141 9.99098 15.1409 10.3035 14.8283L12 13.1312L13.6965 14.8283C14.009 15.1409 14.5158 15.141 14.8284 14.8284C15.141 14.5158 15.1409 14.009 14.8283 13.6965L13.1312 12L14.8283 10.3035C15.1409 9.99098 15.141 9.48418 14.8284 9.1716C14.5158 8.85902 14.009 8.85907 13.6965 9.1717L12 10.8688Z"
          fill={props.color ?? "#739B67"}
        />
      </g>
      <defs>
        <clipPath id="clip0_120_5352">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
