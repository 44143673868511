import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import BackSideCard from "components/CardFullSize/BackSideCard";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import {
  CARD_ACTION,
  setIsCardInfoOpen,
  setScreen,
} from "../../../../store/slices/cardsSlice";

import { IconLimit } from "atoms/IconLimit/IconLimit";
import { IconLock } from "atoms/IconLock/IconLock";
import { IconSnow } from "atoms/IconSnow/IconSnow";
import { IconTransfer } from "atoms/IconTransfer/IconTransfer";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import { TabVariants, systemActions } from "store/slices/system";
import CardAccount from "./CardAccount";
import { CardButtons } from "./CardButtons";
import { CardsSlider } from "./CardsSlider";
import { IconFlame } from "atoms/IconFlame/IconFlame";
import { IconBtnPlus } from "atoms/IconBtnPlus/IconBtnPlus";

import { refillAction } from "utils/refillAction";
import { SHOW_REFILL } from "utils/constants";
import { useIsMobile } from "hooks/useIsMobile";

export const CardInfo = ({ handleMenuClick }) => {
  const [isIndex, setIsIndex] = useState(0);
  const { isMobile } = useIsMobile();

  const {
    cards: { allCards, chosenCardIndex },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const setTab = (tab: TabVariants) => {
    switch (tab) {
      case TabVariants.history:
        dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
        dispatch(systemActions.setActiveTab({ tab }));
        break;
      case TabVariants.main:
        dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
        dispatch(systemActions.setActiveTab({ tab }));
        break;
      default:
        dispatch(systemActions.setActiveTab({ tab }));
    }
  };

  const handleClick = async (id: CARD_ACTION) => {
    if (id === CARD_ACTION.TRANSFER) {
      setTab(TabVariants.transfersHub);
      dispatch(setIsCardInfoOpen(0));
    } else if (id === CARD_ACTION.REFILL) {
      try {
        const result = await refillAction(dispatch);
        if (result.success) {
          dispatch(setScreen(CARD_ACTION.REFILL));
        }
      } catch (error) {
        dispatch(setScreen(CARD_ACTION.REFILL_ERROR));
      }
    } else {
      dispatch(setScreen(id));
    }
  };

  const dataIcon = useMemo(
    () =>
      [
        {
          id: CARD_ACTION.REFERRAL,
          title: "Заморозить",
          title2: "Разморозить",
          svg: () => <IconSnow color="#739B67" />,
          svg2: <IconFlame />,
        },
        {
          id: CARD_ACTION.LOST,
          title: "Заблокировать",
          svg: (color: string) => (
            <IconLock color="var(--error-color-illustration)" />
          ),
        },
        {
          id: CARD_ACTION.LIMIT,
          title: "Установить лимит",
          svg: (color: string) => <IconLimit color={color} />,
        },
        {
          id: CARD_ACTION.TRANSFER,
          title: "Перевести",
          svg: (color: string) => <IconTransfer color={color} />,
        },

        // SHOW_REFILL && {
        //   id: CARD_ACTION.REFILL,
        //   title: "Пополнить",
        //   svg: (color: string) => <IconBtnPlus />,
        // },
      ].filter(Boolean),
    []
  );

  return (
    <Box position="relative">
      <CardsSlider
        chosenCardIndex={chosenCardIndex}
        setIsIndex={setIsIndex}
        allCards={allCards}
        handleMenuClick={handleMenuClick}
      />
      <Box px={isMobile ? 16 : 32}>
        <CardButtons
          card={allCards[isIndex || chosenCardIndex]}
          dataIcon={dataIcon}
          handleClick={handleClick}
        />

        {/* @ts-ignore  */}
        <BackSideCard card={allCards[isIndex || chosenCardIndex]} />
        <CardAccount />
      </Box>
    </Box>
  );
};
