import styled from "@emotion/styled/macro";

const checkboxStyle = `
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
`;

export const ItemCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 4px 16px 4px 0;
  border-radius: 12px;
`;

export const ItemCardMenu = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  border: none;
  border-radius: 13px;
  height: 23px;
  width: 11px;
`;

export const ItemCardActionsBlock = styled.div`
  position: absolute;
  z-index: 100;
  top: 50px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px -2px #67798152;
  background: var(--main-color-bg-widgets);
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
`;

export const ItemCardRemoveIcon = styled.img`
  width: 18px;
  height: 20px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionTitle = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 5px;
`;

export const ItemCardDot = styled.div`
  width: 3px;
  height: 3px;
  background: var(--main-color-text-title);
  border-radius: 50%;
`;

export const ItemCardIconWrapper = styled.div`
  width: 78px;
  height: 48px;
  border-radius: 9px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props: { background: string }) => props.background};
`;

export const ItemCardTitle = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--main-color-text-title);
  margin-left: 10px;
`;

export const ItemCardNumber = styled.span`
  marign: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #777e8a;
`;

export const Checkbox = styled.div`
  ${checkboxStyle}
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--brand-color-primary);
  border-radius: 50%;
`;

export const EmptyCheckbox = styled.div`
  ${checkboxStyle}
  background: var(--main-color-bg-secondary);
  border-radius: 50%;
`;

export const CheckboxIcon = styled.div`
  ${checkboxStyle}
`;

export const SmallTitle = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
  padding: 10px 0;
`;

export const ItemCardIcon = styled.img`
  width: 30px;
  height: 22.5px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

export const AllCardsWrapper = styled.div`
  position: relative;
`;
