import React from "react";

interface IconCardTranferCheckProps extends React.SVGProps<SVGSVGElement> {}

export const IconCardTranferCheck = (props: IconCardTranferCheckProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 3.75H13.5C15.1569 3.75 16.5 5.09315 16.5 6.75V11.25C16.5 12.9069 15.1569 14.25 13.5 14.25H4.5C2.84315 14.25 1.5 12.9069 1.5 11.25V6.75C1.5 5.09315 2.84315 3.75 4.5 3.75ZM3.945 11.0625H14.055C14.3657 11.0625 14.6175 10.8107 14.6175 10.5C14.6175 10.1893 14.3657 9.9375 14.055 9.9375H3.945C3.63434 9.9375 3.3825 10.1893 3.3825 10.5C3.3825 10.8107 3.63434 11.0625 3.945 11.0625Z"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
