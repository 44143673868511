import { FC, useEffect, useRef, useState } from "react";
import { InputNumber } from "components";
import { SearchFiltersValues } from "pages/home/Cards/History/History";
import { formatNumber, stringToNumber } from "utils/formatCurrency";
import { AnchorStyled, DropdownAnchor } from "../..";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Button } from "components/Button";
import { Box, Typography } from "@mui/material";

const ERROR_MESSAGE_FROMAMOUNT =
  "Начальная сумма не может быть больше конечной";
const ERROR_MESSAGE_TOAMOUNT = "Конечная сумма не может быть меньше начальной";

type ValueAmountType = {
  fromAmount: number | null;
  toAmount: number | null;
};

interface OperationAmountProps {
  value: ValueAmountType;
  isMobile: boolean;
  onChange: (values: Partial<SearchFiltersValues>) => void;
  onSearch: () => void;
  onClear: () => void;
}

export const OperationAmount: FC<OperationAmountProps> = ({
  value,
  isMobile,
  onChange,
  onSearch,
  onClear,
}) => {
  const amountRef = useRef<HTMLDivElement | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isDropped, setDropped] = useState<boolean>(false);
  const [appliedValues, setAppliedValues] = useState<ValueAmountType>(value);
  const [lastModified, setLastModified] = useState<"from" | "to" | null>(null);
  const {
    cards: {
      account: { currency },
    },
  } = useSelector((state: RootState) => state);

  const handleSearch = () => {
    setDropped(false);
    setAppliedValues(value);
    onSearch();
  };

  const handleDrop = () => setDropped(!isDropped);
  const handleBlur = () => setDropped(false);

  useEffect(() => {
    if (value.fromAmount === null && value.toAmount === null) {
      setAppliedValues(value);
    }
  }, [value]);

  const errorMessageFrom =
    lastModified === "from" &&
    value.fromAmount &&
    value.toAmount &&
    value.fromAmount > value.toAmount
      ? ERROR_MESSAGE_FROMAMOUNT
      : "";

  const errorMessageTo =
    lastModified === "to" &&
    value.fromAmount &&
    value.toAmount &&
    value.toAmount < value.fromAmount
      ? ERROR_MESSAGE_TOAMOUNT
      : "";

  const isAmountFromError = errorMessageFrom;
  const isAmountToError = errorMessageTo;

  const content = (
    <>
      <Typography
        color="var(--main-color-text-title)"
        fontWeight={400}
        fontSize={14}
        lineHeight="20px"
      >
        Введите сумму
      </Typography>
      <Box display="flex" flexDirection="row" gap={16} mt={-6}>
        <Box width="calc(50% - 8px)">
          <InputNumber
            label="От"
            placeholder={`Сумма,${currency?.sign}`}
            suffix={` ${currency?.sign}`}
            value={value.fromAmount}
            error={!!isAmountFromError}
            hint={isAmountFromError}
            variant="small"
            onChange={(event) => {
              setIsDisabled(false);
              setLastModified("from");
              onChange({
                fromAmount: stringToNumber(event.target.value),
              });
            }}
          />
        </Box>
        <Box width="calc(50% - 8px)">
          <InputNumber
            label="До"
            placeholder={`Сумма,${currency?.sign}`}
            suffix={` ${currency?.sign}`}
            value={value.toAmount}
            error={!!isAmountToError}
            hint={isAmountToError}
            variant="small"
            onChange={(event) => {
              setIsDisabled(false);
              setLastModified("to");
              onChange({
                toAmount: stringToNumber(event.target.value),
              });
            }}
          />
        </Box>
      </Box>
      <Button
        variant="primary"
        style={{ height: "44px" }}
        onClick={handleSearch}
        disabled={!!isAmountFromError || !!isAmountToError || isDisabled}
        title="Применить"
      />
    </>
  );
  const amountAnchor = appliedValues?.fromAmount
    ? appliedValues?.toAmount
      ? `от ${formatNumber(
          appliedValues?.fromAmount,
          true,
          currency?.sign
        )} до ${formatNumber(appliedValues?.toAmount, true, currency?.sign)}`
      : `от ${formatNumber(appliedValues?.fromAmount, true, currency?.sign)}`
    : appliedValues?.toAmount
    ? `до ${formatNumber(appliedValues?.toAmount, true, currency?.sign)}`
    : "";

  const trigger = amountAnchor ? (
    <AnchorStyled>{amountAnchor}</AnchorStyled>
  ) : (
    "Сумма"
  );

  return (
    <DropdownAnchor
      trigger={trigger}
      withArrow={!amountAnchor}
      isDropped={isDropped}
      onDrop={handleDrop}
      onBlur={handleBlur}
      refElement={isMobile ? null : amountRef}
      clear={!!amountAnchor}
      onClear={onClear}
    >
      {content}
    </DropdownAnchor>
  );
};
