import styled from "@emotion/styled/macro";

interface DropdownArrowProps {
  open: boolean;
}

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  margin-top: 54px;
  background: var(--main-color-white);
  border-radius: 15px;
  z-index: 10;
  box-shadow: 18px 19px 64.8px 0px var(--main-color-box-shadow-5);
  padding: 8px 0;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 45px;
  }
`;

export const DropdownTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px 8px 16px;
  background: var(--main-color-bg-widgets);
  border-radius: 12px;
  color: var(--main-color-text-title);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
`;

export const DropdownTitleAnchored = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px 8px 16px;
  background: var(--brand-color-disabled);
  border-radius: 12px;
  color: var(--brand-color-primary);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
`;

export const DropdownItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: var(--brand-color-disabled);
  }
`;

export const Container = styled.div`
  display: flex;
`;
