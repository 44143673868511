import { useDispatch, useSelector } from "react-redux";
import { notificationSelector } from "store/selectors";
import { systemActions } from "store/slices/system";

import styles from "./styles.module.css";

import { IconBell } from "atoms/IconBell/IconBell";
import { useAppThunkDispatch } from "store";
import {
  getNewsList,
  getNotificationList,
  notificationActions,
} from "store/slices/notifications";
import { useEffect } from "react";

export const NotificationAndNews = () => {
  const dispatch = useDispatch();
  const { newNotifications, newNews } = useSelector(notificationSelector);
  const thunkDispatch = useAppThunkDispatch();

  const getNotifications = () => {
    thunkDispatch(getNotificationList({ page: 0, size: 1 }));
  };

  const getNews = () => {
    thunkDispatch(getNewsList({ page: 0, size: 1 }));
  };

  useEffect(() => {
    getNotifications();
    getNews();

    return () => {
      dispatch(notificationActions.clearNotifications());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const hasAnyEntries = newNotifications || newNews;

  const handleNotification = () => {
    dispatch(systemActions.setNewAndNotificationsOpen(true));
  };

  return (
    <div className={styles.notification} onClick={handleNotification}>
      <IconBell />
      {hasAnyEntries && <span />}
    </div>
  );
};
