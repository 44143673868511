import styled from "@emotion/styled/macro";

export const Container = styled.div`
  padding: 12px 16px 16px;
  position: absolute;
  left: 32px;
  right: 32px;
  bottom: 75px;
  z-index: 1;

  border-radius: 15px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 20.4px 0px rgba(213, 223, 201, 0.31);
  backdrop-filter: blur(19px);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media (max-width: 430px) {
    width: -webkit-fill-available;
  }
  @media (max-width: 600px) {
    left: 16px;
    right: 16px;
  }
`;

export const Title = styled.p`
  margin: 0px;
  line-height: 20px;
  color: var(--main-color-text-title);
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  width: 70%;
  margin: 0 auto;
`;

export const Description = styled.p`
  margin: 0px;
  color: #777e8a;
  text-align: center;
  color: var(--main-color-text-subtitle);

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const CloseWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
`;

export const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const Star = styled.div`
  width: 38px;
  height: 36px;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--brand-color-primary);
  color: white;
  border-radius: 12px;
  width: 100%;
  height: 44px;
  margin-left: auto;
  margin-right: auto;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

export const Icon = styled.img`
  width: 48px;
  height: 46px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  margin-top: 8px;
  margin-bottom: 8px;
`;

export const RepeatButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: #f5f5f5;
  color: var(--brand-color-primary);
  border-radius: 12px;
  width: 100%;
  height: 44px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 10px;
`;

export const IconRepeat = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
