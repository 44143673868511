import { Box } from "@mui/material";
import { CardLimitConfirmResponse } from "api/account";
import { IconSuccess } from "atoms/IconSuccess";
import { NotificationResult } from "molecules/NotificationResult";
import { rusSubtitle } from "./LimitItem";

type Props = {
  onClick: () => void;
  limitItem?: CardLimitConfirmResponse;
};

export const Success = ({ onClick, limitItem }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box width="100%" mt="32px" height="100%">
        <NotificationResult
          title="Лимит успешно изменён"
          titleSize="text_12"
          subtitleSize="text_5"
          subtitle={`${
            limitItem?.limitType && rusSubtitle(limitItem.limitType)
          } ${limitItem?.limitName?.toLocaleLowerCase()} изменён`}
          icon={<IconSuccess />}
          height="100%"
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              size: "lg",
              onClick,
            },
          ]}
        />
      </Box>
    </Box>
  );
};
