import { useTheme } from "@mui/material";

export const IconUnBlocked = () => {
  const theme = useTheme();
  const { gray, blue } = theme.palette;

  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill={gray.b50}
      />
      <rect x="33.5" y="32" width="82" height="86" rx="8" fill="white" />
      <rect x="45.5" y="44" width="28" height="28" rx="4" fill={blue.b400} />
      <path
        d="M65.5 65V63.4444C65.5 62.6193 65.1839 61.828 64.6213 61.2446C64.0587 60.6611 63.2956 60.3333 62.5 60.3333H56.5C55.7044 60.3333 54.9413 60.6611 54.3787 61.2446C53.8161 61.828 53.5 62.6193 53.5 63.4444V65M62.5 54.1111C62.5 55.8293 61.1569 57.2222 59.5 57.2222C57.8431 57.2222 56.5 55.8293 56.5 54.1111C56.5 52.3929 57.8431 51 59.5 51C61.1569 51 62.5 52.3929 62.5 54.1111Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.7 82H48.0034C46.6208 82 45.5 83.3431 45.5 85C45.5 86.6569 46.6208 88 48.0034 88H69.7C71.0826 88 72.2034 86.6569 72.2034 85C72.2034 83.3431 71.0826 82 69.7 82Z"
        fill="#EBEDF3"
      />
      <path
        d="M84.7207 92.8481H48.0034C46.6208 92.8481 45.5 94.1913 45.5 95.8481C45.5 97.505 46.6208 98.8481 48.0034 98.8481H84.7207C86.1033 98.8481 87.2241 97.505 87.2241 95.8481C87.2241 94.1913 86.1033 92.8481 84.7207 92.8481Z"
        fill="#EBEDF3"
      />
    </svg>
  );
};
