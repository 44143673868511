export const IconBlockedCardSmall = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#FFEEED"
      />
      <g filter="url(#filter0_d_137_13727)">
        <mask
          id="mask0_137_13727"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="150"
          height="150"
        >
          <path
            d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
            fill="#FFF4F4"
          />
        </mask>
        <g mask="url(#mask0_137_13727)">
          <path
            d="M133.5 105C133.5 109.418 129.918 113 125.5 113H23.5C19.0817 113 15.5 109.418 15.5 105V45.002C15.5 40.5856 19.0795 37.0046 23.4959 37.0029C61.5159 36.9886 97.1489 37.0328 125.497 37.0141C129.919 37.0112 133.5 40.5943 133.5 45.0163V105Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M74.5 99C87.7548 99 98.5 88.2548 98.5 75C98.5 61.7452 87.7548 51 74.5 51C61.2452 51 50.5 61.7452 50.5 75C50.5 88.2548 61.2452 99 74.5 99Z"
        fill="#E64545"
      />
      <path
        d="M84.0264 84.5271C83.2454 85.3081 81.9791 85.3081 81.198 84.5271L74.127 77.456L67.0559 84.5271C66.2748 85.3081 65.0085 85.3081 64.2275 84.5271C63.4464 83.746 63.4464 82.4797 64.2275 81.6987L71.2985 74.6276L64.2275 67.5565C63.4464 66.7755 63.4464 65.5092 64.2275 64.7281C65.0085 63.9471 66.2748 63.9471 67.0559 64.7281L74.127 71.7992L81.198 64.7281C81.9791 63.9471 83.2454 63.9471 84.0264 64.7281C84.8075 65.5092 84.8075 66.7755 84.0264 67.5565L76.9554 74.6276L84.0264 81.6987C84.8075 82.4797 84.8075 83.746 84.0264 84.5271Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_137_13727"
          x="9.5"
          y="28"
          width="130"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_137_13727"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_137_13727"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
