import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background: rgba(69, 74, 63, 0.5);
  backdrop-filter: blur(4px);
`;

export const Content = styled.div`
  width: 100%;
  max-width: 293px;
  padding-top: 20px;
  border-radius: 14px;
  background: var(--main-color-bg-widgets);
  backdrop-filter: blur(50px);
  overflow: hidden;
`;

export const Title = styled.p`
  margin: 0px;

  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  text-align: center;

  /* color: var(--main-color-text-title); */
  color: #232423;
`;

export const Description = styled.p`
  margin: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: 400;
  /* color: var(--main-color-text-secondary); */
  color: #232423;
`;
export const Button = styled.button`
  border: none;
  outline: none;
  border-top: 0.5px solid var(--main-color-border-icon);
  width: 100%;
  padding: 12px 0;
  background-color: transparent;

  color: #739b67; // TODO add to theme colors

  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
`;
