import React from "react";

interface IconIconWarningProps extends React.SVGProps<SVGSVGElement> {}

const IconWarning = (props: IconIconWarningProps) => {
  return (
    <svg
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#FFF4F4"
      />
      <path
        d="M69.4378 26.5C72.1321 21.8333 78.8679 21.8333 81.5622 26.5L120.533 94C123.228 98.6667 119.86 104.5 114.471 104.5H36.5289C31.1403 104.5 27.7724 98.6667 30.4667 94L69.4378 26.5Z"
        fill="white"
      />
      <rect
        x="71.937"
        y="43.8101"
        width="7.71997"
        height="40.9005"
        rx="3.85998"
        fill="#F29494"
      />
      <ellipse
        cx="75.797"
        cy="91.1013"
        rx="3.85998"
        ry="3.83442"
        fill="#F29494"
      />
    </svg>
  );
};

export default IconWarning;
