import React from "react";

const BlockedCardConfirmMobile = () => {
  return (
    <svg
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="#FFF4F4"
      />
      <path
        d="M134 142C134 146.418 130.418 150 126 150H24C19.5817 150 16 146.418 16 142V59.0026C16 54.5868 19.5786 51.006 23.9944 51.0038C62.0136 50.9851 97.6462 51.0427 125.994 51.0184C130.417 51.0146 134 54.5982 134 59.0213V142Z"
        fill="white"
      />
      <path
        d="M82.3125 106H67.6875C66.7555 106 66 107.343 66 109C66 110.657 66.7555 112 67.6875 112H82.3125C83.2445 112 84 110.657 84 109C84 107.343 83.2445 106 82.3125 106Z"
        fill="#F5F5F5"
      />
      <path
        d="M97 116H53C51.3431 116 50 117.343 50 119C50 120.657 51.3431 122 53 122H97C98.6569 122 100 120.657 100 119C100 117.343 98.6569 116 97 116Z"
        fill="#F5F5F5"
      />
      <path
        d="M75 94C88.2548 94 99 83.2548 99 70C99 56.7452 88.2548 46 75 46C61.7452 46 51 56.7452 51 70C51 83.2548 61.7452 94 75 94Z"
        fill="#E64545"
      />
      <rect
        width="29"
        height="29"
        transform="translate(60.5 56)"
        fill="#E64545"
      />
      <rect
        x="63.9167"
        y="67.875"
        width="22.1667"
        height="14.9167"
        rx="2"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M68.9583 68.083V63.2497C68.9583 61.6473 69.5948 60.1106 70.7279 58.9776C71.8609 57.8445 73.3976 57.208 75 57.208C76.6023 57.208 78.1391 57.8445 79.2721 58.9776C80.4051 60.1106 81.0416 61.6473 81.0416 63.2497V68.083"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default BlockedCardConfirmMobile;
