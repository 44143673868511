import { Modal } from "molecules/Modal/Modal";
import { HistoryTabs } from "../HistoryTabs";
import { Box } from "@mui/material";
import { FinancialAnalysisWidget } from "./FinancialAnalysisWidget";
import { SkeletonContainer } from "components";
import { InfiniteScroll } from "widgets/ChatWidget/components";
import { useRef, useState } from "react";
import { DateStyled } from "../../History";
import { parseDate } from "utils";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { HISTORY_TABS, setActiveTab, setDate } from "store/slices/historySlice";
import { HistoryModal } from "../../HistoryModal";
import { EmptyScreenDefault } from "../EmptyScreenDefault/EmptyScreenDefault";
import { EmptyScreenCustom } from "../EmptyScreenCustom/EmptyScreenCustom";
import { getEndOfDay } from "utils/getEndOfDay";

import { useIsMobile } from "hooks/useIsMobile";

import { OperationItem } from "../OperationItem";

interface FinancialAnalysisMobilePageProps {
  isOpen: boolean;
  onClose: () => void;
  loadMore: () => void;
  reset: () => void;
}

export const FinancialAnalysisMobilePage = ({
  isOpen,
  onClose,
  loadMore,
  reset,
}: FinancialAnalysisMobilePageProps) => {
  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });
  const {
    history: { activeTab, expenseList, incomeList, isLoading, date },
    system,
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closePage = () => {
    dispatch(
      setDate({
        from: firstDayMonth.toISOString(),
        to: getEndOfDay(dateNow),
      })
    );
    if (system.activeTab === "history" && isMobile) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    if (system.activeTab === "main" && isMobile) {
      dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
    }
    onClose();
  };

  const lastBlockRef = useRef<HTMLDivElement>(null);
  const rootSentinelRef = useRef<HTMLDivElement>(null);

  const dateNow = new Date();
  const firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

  const fromTextFilter = parseDate(date.from);
  const toTextFilter = parseDate(date.to);
  const fromTextDefault = parseDate(firstDayMonth.toISOString());
  const toTextDefault = parseDate(dateNow.toISOString());

  const isDefaultFilters =
    fromTextFilter === fromTextDefault && toTextFilter === toTextDefault;

  const DATA = {
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  if (activeTab === HISTORY_TABS.ALL_OPERATIONS) return null;

  return (
    // @ts-ignore
    <Modal
      isOpen={isOpen}
      onClose={closePage}
      callback={closePage}
      title="Финансовая статистика"
      isProfile
      buttonVisible={isMobile}
    >
      <Box mt={10} pl={20} pr={20}>
        <HistoryTabs marginTop reset={reset} />
        <FinancialAnalysisWidget isPreview={false} />
        <SkeletonContainer height="570px" isLoading={isLoading} width="100%">
          {!isLoading && DATA[activeTab].length ? (
            <InfiniteScroll
              lastBlockRef={lastBlockRef}
              rootSentinelRef={rootSentinelRef}
              isLoading={false}
              onLoadMore={loadMore}
              reverse
              cssVieportHeight="calc(100svh - 300px)"
            >
              {DATA[activeTab].map((item) => (
                <Box key={item.date}>
                  <DateStyled>{parseDate(item.date || "")}</DateStyled>
                  <Box display="flex" flexDirection="column" gap="16px">
                    {/*@ts-ignore*/}
                    {item.statements.map((el) => (
                      <OperationItem
                        key={el.statementId}
                        item={el}
                        onOpenModal={openModal}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </InfiniteScroll>
          ) : isDefaultFilters ? (
            <EmptyScreenDefault />
          ) : (
            <EmptyScreenCustom />
          )}
          {modalStatus.isOpen && (
            <HistoryModal
              isOpen={modalStatus.isOpen}
              onClose={closeModal}
              historyId={modalStatus.historyId}
            />
          )}
        </SkeletonContainer>
      </Box>
    </Modal>
  );
};
