import * as React from "react";

export const IconMainTab = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.4215 19.896V17.1441C9.4215 16.4416 9.99515 15.8721 10.7028 15.8721H13.2895C13.6294 15.8721 13.9553 16.0061 14.1956 16.2446C14.4358 16.4832 14.5708 16.8067 14.5708 17.1441V19.896C14.5687 20.1881 14.6841 20.4689 14.8913 20.6762C15.0986 20.8834 15.3806 21 15.6748 21H17.4396C18.2639 21.0021 19.0551 20.6786 19.6386 20.1007C20.2222 19.5229 20.5502 18.7383 20.5502 17.92V10.0802C20.5502 9.41921 20.2551 8.79226 19.7444 8.3682L13.7408 3.60828C12.6965 2.77371 11.2002 2.80065 10.187 3.67228L4.32051 8.3682C3.78566 8.77976 3.46599 9.40857 3.4502 10.0802V17.912C3.4502 19.6175 4.84284 21 6.56075 21H8.28526C8.8963 21 9.39289 20.5106 9.39732 19.904L9.4215 19.896Z"
      fill={props.color ?? "#739B67"}
    />
  </svg>
);
