import React from "react";

export const OtherIn = ({ fill = "#0082BA" }) => {
  return (
    <svg
      width={49}
      height={48}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={0.5} width={48} height={48} rx={24} fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.975 32.338c0 1.796 2.212 2.72 3.532 1.526l8.23-8.474a2.054 2.054 0 00-.01-2.904l-8.176-8.42c-1.364-1.237-3.576-.313-3.576 1.483v3.642H19.27a.95.95 0 000 1.9h6.75a.856.856 0 00.855-.855V15.55c0-.121.24-.221.356-.116l8.142 8.389c.072.072.072.17.01.233l-8.197 8.442c-.072.063-.311-.038-.311-.159v-5.334a.208.208 0 00-.209-.209h-14.05a.95.95 0 000 1.901h12.358v3.642zm-10.457-8.395c0 .525.426.95.95.95h3.803a.95.95 0 000-1.9H15.47a.95.95 0 00-.95.95z"
        fill={fill}
        stroke={fill}
        strokeWidth={0.5}
      />
    </svg>
  );
};
