export enum FileType {
  PDF = "pdf",
  EXCEL = "excel",
}

const TYPES = {
  [FileType.PDF]: FileType.PDF,
  [FileType.EXCEL]: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
};

export const downloadFile = (res: string, name: string, type: FileType) => {
  const base64URL = res;
  const binary = window.atob(base64URL.replace(/\s/g, ""));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([view], {
    type: `application/${TYPES[type]}`,
  });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.download = name;
  a.target = "_blank";
  a.click();
};
