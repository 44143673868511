const BlockedCardConfirm = () => {
  return (
    <svg
      width="150"
      height="151"
      viewBox="0 0 150 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75 150.5C116.421 150.5 150 116.921 150 75.5C150 34.0786 116.421 0.5 75 0.5C33.5786 0.5 0 34.0786 0 75.5C0 116.921 33.5786 150.5 75 150.5Z"
        fill="#FFF4F4"
      />
      <g filter="url(#filter0_d_3396_1019)">
        <mask
          id="mask0_3396_1019"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="150"
          height="151"
        >
          <path
            d="M75 150.5C116.421 150.5 150 116.921 150 75.5C150 34.0786 116.421 0.5 75 0.5C33.5786 0.5 0 34.0786 0 75.5C0 116.921 33.5786 150.5 75 150.5Z"
            fill="url(#paint0_linear_3396_1019)"
          />
        </mask>
        <g mask="url(#mask0_3396_1019)">
          <path
            d="M127 101.5C127 105.918 123.418 109.5 119 109.5H30C25.5817 109.5 22 105.918 22 101.5V49.5012C22 45.0849 25.5799 41.5038 29.9963 41.5023C63.1016 41.4906 94.1323 41.5283 118.991 41.5132C123.413 41.5105 127 45.0937 127 49.5157V101.5Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M75 93.5C84.9411 93.5 93 85.4411 93 75.5C93 65.5589 84.9411 57.5 75 57.5C65.0589 57.5 57 65.5589 57 75.5C57 85.4411 65.0589 93.5 75 93.5Z"
        fill="#FDA29B"
      />
      <path
        d="M82.1452 82.645C81.5594 83.2307 80.6097 83.2307 80.0239 82.645L74.7206 77.3417L69.4173 82.645C68.8315 83.2307 67.8817 83.2307 67.296 82.645C66.7102 82.0592 66.7102 81.1094 67.296 80.5236L72.5993 75.2203L67.296 69.917C66.7102 69.3312 66.7102 68.3815 67.296 67.7957C67.8817 67.2099 68.8315 67.2099 69.4173 67.7957L74.7206 73.099L80.0239 67.7957C80.6097 67.2099 81.5594 67.2099 82.1452 67.7957C82.731 68.3815 82.731 69.3312 82.1452 69.917L76.8419 75.2203L82.1452 80.5236C82.731 81.1094 82.731 82.0592 82.1452 82.645Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_3396_1019"
          x="16"
          y="32.5"
          width="117"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3396_1019"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3396_1019"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3396_1019"
          x1="75"
          y1="0.5"
          x2="75"
          y2="150.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3ECFA" />
          <stop offset="1" stopColor="#DAE7FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BlockedCardConfirm;
