import React, { FC } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Notification } from "../Notification";
import { makeStyles } from "@mui/styles";
import { Slide, SlideProps, Theme } from "@mui/material";
import { hideErrorMessage } from "store/slices/alerts";
import { useDispatch } from "react-redux";
import { createPortal } from "react-dom";

interface IAlertProps {
  title: string;
  message?: string;
  isOpen?: boolean;
}

type TransitionProps = Omit<SlideProps, "direction">;

const TransitionComponent = (props: TransitionProps) => (
  <Slide {...props} direction="down" />
);

const useStyles = makeStyles((theme: Theme) => ({
  snackbar: {
    [theme.breakpoints.down("md")]: {
      left: "auto",
      right: 15,
    },
    top: 3,
    right: 43,
  },
  message: {
    [theme.breakpoints.down("md")]: {
      width: 344,
    },
    "& > .MuiBox-root": {
      border: "1px solid #FEE4E2",
    },
    width: 404,
  },
}));

const Alert: FC<IAlertProps> = ({ title, message, isOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideErrorMessage());
  };

  return createPortal(
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      TransitionComponent={TransitionComponent}
      sx={{ width: "auto" }}
    >
      <div className={classes.message}>
        <Notification
          onClose={handleClose}
          type="error"
          title={title}
          description={message}
        />
      </div>
    </Snackbar>,
    document.body
  );
};

export default Alert;
