import React from "react";

interface IconAccountRubProps extends React.SVGProps<SVGSVGElement> {}

export const IconAccountRub = (props: IconAccountRubProps) => {
  const { width = 16, height = 20, color = "#739B67" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
    >
      <g clipPath="url(#clip0_3684_2728)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.141 2.56348C14.5375 2.56348 14.859 2.88491 14.859 3.28142V16.2045C14.859 16.601 14.5375 16.9224 14.141 16.9224H13.4233V17.6402C13.4233 18.0368 13.1018 18.3582 12.7053 18.3582C12.3088 18.3582 11.9874 18.0368 11.9874 17.6402V16.9224H3.37144V17.6402C3.37144 18.0368 3.05001 18.3582 2.6535 18.3582C2.25698 18.3582 1.93555 18.0368 1.93555 17.6402V16.9224H1.21795C0.821436 16.9224 0.5 16.601 0.5 16.2045V3.28142C0.5 2.88491 0.821436 2.56348 1.21795 2.56348H14.141ZM8.27759 10.7603C9.58383 10.7603 10.6708 9.6734 10.6708 8.36716C10.6708 7.0645 9.54181 5.974 8.23152 5.974H6.46477C6.13371 5.974 5.86558 6.24286 5.86648 6.57392L5.87464 9.56374H5.28614C4.95571 9.56374 4.68785 9.8316 4.68785 10.162C4.68785 10.4925 4.95571 10.7603 5.28614 10.7603H5.8779L5.87978 11.3631H5.19639C4.91553 11.3631 4.68785 11.5907 4.68785 11.8716C4.68785 12.1525 4.91553 12.3802 5.19639 12.3802H5.88294L5.88443 12.8579C5.88533 13.1884 6.15392 13.4555 6.48435 13.4546C6.81478 13.4537 7.08191 13.1851 7.08101 12.8547L7.07953 12.3802H8.18785C8.46871 12.3802 8.69639 12.1525 8.69639 11.8716C8.69639 11.5907 8.46871 11.3631 8.18785 11.3631H7.07636L7.07448 10.7603H8.27759ZM7.07122 9.56374L7.06469 7.17058H8.23152C8.89005 7.17058 9.47417 7.73481 9.47417 8.36716C9.47417 9.01254 8.92298 9.56374 8.27759 9.56374H7.07122Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3684_2728">
          <rect
            width="14.6154"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
