import styled from "@emotion/styled/macro";
import CloseIcon from "./assets/close-icon.png";

/**
 * TO DO: градиент
 */
const Container = styled.div`
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #fee4e2;
  background: linear-gradient(0deg, #fee4e2, #fee4e2),
    linear-gradient(0deg, #fef3f2, #fef3f2);
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 24px;
  margin-right: 5px;
`;

const Title = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--error-color-primary);
`;

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-secondary);
  margin-top: 10px;
`;

interface ErrorFullScreenProps {
  title: string;
  description?: string;
}

export const ErrorFullScreen = ({
  title,
  description,
}: ErrorFullScreenProps) => {
  return (
    <Container>
      <TitleBlock>
        <Icon src={CloseIcon} />
        <Title>{title}</Title>
      </TitleBlock>
      {description && <Description>{description}</Description>}
    </Container>
  );
};
