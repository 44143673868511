import { RadioCheckGreen } from "./RadioCheck";
import { useEffect, useMemo, useState } from "react";
import { validateLength } from "utils/validateLength";
import { validateDigits } from "utils/validateDigits";
import { validateUpperLower } from "utils/validateUpperLower";
import { validateSpecialChars } from "utils/validateSpecialChars";
import { PasswordStepVariant } from "organisms/Registration/FourthScreen/FourthScreen";
import styles from "./CheckBlock.module.css";
import stylesProfile from "./CheckBlockProfile.module.css";

export type CheckBlockType = "login" | "password";

interface CheckBlockProps {
  value: string;
  isDisabled: (value: boolean) => void;
  type: CheckBlockType;
  isPasswordMatch?: boolean;
  isFocus?: boolean;
  isProfile?: boolean;
  passwordStep?: PasswordStepVariant;
}

export const CheckBlock = ({
  value,
  isDisabled,
  type,
  isPasswordMatch,
  isFocus,
  isProfile,
  passwordStep,
}: CheckBlockProps) => {
  const [isValidSpecialChars, setIsValidSpecialChars] = useState(false);
  const [isValidUpperLower, setIsValidUpperLower] = useState(false);
  const [isDigitsValid, setIsDigitsValid] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);

  const st = useMemo(() => (isProfile ? stylesProfile : styles), [isProfile]);

  const isFirstStep = !(
    isValidSpecialChars &&
    isValidUpperLower &&
    isDigitsValid &&
    isLengthValid
  );

  useEffect(() => {
    if (value) {
      setIsLengthValid(
        validateLength(
          value,
          type === "password" ? 8 : 6,
          type === "password" ? undefined : 16
        )
      );
      setIsDigitsValid(validateDigits(value));
      setIsValidUpperLower(validateUpperLower(value, type));
      setIsValidSpecialChars(validateSpecialChars(value, type));
    } else {
      setIsLengthValid(false);
      setIsDigitsValid(false);
      setIsValidUpperLower(false);
      setIsValidSpecialChars(false);
    }
  }, [value, type]);

  useEffect(() => {
    const disabledLogin = isValidUpperLower && isLengthValid;
    const disabledPassword =
      isValidSpecialChars &&
      isValidUpperLower &&
      isDigitsValid &&
      isLengthValid;
    isDisabled(type === "password" ? disabledPassword : disabledLogin);
  }, [
    isValidSpecialChars,
    isValidUpperLower,
    isDigitsValid,
    isLengthValid,
    type,
    isDisabled,
  ]);

  return type === "password" ? (
    <div>
      {isFirstStep ||
      (!isFirstStep &&
        !isFocus &&
        !isPasswordMatch &&
        passwordStep !== "repeat") ? (
        <div>
          <p className={st.title}>Пароль должен содержать:</p>
          <div className={st.pointBlock}>
            <RadioCheckGreen isChecked={isValidUpperLower} />
            <p className={st.pointTitle}>
              Прописные и строчные латинские буквы
            </p>
          </div>
          <div className={st.pointBlock}>
            <RadioCheckGreen isChecked={isDigitsValid} />
            <p className={st.pointTitle}>Цифры</p>
          </div>
          <div className={st.pointBlock}>
            <RadioCheckGreen isChecked={isValidSpecialChars} />
            <p className={st.pointTitle}>
              Спецсимволы (+ : _ . ( ) - ! ? и пр.)
            </p>
          </div>
          <div className={st.pointBlock}>
            <RadioCheckGreen isChecked={isLengthValid} />
            <p className={st.pointTitle}>8-18 символов</p>
          </div>
        </div>
      ) : (
        <div className={st.pointBlock}>
          <RadioCheckGreen isChecked={Boolean(isPasswordMatch)} />
          <p className={st.pointTitle}>Пароли совпадают</p>
        </div>
      )}
    </div>
  ) : (
    <div>
      <p className={st.title}>Логин должен содержать:</p>
      <div className={st.pointBlock} style={{ alignItems: "flex-start" }}>
        <RadioCheckGreen isChecked={isValidUpperLower} />
        <p className={st.pointTitle}>
          Латинские буквы, цифры или спецсимволы <br />
          (+ : _ . ( ) - ! ? и пр.)
        </p>
      </div>
      <div className={st.pointBlock}>
        <RadioCheckGreen isChecked={isLengthValid} />
        <p className={st.pointTitle}>6-16 латинских символов</p>
      </div>
    </div>
  );
};
