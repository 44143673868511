import * as React from "react";

export const IconOtherTab = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.69995 5.25977C3.69995 4.01713 4.70731 3.00977 5.94995 3.00977H9.03995C10.2826 3.00977 11.29 4.01713 11.29 5.25977V8.34976C11.29 9.59241 10.2826 10.5998 9.03995 10.5998H5.94995C4.70731 10.5998 3.69995 9.59241 3.69995 8.34977V5.25977Z"
      fill={props.color ?? "#739B67"}
    />
    <path
      d="M14.11 5.25977C14.11 4.01713 15.1173 3.00977 16.36 3.00977H19.45C20.6926 3.00977 21.7 4.01713 21.7 5.25977V8.34976C21.7 9.59241 20.6926 10.5998 19.45 10.5998H16.36C15.1173 10.5998 14.11 9.59241 14.11 8.34977V5.25977Z"
      fill={props.color ?? "#739B67"}
    />
    <path
      d="M3.69995 15.6598C3.69995 14.4171 4.70731 13.4098 5.94995 13.4098H9.03995C10.2826 13.4098 11.29 14.4171 11.29 15.6598V18.7498C11.29 19.9924 10.2826 20.9998 9.03995 20.9998H5.94995C4.70731 20.9998 3.69995 19.9924 3.69995 18.7498V15.6598Z"
      fill={props.color ?? "#739B67"}
    />
    <path
      d="M14.11 15.6598C14.11 14.4171 15.1173 13.4098 16.36 13.4098H19.45C20.6926 13.4098 21.7 14.4171 21.7 15.6598V18.7498C21.7 19.9924 20.6926 20.9998 19.45 20.9998H16.36C15.1173 20.9998 14.11 19.9924 14.11 18.7498V15.6598Z"
      fill={props.color ?? "#739B67"}
    />
  </svg>
);
