import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(69, 74, 63, 0.5);
  backdrop-filter: blur(4px);
`;

export const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0 32px;
  border-radius: 18px 18px 0 0;
  background: #fff;
  backdrop-filter: blur(12.899px);

  position: fixed;
  bottom: 0;
  @media (max-width: 550px) {
  }
`;
