export const IconWarningTransfer = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="110" height="110" rx="55" fill="#F5F5F5" />
      <path
        d="M48.5 87C67.5538 87 83 71.5538 83 52.5C83 33.4462 67.5538 18 48.5 18C29.4462 18 14 33.4462 14 52.5C14 71.5538 29.4462 87 48.5 87Z"
        fill="#D9E3CC"
        stroke="#F5F5F5"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7999 68.6668H42.1833C36.2999 68.6668 32.3333 64.8818 32.3333 59.2335V44.7835C32.3333 39.1335 36.2999 35.3335 42.1833 35.3335H55.7999C61.6999 35.3335 65.6666 39.1335 65.6666 44.7835V59.2335C65.6666 64.8818 61.6999 68.6668 55.7999 68.6668ZM52.7833 50.7502H42.1999C41.4999 50.7502 40.9499 51.3168 40.9499 52.0002C40.9499 52.7002 41.4999 53.2502 42.1999 53.2502H52.7833L48.6499 57.3668C48.4166 57.6002 48.2833 57.9335 48.2833 58.2502C48.2833 58.5652 48.4166 58.8835 48.6499 59.1335C49.1333 59.6168 49.9333 59.6168 50.4166 59.1335L56.6999 52.8835C57.1666 52.4168 57.1666 51.5835 56.6999 51.1168L50.4166 44.8668C49.9333 44.3835 49.1333 44.3835 48.6499 44.8668C48.1666 45.3668 48.1666 46.1502 48.6499 46.6502L52.7833 50.7502Z"
        fill="#739B67"
      />
      <path
        d="M80.5 92C90.165 92 98 84.165 98 74.5C98 64.835 90.165 57 80.5 57C70.835 57 63 64.835 63 74.5C63 84.165 70.835 92 80.5 92Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.4167 74.4993C68.4167 67.8306 73.8301 62.416 80.5001 62.416C87.1822 62.416 92.5834 67.8306 92.5834 74.4993C92.5834 81.1706 87.1822 86.5827 80.5001 86.5827C73.8301 86.5827 68.4167 81.1706 68.4167 74.4993ZM79.4367 69.9198C79.4367 69.341 79.9201 68.8564 80.5001 68.8564C81.0801 68.8564 81.5513 69.341 81.5513 69.9198V75.2606C81.5513 75.8418 81.0801 76.3118 80.5001 76.3118C79.9201 76.3118 79.4367 75.8418 79.4367 75.2606V69.9198ZM80.5122 80.1556C79.9201 80.1556 79.4488 79.6722 79.4488 79.0922C79.4488 78.5122 79.9201 78.041 80.5001 78.041C81.0922 78.041 81.5634 78.5122 81.5634 79.0922C81.5634 79.6722 81.0922 80.1556 80.5122 80.1556Z"
        fill="#B5CDA3"
      />
    </svg>
  );
};
