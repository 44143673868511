import React from "react";
import { InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  labelRoot: {
    "&.MuiInputLabel-root": {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
      marginBottom: 6,
      textAlign: "left",
      color: "var(--main-color-text-subtitle)",
    },
  },
}));

export const Label = ({ label, htmlFor, ...props }) => {
  const classes = useStyles();
  return (
    <InputLabel
      {...props}
      classes={{ root: classes.labelRoot }}
      htmlFor={htmlFor}
    >
      {label}
    </InputLabel>
  );
};
