import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllPromotions, getPromotionById } from "./asyncThunks";
import { ErrorResponse } from "api/auth";
import { ICardState } from "../system/slice";

interface ActivePromotion {
  title?: string;
  description?: string;
  srcImgDetail?: string;
  srcImgLenta?: string;
  id?: number;
  category?: string;
  dateFrom?: string;
  buttonName?: string;
  buttonLink?: string;
}

export type PromotionsState = {
  promotions: Array<ICardState>;
  loading: boolean;
  error: ErrorResponse | null;
  activePromotion: ActivePromotion;
};

const initialState: PromotionsState = {
  promotions: [],
  loading: false,
  error: null,
  activePromotion: {},
};

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [getAllPromotions.pending.type]: (state) => {
      state.loading = true;
      if (state.error) {
        state.error = null;
      }
    },
    [getAllPromotions.fulfilled.type]: (
      state,
      { payload }: PayloadAction<Array<ICardState>>
    ) => {
      state.loading = false;
      state.promotions = payload;
    },
    [getAllPromotions.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = payload?.response?.data || undefined;
    },
    [getPromotionById.pending.type]: (state) => {
      state.loading = true;
      if (state.error) {
        state.error = null;
      }
    },
    [getPromotionById.fulfilled.type]: (
      state,
      { payload }: PayloadAction<ActivePromotion>
    ) => {
      state.loading = false;
      state.activePromotion = payload;
    },
    [getPromotionById.rejected.type]: (state, { payload }) => {
      state.loading = false;
      state.error = payload?.response?.data || undefined;
    },
  },
});

export default promotionsSlice;
