import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { getSecondsPluralForms } from "utils";
import { useCounter } from "hooks/useCounter";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  registerCheck,
  retryOneTimePassword,
} from "store/slices/auth";
import { authSelector } from "store";
import styles from "./style.module.css";
import { BackButtonArrow } from "components/BackButtonArrow";
import { CodeInput } from "components/CodeInput";
import { Button } from "components/Button";

const SecondScreen = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>("");
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const { isLoading, error } = useSelector(authSelector);

  const handleConfirm = () => {
    dispatch(registerCheck(code));
  };

  const handleRetryOneTimePassword = () => {
    dispatch(retryOneTimePassword());
    restartCounter();
  };

  return (
    <div className={styles.container}>
      <div className={styles.backBtn}>
        <BackButtonArrow
          onClick={() => dispatch(authActions.setRegisterScreen("initial"))}
        />
      </div>
      <div className={styles.titleBlock}>
        <p className={styles.title}>Подтверждение номера</p>
        <p className={styles.description}>Код из SMS</p>
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        {error && (
          <p className={styles.error}>Ошибка: Неверный код. Попробуйте снова</p>
        )}
        <CodeInput
          length={4}
          setCode={setCode}
          error={Boolean(error)}
          clearError={() => dispatch(authActions.resetError())}
        />
      </Box>
      <Box mt={40}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          rowGap={count > 0 ? 22 : 12}
        >
          <Button
            onClick={handleConfirm}
            variant="primary"
            disabled={code.length < 4}
            isLoading={isLoading}
            title="Продолжить"
          />
          {count === 0 ? (
            <Button
              onClick={handleRetryOneTimePassword}
              variant="secondary"
              title="Отправить код повторно"
            />
          ) : (
            <Box minHeight={48} textAlign="center">
              <Typography variant="text_5" color="gray.b400">
                Вы сможете отправить код повторно
                <br />
                через{" "}
                <Typography variant="text_5" color="blue.b400">
                  {count} {secondsWord}.
                </Typography>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default SecondScreen;
