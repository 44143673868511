import theme from "theme";

type IconInfoProps = {
  fill?: string;
};

const IconInfo = ({ fill = theme.palette.blue.b400 }: IconInfoProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9V15H11V9H9ZM9 5V7H11V5H9Z"
      fill={fill}
    />
  </svg>
);

export default IconInfo;
