import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { IconAccountRub } from "atoms/IconAccountRub/IconAccountRub";
import IconWrapper from "components/IconWrapper/IconWrapper";
import { useIsMobile } from "hooks/useIsMobile";
import { formatAmount } from "utils/formatCurrency";

import { Modal } from "molecules/Modal/Modal";
import { AccountModal } from "components/AccountModal/AccountModal";
import { useState } from "react";

const AccountsContainer = styled(Box)({
  display: "flex",
  columnGap: "12px",
  cursor: "pointer",
  padding: "12px 16px",
  backgroundColor: " #F3F5F8",
  borderRadius: "20px",
});

interface IAccountCardProps {
  availableBalance: any;
  currency: any;
  description: any;
  accountNumber: any;
}

export const AccountCard = ({
  availableBalance,
  currency,
  description,
  accountNumber,
}: IAccountCardProps) => {
  const { isMobile } = useIsMobile();
  const [modaliIsOpen, setModalIsOpen] = useState(false);
  const accountAmount = formatAmount(availableBalance, currency?.sign);
  return (
    <>
      <AccountsContainer onClick={() => setModalIsOpen(true)}>
        <IconWrapper>
          <IconAccountRub />
        </IconWrapper>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            noWrap
            sx={{
              color: "#454A3F",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Счёт
          </Typography>
          <Typography
            noWrap
            sx={{
              color: "#454A3F",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            {accountAmount}
          </Typography>
        </Box>
      </AccountsContainer>

      <Modal
        title="Изменение названия счёта"
        isOpen={modaliIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
        buttonVisible={modaliIsOpen}
        callback={() => {
          setModalIsOpen(false);
        }}
        isProfile
      >
        <AccountModal
          name={description}
          accId={accountNumber}
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
