type PluralVariants = "one" | "few" | "many";

export const getSecondsPluralForms = (number: number) => {
  const pluralRules = new Intl.PluralRules("ru-RU");
  const pluralValue = pluralRules.select(number) as PluralVariants;

  const pluralForms: Record<PluralVariants, string> = {
    one: "секунду",
    few: "секунды",
    many: "секунд",
  };

  return pluralForms[pluralValue];
};
