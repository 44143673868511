/* tslint:disable */
/* eslint-disable */
/**
 * API auth-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddEnterRecordRequest
 */
export interface AddEnterRecordRequest {
    /**
     * refresh token
     * @type {string}
     * @memberof AddEnterRecordRequest
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof AddEnterRecordRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {RefreshType}
     * @memberof AddEnterRecordRequest
     */
    'type': RefreshType;
}
/**
 * Идентификатора приложения
 * @export
 * @interface AppIdDto
 */
export interface AppIdDto {
    /**
     * URL приложения
     * @type {string}
     * @memberof AppIdDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AssertionExtensionInputsDto
 */
export interface AssertionExtensionInputsDto {
    /**
     * 
     * @type {AppIdDto}
     * @memberof AssertionExtensionInputsDto
     */
    'appid'?: AppIdDto;
    /**
     * 
     * @type {LargeBlobAuthenticationInputDto}
     * @memberof AssertionExtensionInputsDto
     */
    'largeBlob'?: LargeBlobAuthenticationInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof AssertionExtensionInputsDto
     */
    'uvm'?: boolean;
}
/**
 * Тип передачи аттестации при создании учетных данных
 * @export
 * @enum {string}
 */

export const AttestationConveyancePreferenceType = {
    None: 'none',
    Indirect: 'indirect',
    Direct: 'direct',
    Enterprise: 'enterprise'
} as const;

export type AttestationConveyancePreferenceType = typeof AttestationConveyancePreferenceType[keyof typeof AttestationConveyancePreferenceType];


/**
 * 
 * @export
 * @interface Auth
 */
export interface Auth {
    /**
     * 
     * @type {string}
     * @memberof Auth
     */
    'refreshToken'?: string;
    /**
     * Срок жизни `refresh_token` в минутах
     * @type {number}
     * @memberof Auth
     */
    'refreshTokenValid'?: number;
    /**
     * Срок жизни `access_token` в минутах
     * @type {number}
     * @memberof Auth
     */
    'accessTokenValid'?: number;
    /**
     * 
     * @type {string}
     * @memberof Auth
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface AuthenticatorAssertionResponseDto
 */
export interface AuthenticatorAssertionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponseDto
     */
    'authenticatorData': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponseDto
     */
    'clientDataJSON': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponseDto
     */
    'signature': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponseDto
     */
    'userHandle'?: string;
}
/**
 * Тип подключения аутентификаторов
 * @export
 * @enum {string}
 */

export const AuthenticatorAttachmentType = {
    CrossPlatform: 'cross-platform',
    Platform: 'platform'
} as const;

export type AuthenticatorAttachmentType = typeof AuthenticatorAttachmentType[keyof typeof AuthenticatorAttachmentType];


/**
 * 
 * @export
 * @interface AuthenticatorAttestationResponseDto
 */
export interface AuthenticatorAttestationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAttestationResponseDto
     */
    'attestationObject': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAttestationResponseDto
     */
    'clientDataJSON': string;
    /**
     * 
     * @type {Array<AuthenticatorTransportType>}
     * @memberof AuthenticatorAttestationResponseDto
     */
    'transports'?: Array<AuthenticatorTransportType>;
}
/**
 * 
 * @export
 * @interface AuthenticatorSelectionCriteriaDto
 */
export interface AuthenticatorSelectionCriteriaDto {
    /**
     * 
     * @type {AuthenticatorAttachmentType}
     * @memberof AuthenticatorSelectionCriteriaDto
     */
    'authenticatorAttachment'?: AuthenticatorAttachmentType;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatorSelectionCriteriaDto
     */
    'requireResidentKey'?: boolean;
    /**
     * 
     * @type {ResidentKeyRequirementType}
     * @memberof AuthenticatorSelectionCriteriaDto
     */
    'residentKey'?: ResidentKeyRequirementType;
    /**
     * 
     * @type {UserVerificationRequirementType}
     * @memberof AuthenticatorSelectionCriteriaDto
     */
    'userVerification'?: UserVerificationRequirementType;
}
/**
 * Тип аутентикатора
 * @export
 * @enum {string}
 */

export const AuthenticatorTransportType = {
    Usb: 'usb',
    Nfc: 'nfc',
    Ble: 'ble',
    Hybrid: 'hybrid',
    Internal: 'internal'
} as const;

export type AuthenticatorTransportType = typeof AuthenticatorTransportType[keyof typeof AuthenticatorTransportType];


/**
 * 
 * @export
 * @interface AuthorizationWebAuthnCheckRequest
 */
export interface AuthorizationWebAuthnCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationWebAuthnCheckRequest
     */
    'identityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationWebAuthnCheckRequest
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface AuthorizationWebAuthnCheckResponse
 */
export interface AuthorizationWebAuthnCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationWebAuthnCheckResponse
     */
    'webAuthnAllowed'?: boolean;
}
/**
 * 
 * @export
 * @interface AuthorizationWebAuthnConfirmRequest
 */
export interface AuthorizationWebAuthnConfirmRequest {
    /**
     * Token на подтверждение авторизации по открытым ключам
     * @type {string}
     * @memberof AuthorizationWebAuthnConfirmRequest
     */
    'assertionToken': string;
    /**
     * 
     * @type {PublicKeyCredentialAssertionDto}
     * @memberof AuthorizationWebAuthnConfirmRequest
     */
    'credential': PublicKeyCredentialAssertionDto;
    /**
     * Таймзона клиента https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof AuthorizationWebAuthnConfirmRequest
     */
    'timeZone': string;
    /**
     * 
     * @type {PmpDeviceDto}
     * @memberof AuthorizationWebAuthnConfirmRequest
     */
    'device': PmpDeviceDto;
}
/**
 * 
 * @export
 * @interface AuthorizationWebAuthnRequest
 */
export interface AuthorizationWebAuthnRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationWebAuthnRequest
     */
    'login'?: string;
    /**
     * 
     * @type {PmpDeviceDto}
     * @memberof AuthorizationWebAuthnRequest
     */
    'device': PmpDeviceDto;
}
/**
 * 
 * @export
 * @interface AuthorizationWebAuthnResponse
 */
export interface AuthorizationWebAuthnResponse {
    /**
     * Сгенерированный токен для подтверждения авторизации по открытым ключам
     * @type {string}
     * @memberof AuthorizationWebAuthnResponse
     */
    'assertionId'?: string;
    /**
     * 
     * @type {PublicKeyCredentialRequestOptionsDto}
     * @memberof AuthorizationWebAuthnResponse
     */
    'publicKeyCredentialRequestOptions'?: PublicKeyCredentialRequestOptionsDto;
}
/**
 * 
 * @export
 * @interface AvatarLoadRequest
 */
export interface AvatarLoadRequest {
    /**
     * Файл аватара в base64
     * @type {string}
     * @memberof AvatarLoadRequest
     */
    'file'?: string;
}
/**
 * 
 * @export
 * @interface BooleanCheckResponse
 */
export interface BooleanCheckResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanCheckResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ChangePinWebConfirmRequest
 */
export interface ChangePinWebConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePinWebConfirmRequest
     */
    'verifyPinToken': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePinWebConfirmRequest
     */
    'newPinCode': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePinWebConfirmRequest
     */
    'repeatNewPinCode': string;
}
/**
 * 
 * @export
 * @interface ChangePinWebVerifyRequest
 */
export interface ChangePinWebVerifyRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePinWebVerifyRequest
     */
    'pinCode': string;
}
/**
 * 
 * @export
 * @interface ChangePinWebVerifyResponse
 */
export interface ChangePinWebVerifyResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangePinWebVerifyResponse
     */
    'verifyPinToken'?: string;
}
/**
 * 
 * @export
 * @interface CheckPinAllowedResponse
 */
export interface CheckPinAllowedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckPinAllowedResponse
     */
    'pinAllowed'?: boolean;
}
/**
 * 
 * @export
 * @interface ClientAssertionExtensionOutputsDto
 */
export interface ClientAssertionExtensionOutputsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ClientAssertionExtensionOutputsDto
     */
    'appid'?: boolean;
    /**
     * 
     * @type {LargeBlobAuthenticationOutputDto}
     * @memberof ClientAssertionExtensionOutputsDto
     */
    'largeBlob'?: LargeBlobAuthenticationOutputDto;
}
/**
 * 
 * @export
 * @interface ClientRegistrationExtensionOutputsDto
 */
export interface ClientRegistrationExtensionOutputsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ClientRegistrationExtensionOutputsDto
     */
    'appidExclude'?: boolean;
    /**
     * 
     * @type {CredentialPropertiesOutputDto}
     * @memberof ClientRegistrationExtensionOutputsDto
     */
    'credProps'?: CredentialPropertiesOutputDto;
    /**
     * 
     * @type {LargeBlobRegistrationOutputDto}
     * @memberof ClientRegistrationExtensionOutputsDto
     */
    'largeBlob'?: LargeBlobRegistrationOutputDto;
}
/**
 * 
 * @export
 * @interface CountryResponse
 */
export interface CountryResponse {
    /**
     * 
     * @type {number}
     * @memberof CountryResponse
     */
    'id'?: number;
    /**
     * Код страны
     * @type {string}
     * @memberof CountryResponse
     */
    'code'?: string;
    /**
     * Наименование страны
     * @type {string}
     * @memberof CountryResponse
     */
    'name'?: string;
    /**
     * Флаг страны
     * @type {string}
     * @memberof CountryResponse
     */
    'flag'?: string;
    /**
     * Маска телефонных номеров
     * @type {string}
     * @memberof CountryResponse
     */
    'mask'?: string;
    /**
     * Регулярное выражение
     * @type {string}
     * @memberof CountryResponse
     */
    'regexp'?: string;
}
/**
 * 
 * @export
 * @interface CredentialPropertiesOutputDto
 */
export interface CredentialPropertiesOutputDto {
    /**
     * 
     * @type {boolean}
     * @memberof CredentialPropertiesOutputDto
     */
    'rk'?: boolean;
}
/**
 * Тип устройства. \'A\' - Android, \'I\' - iOS, \'H\' - Huawei
 * @export
 * @enum {string}
 */

export const DeviceType = {
    A: 'A',
    I: 'I',
    W: 'W',
    H: 'H'
} as const;

export type DeviceType = typeof DeviceType[keyof typeof DeviceType];


/**
 * 
 * @export
 * @interface ErrorParameterResponse
 */
export interface ErrorParameterResponse {
    /**
     * Символьный код ошибки
     * @type {string}
     * @memberof ErrorParameterResponse
     */
    'code'?: string;
    /**
     * Заголовок ошибки
     * @type {string}
     * @memberof ErrorParameterResponse
     */
    'title'?: string;
    /**
     * Подзаголовок ошибки
     * @type {string}
     * @memberof ErrorParameterResponse
     */
    'subtitle'?: string;
    /**
     * Поясняющее сообщение
     * @type {string}
     * @memberof ErrorParameterResponse
     */
    'comment'?: string;
    /**
     * Массив ошибок
     * @type {Array<string>}
     * @memberof ErrorParameterResponse
     */
    'errors'?: Array<string>;
    /**
     * Дополнительная информация
     * @type {string}
     * @memberof ErrorParameterResponse
     */
    'additional'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorParameterResponse
     */
    'id'?: string;
    /**
     * Дополнительный параметр
     * @type {string}
     * @memberof ErrorParameterResponse
     */
    'parameter'?: string;
}
/**
 * 
 * @export
 * @interface ErrorParameterResponseAllOf
 */
export interface ErrorParameterResponseAllOf {
    /**
     * Дополнительный параметр
     * @type {string}
     * @memberof ErrorParameterResponseAllOf
     */
    'parameter'?: string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * Символьный код ошибки
     * @type {string}
     * @memberof ErrorResponse
     */
    'code'?: string;
    /**
     * Заголовок ошибки
     * @type {string}
     * @memberof ErrorResponse
     */
    'title'?: string;
    /**
     * Подзаголовок ошибки
     * @type {string}
     * @memberof ErrorResponse
     */
    'subtitle'?: string;
    /**
     * Поясняющее сообщение
     * @type {string}
     * @memberof ErrorResponse
     */
    'comment'?: string;
    /**
     * Массив ошибок
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    'errors'?: Array<string>;
    /**
     * Дополнительная информация
     * @type {string}
     * @memberof ErrorResponse
     */
    'additional'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface LargeBlobAuthenticationInputDto
 */
export interface LargeBlobAuthenticationInputDto {
    /**
     * 
     * @type {boolean}
     * @memberof LargeBlobAuthenticationInputDto
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LargeBlobAuthenticationInputDto
     */
    'write'?: string;
}
/**
 * 
 * @export
 * @interface LargeBlobAuthenticationOutputDto
 */
export interface LargeBlobAuthenticationOutputDto {
    /**
     * 
     * @type {string}
     * @memberof LargeBlobAuthenticationOutputDto
     */
    'blob'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LargeBlobAuthenticationOutputDto
     */
    'written'?: boolean;
}
/**
 * 
 * @export
 * @interface LargeBlobRegistrationInputDto
 */
export interface LargeBlobRegistrationInputDto {
    /**
     * 
     * @type {LargeBlobSupportType}
     * @memberof LargeBlobRegistrationInputDto
     */
    'support'?: LargeBlobSupportType;
}
/**
 * 
 * @export
 * @interface LargeBlobRegistrationOutputDto
 */
export interface LargeBlobRegistrationOutputDto {
    /**
     * 
     * @type {boolean}
     * @memberof LargeBlobRegistrationOutputDto
     */
    'supported'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LargeBlobSupportType = {
    Required: 'required',
    Preferred: 'preferred'
} as const;

export type LargeBlobSupportType = typeof LargeBlobSupportType[keyof typeof LargeBlobSupportType];


/**
 * 
 * @export
 * @interface LinkedDeviceRequest
 */
export interface LinkedDeviceRequest {
    /**
     * True - привязка, False - отвязка
     * @type {boolean}
     * @memberof LinkedDeviceRequest
     */
    'link'?: boolean;
    /**
     * 
     * @type {PmpDeviceDto}
     * @memberof LinkedDeviceRequest
     */
    'device'?: PmpDeviceDto;
}
/**
 * 
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
    /**
     * 
     * @type {string}
     * @memberof LogoutRequest
     */
    'deviceId'?: string;
}
/**
 * 
 * @export
 * @interface PMPLogin
 */
export interface PMPLogin {
    /**
     * 
     * @type {string}
     * @memberof PMPLogin
     */
    'loginToken'?: string;
}
/**
 * 
 * @export
 * @interface PMPLoginCheckPinAllowedWebRequest
 */
export interface PMPLoginCheckPinAllowedWebRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPLoginCheckPinAllowedWebRequest
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface PMPLoginConfirmRequest
 */
export interface PMPLoginConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPLoginConfirmRequest
     */
    'loginToken': string;
    /**
     * 
     * @type {string}
     * @memberof PMPLoginConfirmRequest
     */
    'pinCode': string;
    /**
     * Таймзона клиента https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof PMPLoginConfirmRequest
     */
    'timeZone': string;
    /**
     * 
     * @type {PmpDeviceDto}
     * @memberof PMPLoginConfirmRequest
     */
    'device': PmpDeviceDto;
}
/**
 * 
 * @export
 * @interface PMPLoginConfirmWebRequest
 */
export interface PMPLoginConfirmWebRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPLoginConfirmWebRequest
     */
    'loginToken': string;
    /**
     * 
     * @type {string}
     * @memberof PMPLoginConfirmWebRequest
     */
    'pinCode': string;
    /**
     * Таймзона клиента https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof PMPLoginConfirmWebRequest
     */
    'timeZone': string;
    /**
     * 
     * @type {PmpDeviceDto}
     * @memberof PMPLoginConfirmWebRequest
     */
    'device': PmpDeviceDto;
}
/**
 * 
 * @export
 * @interface PMPLoginCreatePinWebRequest
 */
export interface PMPLoginCreatePinWebRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPLoginCreatePinWebRequest
     */
    'pinCode': string;
    /**
     * 
     * @type {string}
     * @memberof PMPLoginCreatePinWebRequest
     */
    'repeatPin': string;
}
/**
 * 
 * @export
 * @interface PMPLoginPinWebRequest
 */
export interface PMPLoginPinWebRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPLoginPinWebRequest
     */
    'pinCode': string;
    /**
     * Таймзона клиента https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof PMPLoginPinWebRequest
     */
    'timeZone': string;
    /**
     * 
     * @type {PmpDeviceDto}
     * @memberof PMPLoginPinWebRequest
     */
    'device': PmpDeviceDto;
}
/**
 * 
 * @export
 * @interface PMPLoginRequest
 */
export interface PMPLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPLoginRequest
     */
    'login': string;
    /**
     * 
     * @type {string}
     * @memberof PMPLoginRequest
     */
    'password': string;
    /**
     * 
     * @type {PmpDeviceDto}
     * @memberof PMPLoginRequest
     */
    'device'?: PmpDeviceDto;
}
/**
 * 
 * @export
 * @interface PMPRegisterCheckCvvRequest
 */
export interface PMPRegisterCheckCvvRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckCvvRequest
     */
    'registerToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckCvvRequest
     */
    'cvv'?: string;
}
/**
 * 
 * @export
 * @interface PMPRegisterCheckCvvRequestAllOf
 */
export interface PMPRegisterCheckCvvRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckCvvRequestAllOf
     */
    'registerToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckCvvRequestAllOf
     */
    'cvv'?: string;
}
/**
 * 
 * @export
 * @interface PMPRegisterCheckLoginRequest
 */
export interface PMPRegisterCheckLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckLoginRequest
     */
    'registerToken': string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckLoginRequest
     */
    'login': string;
    /**
     * Таймзона клиента https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof PMPRegisterCheckLoginRequest
     */
    'timeZone'?: string;
}
/**
 * 
 * @export
 * @interface PMPRegisterCheckRequest
 */
export interface PMPRegisterCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckRequest
     */
    'registerToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckRequest
     */
    'verifyCode'?: string;
}
/**
 * 
 * @export
 * @interface PMPRegisterCheckRequestAllOf
 */
export interface PMPRegisterCheckRequestAllOf {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterCheckRequestAllOf
     */
    'verifyCode'?: string;
}
/**
 * 
 * @export
 * @interface PMPRegisterConfirmRequest
 */
export interface PMPRegisterConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterConfirmRequest
     */
    'registerToken': string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterConfirmRequest
     */
    'login': string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterConfirmRequest
     */
    'password': string;
    /**
     * Таймзона клиента https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof PMPRegisterConfirmRequest
     */
    'timeZone'?: string;
}
/**
 * 
 * @export
 * @interface PMPRegisterRequestV2
 */
export interface PMPRegisterRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterRequestV2
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterRequestV2
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterRequestV2
     */
    'pan': string;
}
/**
 * 
 * @export
 * @interface PMPRegisterRequestV3
 */
export interface PMPRegisterRequestV3 {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterRequestV3
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterRequestV3
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterRequestV3
     */
    'pan': string;
}
/**
 * 
 * @export
 * @interface PMPRegisterResponse
 */
export interface PMPRegisterResponse {
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterResponse
     */
    'registerToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof PMPRegisterResponse
     */
    'login'?: string;
}
/**
 * 
 * @export
 * @interface PasswordCheckRequest
 */
export interface PasswordCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordCheckRequest
     */
    'passwordResetToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordCheckRequest
     */
    'verifyCode'?: string;
}
/**
 * 
 * @export
 * @interface PasswordConfirmRequest
 */
export interface PasswordConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordConfirmRequest
     */
    'deviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordConfirmRequest
     */
    'passwordResetToken': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordConfirmRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PasswordConfirmResponse
 */
export interface PasswordConfirmResponse {
    /**
     * 
     * @type {string}
     * @memberof PasswordConfirmResponse
     */
    'restoreToken'?: string;
}
/**
 * 
 * @export
 * @interface PasswordCvvRequest
 */
export interface PasswordCvvRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordCvvRequest
     */
    'passwordResetToken': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordCvvRequest
     */
    'cvv': string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'pan': string;
}
/**
 * 
 * @export
 * @interface PasswordResetResponse
 */
export interface PasswordResetResponse {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetResponse
     */
    'confirmPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetResponse
     */
    'resetToken'?: string;
}
/**
 * 
 * @export
 * @interface PhoneChangedDto
 */
export interface PhoneChangedDto {
    /**
     * 
     * @type {string}
     * @memberof PhoneChangedDto
     */
    'old'?: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneChangedDto
     */
    'new'?: string;
}
/**
 * 
 * @export
 * @interface PmpDeviceDto
 */
export interface PmpDeviceDto {
    /**
     * ID устройства
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'deviceId': string;
    /**
     * 
     * @type {DeviceType}
     * @memberof PmpDeviceDto
     */
    'deviceType'?: DeviceType;
    /**
     * ID сборки
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'buildId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'brand'?: string;
    /**
     * Номер сборки
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'buildNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'bundleId'?: string;
    /**
     * Поставщик
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'carrier'?: string;
    /**
     * Имя устройства
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'deviceName'?: string;
    /**
     * Тип устройства
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'deviceTypeFull'?: string;
    /**
     * Время установки
     * @type {number}
     * @memberof PmpDeviceDto
     */
    'firstInstallTime'?: number;
    /**
     * IP
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'ipAddress'?: string;
    /**
     * Имя пакета установщика
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'installerPackageName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'macAddress'?: string;
    /**
     * Производитель
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'manufacturer'?: string;
    /**
     * Модель
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'model'?: string;
    /**
     * Название системы
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'systemName'?: string;
    /**
     * Версия системы
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'systemVersion'?: string;
    /**
     * Версия
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'version'?: string;
    /**
     * Операционная система
     * @type {string}
     * @memberof PmpDeviceDto
     */
    'baseOs'?: string;
}
/**
 * 
 * @export
 * @interface PmpDto
 */
export interface PmpDto {
    /**
     * 
     * @type {number}
     * @memberof PmpDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'fio'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'accountLifetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'lastLoginTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof PmpDto
     */
    'avatarContentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'timeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PmpDto
     */
    'region'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PmpDto
     */
    'linkedDevice'?: boolean;
    /**
     * 
     * @type {Array<PmpInBankDto>}
     * @memberof PmpDto
     */
    'pmpInBanks'?: Array<PmpInBankDto>;
    /**
     * Аватар ПМП
     * @type {string}
     * @memberof PmpDto
     */
    'avatar'?: string;
    /**
     * Отображать все карты, включая закрытые
     * @type {boolean}
     * @memberof PmpDto
     */
    'showAllCards'?: boolean;
}
/**
 * 
 * @export
 * @interface PmpInBankDto
 */
export interface PmpInBankDto {
    /**
     * 
     * @type {number}
     * @memberof PmpInBankDto
     */
    'personId'?: number;
}
/**
 * 
 * @export
 * @interface PublicKeyCredentialAssertionDto
 */
export interface PublicKeyCredentialAssertionDto {
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialAssertionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialAssertionDto
     */
    'rawId'?: string;
    /**
     * 
     * @type {AuthenticatorAssertionResponseDto}
     * @memberof PublicKeyCredentialAssertionDto
     */
    'response': AuthenticatorAssertionResponseDto;
    /**
     * 
     * @type {AuthenticatorAttachmentType}
     * @memberof PublicKeyCredentialAssertionDto
     */
    'authenticatorAttachment'?: AuthenticatorAttachmentType;
    /**
     * 
     * @type {ClientAssertionExtensionOutputsDto}
     * @memberof PublicKeyCredentialAssertionDto
     */
    'clientExtensionResults': ClientAssertionExtensionOutputsDto;
    /**
     * 
     * @type {PublicKeyCredentialType}
     * @memberof PublicKeyCredentialAssertionDto
     */
    'type'?: PublicKeyCredentialType;
}
/**
 * Параметры для генерации открытых ключей
 * @export
 * @interface PublicKeyCredentialCreationOptionsDto
 */
export interface PublicKeyCredentialCreationOptionsDto {
    /**
     * 
     * @type {RelyingPartyIdentityDto}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'rp': RelyingPartyIdentityDto;
    /**
     * 
     * @type {UserIdentityDto}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'user': UserIdentityDto;
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'challenge': string;
    /**
     * 
     * @type {Array<PublicKeyCredentialParametersDto>}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'pubKeyCredParams': Array<PublicKeyCredentialParametersDto>;
    /**
     * 
     * @type {number}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'timeout'?: number;
    /**
     * 
     * @type {Array<PublicKeyCredentialDescriptorDto>}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'excludeCredentials'?: Array<PublicKeyCredentialDescriptorDto>;
    /**
     * 
     * @type {AuthenticatorSelectionCriteriaDto}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'authenticatorSelection'?: AuthenticatorSelectionCriteriaDto;
    /**
     * 
     * @type {AttestationConveyancePreferenceType}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'attestation'?: AttestationConveyancePreferenceType;
    /**
     * 
     * @type {RegistrationExtensionInputsDto}
     * @memberof PublicKeyCredentialCreationOptionsDto
     */
    'extensions'?: RegistrationExtensionInputsDto;
}
/**
 * Описание ключей
 * @export
 * @interface PublicKeyCredentialDescriptorDto
 */
export interface PublicKeyCredentialDescriptorDto {
    /**
     * 
     * @type {PublicKeyCredentialType}
     * @memberof PublicKeyCredentialDescriptorDto
     */
    'type'?: PublicKeyCredentialType;
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialDescriptorDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<AuthenticatorTransportType>}
     * @memberof PublicKeyCredentialDescriptorDto
     */
    'transports'?: Array<AuthenticatorTransportType>;
}
/**
 * 
 * @export
 * @interface PublicKeyCredentialDto
 */
export interface PublicKeyCredentialDto {
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialDto
     */
    'rawId'?: string;
    /**
     * 
     * @type {AuthenticatorAttestationResponseDto}
     * @memberof PublicKeyCredentialDto
     */
    'response': AuthenticatorAttestationResponseDto;
    /**
     * 
     * @type {AuthenticatorAttachmentType}
     * @memberof PublicKeyCredentialDto
     */
    'authenticatorAttachment'?: AuthenticatorAttachmentType;
    /**
     * 
     * @type {ClientRegistrationExtensionOutputsDto}
     * @memberof PublicKeyCredentialDto
     */
    'clientExtensionResults': ClientRegistrationExtensionOutputsDto;
    /**
     * 
     * @type {PublicKeyCredentialType}
     * @memberof PublicKeyCredentialDto
     */
    'type'?: PublicKeyCredentialType;
}
/**
 * Параметры ключей
 * @export
 * @interface PublicKeyCredentialParametersDto
 */
export interface PublicKeyCredentialParametersDto {
    /**
     * 
     * @type {number}
     * @memberof PublicKeyCredentialParametersDto
     */
    'alg'?: number;
    /**
     * 
     * @type {PublicKeyCredentialType}
     * @memberof PublicKeyCredentialParametersDto
     */
    'type'?: PublicKeyCredentialType;
}
/**
 * 
 * @export
 * @interface PublicKeyCredentialRequestOptionsDto
 */
export interface PublicKeyCredentialRequestOptionsDto {
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialRequestOptionsDto
     */
    'challenge': string;
    /**
     * 
     * @type {number}
     * @memberof PublicKeyCredentialRequestOptionsDto
     */
    'timeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialRequestOptionsDto
     */
    'rpId'?: string;
    /**
     * 
     * @type {Array<PublicKeyCredentialDescriptorDto>}
     * @memberof PublicKeyCredentialRequestOptionsDto
     */
    'allowCredentials'?: Array<PublicKeyCredentialDescriptorDto>;
    /**
     * 
     * @type {UserVerificationRequirementType}
     * @memberof PublicKeyCredentialRequestOptionsDto
     */
    'userVerification'?: UserVerificationRequirementType;
    /**
     * 
     * @type {AssertionExtensionInputsDto}
     * @memberof PublicKeyCredentialRequestOptionsDto
     */
    'extensions': AssertionExtensionInputsDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PublicKeyCredentialType = {
    PublicKey: 'public-key'
} as const;

export type PublicKeyCredentialType = typeof PublicKeyCredentialType[keyof typeof PublicKeyCredentialType];


/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'appVersion'?: string;
    /**
     * Таймзона клиента https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'timeZone'?: string;
    /**
     * 
     * @type {RefreshType}
     * @memberof RefreshTokenRequest
     */
    'type'?: RefreshType;
}
/**
 * Тип обновления токена доступа
 * @export
 * @enum {string}
 */

export const RefreshType = {
    Internal: 'internal',
    Pin: 'pin',
    FaceId: 'face_id',
    FingerPrint: 'finger_print'
} as const;

export type RefreshType = typeof RefreshType[keyof typeof RefreshType];


/**
 * 
 * @export
 * @interface RegisterCvvV3200Response
 */
export interface RegisterCvvV3200Response {
    /**
     * 
     * @type {number}
     * @memberof RegisterCvvV3200Response
     */
    'remaining'?: number;
}
/**
 * 
 * @export
 * @interface RegisterFlow
 */
export interface RegisterFlow {
    /**
     * 
     * @type {string}
     * @memberof RegisterFlow
     */
    'registerToken'?: string;
}
/**
 * 
 * @export
 * @interface RegistrationExtensionInputsDto
 */
export interface RegistrationExtensionInputsDto {
    /**
     * 
     * @type {AppIdDto}
     * @memberof RegistrationExtensionInputsDto
     */
    'appidExclude'?: AppIdDto;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationExtensionInputsDto
     */
    'credProps'?: boolean;
    /**
     * 
     * @type {LargeBlobRegistrationInputDto}
     * @memberof RegistrationExtensionInputsDto
     */
    'largeBlob'?: LargeBlobRegistrationInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationExtensionInputsDto
     */
    'uvm'?: boolean;
}
/**
 * 
 * @export
 * @interface RegistrationRequest
 */
export interface RegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequest
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequest
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationRequest
     */
    'pan': string;
}
/**
 * 
 * @export
 * @interface RegistrationWebAuthnAddRequest
 */
export interface RegistrationWebAuthnAddRequest {
    /**
     * 
     * @type {string}
     * @memberof RegistrationWebAuthnAddRequest
     */
    'registrationAddToken'?: string;
}
/**
 * 
 * @export
 * @interface RegistrationWebAuthnConfirmRequest
 */
export interface RegistrationWebAuthnConfirmRequest {
    /**
     * Token на подтверждение регистрации и сохранения открытых ключей
     * @type {string}
     * @memberof RegistrationWebAuthnConfirmRequest
     */
    'registrationToken'?: string;
    /**
     * 
     * @type {PublicKeyCredentialDto}
     * @memberof RegistrationWebAuthnConfirmRequest
     */
    'credential'?: PublicKeyCredentialDto;
}
/**
 * 
 * @export
 * @interface RegistrationWebAuthnConfirmResponse
 */
export interface RegistrationWebAuthnConfirmResponse {
    /**
     * Сгенерированный token для восстановления или перезаписи открытых ключей
     * @type {string}
     * @memberof RegistrationWebAuthnConfirmResponse
     */
    'recoveryId'?: string;
    /**
     * Сгенерированный token для использования при авторизации
     * @type {string}
     * @memberof RegistrationWebAuthnConfirmResponse
     */
    'identityId'?: string;
}
/**
 * 
 * @export
 * @interface RegistrationWebAuthnRecoveryRequest
 */
export interface RegistrationWebAuthnRecoveryRequest {
    /**
     * 
     * @type {string}
     * @memberof RegistrationWebAuthnRecoveryRequest
     */
    'recoveryId'?: string;
}
/**
 * 
 * @export
 * @interface RegistrationWebAuthnResponse
 */
export interface RegistrationWebAuthnResponse {
    /**
     * Сгенерированный токен для подтверждения регистрации на добавление открытых ключей
     * @type {string}
     * @memberof RegistrationWebAuthnResponse
     */
    'registrationId'?: string;
    /**
     * 
     * @type {PublicKeyCredentialCreationOptionsDto}
     * @memberof RegistrationWebAuthnResponse
     */
    'publicKeyCredentialCreationOptions'?: PublicKeyCredentialCreationOptionsDto;
}
/**
 * 
 * @export
 * @interface RegistrationWebAuthnTokenRequest
 */
export interface RegistrationWebAuthnTokenRequest {
    /**
     * Идентификатор устройства
     * @type {string}
     * @memberof RegistrationWebAuthnTokenRequest
     */
    'deviceId': string;
}
/**
 * 
 * @export
 * @interface RegistrationWebAuthnTokenResponse
 */
export interface RegistrationWebAuthnTokenResponse {
    /**
     * Сгенерированный Registration Token для генерации параметров
     * @type {string}
     * @memberof RegistrationWebAuthnTokenResponse
     */
    'registrationAddToken'?: string;
}
/**
 * Идентификация сервера webauthn
 * @export
 * @interface RelyingPartyIdentityDto
 */
export interface RelyingPartyIdentityDto {
    /**
     * 
     * @type {string}
     * @memberof RelyingPartyIdentityDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RelyingPartyIdentityDto
     */
    'id': string;
}
/**
 * Тип требования к проверке обнаруженных учетных данных
 * @export
 * @enum {string}
 */

export const ResidentKeyRequirementType = {
    Discouraged: 'discouraged',
    Preferred: 'preferred',
    Required: 'required'
} as const;

export type ResidentKeyRequirementType = typeof ResidentKeyRequirementType[keyof typeof ResidentKeyRequirementType];


/**
 * 
 * @export
 * @interface SessionDto
 */
export interface SessionDto {
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionDto
     */
    'pmp'?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    'device'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    'refreshTokenId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDto
     */
    'sessionStartTime'?: string;
}
/**
 * 
 * @export
 * @interface ShowAllCardsResponse
 */
export interface ShowAllCardsResponse {
    /**
     * True - показывать все карты, False - показывать только активные карты
     * @type {boolean}
     * @memberof ShowAllCardsResponse
     */
    'all'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateShowAllCardsRequest
 */
export interface UpdateShowAllCardsRequest {
    /**
     * True - показывать все карты, False - показывать только активные карты
     * @type {boolean}
     * @memberof UpdateShowAllCardsRequest
     */
    'all': boolean;
}
/**
 * Идентификация пользователя
 * @export
 * @interface UserIdentityDto
 */
export interface UserIdentityDto {
    /**
     * 
     * @type {string}
     * @memberof UserIdentityDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentityDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UserUpdateEmailRequest
 */
export interface UserUpdateEmailRequest {
    /**
     * Почта
     * @type {string}
     * @memberof UserUpdateEmailRequest
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateLoginRequest
 */
export interface UserUpdateLoginRequest {
    /**
     * Новый логин пользователя
     * @type {string}
     * @memberof UserUpdateLoginRequest
     */
    'login'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdatePasswordRequest
 */
export interface UserUpdatePasswordRequest {
    /**
     * Текущий пароль пользователя
     * @type {string}
     * @memberof UserUpdatePasswordRequest
     */
    'password'?: string;
    /**
     * Новый пароль пользователя
     * @type {string}
     * @memberof UserUpdatePasswordRequest
     */
    'newPassword'?: string;
}
/**
 * Тип требования к проверке обнаруженных учетных данных
 * @export
 * @enum {string}
 */

export const UserVerificationRequirementType = {
    Discouraged: 'discouraged',
    Preferred: 'preferred',
    Required: 'required'
} as const;

export type UserVerificationRequirementType = typeof UserVerificationRequirementType[keyof typeof UserVerificationRequirementType];


/**
 * 
 * @export
 * @interface VersionInfoResponse
 */
export interface VersionInfoResponse {
    /**
     * id Компании
     * @type {string}
     * @memberof VersionInfoResponse
     */
    'companyId': string;
    /**
     * Время отправки запроса (2022-07-08T13:24:41.8328711+03:00). Не должно отличаться от текущего серверного времени более, чем на 60 секунд.
     * @type {string}
     * @memberof VersionInfoResponse
     */
    'timestamp': string;
    /**
     * RSA-подпись SHA-512 hash от строки, содержащей companyId и timestamp запроса. Алгоритм создания и проверки подписи SHA512withRSA
     * @type {string}
     * @memberof VersionInfoResponse
     */
    'signature': string;
}

/**
 * AuthorizationApi - axios parameter creator
 * @export
 */
export const AuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавление записи о неудачной попытке входа
         * @param {AddEnterRecordRequest} [addEnterRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterRecord: async (addEnterRecordRequest?: AddEnterRecordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/login/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addEnterRecordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение логина ПМП
         * @param {PMPLoginConfirmRequest} pMPLoginConfirmRequest Подтверждение кода по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginConfirm: async (pMPLoginConfirmRequest: PMPLoginConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPLoginConfirmRequest' is not null or undefined
            assertParamExists('loginConfirm', 'pMPLoginConfirmRequest', pMPLoginConfirmRequest)
            const localVarPath = `/v1/user/login/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPLoginConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Авторизация
         * @param {PMPLoginRequest} [pMPLoginRequest] Авторизация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRequest: async (pMPLoginRequest?: PMPLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {string} authorization Access token
         * @param {LogoutRequest} [logoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (authorization: string, logoutRequest?: LogoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('logout', 'authorization', authorization)
            const localVarPath = `/v1/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление токенов доступа
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refreshTokenRequest?: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавление записи о неудачной попытке входа
         * @param {AddEnterRecordRequest} [addEnterRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEnterRecord(addEnterRecordRequest?: AddEnterRecordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEnterRecord(addEnterRecordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтверждение логина ПМП
         * @param {PMPLoginConfirmRequest} pMPLoginConfirmRequest Подтверждение кода по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginConfirm(pMPLoginConfirmRequest: PMPLoginConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginConfirm(pMPLoginConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Авторизация
         * @param {PMPLoginRequest} [pMPLoginRequest] Авторизация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginRequest(pMPLoginRequest?: PMPLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PMPLogin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginRequest(pMPLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout
         * @param {string} authorization Access token
         * @param {LogoutRequest} [logoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(authorization: string, logoutRequest?: LogoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(authorization, logoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновление токенов доступа
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refreshTokenRequest?: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавление записи о неудачной попытке входа
         * @param {AddEnterRecordRequest} [addEnterRecordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEnterRecord(addEnterRecordRequest?: AddEnterRecordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addEnterRecord(addEnterRecordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтверждение логина ПМП
         * @param {PMPLoginConfirmRequest} pMPLoginConfirmRequest Подтверждение кода по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginConfirm(pMPLoginConfirmRequest: PMPLoginConfirmRequest, options?: any): AxiosPromise<Auth> {
            return localVarFp.loginConfirm(pMPLoginConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Авторизация
         * @param {PMPLoginRequest} [pMPLoginRequest] Авторизация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRequest(pMPLoginRequest?: PMPLoginRequest, options?: any): AxiosPromise<PMPLogin> {
            return localVarFp.loginRequest(pMPLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout
         * @param {string} authorization Access token
         * @param {LogoutRequest} [logoutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(authorization: string, logoutRequest?: LogoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.logout(authorization, logoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление токенов доступа
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refreshTokenRequest?: RefreshTokenRequest, options?: any): AxiosPromise<Auth> {
            return localVarFp.refreshToken(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * 
     * @summary Добавление записи о неудачной попытке входа
     * @param {AddEnterRecordRequest} [addEnterRecordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public addEnterRecord(addEnterRecordRequest?: AddEnterRecordRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).addEnterRecord(addEnterRecordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтверждение логина ПМП
     * @param {PMPLoginConfirmRequest} pMPLoginConfirmRequest Подтверждение кода по смс
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public loginConfirm(pMPLoginConfirmRequest: PMPLoginConfirmRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).loginConfirm(pMPLoginConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Авторизация
     * @param {PMPLoginRequest} [pMPLoginRequest] Авторизация пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public loginRequest(pMPLoginRequest?: PMPLoginRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).loginRequest(pMPLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout
     * @param {string} authorization Access token
     * @param {LogoutRequest} [logoutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public logout(authorization: string, logoutRequest?: LogoutRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).logout(authorization, logoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновление токенов доступа
     * @param {RefreshTokenRequest} [refreshTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public refreshToken(refreshTokenRequest?: RefreshTokenRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).refreshToken(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthorizationWebApi - axios parameter creator
 * @export
 */
export const AuthorizationWebApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Проверка возможности входа с PIN по ID устройства
         * @param {PMPLoginCheckPinAllowedWebRequest} pMPLoginCheckPinAllowedWebRequest Проверка возможности входа с PIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPinAllowed: async (pMPLoginCheckPinAllowedWebRequest: PMPLoginCheckPinAllowedWebRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPLoginCheckPinAllowedWebRequest' is not null or undefined
            assertParamExists('checkPinAllowed', 'pMPLoginCheckPinAllowedWebRequest', pMPLoginCheckPinAllowedWebRequest)
            const localVarPath = `/v1/web/user/pin/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPLoginCheckPinAllowedWebRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание PIN для входа через WEB
         * @param {string} authorization Access token
         * @param {PMPLoginCreatePinWebRequest} [pMPLoginCreatePinWebRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPin: async (authorization: string, pMPLoginCreatePinWebRequest?: PMPLoginCreatePinWebRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createPin', 'authorization', authorization)
            const localVarPath = `/v1/web/user/pin/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPLoginCreatePinWebRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение логина ПМП
         * @param {PMPLoginConfirmWebRequest} pMPLoginConfirmWebRequest Подтверждение кода по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginConfirmWeb: async (pMPLoginConfirmWebRequest: PMPLoginConfirmWebRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPLoginConfirmWebRequest' is not null or undefined
            assertParamExists('loginConfirmWeb', 'pMPLoginConfirmWebRequest', pMPLoginConfirmWebRequest)
            const localVarPath = `/v1/web/user/login/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPLoginConfirmWebRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Вход в WEB через PIN
         * @param {PMPLoginPinWebRequest} pMPLoginPinWebRequest Вход в WEB через PIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPinWeb: async (pMPLoginPinWebRequest: PMPLoginPinWebRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPLoginPinWebRequest' is not null or undefined
            assertParamExists('loginPinWeb', 'pMPLoginPinWebRequest', pMPLoginPinWebRequest)
            const localVarPath = `/v1/web/user/pin/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPLoginPinWebRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationWebApi - functional programming interface
 * @export
 */
export const AuthorizationWebApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationWebApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Проверка возможности входа с PIN по ID устройства
         * @param {PMPLoginCheckPinAllowedWebRequest} pMPLoginCheckPinAllowedWebRequest Проверка возможности входа с PIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPinAllowed(pMPLoginCheckPinAllowedWebRequest: PMPLoginCheckPinAllowedWebRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckPinAllowedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPinAllowed(pMPLoginCheckPinAllowedWebRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание PIN для входа через WEB
         * @param {string} authorization Access token
         * @param {PMPLoginCreatePinWebRequest} [pMPLoginCreatePinWebRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPin(authorization: string, pMPLoginCreatePinWebRequest?: PMPLoginCreatePinWebRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPin(authorization, pMPLoginCreatePinWebRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтверждение логина ПМП
         * @param {PMPLoginConfirmWebRequest} pMPLoginConfirmWebRequest Подтверждение кода по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginConfirmWeb(pMPLoginConfirmWebRequest: PMPLoginConfirmWebRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginConfirmWeb(pMPLoginConfirmWebRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Вход в WEB через PIN
         * @param {PMPLoginPinWebRequest} pMPLoginPinWebRequest Вход в WEB через PIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginPinWeb(pMPLoginPinWebRequest: PMPLoginPinWebRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginPinWeb(pMPLoginPinWebRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizationWebApi - factory interface
 * @export
 */
export const AuthorizationWebApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationWebApiFp(configuration)
    return {
        /**
         * 
         * @summary Проверка возможности входа с PIN по ID устройства
         * @param {PMPLoginCheckPinAllowedWebRequest} pMPLoginCheckPinAllowedWebRequest Проверка возможности входа с PIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPinAllowed(pMPLoginCheckPinAllowedWebRequest: PMPLoginCheckPinAllowedWebRequest, options?: any): AxiosPromise<CheckPinAllowedResponse> {
            return localVarFp.checkPinAllowed(pMPLoginCheckPinAllowedWebRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание PIN для входа через WEB
         * @param {string} authorization Access token
         * @param {PMPLoginCreatePinWebRequest} [pMPLoginCreatePinWebRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPin(authorization: string, pMPLoginCreatePinWebRequest?: PMPLoginCreatePinWebRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createPin(authorization, pMPLoginCreatePinWebRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтверждение логина ПМП
         * @param {PMPLoginConfirmWebRequest} pMPLoginConfirmWebRequest Подтверждение кода по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginConfirmWeb(pMPLoginConfirmWebRequest: PMPLoginConfirmWebRequest, options?: any): AxiosPromise<Auth> {
            return localVarFp.loginConfirmWeb(pMPLoginConfirmWebRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Вход в WEB через PIN
         * @param {PMPLoginPinWebRequest} pMPLoginPinWebRequest Вход в WEB через PIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPinWeb(pMPLoginPinWebRequest: PMPLoginPinWebRequest, options?: any): AxiosPromise<Auth> {
            return localVarFp.loginPinWeb(pMPLoginPinWebRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationWebApi - object-oriented interface
 * @export
 * @class AuthorizationWebApi
 * @extends {BaseAPI}
 */
export class AuthorizationWebApi extends BaseAPI {
    /**
     * 
     * @summary Проверка возможности входа с PIN по ID устройства
     * @param {PMPLoginCheckPinAllowedWebRequest} pMPLoginCheckPinAllowedWebRequest Проверка возможности входа с PIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationWebApi
     */
    public checkPinAllowed(pMPLoginCheckPinAllowedWebRequest: PMPLoginCheckPinAllowedWebRequest, options?: AxiosRequestConfig) {
        return AuthorizationWebApiFp(this.configuration).checkPinAllowed(pMPLoginCheckPinAllowedWebRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание PIN для входа через WEB
     * @param {string} authorization Access token
     * @param {PMPLoginCreatePinWebRequest} [pMPLoginCreatePinWebRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationWebApi
     */
    public createPin(authorization: string, pMPLoginCreatePinWebRequest?: PMPLoginCreatePinWebRequest, options?: AxiosRequestConfig) {
        return AuthorizationWebApiFp(this.configuration).createPin(authorization, pMPLoginCreatePinWebRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтверждение логина ПМП
     * @param {PMPLoginConfirmWebRequest} pMPLoginConfirmWebRequest Подтверждение кода по смс
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationWebApi
     */
    public loginConfirmWeb(pMPLoginConfirmWebRequest: PMPLoginConfirmWebRequest, options?: AxiosRequestConfig) {
        return AuthorizationWebApiFp(this.configuration).loginConfirmWeb(pMPLoginConfirmWebRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Вход в WEB через PIN
     * @param {PMPLoginPinWebRequest} pMPLoginPinWebRequest Вход в WEB через PIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationWebApi
     */
    public loginPinWeb(pMPLoginPinWebRequest: PMPLoginPinWebRequest, options?: AxiosRequestConfig) {
        return AuthorizationWebApiFp(this.configuration).loginPinWeb(pMPLoginPinWebRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthorizationWebAuthnApi - axios parameter creator
 * @export
 */
export const AuthorizationWebAuthnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Проверка наличия зарегистрированных открытых ключей
         * @param {AuthorizationWebAuthnCheckRequest} authorizationWebAuthnCheckRequest Проверка наличия зарегистрированных открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCheckWebAuthn: async (authorizationWebAuthnCheckRequest: AuthorizationWebAuthnCheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizationWebAuthnCheckRequest' is not null or undefined
            assertParamExists('loginCheckWebAuthn', 'authorizationWebAuthnCheckRequest', authorizationWebAuthnCheckRequest)
            const localVarPath = `/v1/webauthn/login/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizationWebAuthnCheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение пользователя по открытым ключам
         * @param {AuthorizationWebAuthnConfirmRequest} authorizationWebAuthnConfirmRequest Проверка подписанной кодовой фразы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginConfirmWebAuthn: async (authorizationWebAuthnConfirmRequest: AuthorizationWebAuthnConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizationWebAuthnConfirmRequest' is not null or undefined
            assertParamExists('loginConfirmWebAuthn', 'authorizationWebAuthnConfirmRequest', authorizationWebAuthnConfirmRequest)
            const localVarPath = `/v1/webauthn/login/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizationWebAuthnConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аутентификация по открытым ключам
         * @param {AuthorizationWebAuthnRequest} authorizationWebAuthnRequest Аутентификация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWebAuthn: async (authorizationWebAuthnRequest: AuthorizationWebAuthnRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizationWebAuthnRequest' is not null or undefined
            assertParamExists('loginWebAuthn', 'authorizationWebAuthnRequest', authorizationWebAuthnRequest)
            const localVarPath = `/v1/webauthn/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizationWebAuthnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationWebAuthnApi - functional programming interface
 * @export
 */
export const AuthorizationWebAuthnApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationWebAuthnApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Проверка наличия зарегистрированных открытых ключей
         * @param {AuthorizationWebAuthnCheckRequest} authorizationWebAuthnCheckRequest Проверка наличия зарегистрированных открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginCheckWebAuthn(authorizationWebAuthnCheckRequest: AuthorizationWebAuthnCheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationWebAuthnCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginCheckWebAuthn(authorizationWebAuthnCheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтверждение пользователя по открытым ключам
         * @param {AuthorizationWebAuthnConfirmRequest} authorizationWebAuthnConfirmRequest Проверка подписанной кодовой фразы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginConfirmWebAuthn(authorizationWebAuthnConfirmRequest: AuthorizationWebAuthnConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginConfirmWebAuthn(authorizationWebAuthnConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аутентификация по открытым ключам
         * @param {AuthorizationWebAuthnRequest} authorizationWebAuthnRequest Аутентификация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWebAuthn(authorizationWebAuthnRequest: AuthorizationWebAuthnRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationWebAuthnResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWebAuthn(authorizationWebAuthnRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizationWebAuthnApi - factory interface
 * @export
 */
export const AuthorizationWebAuthnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationWebAuthnApiFp(configuration)
    return {
        /**
         * 
         * @summary Проверка наличия зарегистрированных открытых ключей
         * @param {AuthorizationWebAuthnCheckRequest} authorizationWebAuthnCheckRequest Проверка наличия зарегистрированных открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCheckWebAuthn(authorizationWebAuthnCheckRequest: AuthorizationWebAuthnCheckRequest, options?: any): AxiosPromise<AuthorizationWebAuthnCheckResponse> {
            return localVarFp.loginCheckWebAuthn(authorizationWebAuthnCheckRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтверждение пользователя по открытым ключам
         * @param {AuthorizationWebAuthnConfirmRequest} authorizationWebAuthnConfirmRequest Проверка подписанной кодовой фразы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginConfirmWebAuthn(authorizationWebAuthnConfirmRequest: AuthorizationWebAuthnConfirmRequest, options?: any): AxiosPromise<Auth> {
            return localVarFp.loginConfirmWebAuthn(authorizationWebAuthnConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аутентификация по открытым ключам
         * @param {AuthorizationWebAuthnRequest} authorizationWebAuthnRequest Аутентификация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWebAuthn(authorizationWebAuthnRequest: AuthorizationWebAuthnRequest, options?: any): AxiosPromise<AuthorizationWebAuthnResponse> {
            return localVarFp.loginWebAuthn(authorizationWebAuthnRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationWebAuthnApi - object-oriented interface
 * @export
 * @class AuthorizationWebAuthnApi
 * @extends {BaseAPI}
 */
export class AuthorizationWebAuthnApi extends BaseAPI {
    /**
     * 
     * @summary Проверка наличия зарегистрированных открытых ключей
     * @param {AuthorizationWebAuthnCheckRequest} authorizationWebAuthnCheckRequest Проверка наличия зарегистрированных открытых ключей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationWebAuthnApi
     */
    public loginCheckWebAuthn(authorizationWebAuthnCheckRequest: AuthorizationWebAuthnCheckRequest, options?: AxiosRequestConfig) {
        return AuthorizationWebAuthnApiFp(this.configuration).loginCheckWebAuthn(authorizationWebAuthnCheckRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтверждение пользователя по открытым ключам
     * @param {AuthorizationWebAuthnConfirmRequest} authorizationWebAuthnConfirmRequest Проверка подписанной кодовой фразы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationWebAuthnApi
     */
    public loginConfirmWebAuthn(authorizationWebAuthnConfirmRequest: AuthorizationWebAuthnConfirmRequest, options?: AxiosRequestConfig) {
        return AuthorizationWebAuthnApiFp(this.configuration).loginConfirmWebAuthn(authorizationWebAuthnConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аутентификация по открытым ключам
     * @param {AuthorizationWebAuthnRequest} authorizationWebAuthnRequest Аутентификация пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationWebAuthnApi
     */
    public loginWebAuthn(authorizationWebAuthnRequest: AuthorizationWebAuthnRequest, options?: AxiosRequestConfig) {
        return AuthorizationWebAuthnApiFp(this.configuration).loginWebAuthn(authorizationWebAuthnRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChangePinWebApi - axios parameter creator
 * @export
 */
export const ChangePinWebApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание нового PIN, изменение старого PIN
         * @param {string} authorization Access Token
         * @param {ChangePinWebConfirmRequest} [changePinWebConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePin: async (authorization: string, changePinWebConfirmRequest?: ChangePinWebConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('changePin', 'authorization', authorization)
            const localVarPath = `/v1/web/user/pin/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePinWebConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверка старого PIN для последующего изменения PIN
         * @param {string} authorization Access Token
         * @param {ChangePinWebVerifyRequest} [changePinWebVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPin: async (authorization: string, changePinWebVerifyRequest?: ChangePinWebVerifyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('verifyPin', 'authorization', authorization)
            const localVarPath = `/v1/web/user/pin/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePinWebVerifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChangePinWebApi - functional programming interface
 * @export
 */
export const ChangePinWebApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChangePinWebApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание нового PIN, изменение старого PIN
         * @param {string} authorization Access Token
         * @param {ChangePinWebConfirmRequest} [changePinWebConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePin(authorization: string, changePinWebConfirmRequest?: ChangePinWebConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePin(authorization, changePinWebConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверка старого PIN для последующего изменения PIN
         * @param {string} authorization Access Token
         * @param {ChangePinWebVerifyRequest} [changePinWebVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyPin(authorization: string, changePinWebVerifyRequest?: ChangePinWebVerifyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePinWebVerifyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPin(authorization, changePinWebVerifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChangePinWebApi - factory interface
 * @export
 */
export const ChangePinWebApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChangePinWebApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание нового PIN, изменение старого PIN
         * @param {string} authorization Access Token
         * @param {ChangePinWebConfirmRequest} [changePinWebConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePin(authorization: string, changePinWebConfirmRequest?: ChangePinWebConfirmRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changePin(authorization, changePinWebConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверка старого PIN для последующего изменения PIN
         * @param {string} authorization Access Token
         * @param {ChangePinWebVerifyRequest} [changePinWebVerifyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyPin(authorization: string, changePinWebVerifyRequest?: ChangePinWebVerifyRequest, options?: any): AxiosPromise<ChangePinWebVerifyResponse> {
            return localVarFp.verifyPin(authorization, changePinWebVerifyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChangePinWebApi - object-oriented interface
 * @export
 * @class ChangePinWebApi
 * @extends {BaseAPI}
 */
export class ChangePinWebApi extends BaseAPI {
    /**
     * 
     * @summary Создание нового PIN, изменение старого PIN
     * @param {string} authorization Access Token
     * @param {ChangePinWebConfirmRequest} [changePinWebConfirmRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangePinWebApi
     */
    public changePin(authorization: string, changePinWebConfirmRequest?: ChangePinWebConfirmRequest, options?: AxiosRequestConfig) {
        return ChangePinWebApiFp(this.configuration).changePin(authorization, changePinWebConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверка старого PIN для последующего изменения PIN
     * @param {string} authorization Access Token
     * @param {ChangePinWebVerifyRequest} [changePinWebVerifyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChangePinWebApi
     */
    public verifyPin(authorization: string, changePinWebVerifyRequest?: ChangePinWebVerifyRequest, options?: AxiosRequestConfig) {
        return ChangePinWebApiFp(this.configuration).verifyPin(authorization, changePinWebVerifyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Список стран по номерам которых доступна регистрация в системе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableCountries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Список стран по номерам которых доступна регистрация в системе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableCountries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @summary Список стран по номерам которых доступна регистрация в системе.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableCountries(options?: any): AxiosPromise<Array<CountryResponse>> {
            return localVarFp.getAvailableCountries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @summary Список стран по номерам которых доступна регистрация в системе.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public getAvailableCountries(options?: AxiosRequestConfig) {
        return CountryApiFp(this.configuration).getAvailableCountries(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CurrentUserApi - axios parameter creator
 * @export
 */
export const CurrentUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Проверка старого пароля пользователя
         * @param {string} authorization 
         * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOldPassword: async (authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('checkOldPassword', 'authorization', authorization)
            // verify required parameter 'userUpdatePasswordRequest' is not null or undefined
            assertParamExists('checkOldPassword', 'userUpdatePasswordRequest', userUpdatePasswordRequest)
            const localVarPath = `/v1/user/check/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Функция сбрасывает аватар пользователя 
         * @summary Удаление аватара пользователя
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteAvatar', 'authorization', authorization)
            const localVarPath = `/v1/user/avatar/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary auth counter
         * @param {number} current 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCounter: async (current: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'current' is not null or undefined
            assertParamExists('getAuthCounter', 'current', current)
            const localVarPath = `/v1/user/{current}/counter`
                .replace(`{${"current"}}`, encodeURIComponent(String(current)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных о пользователе
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPmpInfo: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getPmpInfo', 'authorization', authorization)
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Отправка подтверждения на смену email на бэке
         * @summary Обновление email пользователя
         * @param {string} authorization 
         * @param {UserUpdateEmailRequest} userUpdateEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail: async (authorization: string, userUpdateEmailRequest: UserUpdateEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateEmail', 'authorization', authorization)
            // verify required parameter 'userUpdateEmailRequest' is not null or undefined
            assertParamExists('updateEmail', 'userUpdateEmailRequest', userUpdateEmailRequest)
            const localVarPath = `/v1/user/update/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление привязанного устройства
         * @param {string} authorization 
         * @param {LinkedDeviceRequest} [linkedDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkedDevice: async (authorization: string, linkedDeviceRequest?: LinkedDeviceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateLinkedDevice', 'authorization', authorization)
            const localVarPath = `/v1/user/update/linked_device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkedDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * User login update
         * @summary Обновление данных о логине пользователя
         * @param {string} authorization 
         * @param {UserUpdateLoginRequest} userUpdateLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogin: async (authorization: string, userUpdateLoginRequest: UserUpdateLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateLogin', 'authorization', authorization)
            // verify required parameter 'userUpdateLoginRequest' is not null or undefined
            assertParamExists('updateLogin', 'userUpdateLoginRequest', userUpdateLoginRequest)
            const localVarPath = `/v1/user/update/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновление данных о пароле пользователя
         * @param {string} authorization 
         * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updatePassword', 'authorization', authorization)
            // verify required parameter 'userUpdatePasswordRequest' is not null or undefined
            assertParamExists('updatePassword', 'userUpdatePasswordRequest', userUpdatePasswordRequest)
            const localVarPath = `/v1/user/update/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Показывать все карты или только активные
         * @param {string} authorization 
         * @param {UpdateShowAllCardsRequest} [updateShowAllCardsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShowAllCards: async (authorization: string, updateShowAllCardsRequest?: UpdateShowAllCardsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateShowAllCards', 'authorization', authorization)
            const localVarPath = `/v1/user/update/show-all-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateShowAllCardsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Функция устанавливает переданное изображение, как аватар пользователя 
         * @summary Загрузка аватара пользователя
         * @param {string} authorization 
         * @param {AvatarLoadRequest} avatarLoadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUserAvatar: async (authorization: string, avatarLoadRequest: AvatarLoadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('uploadUserAvatar', 'authorization', authorization)
            // verify required parameter 'avatarLoadRequest' is not null or undefined
            assertParamExists('uploadUserAvatar', 'avatarLoadRequest', avatarLoadRequest)
            const localVarPath = `/v1/user/avatar/load`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(avatarLoadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentUserApi - functional programming interface
 * @export
 */
export const CurrentUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Проверка старого пароля пользователя
         * @param {string} authorization 
         * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkOldPassword(authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkOldPassword(authorization, userUpdatePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Функция сбрасывает аватар пользователя 
         * @summary Удаление аватара пользователя
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAvatar(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAvatar(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTestUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTestUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary auth counter
         * @param {number} current 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthCounter(current: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthCounter(current, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение данных о пользователе
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPmpInfo(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmpDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPmpInfo(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Отправка подтверждения на смену email на бэке
         * @summary Обновление email пользователя
         * @param {string} authorization 
         * @param {UserUpdateEmailRequest} userUpdateEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmail(authorization: string, userUpdateEmailRequest: UserUpdateEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmpDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmail(authorization, userUpdateEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновление привязанного устройства
         * @param {string} authorization 
         * @param {LinkedDeviceRequest} [linkedDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLinkedDevice(authorization: string, linkedDeviceRequest?: LinkedDeviceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLinkedDevice(authorization, linkedDeviceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * User login update
         * @summary Обновление данных о логине пользователя
         * @param {string} authorization 
         * @param {UserUpdateLoginRequest} userUpdateLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLogin(authorization: string, userUpdateLoginRequest: UserUpdateLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmpDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogin(authorization, userUpdateLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновление данных о пароле пользователя
         * @param {string} authorization 
         * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmpDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(authorization, userUpdatePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Показывать все карты или только активные
         * @param {string} authorization 
         * @param {UpdateShowAllCardsRequest} [updateShowAllCardsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShowAllCards(authorization: string, updateShowAllCardsRequest?: UpdateShowAllCardsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowAllCardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShowAllCards(authorization, updateShowAllCardsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Функция устанавливает переданное изображение, как аватар пользователя 
         * @summary Загрузка аватара пользователя
         * @param {string} authorization 
         * @param {AvatarLoadRequest} avatarLoadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUserAvatar(authorization: string, avatarLoadRequest: AvatarLoadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PmpDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUserAvatar(authorization, avatarLoadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CurrentUserApi - factory interface
 * @export
 */
export const CurrentUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Проверка старого пароля пользователя
         * @param {string} authorization 
         * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkOldPassword(authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options?: any): AxiosPromise<BooleanCheckResponse> {
            return localVarFp.checkOldPassword(authorization, userUpdatePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Функция сбрасывает аватар пользователя 
         * @summary Удаление аватара пользователя
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAvatar(authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAvatar(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTestUser(options?: any): AxiosPromise<void> {
            return localVarFp.deleteTestUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary auth counter
         * @param {number} current 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCounter(current: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAuthCounter(current, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение данных о пользователе
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPmpInfo(authorization: string, options?: any): AxiosPromise<PmpDto> {
            return localVarFp.getPmpInfo(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Отправка подтверждения на смену email на бэке
         * @summary Обновление email пользователя
         * @param {string} authorization 
         * @param {UserUpdateEmailRequest} userUpdateEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(authorization: string, userUpdateEmailRequest: UserUpdateEmailRequest, options?: any): AxiosPromise<PmpDto> {
            return localVarFp.updateEmail(authorization, userUpdateEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление привязанного устройства
         * @param {string} authorization 
         * @param {LinkedDeviceRequest} [linkedDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkedDevice(authorization: string, linkedDeviceRequest?: LinkedDeviceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateLinkedDevice(authorization, linkedDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * User login update
         * @summary Обновление данных о логине пользователя
         * @param {string} authorization 
         * @param {UserUpdateLoginRequest} userUpdateLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogin(authorization: string, userUpdateLoginRequest: UserUpdateLoginRequest, options?: any): AxiosPromise<PmpDto> {
            return localVarFp.updateLogin(authorization, userUpdateLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновление данных о пароле пользователя
         * @param {string} authorization 
         * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options?: any): AxiosPromise<PmpDto> {
            return localVarFp.updatePassword(authorization, userUpdatePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Показывать все карты или только активные
         * @param {string} authorization 
         * @param {UpdateShowAllCardsRequest} [updateShowAllCardsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShowAllCards(authorization: string, updateShowAllCardsRequest?: UpdateShowAllCardsRequest, options?: any): AxiosPromise<ShowAllCardsResponse> {
            return localVarFp.updateShowAllCards(authorization, updateShowAllCardsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Функция устанавливает переданное изображение, как аватар пользователя 
         * @summary Загрузка аватара пользователя
         * @param {string} authorization 
         * @param {AvatarLoadRequest} avatarLoadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUserAvatar(authorization: string, avatarLoadRequest: AvatarLoadRequest, options?: any): AxiosPromise<PmpDto> {
            return localVarFp.uploadUserAvatar(authorization, avatarLoadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentUserApi - object-oriented interface
 * @export
 * @class CurrentUserApi
 * @extends {BaseAPI}
 */
export class CurrentUserApi extends BaseAPI {
    /**
     * 
     * @summary Проверка старого пароля пользователя
     * @param {string} authorization 
     * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public checkOldPassword(authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).checkOldPassword(authorization, userUpdatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Функция сбрасывает аватар пользователя 
     * @summary Удаление аватара пользователя
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public deleteAvatar(authorization: string, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).deleteAvatar(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public deleteTestUser(options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).deleteTestUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary auth counter
     * @param {number} current 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public getAuthCounter(current: number, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).getAuthCounter(current, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение данных о пользователе
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public getPmpInfo(authorization: string, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).getPmpInfo(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Отправка подтверждения на смену email на бэке
     * @summary Обновление email пользователя
     * @param {string} authorization 
     * @param {UserUpdateEmailRequest} userUpdateEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updateEmail(authorization: string, userUpdateEmailRequest: UserUpdateEmailRequest, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).updateEmail(authorization, userUpdateEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновление привязанного устройства
     * @param {string} authorization 
     * @param {LinkedDeviceRequest} [linkedDeviceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updateLinkedDevice(authorization: string, linkedDeviceRequest?: LinkedDeviceRequest, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).updateLinkedDevice(authorization, linkedDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * User login update
     * @summary Обновление данных о логине пользователя
     * @param {string} authorization 
     * @param {UserUpdateLoginRequest} userUpdateLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updateLogin(authorization: string, userUpdateLoginRequest: UserUpdateLoginRequest, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).updateLogin(authorization, userUpdateLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновление данных о пароле пользователя
     * @param {string} authorization 
     * @param {UserUpdatePasswordRequest} userUpdatePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updatePassword(authorization: string, userUpdatePasswordRequest: UserUpdatePasswordRequest, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).updatePassword(authorization, userUpdatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Показывать все карты или только активные
     * @param {string} authorization 
     * @param {UpdateShowAllCardsRequest} [updateShowAllCardsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public updateShowAllCards(authorization: string, updateShowAllCardsRequest?: UpdateShowAllCardsRequest, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).updateShowAllCards(authorization, updateShowAllCardsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Функция устанавливает переданное изображение, как аватар пользователя 
     * @summary Загрузка аватара пользователя
     * @param {string} authorization 
     * @param {AvatarLoadRequest} avatarLoadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentUserApi
     */
    public uploadUserAvatar(authorization: string, avatarLoadRequest: AvatarLoadRequest, options?: AxiosRequestConfig) {
        return CurrentUserApiFp(this.configuration).uploadUserAvatar(authorization, avatarLoadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PasswordResetApi - axios parameter creator
 * @export
 */
export const PasswordResetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Проверка cvv кода
         * @param {PasswordCvvRequest} passwordCvvRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCvv: async (passwordCvvRequest: PasswordCvvRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordCvvRequest' is not null or undefined
            assertParamExists('checkCvv', 'passwordCvvRequest', passwordCvvRequest)
            const localVarPath = `/v1/user/password/cvv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordCvvRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordCheckRequest} passwordCheckRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPassword: async (passwordCheckRequest: PasswordCheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordCheckRequest' is not null or undefined
            assertParamExists('checkPassword', 'passwordCheckRequest', passwordCheckRequest)
            const localVarPath = `/v1/user/password/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordCheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordConfirmRequest} passwordConfirmRequest Подтверждение пароля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPassword: async (passwordConfirmRequest: PasswordConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordConfirmRequest' is not null or undefined
            assertParamExists('confirmPassword', 'passwordConfirmRequest', passwordConfirmRequest)
            const localVarPath = `/v1/user/password/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordResetRequest} passwordResetRequest Восстановление пароля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (passwordResetRequest: PasswordResetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('resetPassword', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/v1/user/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResetApi - functional programming interface
 * @export
 */
export const PasswordResetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordResetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Проверка cvv кода
         * @param {PasswordCvvRequest} passwordCvvRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCvv(passwordCvvRequest: PasswordCvvRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordConfirmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCvv(passwordCvvRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordCheckRequest} passwordCheckRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPassword(passwordCheckRequest: PasswordCheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordConfirmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPassword(passwordCheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordConfirmRequest} passwordConfirmRequest Подтверждение пароля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmPassword(passwordConfirmRequest: PasswordConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmPassword(passwordConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordResetRequest} passwordResetRequest Восстановление пароля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(passwordResetRequest: PasswordResetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(passwordResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordResetApi - factory interface
 * @export
 */
export const PasswordResetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordResetApiFp(configuration)
    return {
        /**
         * 
         * @summary Проверка cvv кода
         * @param {PasswordCvvRequest} passwordCvvRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCvv(passwordCvvRequest: PasswordCvvRequest, options?: any): AxiosPromise<PasswordConfirmResponse> {
            return localVarFp.checkCvv(passwordCvvRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordCheckRequest} passwordCheckRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPassword(passwordCheckRequest: PasswordCheckRequest, options?: any): AxiosPromise<PasswordConfirmResponse> {
            return localVarFp.checkPassword(passwordCheckRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordConfirmRequest} passwordConfirmRequest Подтверждение пароля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPassword(passwordConfirmRequest: PasswordConfirmRequest, options?: any): AxiosPromise<void> {
            return localVarFp.confirmPassword(passwordConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сброс пароля пользователя
         * @param {PasswordResetRequest} passwordResetRequest Восстановление пароля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<PasswordResetResponse> {
            return localVarFp.resetPassword(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordResetApi - object-oriented interface
 * @export
 * @class PasswordResetApi
 * @extends {BaseAPI}
 */
export class PasswordResetApi extends BaseAPI {
    /**
     * 
     * @summary Проверка cvv кода
     * @param {PasswordCvvRequest} passwordCvvRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public checkCvv(passwordCvvRequest: PasswordCvvRequest, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).checkCvv(passwordCvvRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сброс пароля пользователя
     * @param {PasswordCheckRequest} passwordCheckRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public checkPassword(passwordCheckRequest: PasswordCheckRequest, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).checkPassword(passwordCheckRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сброс пароля пользователя
     * @param {PasswordConfirmRequest} passwordConfirmRequest Подтверждение пароля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public confirmPassword(passwordConfirmRequest: PasswordConfirmRequest, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).confirmPassword(passwordConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сброс пароля пользователя
     * @param {PasswordResetRequest} passwordResetRequest Восстановление пароля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public resetPassword(passwordResetRequest: PasswordResetRequest, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).resetPassword(passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistrationV3Api - axios parameter creator
 * @export
 */
export const RegistrationV3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Проверка логина при регистрации ПМП
         * @param {PMPRegisterCheckLoginRequest} pMPRegisterCheckLoginRequest Проверка логина при регистрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCheckLoginV3: async (pMPRegisterCheckLoginRequest: PMPRegisterCheckLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPRegisterCheckLoginRequest' is not null or undefined
            assertParamExists('registerCheckLoginV3', 'pMPRegisterCheckLoginRequest', pMPRegisterCheckLoginRequest)
            const localVarPath = `/v3/user/register/check/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPRegisterCheckLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение номера телефона ПМП
         * @param {PMPRegisterCheckRequest} pMPRegisterCheckRequest Проверка мобильного телефона по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCheckV3: async (pMPRegisterCheckRequest: PMPRegisterCheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPRegisterCheckRequest' is not null or undefined
            assertParamExists('registerCheckV3', 'pMPRegisterCheckRequest', pMPRegisterCheckRequest)
            const localVarPath = `/v3/user/register/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPRegisterCheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Подтверждение регистрации ПМП
         * @param {PMPRegisterConfirmRequest} pMPRegisterConfirmRequest Подтверждение регистрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerConfirmV3: async (pMPRegisterConfirmRequest: PMPRegisterConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPRegisterConfirmRequest' is not null or undefined
            assertParamExists('registerConfirmV3', 'pMPRegisterConfirmRequest', pMPRegisterConfirmRequest)
            const localVarPath = `/v3/user/register/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPRegisterConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PMPRegisterCheckCvvRequest} [pMPRegisterCheckCvvRequest] Проверка cvv кода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCvvV3: async (pMPRegisterCheckCvvRequest?: PMPRegisterCheckCvvRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v3/user/register/cvv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPRegisterCheckCvvRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Регистрация ПМП
         * @param {PMPRegisterRequestV3} pMPRegisterRequestV3 Регистрация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerV3: async (pMPRegisterRequestV3: PMPRegisterRequestV3, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pMPRegisterRequestV3' is not null or undefined
            assertParamExists('registerV3', 'pMPRegisterRequestV3', pMPRegisterRequestV3)
            const localVarPath = `/v3/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pMPRegisterRequestV3, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationV3Api - functional programming interface
 * @export
 */
export const RegistrationV3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationV3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Проверка логина при регистрации ПМП
         * @param {PMPRegisterCheckLoginRequest} pMPRegisterCheckLoginRequest Проверка логина при регистрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCheckLoginV3(pMPRegisterCheckLoginRequest: PMPRegisterCheckLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCheckLoginV3(pMPRegisterCheckLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтверждение номера телефона ПМП
         * @param {PMPRegisterCheckRequest} pMPRegisterCheckRequest Проверка мобильного телефона по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCheckV3(pMPRegisterCheckRequest: PMPRegisterCheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCheckV3(pMPRegisterCheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Подтверждение регистрации ПМП
         * @param {PMPRegisterConfirmRequest} pMPRegisterConfirmRequest Подтверждение регистрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerConfirmV3(pMPRegisterConfirmRequest: PMPRegisterConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerConfirmV3(pMPRegisterConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PMPRegisterCheckCvvRequest} [pMPRegisterCheckCvvRequest] Проверка cvv кода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerCvvV3(pMPRegisterCheckCvvRequest?: PMPRegisterCheckCvvRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterCvvV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerCvvV3(pMPRegisterCheckCvvRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Регистрация ПМП
         * @param {PMPRegisterRequestV3} pMPRegisterRequestV3 Регистрация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerV3(pMPRegisterRequestV3: PMPRegisterRequestV3, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PMPRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerV3(pMPRegisterRequestV3, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationV3Api - factory interface
 * @export
 */
export const RegistrationV3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationV3ApiFp(configuration)
    return {
        /**
         * 
         * @summary Проверка логина при регистрации ПМП
         * @param {PMPRegisterCheckLoginRequest} pMPRegisterCheckLoginRequest Проверка логина при регистрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCheckLoginV3(pMPRegisterCheckLoginRequest: PMPRegisterCheckLoginRequest, options?: any): AxiosPromise<BooleanCheckResponse> {
            return localVarFp.registerCheckLoginV3(pMPRegisterCheckLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтверждение номера телефона ПМП
         * @param {PMPRegisterCheckRequest} pMPRegisterCheckRequest Проверка мобильного телефона по смс
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCheckV3(pMPRegisterCheckRequest: PMPRegisterCheckRequest, options?: any): AxiosPromise<void> {
            return localVarFp.registerCheckV3(pMPRegisterCheckRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Подтверждение регистрации ПМП
         * @param {PMPRegisterConfirmRequest} pMPRegisterConfirmRequest Подтверждение регистрации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerConfirmV3(pMPRegisterConfirmRequest: PMPRegisterConfirmRequest, options?: any): AxiosPromise<void> {
            return localVarFp.registerConfirmV3(pMPRegisterConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PMPRegisterCheckCvvRequest} [pMPRegisterCheckCvvRequest] Проверка cvv кода
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerCvvV3(pMPRegisterCheckCvvRequest?: PMPRegisterCheckCvvRequest, options?: any): AxiosPromise<RegisterCvvV3200Response> {
            return localVarFp.registerCvvV3(pMPRegisterCheckCvvRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Регистрация ПМП
         * @param {PMPRegisterRequestV3} pMPRegisterRequestV3 Регистрация пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerV3(pMPRegisterRequestV3: PMPRegisterRequestV3, options?: any): AxiosPromise<PMPRegisterResponse> {
            return localVarFp.registerV3(pMPRegisterRequestV3, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationV3Api - object-oriented interface
 * @export
 * @class RegistrationV3Api
 * @extends {BaseAPI}
 */
export class RegistrationV3Api extends BaseAPI {
    /**
     * 
     * @summary Проверка логина при регистрации ПМП
     * @param {PMPRegisterCheckLoginRequest} pMPRegisterCheckLoginRequest Проверка логина при регистрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationV3Api
     */
    public registerCheckLoginV3(pMPRegisterCheckLoginRequest: PMPRegisterCheckLoginRequest, options?: AxiosRequestConfig) {
        return RegistrationV3ApiFp(this.configuration).registerCheckLoginV3(pMPRegisterCheckLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтверждение номера телефона ПМП
     * @param {PMPRegisterCheckRequest} pMPRegisterCheckRequest Проверка мобильного телефона по смс
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationV3Api
     */
    public registerCheckV3(pMPRegisterCheckRequest: PMPRegisterCheckRequest, options?: AxiosRequestConfig) {
        return RegistrationV3ApiFp(this.configuration).registerCheckV3(pMPRegisterCheckRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Подтверждение регистрации ПМП
     * @param {PMPRegisterConfirmRequest} pMPRegisterConfirmRequest Подтверждение регистрации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationV3Api
     */
    public registerConfirmV3(pMPRegisterConfirmRequest: PMPRegisterConfirmRequest, options?: AxiosRequestConfig) {
        return RegistrationV3ApiFp(this.configuration).registerConfirmV3(pMPRegisterConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PMPRegisterCheckCvvRequest} [pMPRegisterCheckCvvRequest] Проверка cvv кода
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationV3Api
     */
    public registerCvvV3(pMPRegisterCheckCvvRequest?: PMPRegisterCheckCvvRequest, options?: AxiosRequestConfig) {
        return RegistrationV3ApiFp(this.configuration).registerCvvV3(pMPRegisterCheckCvvRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Регистрация ПМП
     * @param {PMPRegisterRequestV3} pMPRegisterRequestV3 Регистрация пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationV3Api
     */
    public registerV3(pMPRegisterRequestV3: PMPRegisterRequestV3, options?: AxiosRequestConfig) {
        return RegistrationV3ApiFp(this.configuration).registerV3(pMPRegisterRequestV3, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistrationWebAuthnApi - axios parameter creator
 * @export
 */
export const RegistrationWebAuthnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Восстановление новых приватных и открытых ключей
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnRecoveryRequest} registrationWebAuthnRecoveryRequest Получение параметров генерации открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recovery: async (authorization: string, registrationWebAuthnRecoveryRequest: RegistrationWebAuthnRecoveryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('recovery', 'authorization', authorization)
            // verify required parameter 'registrationWebAuthnRecoveryRequest' is not null or undefined
            assertParamExists('recovery', 'registrationWebAuthnRecoveryRequest', registrationWebAuthnRecoveryRequest)
            const localVarPath = `/v1/webauthn/recovery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationWebAuthnRecoveryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение параметров для создания приватных и открытых ключей
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnAddRequest} registrationWebAuthnAddRequest Получение параметров генерации открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registration: async (authorization: string, registrationWebAuthnAddRequest: RegistrationWebAuthnAddRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('registration', 'authorization', authorization)
            // verify required parameter 'registrationWebAuthnAddRequest' is not null or undefined
            assertParamExists('registration', 'registrationWebAuthnAddRequest', registrationWebAuthnAddRequest)
            const localVarPath = `/v1/webauthn/reg/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationWebAuthnAddRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление в систему открытых ключей пользователя
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnConfirmRequest} registrationWebAuthnConfirmRequest Получение открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationConfirm: async (authorization: string, registrationWebAuthnConfirmRequest: RegistrationWebAuthnConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('registrationConfirm', 'authorization', authorization)
            // verify required parameter 'registrationWebAuthnConfirmRequest' is not null or undefined
            assertParamExists('registrationConfirm', 'registrationWebAuthnConfirmRequest', registrationWebAuthnConfirmRequest)
            const localVarPath = `/v1/webauthn/reg/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=utf-8';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registrationWebAuthnConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Инициализация процесса добавления ключей пользователя
         * @param {string} authorization Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationToken: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('registrationToken', 'authorization', authorization)
            const localVarPath = `/v1/webauthn/reg/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationWebAuthnApi - functional programming interface
 * @export
 */
export const RegistrationWebAuthnApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationWebAuthnApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Восстановление новых приватных и открытых ключей
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnRecoveryRequest} registrationWebAuthnRecoveryRequest Получение параметров генерации открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recovery(authorization: string, registrationWebAuthnRecoveryRequest: RegistrationWebAuthnRecoveryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationWebAuthnResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recovery(authorization, registrationWebAuthnRecoveryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение параметров для создания приватных и открытых ключей
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnAddRequest} registrationWebAuthnAddRequest Получение параметров генерации открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registration(authorization: string, registrationWebAuthnAddRequest: RegistrationWebAuthnAddRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationWebAuthnResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registration(authorization, registrationWebAuthnAddRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавление в систему открытых ключей пользователя
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnConfirmRequest} registrationWebAuthnConfirmRequest Получение открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrationConfirm(authorization: string, registrationWebAuthnConfirmRequest: RegistrationWebAuthnConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationWebAuthnConfirmResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrationConfirm(authorization, registrationWebAuthnConfirmRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Инициализация процесса добавления ключей пользователя
         * @param {string} authorization Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrationToken(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationWebAuthnTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrationToken(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationWebAuthnApi - factory interface
 * @export
 */
export const RegistrationWebAuthnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationWebAuthnApiFp(configuration)
    return {
        /**
         * 
         * @summary Восстановление новых приватных и открытых ключей
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnRecoveryRequest} registrationWebAuthnRecoveryRequest Получение параметров генерации открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recovery(authorization: string, registrationWebAuthnRecoveryRequest: RegistrationWebAuthnRecoveryRequest, options?: any): AxiosPromise<RegistrationWebAuthnResponse> {
            return localVarFp.recovery(authorization, registrationWebAuthnRecoveryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение параметров для создания приватных и открытых ключей
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnAddRequest} registrationWebAuthnAddRequest Получение параметров генерации открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registration(authorization: string, registrationWebAuthnAddRequest: RegistrationWebAuthnAddRequest, options?: any): AxiosPromise<RegistrationWebAuthnResponse> {
            return localVarFp.registration(authorization, registrationWebAuthnAddRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление в систему открытых ключей пользователя
         * @param {string} authorization Access Token
         * @param {RegistrationWebAuthnConfirmRequest} registrationWebAuthnConfirmRequest Получение открытых ключей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationConfirm(authorization: string, registrationWebAuthnConfirmRequest: RegistrationWebAuthnConfirmRequest, options?: any): AxiosPromise<RegistrationWebAuthnConfirmResponse> {
            return localVarFp.registrationConfirm(authorization, registrationWebAuthnConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Инициализация процесса добавления ключей пользователя
         * @param {string} authorization Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationToken(authorization: string, options?: any): AxiosPromise<RegistrationWebAuthnTokenResponse> {
            return localVarFp.registrationToken(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationWebAuthnApi - object-oriented interface
 * @export
 * @class RegistrationWebAuthnApi
 * @extends {BaseAPI}
 */
export class RegistrationWebAuthnApi extends BaseAPI {
    /**
     * 
     * @summary Восстановление новых приватных и открытых ключей
     * @param {string} authorization Access Token
     * @param {RegistrationWebAuthnRecoveryRequest} registrationWebAuthnRecoveryRequest Получение параметров генерации открытых ключей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationWebAuthnApi
     */
    public recovery(authorization: string, registrationWebAuthnRecoveryRequest: RegistrationWebAuthnRecoveryRequest, options?: AxiosRequestConfig) {
        return RegistrationWebAuthnApiFp(this.configuration).recovery(authorization, registrationWebAuthnRecoveryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение параметров для создания приватных и открытых ключей
     * @param {string} authorization Access Token
     * @param {RegistrationWebAuthnAddRequest} registrationWebAuthnAddRequest Получение параметров генерации открытых ключей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationWebAuthnApi
     */
    public registration(authorization: string, registrationWebAuthnAddRequest: RegistrationWebAuthnAddRequest, options?: AxiosRequestConfig) {
        return RegistrationWebAuthnApiFp(this.configuration).registration(authorization, registrationWebAuthnAddRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление в систему открытых ключей пользователя
     * @param {string} authorization Access Token
     * @param {RegistrationWebAuthnConfirmRequest} registrationWebAuthnConfirmRequest Получение открытых ключей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationWebAuthnApi
     */
    public registrationConfirm(authorization: string, registrationWebAuthnConfirmRequest: RegistrationWebAuthnConfirmRequest, options?: AxiosRequestConfig) {
        return RegistrationWebAuthnApiFp(this.configuration).registrationConfirm(authorization, registrationWebAuthnConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Инициализация процесса добавления ключей пользователя
     * @param {string} authorization Access Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationWebAuthnApi
     */
    public registrationToken(authorization: string, options?: AxiosRequestConfig) {
        return RegistrationWebAuthnApiFp(this.configuration).registrationToken(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение информации о версии приложении в RuStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/version/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение информации о версии приложении в RuStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение информации о версии приложении в RuStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionInfo(options?: any): AxiosPromise<VersionInfoResponse> {
            return localVarFp.getVersionInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     * 
     * @summary Получение информации о версии приложении в RuStore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public getVersionInfo(options?: AxiosRequestConfig) {
        return VersionApiFp(this.configuration).getVersionInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


